/**
 * JS version of the python UserException error that the backend uses to show warnings to the user.
 *
 * When `raiseWarnings` is passed on the config to our API service, this exception type will be raised.
 *
 * Example api call that will raise a UserException if warnings are returned by the backend:
 *
 * import api from '@shared/services/api.js';
 * api.post('/some-url', payload, {
 *   raiseWarnings: true,
 * });
 *
 * The `messages` property will contain all the messages returned by the backend.
 */
export class UserException extends Error {
  constructor(messages, ...params) {
    super(...params);
    this.messages = messages;
  }
}

export function isUserException(error) {
  return error instanceof UserException;
}

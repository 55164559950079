<template>
  <b-modal
    :id="id"
    v-bind="$attrs"
    :body-class="[
      { 'px-base-modal__body--empty': !title },
      'px-base-modal__body',
      `${$attrs['body-class'] || ''}`,
    ]"
    :footer-class="`px-base-modal__footer ${$attrs['footer-class'] || ''}`"
    :header-class="[{ 'with-controls': showControls }, 'px-base-modal__header']"
    :modal-class="`px-base-modal ${modalClass}`"
    v-on="$listeners"
  >
    <template #modal-title>
      <div class="px-base-modal__title">
        <px-loading-wrapper :loading="isLoading" type="row">
          <div class="d-flex">
            <h2>{{ title }}</h2>
            <div v-if="showTabs && !isLoading" class="ml-3">
              <slot name="tabs"></slot>
            </div>
          </div>
          <div class="px-base-modal__title-subtitle mt-1">
            <slot name="subtitle"></slot>
          </div>
        </px-loading-wrapper>
        <div v-if="showControls" class="px-base-modal__title-controls">
          <slot name="controls"></slot>
        </div>
      </div>
      <slot name="extraHeaderControls"></slot>
    </template>

    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot v-bind="scope" :name="slot" />
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'PxBaseModal',
  props: {
    id: {
      required: true,
      type: String,
    },
    title: {
      default: '',
      required: false,
      type: String,
    },
    showControls: {
      default: false,
      required: false,
      type: Boolean,
    },
    showTabs: {
      default: false,
      required: false,
      type: Boolean,
    },
    isLoading: {
      required: false,
      default: false,
      type: Boolean,
    },
    modalClass: {
      default: '',
      required: false,
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/variables';

.px-base-modal {
  ::v-deep &__header.with-controls .modal-title {
    width: 100%;
  }

  &__title {
    display: flex;
    width: 100%;
    gap: 16px;
    align-items: baseline;

    h2,
    h3,
    ::v-deep &-subtitle h3 {
      color: $polly-dark-blue;
    }
  }

  &__title-controls {
    margin-left: auto;
  }

  &__footer {
    display: flex;
    justify-content: center;
  }

  ::v-deep &__header,
  ::v-deep &__body,
  ::v-deep &__footer {
    padding: 28px;
  }

  ::v-deep &__header {
    padding-bottom: 0;
  }

  ::v-deep &__body--empty {
    padding-top: 8px;
  }

  ::v-deep &__footer {
    padding-top: 0;
  }
}
</style>

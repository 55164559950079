import { publishTypeChoices, versionsFromChoices } from '@shared/constants';

export default {
  namespaced: true,
  state: {
    versionsFrom: versionsFromChoices.pastWeek,
    savedPublishType: publishTypeChoices.all,
    savedDateRange: null,
  },
  getters: {
    versionsFrom: state => state.versionsFrom,
    savedDateRange: state => state.savedDateRange,
    savedPublishType: state => state.savedPublishType,
  },
  mutations: {
    setVersionsFrom(state, newVersionsFrom) {
      state.versionsFrom = newVersionsFrom;
    },
    setSavedDateRange(state, newSavedDateRange) {
      state.savedDateRange = newSavedDateRange;
    },
    setSavedPublishType(state, newSavedPublishType) {
      state.savedPublishType = newSavedPublishType;
    },
  },
  actions: {
    async updateVersionsFrom({ commit }, newVersionsFrom) {
      commit('setVersionsFrom', newVersionsFrom);
    },
    async updateSavedDateRange({ commit }, savedDateRange) {
      commit('setSavedDateRange', savedDateRange);
    },
    async updateSavedPublishType({ commit }, newSavedPublishType) {
      commit('setSavedPublishType', newSavedPublishType);
    },
  },
};

<template>
  <b-card
    v-bind="$attrs"
    :class="['px-card', { 'px-card--no-min-height': noMinHeight }]"
  >
    <px-loading-wrapper
      :columns="loadingOptions.columns"
      :loading="isLoading"
      :rows="loadingOptions.rows"
      :title="loadingOptions.title"
      :type="loadingOptions.type"
    >
      <div v-if="!noHeader" class="px-card__header">
        <div class="px-card__header-main">
          <div class="px-card__heading-group">
            <h2 class="px-card__header__title">
              <span v-if="title">{{ title }}</span>
              <slot v-else name="title"></slot>
            </h2>

            <div class="px-card__header__subtitle">
              <h3 v-if="subtitle">
                {{ subtitle }}
              </h3>
              <slot v-else name="subtitle"></slot>
            </div>
          </div>

          <div class="px-card__header__controls">
            <slot name="controls"></slot>
          </div>
        </div>

        <div
          v-if="description || $slots.description"
          class="px-card__header-secondary px-card__description"
        >
          <h4 v-if="description && !$slots.description">
            {{ description }}
          </h4>

          <slot v-else name="description"></slot>
        </div>
      </div>

      <div class="px-card__body" :class="{ 'px-card__body--full': noHeader }">
        <slot></slot>
      </div>
    </px-loading-wrapper>
  </b-card>
</template>

<script>
export default {
  name: 'PxCard',
  props: {
    description: {
      default: '',
      required: false,
      type: String,
    },
    isLoading: {
      default: false,
      type: Boolean,
    },
    loadingOptions: {
      default: () => ({
        columns: 3,
        type: '',
        rows: 4,
        title: false,
      }),
      type: Object,
    },
    noHeader: {
      default: false,
      type: Boolean,
    },
    noMinHeight: {
      default: false,
      type: Boolean,
    },
    title: {
      default: '',
      type: String,
    },
    subtitle: {
      default: '',
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/variables';
@import '@shared/styles/mixins/main';

.px-card {
  box-shadow: $polly-default-shadow;
  min-height: 140px;
  padding: 24px;
  margin-bottom: 24px;

  &--no-min-height {
    min-height: unset;
  }

  ::v-deep .loaded-content {
    height: 100%;
  }

  ::v-deep .wide-pill {
    border-color: $polly-light-blue;
    border-radius: 16px;
    box-sizing: border-box;
    padding: 0 1rem;

    &.warning {
      border-color: $warning;
      color: $warning;

      &:hover {
        background-color: $warning;
        color: $white;
      }
    }
  }

  &__description {
    color: $polly-dark-blue;
    margin-top: 4px;
  }

  &__body--full {
    height: 100%;
  }

  &__heading-group {
    display: flex;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    min-height: 2rem;
    justify-content: space-between;
    margin-bottom: 12px;

    &__title {
      display: inherit;
      line-height: 20px;
      margin-bottom: 0;
      margin-right: 12px;
    }

    &__subtitle {
      display: flex;

      & ::v-deep h3 {
        margin: auto 0;
      }
    }

    &__controls {
      margin: auto 0 auto auto;

      ::v-deep > button,
      ::v-deep > input {
        &:not(:first-child) {
          margin-left: 12px;
        }
      }
    }
  }

  &__header-main {
    display: flex;
    width: 100%;
  }

  ::v-deep em {
    @include text-emphasis-light;
  }
}
</style>

import api from '@shared/services/api.js';
import { showSuccessMessage } from '@shared/utils/notifier.js';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import * as mutationTypes from './mutationTypes.js';

export default {
  namespaced: true,
  state: () => ({
    isDownloading: false,
    commitmentsGroups: [
      {
        name: 'Commitments',
        withoutIndent: true,
        selected: null,
        options: [
          { text: 'Mandatory', value: '1' },
          { text: 'Best Efforts', value: '2' },
          { text: 'Bulk Bids', value: '4' },
        ],
      },
    ],
    bidDueDateGroups: [
      {
        name: 'Bid Due Date',
        withoutIndent: true,
        selected: null,
        options: [
          { text: 'Bid Due Date Set', value: 'date_set' },
          { text: 'No Bid Due Date Set', value: 'date_not_set' },
        ],
      },
    ],
    selectedPartner: { partner_id: null, servicer_id: null, retained: false },
  }),
  getters: {
    commitmentsGroups: state => state.commitmentsGroups,
    selectedPartner: state => state.selectedPartner,
    bidDueDateGroups: state => state.bidDueDateGroups,
    isDownloading: state => state.isDownloading,
  },
  mutations: {
    [mutationTypes.SET_COMMITMENT_GROUPS](state, groups) {
      state.commitmentsGroups = groups;
    },
    [mutationTypes.SET_SELECTED_PARTNER](state, data) {
      state.selectedPartner = data;
    },
    [mutationTypes.SET_BID_DUE_DATE_GROUPS](state, groups) {
      state.bidDueDateGroups = groups;
    },
    [mutationTypes.SET_IS_DOWNLOADING_STATE](state, payload) {
      state.isDownloading = payload;
    },
  },
  actions: {
    async downloadActiveBids({ commit, dispatch }, loanIds) {
      commit(mutationTypes.SET_IS_DOWNLOADING_STATE, true);

      try {
        const { task_id } = await api.get(
          `/sell/api/active_bids_download?loan_ids=${loanIds}`,
        );
        showSuccessMessage('Active bids file is generating...');

        await dispatch(
          'asyncTasks/getStreamData',
          {
            taskId: task_id,
            fileName: `active_bids_${new Date().toLocaleDateString()}.xlsx`,
          },
          { root: true },
        );
      } catch (error) {
        onErrorHandler(error, 'sell-pipeline-downloadActiveBids');
      } finally {
        commit(mutationTypes.SET_IS_DOWNLOADING_STATE, false);
      }
    },
  },
};

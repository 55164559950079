<template>
  <div class="px-date-range-picker">
    <b-dropdown
      ref="pickerDropdown"
      class="m-0 px-date-range-picker-button"
      no-flip
      :right="right"
      toggle-class="text-left border-primary"
      variant="outline-primary"
    >
      <template #button-content>
        <div class="d-flex align-items-center">
          <px-icon icon="calendar"></px-icon>
          <span class="mx-2">{{ title }}</span>
          <px-icon icon="chevron-down" class="ml-auto"></px-icon>
        </div>
      </template>
      <b-dropdown-form>
        <b-container>
          <b-row class="picker-header">
            <b-col class="text-center" md="4">{{ startDayText }}</b-col>
            <b-col class="text-center" md="4">
              <i class="fas fa-long-arrow-alt-right"></i>
              <div>{{ daysAmount ? daysAmount : 'Selected Days' }}</div>
            </b-col>
            <b-col class="text-center" md="4">{{ endDayText }}</b-col>
          </b-row>
          <b-form-row>
            <v-date-picker
              v-model="dateRange"
              color="blue"
              :columns="$screens({ default: 1, lg: 2 })"
              is-inline
              is-range
              :step="1"
              :value="null"
              @dayclick="setStartDate"
              @input="updateLayout"
            />
          </b-form-row>
          <b-row align-h="end">
            <b-col md="4">
              <b-button class="btn-space" @click="clear">Clear</b-button>
              <b-button
                class="btn-space"
                :disabled="!isDateRangeValid"
                variant="primary"
                @click="apply"
              >
                Apply
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-dropdown-form>
    </b-dropdown>
  </div>
</template>

<script>
import Pluralize from 'pluralize';
import formatISO from 'date-fns/formatISO';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';

import { storageDateRangeKey } from '@shared/helpers/dateRange.js';

export default {
  name: 'PxDateRangePicker',
  model: {
    prop: 'range',
    event: 'change',
  },
  props: {
    range: { type: Object, default: null },
    right: { type: Boolean, default: false },
  },
  data() {
    return {
      dateRange: this.range,
      dirty: false,
    };
  },
  computed: {
    isDateRangeValid() {
      return Boolean(this.dateRange?.start && this.dateRange?.end);
    },
    startDayText() {
      return this.dateRange?.start
        ? this.replaceUnderScoreWithSpace(this.dateRange.start.toDateString())
        : 'Start Date';
    },
    endDayText() {
      return this.dateRange?.end
        ? this.replaceUnderScoreWithSpace(this.dateRange.end.toDateString())
        : 'End Date';
    },
    daysAmount() {
      if (!this.isDateRangeValid) return null;

      const oneDay = 24 * 60 * 60 * 1000;
      const days =
        Math.floor(
          Math.abs(
            this.dateRange.start.getTime() - this.dateRange.end.getTime(),
          ) / oneDay,
        ) + 1;
      return `${days} ${Pluralize('Day', days)}`;
    },
    title() {
      if (!this.isDateRangeValid) return 'Date Range';

      return `${this.dateRange.start.toLocaleDateString()} - ${this.dateRange.end.toLocaleDateString()}`;
    },
  },
  methods: {
    replaceUnderScoreWithSpace(string) {
      return string.replace(/^[A-Za-z]+ /g, '');
    },
    setStartDate(selection) {
      this.dirty = true;
      if (!this.dateRange?.start) {
        this.$emit('change', {
          start: selection.date,
          end: this.dateRange?.end,
        });
      }
    },
    updateLayout() {
      if (!this.dirty || !this.dateRange.end) {
        return;
      }
      this.$refs.pickerDropdown.hide(true);
      this.$refs.pickerDropdown.show();
    },
    clear() {
      this.rangeClear();
      this.emit();
      this.$refs.pickerDropdown.hide(true);
    },
    apply() {
      this.dateRange.start = startOfDay(this.dateRange.start);
      this.dateRange.end = endOfDay(this.dateRange.end);
      this.emit();
      this.$refs.pickerDropdown.hide(true);
    },
    emit() {
      this.$emit('change', {
        start: this.dateRange?.start,
        end: this.dateRange?.end,
      });
      const dateRange = {
        startDate: this.dateRange?.start
          ? formatISO(this.dateRange.start)
          : null,
        endDate: this.dateRange?.end ? formatISO(this.dateRange.end) : null,
      };
      this.$storage.set(storageDateRangeKey(), {
        start: dateRange.startDate,
        end: dateRange.endDate,
      });
      this.$emit('dateFilterChanged', dateRange);
    },
    rangeClear() {
      if (this.dateRange) {
        this.dateRange.start = null;
        this.dateRange.end = null;
      }
    },
  },
};
</script>

<style lang="scss">
@import '@shared/styles/variables';
.px-date-range-picker {
  div {
    font-family: $calendar-font-family;
    font-weight: 400;
  }

  .vc-container {
    border: none;
  }
  .b-dropdown-form {
    padding: 0;
  }
  .dropdown-menu {
    padding: 0;
  }
  .picker-header {
    margin-top: 10px;
  }
  .container {
    padding: 20px 30px 30px;
  }
}
</style>

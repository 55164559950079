import api from '@shared/services/api.js';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';

export default {
  getCouponRates() {
    const url = '/api/v1/security_coupon_rates/';
    try {
      return api.get(url);
    } catch (error) {
      onErrorHandler(error, 'coupon-rates');
    }
  },
};

<template>
  <div
    v-if="hasToolTip"
    v-b-popover.hover.top.html="{
      customClass: 'has-previous-value',
      content: titleFormatted,
    }"
    :class="cssClasses"
  >
    <slot>
      {{ value }}
    </slot>
  </div>
  <span v-else :class="cssClasses">
    <slot>
      {{ value }}
    </slot>
  </span>
</template>

<script>
import {
  STATUS_INDICATOR_POSITIONS,
  STATUS_INDICATOR_TYPES,
} from '@shared/constants';

export default {
  name: 'PxStatusIndicator',
  props: {
    value: { default: '', type: String },
    hasToolTip: { default: false, type: Boolean },
    property: { default: '', type: String },
    field: { default: '', type: String },
    previousValue: { default: '', type: [String, Number] },
    enabled: {
      default: false,
      type: Boolean,
    },
    position: {
      default: STATUS_INDICATOR_POSITIONS.AFTER,
      validator: function (value) {
        return Object.values(STATUS_INDICATOR_POSITIONS).includes(value);
      },
    },
    statusType: {
      default: STATUS_INDICATOR_TYPES.CHANGED,
      validator: function (value) {
        return Object.values(STATUS_INDICATOR_TYPES).includes(value);
      },
    },
  },
  computed: {
    titleFormatted() {
      return `<strong>Previous ${this.property} ${this.field}:</strong> ${this.previousValue}`;
    },
    cssClasses() {
      return {
        'px-change-indicator': true,
        'px-change-indicator--enabled': this.enabled || this.hasToolTip,
        'px-change-indicator--after':
          this.position === STATUS_INDICATOR_POSITIONS.AFTER,
        'px-change-indicator--before':
          this.position === STATUS_INDICATOR_POSITIONS.BEFORE,
        'px-change-indicator--active':
          this.statusType === STATUS_INDICATOR_TYPES.ACTIVE,
        'px-change-indicator--complete':
          this.statusType === STATUS_INDICATOR_TYPES.COMPLETE,
        'px-change-indicator--changed':
          this.statusType === STATUS_INDICATOR_TYPES.CHANGED &&
          !this.hasToolTip,
        'px-change-indicator--previous': this.hasToolTip,
        'fit-content': this.hasToolTip,
      };
    },
  },
};
</script>

<style lang="scss">
@import '@shared/styles/variables';

.px-change-indicator {
  display: flex;
  align-items: center;
  &.fit-content {
    width: fit-content;
  }

  &--after::after {
    content: '';
    margin-left: 7px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: none;
    flex-shrink: 0;
  }

  &--after#{&}--enabled::after {
    display: inline-block;
  }
  &--after#{&}--changed::after {
    background-color: #f1b959;
  }
  &--after#{&}--previous::after {
    background-color: $polly-teal;
    padding-left: 5px;
  }
  &--after#{&}--active::after {
    background-color: $primary;
  }

  &--before::before {
    content: '';
    margin-right: 7px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: none;
  }

  &--before#{&}--enabled::before {
    display: inline-block;
  }
  &--before#{&}--active::before {
    background-color: $primary;
  }
  &--before#{&}--complete::before {
    background-color: $polly-green;
  }
  &--before#{&}--changed::before {
    background-color: #f1b959;
  }
  &--before#{&}--previous::before {
    background-color: $polly-teal;
  }
}
.has-previous-value {
  inline-size: fit-content;
  &.b-popover.popover {
    box-shadow: 0px 0px 5px $polly-teal;

    &.bs-popover-top {
      .arrow {
        display: none;
      }
    }
  }
}
</style>

import { filterEntitiesByQuery } from '@shared/utils/helpers.js';
import { getCalendarDate } from '@shared/utils/converters.js';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import { PERMISSIONS, ROLES } from '@shared/constants';
import api from '@shared/services/api.js';
import {
  getMember,
  getMembers,
  removeMember,
  updateUserRoles,
} from '@useradmin/services/api/members.js';
import {
  getInvitations,
  inviteMember,
  deleteInvite,
} from '@useradmin/services/api/invitations.js';

const state = {
  currentUser: {},
  currentMember: {
    isLoading: true,
    data: {},
  },
  members: {
    data: {
      active: [],
      all: [],
      inactive: [],
      pending: [],
    },
    isLoading: true,
  },
};

const getters = {
  currentUser(state) {
    return state.currentUser;
  },
  currentUserId(state) {
    return state.currentUser.id;
  },
  currentMember(state) {
    return state.currentMember;
  },
  members(state) {
    return state.members;
  },
  membersByQuery:
    state =>
    (query = '') => {
      return {
        ...state.members,
        data: {
          active: filterEntitiesByQuery(query, state.members.data.active),
          pending: filterEntitiesByQuery(query, state.members.data.pending),
        },
      };
    },
};

const mutations = {
  setCurrentMember(state, payload) {
    state.currentMember = payload;
  },
  setMembers(state, payload) {
    state.members = payload;
  },
  setCurrentUser(state, payload) {
    state.currentUser = payload;
  },
};

const actions = {
  async updateCurrentMemberRoles({ commit, state }, roles) {
    const currentMemberData = { ...state.currentMember.data };
    const memberId = currentMemberData.id;
    await updateUserRoles(memberId, roles);
    const member = await getMember(memberId);
    commit('setCurrentMember', {
      isLoading: false,
      data: { ...currentMemberData, roles: member.roles },
    });
  },
  async inviteMember(
    { commit, dispatch },
    { email, roles, channels, customParameters },
  ) {
    commit('setMembers', { isLoading: true });

    try {
      await inviteMember(email, roles, channels, customParameters);

      dispatch('getMembers');
    } catch (error) {
      onErrorHandler(error, 'useradmin-send-invitation');
    } finally {
      commit('setMembers', { isLoading: false });
    }
  },
  async retractInvite({ commit, dispatch }, { userId, inviteId }) {
    try {
      commit('setMembers', { isLoading: true });

      await deleteInvite(userId, inviteId);

      dispatch('getMembers');
    } catch (error) {
      onErrorHandler(error, 'useradmin-retract-invite');
    } finally {
      commit('setMembers', { isLoading: false });
    }
  },
  async getMember({ commit }, id) {
    try {
      commit('setCurrentMember', { isLoading: true });
      const currentMember = await getMember(id);

      commit('setCurrentMember', {
        isLoading: false,
        data: {
          ...currentMember,
          dateJoinedRelative: getCalendarDate(currentMember.date_joined),
          name: `${currentMember.first_name} ${currentMember.last_name}`,
          lastLoginRelative: getCalendarDate(currentMember.last_login),
          permissions: getMemberPermissions(currentMember),
        },
      });
    } catch (error) {
      onErrorHandler(error, 'get-member');
    }
  },
  async getMembers({ commit }) {
    commit('setMembers', { isLoading: true });

    try {
      const [members, pending] = await Promise.all([
        getMembers(),
        getInvitations(),
      ]);

      const allMembers = members.users.map(member => {
        return {
          ...member,
          dateJoinedRelative: getCalendarDate(member.date_joined),
          name: `${member.first_name} ${member.last_name}`,
          lastLoginRelative: getCalendarDate(member.last_login),
          permissions: getMemberPermissions(member),
        };
      });

      const active = allMembers.filter(member => member.is_active);

      const inactive = allMembers.filter(member => !member.is_active);

      const pendingInvitations = pending.invitations.map(invite => {
        return {
          ...invite,
          dateInvitedRelative: getCalendarDate(invite.invitation_date),
        };
      });

      commit('setMembers', {
        isLoading: false,
        data: {
          active,
          all: allMembers,
          inactive,
          pending: pendingInvitations,
        },
      });
    } catch (error) {
      commit('setMembers', { isLoading: false });
      onErrorHandler(error, 'useradmin-get-members');
    }
  },
  getCurrentMember({ commit, getters }, payload) {
    commit('setCurrentMember', { isLoading: true });

    const currentMember = getters.members.data.all.find(member => {
      return member.id == payload;
    });

    commit('setCurrentMember', {
      isLoading: false,
      data: currentMember,
    });
  },
  async getCurrentUser({ commit }) {
    try {
      const currentUser = await api.get('/company/api/user/');

      const is_approver = currentUser?.roles.includes(ROLES.PE_APPROVER);

      const currentUserObject = {
        ...currentUser,
        is_approver,
      };
      commit('setCurrentUser', currentUserObject);
    } catch (error) {
      onErrorHandler(error, 'get-current-user');
    }
  },
  async removeMember({ commit, dispatch }, id) {
    try {
      commit('setMembers', { isLoading: true });

      await removeMember(id);

      dispatch('getMembers');
    } catch (error) {
      onErrorHandler(error, 'useradmin-remove-member');
    } finally {
      commit('setMembers', { isLoading: false });
    }
  },
};

const getMemberPermissions = member => {
  const memberKeys = Object.keys(member);

  const permissions = memberKeys.map(key => {
    if (Object.keys(PERMISSIONS).indexOf(key) >= 0 && member[key]) {
      return PERMISSIONS[key];
    }
  });

  if (member.is_admin) {
    permissions.unshift('Admin');
  }

  return permissions.filter(key => key !== undefined);
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<!-- eslint-disable vue/no-mutating-props -->
<template>
  <px-card
    class="px-card-table"
    :is-loading="isLoading"
    :loading-options="loadingOptions"
    :no-header="noHeader"
    :subtitle="subtitle"
    :title="title"
  >
    <template #controls>
      <px-card-table-filter
        v-if="showFilter"
        v-model="filterQuery"
        @input="handleSearchInput"
      ></px-card-table-filter>
    </template>

    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot v-bind="scope" :name="slot" />
    </template>

    <b-table
      v-bind="$attrs"
      class="temporary"
      :current-page.sync="currentPage"
      :empty-text="emptyText"
      :fields="entitiesFields"
      :items="entitiesItems"
      :per-page.sync="tableOptions.pageSize"
      show-empty
      :show-pagination="showPagination"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :thead-class="theadClass"
      v-on="$listeners"
    >
      <template #empty="scope">
        <em v-if="filterQuery.length">No results match your search</em>
        <em v-else-if="!filterQuery.length && scope.emptyText">
          {{ scope.emptyText }}
        </em>
        <em v-else>There are no records to show</em>
      </template>

      <template #head()="data">
        {{ data.label }}
        <px-icon
          v-if="data.field.sortable"
          alt="sort icon"
          class="sort-icon"
          focusable="false"
          :icon="sortIcon(data)"
          :inline="false"
          role="presentation"
          solid
        ></px-icon>
      </template>

      <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
        <slot v-bind="scope" :name="slot" />
      </template>
    </b-table>
    <px-table-pagination
      v-if="showPagination && totalRows > tableOptions.pageSize"
      :allow-select-page-size="pageSizeChangeEnabled"
      :page-index="currentPage"
      :page-size.sync="tableOptions.pageSize"
      :total="totalRows"
      @change="paginationChanged"
    ></px-table-pagination>
  </px-card>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import { DEFAULT_TABLE_OPTIONS } from '@shared/constants';
import PxTablePagination from '@shared/components/PxTablePagination.vue';
import tableMixin from '@pe/mixins/tableMixin.js';

export default {
  name: 'PxCardTable',
  components: {
    PxTablePagination,
  },
  mixins: [tableMixin],
  props: {
    emptyText: {
      default: '',
      required: false,
      type: String,
    },
    entitiesFields: {
      default: () => [],
      type: Array,
      required: true,
    },
    entities: {
      default: () => [],
      type: Array,
    },
    isLoading: {
      default: false,
      type: Boolean,
    },
    loadingOptions: {
      default: () => ({
        columns: 3,
        type: 'table',
        rows: 4,
        title: false,
      }),
      type: Object,
    },
    noHeader: {
      default: false,
      type: Boolean,
    },
    pageSizeChangeEnabled: {
      default: false,
      type: Boolean,
    },
    showFilter: {
      default: false,
      type: Boolean,
    },
    showPagination: {
      default: true,
      type: Boolean,
    },
    subtitle: {
      default: '',
      type: String,
    },
    tableOptions: {
      default: () => {
        return { ...DEFAULT_TABLE_OPTIONS };
      },
      required: false,
      type: Object,
    },
    title: {
      default: '',
      type: String,
    },
  },
  data() {
    return {
      filteredEntities: () => [],
      currentPage: 1,
      filterQuery: '',
    };
  },
  computed: {
    entitiesItems() {
      return this.showFilter && this.filterQuery
        ? this.filteredEntities
        : this.entities;
    },
    sortBy: {
      get() {
        return this.tableOptions.sortBy || this.entitiesFields[0].key;
      },
      set(value) {
        this.tableOptions.sortBy = value;
      },
    },
    sortDesc: {
      get() {
        return this.tableOptions?.sortDesc;
      },
      set(value) {
        this.tableOptions.sortDesc = value;
      },
    },
    theadClass() {
      return (this.showFilter &&
        this.filterQuery &&
        !this.filteredEntities.length) ||
        !this.entities?.length
        ? 'no-header'
        : '';
    },
    totalRows() {
      return this.showFilter && this.filterQuery
        ? this.filteredEntities.length
        : this.entities.length;
    },
  },
  watch: {
    /** filterQuery
     *
     * Return values based on a simple case insensitive string matcH for quick
     * item filtering
     */
    filterQuery(query) {
      this.filteredEntities = this.entities.filter(entity =>
        Object.keys(entity).some(
          key =>
            String(entity[key])
              .toLocaleLowerCase()
              .indexOf(query.toLocaleLowerCase()) >= 0,
        ),
      );
    },
  },
  methods: {
    handleSearchInput(event) {
      this.$emit('searchInput', event);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/variables';

.px-card-table {
  ::v-deep .table {
    margin-bottom: 0;
    word-break: break-word;
  }

  /**
  * TODO: This removes a key visual accessibility feature per review feedback.
  * Add visual accessibiilty styling in at global theme
  */
  ::v-deep th:focus {
    outline: none;
  }

  ::v-deep .b-table-empty-row {
    color: $gray;
    margin: 0;
    text-align: center;

    td {
      border-width: 0;
    }
  }

  ::v-deep .px-table-pagination {
    height: unset;
  }

  ::v-deep td {
    vertical-align: middle;
  }
  ::v-deep .table.b-table > thead > tr [aria-sort] {
    background-image: none;
  }
}
</style>

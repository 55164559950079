import querySvc from '@shared/services/dynamicQuery.js';

const state = {
  metaFields: null,
};

const getters = {
  metaFields: state => state.metaFields || [],
};

const mutations = {
  clearMeta(state) {
    state.metaFields = null;
  },
  setMetaFields(state, metaFields) {
    state.metaFields = metaFields;
  },
};

const actions = {
  clearMeta({ commit }) {
    commit('clearMeta');
  },
  async getMeta({ commit }) {
    const meta = await querySvc.get('Bid');

    commit('setMetaFields', meta.fields);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <b-form-group
    v-bind="$attrs"
    class="px-form-group"
    :class="{ inline: inline }"
    content-class="px-form-group__content"
    :label-class="['px-form-group__label', labelClass]"
    :label-cols="labelCols"
    @on="$listeners"
  >
    <div
      class="px-form-group__children"
      :class="{
        'px-form-group__children--flex':
          postLabel ||
          $scopedSlots.postLabel ||
          preLabel ||
          $scopedSlots.preLabel,
        'px-form-group__children--vertical': alignVertical,
      }"
    >
      <div
        v-if="preLabel || $scopedSlots.preLabel"
        class="px-form-group__pre-label"
      >
        <span v-if="preLabel && !$scopedSlots.prelabel">{{ preLabel }}</span>
        <slot name="preLabel"></slot>
      </div>

      <slot></slot>

      <div
        v-if="postLabel || $scopedSlots.postLabel"
        class="px-form-group__post-label"
      >
        <span v-if="postLabel && !$scopedSlots.postLabel">{{ postLabel }}</span>
        <slot name="postLabel"></slot>
      </div>
    </div>

    <div
      v-if="showCharacterCount && characterCountCurrent && characterCountMax"
      class="px-form-group__character-count"
    >
      {{ `${characterCountCurrent.length}/${characterCountMax}` }}
    </div>

    <template v-if="validFeedback" #valid-feedback>
      <div class="text-right" :class="validFeedbackClass">
        {{ validFeedback }}
      </div>
    </template>

    <template v-if="invalidFeedback" #invalid-feedback>
      <div class="text-right" :class="invalidFeedbackClass">
        {{ invalidFeedback }}
      </div>
    </template>
  </b-form-group>
</template>

<script>
const AVAILABLE_COLOR_VARIANTS = ['primary', 'warning', 'danger'];
export default {
  name: 'PxFormGroup',
  props: {
    alignVertical: {
      default: false,
      required: false,
      type: Boolean,
    },
    characterCountCurrent: {
      default: '',
      required: false,
      type: String,
    },
    characterCountMax: {
      default: undefined,
      required: false,
      type: Number,
    },
    inline: {
      default: false,
      required: false,
      type: Boolean,
    },
    labelClass: {
      default: '',
      required: false,
      type: String,
    },
    labelCols: {
      default: '5',
      required: false,
      type: [Number, String],
    },
    preLabel: {
      default: '',
      required: false,
      type: String,
    },
    postLabel: {
      default: '',
      required: false,
      type: String,
    },
    validFeedbackVariant: {
      default: 'primary',
      type: String,
      validator: function (value) {
        return AVAILABLE_COLOR_VARIANTS.indexOf(value) !== -1;
      },
    },
    invalidFeedbackVariant: {
      default: 'danger',
      type: String,
      validator: function (value) {
        return AVAILABLE_COLOR_VARIANTS.indexOf(value) !== -1;
      },
    },
    showCharacterCount: {
      default: false,
      required: false,
      type: Boolean,
    },
  },
  computed: {
    validFeedback() {
      return this.$attrs['valid-feedback'];
    },
    invalidFeedback() {
      return this.$attrs['invalid-feedback'];
    },
    validFeedbackClass() {
      return `text-${this.validFeedbackVariant}`;
    },
    invalidFeedbackClass() {
      return `text-${this.invalidFeedbackVariant}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/variables';
.px-form-group {
  display: flex;
  margin: 4px 0;
  padding: 4px 0;

  ::v-deep .text-warning {
    color: $warning !important;
  }

  ::v-deep .col-form-label {
    padding: 0;
  }

  ::v-deep &:has(.px-form-group__children--vertical) .px-form-group__label {
    margin-bottom: auto;
  }

  ::v-deep &__children {
    display: inline-block;
    width: 100%;

    &--flex {
      display: flex;
    }

    &--vertical {
      flex-direction: column;
    }
  }

  ::v-deep &__label {
    font-weight: 700;
    margin: auto;
    padding: 4px 0;
  }

  ::v-deep {
    .px-form-input,
    .px-calendar-input[disabled] .px-calendar-input > input,
    textarea {
      border-width: 0;
      border-bottom: solid 2px $border-grey;
      background-color: transparent;
      padding: 2px;

      &:active,
      &:focus {
        background-color: transparent;
        outline-width: 0;
        border-bottom-color: $polly-light-blue;
      }

      &-invalid,
      &-invalid:active,
      &-invalid:focus {
        border-bottom-color: $polly-orange;
      }

      &:read-only {
        border-width: 0;
        cursor: default;

        &::placeholder {
          opacity: 0;
        }
      }
    }

    & .vs__selected,
    & .vs__selected-options,
    & textarea {
      margin: 0;
      font-size: 1rem;
    }

    textarea {
      resize: none;
      line-height: 18px;
    }

    .px-form-input,
    .px-calendar-input[disabled] .px-calendar-input input {
      flex: 1;
      height: unset;
      min-width: 40px;
      padding: 2px;
    }

    .icon-switch {
      margin-top: 0;
      margin-bottom: auto;
    }

    .valid-feedback,
    .invalid-feedback {
      margin-top: 0px;
      position: relative;
      top: -2px;
    }
  }

  &__pre-label {
    display: flex;
    padding-left: 4px;
    margin-bottom: auto;
    margin-right: 8px;
    margin-top: auto;

    span {
      margin-bottom: 2px;
    }
  }

  &__post-label {
    align-items: flex-end;
    display: flex;
    margin-bottom: auto;
    margin-left: 4px;
    margin-top: auto;

    span {
      margin-bottom: 2px;
    }
  }

  &.inline {
    min-height: unset;
  }

  &__character-count {
    color: $gray;
    display: inline-block;
    font-size: 11px;
    text-align: right;
    visibility: hidden;
    width: 100%;
  }

  &:focus-within {
    .px-form-group__character-count {
      visibility: visible;
    }
  }
}

::v-deep legend.px-form-group__label {
  margin-top: 4px;
}
</style>

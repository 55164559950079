import Vue from 'vue';

export function showMessages(messages) {
  messages.forEach(function (message) {
    Vue.notify({
      title: message.title,
      // eslint-disable-next-line
      text: message.message.replace(new RegExp('\n\r', 'g'), '<br>'),
      type: message.type,
    });
  }, this);
}

export function showSuccessMessage(text, title = '') {
  Vue.notify({
    title: title,
    text: text,
    type: 'success',
  });
}

export function showWarningMessage(text) {
  Vue.notify({
    title: 'Warning',
    text: text,
    type: 'warn',
  });
}

export function showErrorMessage(text) {
  Vue.notify({
    title: 'Error',
    text: text,
    type: 'error',
  });
}

export function showSoftSuccessMessage(text, title = 'Success') {
  Vue.notify({
    title: title,
    text: text,
    type: 'soft-success',
  });
}

export function showSoftWarningMessage(text, title = 'Warning') {
  Vue.notify({
    title: title,
    text: text,
    type: 'soft-warn',
  });
}

export function showSoftErrorMessage(text, title = 'Error') {
  Vue.notify({
    title: title,
    text: text,
    type: 'soft-error',
  });
}

const toErrorMessage = err => ({
  title: 'Error',
  message: err.error,
  type: 'warn',
});

export function showOperationConflictMessage(error) {
  const errors = error?.response?.data?.errors || [
    {
      error: 'There is another active operation',
    },
  ];
  showMessages(errors.map(toErrorMessage));
}

export function showOutsideOfMarketHoursMessage(error) {
  const errors = error?.response?.data?.errors || [
    {
      error: 'Autobidding outside of business hours is not permitted',
    },
  ];
  showMessages(errors.map(toErrorMessage));
}

export default {
  showMessages,
  showOperationConflictMessage,
  showOutsideOfMarketHoursMessage,
};

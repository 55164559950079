import {
  SERVICING_TYPE_RELEASED,
  LOW_LEVEL_TOLERANCE,
  SECURITY_POOL_STATUS,
  SECURITY_POOL_STATUS_REASON,
} from '@shared/constants';
import { formatMoney, strToPrecision } from '@shared/utils/converters.js';

export const isReleased = pool =>
  parseInt(pool.servicing_type) === SERVICING_TYPE_RELEASED;
export const isOptionSelectable = option => option?.value !== -1;

export const emptyCommitmentPool = Object.freeze({
  product_code: null,
  payup_code: null,
  rate_min: null,
  rate_max: null,
  commitment_type: null,
  amortization_type: null,
  servicing_type: null,
  contract_days: null,
  amount: null,
  decision_at: null,
  expires_at: null,
  contract_identifier: null,
  price_data: null,
  coupon_rate: null,
  settlement_month: null,
  buyer_org_id: null,
  coissuer: null,
  external_pool_id: null,
});

export const isPayupRowVisible = (isValidBuyer, pool, isEditing, isCreation) =>
  isValidBuyer &&
  ((pool.payup_code && pool.payup_code !== 'none') || isEditing || isCreation);

export const getProductOptions = products => {
  // User has selected an agency for the pool. Get the mortgage
  // products information for the products drop down.
  return (
    products?.map(item => {
      return {
        value: item[0],
        label: item[1],
      };
    }) || [
      {
        value: -1,
        label: 'Select an agency for list of products',
      },
    ]
  );
};

export const sharedLoanTableFields = [
  { key: 'loan_number', label: 'Loan ID', sortable: true },
  {
    key: 'unpaid_balance',
    label: 'UPB',
    sortable: true,
    formatter: v => formatMoney(v),
  },
  {
    key: 'loan_program',
    label: 'Product',
    sortable: true,
  },
  {
    key: 'rate',
    label: 'Rate',
    sortable: true,
    formatter: v => strToPrecision(v, 3) || 'N/A',
  },
];

export const getPoolReadyStatus = pool =>
  pool.is_security_pool ? pool?.sub_status_details?.ready_status : undefined;

export const isPoolValid = pool => {
  if (
    pool.sub_status_details.ready_status.status_reasons.includes(
      SECURITY_POOL_STATUS_REASON.HAS_EXPIRED_BIDS,
    )
  ) {
    return false;
  }
  if (pool.is_security_pool) {
    return (
      pool.sub_status_details.ready_status.status === SECURITY_POOL_STATUS.READY
    );
  } else {
    return (
      isPoolFilledWithinTolerance(pool.percent_filled) || isPoolForward(pool)
    );
  }
};

export const isPoolFilledWithinTolerance = percentFilled => {
  return Number(percentFilled) - LOW_LEVEL_TOLERANCE >= 0;
};

export const isPoolForward = pool => {
  return !pool.is_auto_generated;
};

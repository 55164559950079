<!-- eslint-disable vue/no-mutating-props -->
<template>
  <px-box-input
    v-model="value.input"
    v-bind="{ ...$attrs, ...$props }"
    :hide-input="hideInput"
    v-on="inputListeners"
  >
    <b-input-group-append v-if="!hideToggles">
      <b-form-radio-group
        v-model="value.option"
        buttons
        :class="radioGroupClasses"
        size="sm"
      >
        <b-form-radio
          v-for="option in options"
          :key="option.value"
          button
          class="px-box-input-toggle-btn btn-short"
          :name="option.text"
          :value="option.value"
          @change="$emit('isBuyOrBuySell', option.value)"
          @focusin.native="() => setFocus(true)"
          @focusout.native="() => setFocus(false)"
        >
          <span :data-text="option.text">{{ option.text }}</span>
        </b-form-radio>
      </b-form-radio-group>
    </b-input-group-append>
  </px-box-input>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import baseBoxInput from '@shared/mixins/baseBoxInput.js';

export default {
  name: 'PxBoxInputToggle',
  mixins: [baseBoxInput],
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      default: () => ({ input: null, option: null }),
      type: Object,
      required: false,
    },
    hideToggles: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideInput: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isFocused: false,
    };
  },
  computed: {
    radioGroupClasses() {
      return {
        'px-box-input-radio-group': true,
        'px-box-input-radio-group--focused': this.isFocused,
        'px-box-input-radio-group--invalid': this.invalid,
      };
    },
    inputListeners() {
      return {
        ...this.$listeners,
        input: event => {
          this.value.input = event;
          const inputListener = this.$listeners?.input;
          if (inputListener) inputListener(this.value);
        },
        focus: event => {
          this.setFocus(true);
          const focusListener = this.$listeners?.focus;
          if (focusListener) focusListener(event);
        },
        blur: event => {
          this.setFocus(false);
          const blurListener = this.$listeners?.blur;
          if (blurListener) blurListener(event);
        },
      };
    },
  },
  created() {
    // set selection to the first button if not provided by prop
    if (!this.value?.option) {
      this.value.option = this.options[0].value;
    }
  },
  methods: {
    setFocus(isFocused) {
      this.isFocused = isFocused;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/variables';
$unselected-toggle-bg-color: #efefef;

@mixin focused-toggle {
  background-color: $unselected-toggle-bg-color;
  color: $primary;

  &.active {
    background-color: $primary;
    color: $white;
  }
}

.px-box-input-container {
  &.dense {
    .px-box-input-toggle-btn {
      padding: 3px;
      height: 18.985px;
      line-height: 12px;
      font-size: 0.875rem;
    }
  }

  .px-box-input-radio-group {
    .px-box-input-toggle-btn {
      padding: 0 4px;
      display: flex;
      align-items: center;
      outline-width: 0;
      color: $darkest-gray;
      background-color: $unselected-toggle-bg-color;

      // Add invisible text that is already bold to prevent
      // switching the active button from shifting the button layout
      // when the newly active button becomes bold.
      // Stolen from: https://css-tricks.com/bold-on-hover-without-the-layout-shift/
      span[data-text]::before {
        display: block;
        content: attr(data-text);
        font-weight: bold;
        height: 0;
        color: transparent;
        overflow: hidden;
        visibility: hidden;
        user-select: none;
      }

      &.active {
        background-color: $white;
        font-weight: bold;
      }

      &:first-child {
        border-radius: 5px 0 0 5px;
      }
    }

    &--focused {
      .px-box-input-toggle-btn {
        @include focused-toggle;
      }
    }
    .px-box-input-toggle-btn.focus {
      @include focused-toggle;
    }

    &--invalid {
      .px-box-input-toggle-btn {
        border-bottom-color: $warning;
        border-top-color: $warning;

        &:last-child {
          border-right-color: $warning;
        }
      }
    }
  }

  // Override the PxBoxInput component styles for the invalid indicator since we need to display it to the
  // left of the button toggles and have it appear like it's inside the input box.
  &::v-deep .px-box-input + .invalid-icon-container {
    position: relative;
    border-color: $warning;
    border-width: 1px 0;
    border-style: solid;
    height: unset;
    padding-right: 0.5rem;
    // prevent a small gap between the error indicator and the other elements
    right: -1px;
    left: -1px;
  }

  &::v-deep .px-box-input.invalid {
    border-right-color: transparent;
    padding-right: 0.5rem;
  }
}
</style>

import mutationTypes from './mutationTypes.js';
import { getPriceExceptionReviewalStatus } from '@pe/services/lockRequests.js';

export default {
  namespaced: true,
  state: {
    reviewalInfo: null,
    priceExceptions: [],
    exceptionManagementLevelsWithStatus: [],
  },
  getters: {
    reviewalInfo: state => state.reviewalInfo,
    exceptionManagementLevels: state =>
      state.reviewalInfo?.exceptionManagementLevels || [],
    cumulativeExceptionAmount: state => {
      return (state?.priceExceptions ?? []).reduce(
        (accumulator, currentValue) => +accumulator + +currentValue?.value ?? 0,
        0,
      );
    },
    isLockDeskApprovalEnabled: state =>
      Boolean(state.reviewalInfo?.isLockDeskApprovalEnabled),
    lockDeskReviewer: state => state.reviewalInfo?.lockDeskReviewer,
  },
  mutations: {
    [mutationTypes.SET_PE_REVIEWAL_STATUS](state, reviewalInfo) {
      state.reviewalInfo = reviewalInfo;
    },
    [mutationTypes.SET_PRICE_EXCEPTIONS](state, exceptions) {
      state.priceExceptions = exceptions;
    },
    [mutationTypes.CLEAR_REVIEWAL_INFO](state) {
      state.reviewalInfo = null;
      state.priceExceptions = [];
    },
    [mutationTypes.SET_EXCEPTION_MANAGEMENT_LEVELS_WITH_STATUS](state, levels) {
      state.exceptionManagementLevelsWithStatus = levels;
    },
  },
  actions: {
    async getPriceExceptionReviewalStatus({ commit }, lockRequestId) {
      const reviewalInfo = await getPriceExceptionReviewalStatus(lockRequestId);
      commit(mutationTypes.SET_PE_REVIEWAL_STATUS, reviewalInfo);
    },
  },
};

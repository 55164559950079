export const ADD_LEVEL_TO_REVIEWAL_SEQUENCE = 'ADD_LEVEL_TO_REVIEWAL_SEQUENCE';
export const CLEAR_CURRENT_POLICY = 'CLEAR_CURRENT_POLICY';
export const CLEAR_POLICY_DETAILS = 'CLEAR_POLICY_DETAILS';
export const CLEAR_REVIEWAL_SEQUENCE = 'CLEAR_REVIEWAL_SEQUENCE';
export const SET_ACTIVE_WORKFLOW_POLICIES = 'SET_ACTIVE_WORKFLOW_POLICIES';
export const SET_ALL_POLICIES_LIST = 'SET_ALL_POLICIES_LIST';
export const SET_APPROVAL_TYPE_IN_REVIEWAL_SEQUENCE =
  'SET_APPROVAL_TYPE_IN_REVIEWAL_SEQUENCE';
export const SET_APPROVER_GROUP_IN_REVIEWAL_SEQUENCE =
  'SET_APPROVER_GROUP_IN_REVIEWAL_SEQUENCE';
export const SET_COMMENT_REQUIRED = 'SET_COMMENT_REQUIRED';
export const SET_CURRENT_PRODUCT_POLICY_BY_TYPE =
  'SET_CURRENT_PRODUCT_POLICY_BY_TYPE';
export const SET_CUSTOM_POLICIES = 'SET_CUSTOM_POLICIES';
export const SET_DEFAULT_POLICIES_LIST = 'SET_DEFAULT_POLICIES_LIST';
export const SET_DEFAULT_POLICIES_OBJECT = 'SET_DEFAULT_POLICIES_OBJECT';
export const SET_DEFAULT_POLICY_PRODUCTS = 'SET_DEFAULT_POLICY_PRODUCTS';
export const SET_EXCEPTION_CEILING_IN_REVIEWAL_SEQUENCE =
  'SET_EXCEPTION_CEILING_IN_REVIEWAL_SEQUENCE';
export const SET_IS_ALL_POLICIES_LOADING = 'SET_IS_ALL_POLICIES_LOADING';
export const SET_IS_CUSTOM_POLICIES_LOADING = 'SET_IS_CUSTOM_POLICIES_LOADING';
export const SET_IS_DEFAULT_POLICIES_LOADING =
  'SET_IS_DEFAULT_POLICIES_LOADING';
export const SET_IS_LOCK_DESK_SETTINGS_LOADING =
  'SET_IS_LOCK_DESK_SETTINGS_LOADING';
export const SET_IS_NEW_POLICY = 'SET_IS_NEW_POLICY';
export const SET_IS_POLICY_LOADING = 'SET_IS_POLICY_LOADING';
export const SET_IS_POLICY_OPTIONS_LOADING = 'SET_IS_POLICY_OPTIONS_LOADING';
export const SET_IS_POLICY_PAGE_LOADING = 'SET_IS_POLICY_PAGE_LOADING';
export const SET_IS_POLICY_SAVING = 'SET_IS_POLICY_SAVING';
export const SET_LOCK_DESK_SETTINGS = 'SET_LOCK_DESK_SETTINGS';
export const SET_NUMBER_OF_APPROVERS_IN_REVIEWAL_SEQUENCE =
  'SET_NUMBER_OF_APPROVERS_IN_REVIEWAL_SEQUENCE';
export const SET_ORDER_IN_REVIEWAL_SEQUENCE = 'SET_ORDER_IN_REVIEWAL_SEQUENCE';
export const SET_POLICY = 'SET_POLICY';
export const SET_POLICY_FEE = 'SET_POLICY_FEE';
export const SET_POLICY_FEE_SET = 'SET_POLICY_FEE_SET';
export const SET_POLICY_FEE_TYPE = 'SET_POLICY_FEE_TYPE';
export const SET_POLICY_IS_AUTO_APPROVAL_ENABLED =
  'SET_POLICY_IS_AUTO_APPROVAL_ENABLED';
export const SET_POLICY_AUTO_APPROVAL_LEVEL = 'SET_POLICY_AUTO_APPROVAL_LEVEL';
export const SET_POLICY_MAX_DAYS_EXTENDED = 'SET_POLICY_MAX_DAYS_EXTENDED';
export const SET_POLICY_MAX_EXCEPTIONS = 'SET_POLICY_MAX_EXCEPTIONS';
export const SET_POLICY_MAX_WORKFLOW_USAGE = 'SET_POLICY_MAX_WORKFLOW_USAGE';
export const SET_POLICY_MIN_DAYS_EXTENDED = 'SET_POLICY_MIN_DAYS_EXTENDED';
export const SET_POLICY_NAME = 'SET_POLICY_NAME';
export const SET_POLICY_NOTES = 'SET_POLICY_NOTES';
export const SET_POLICY_PRODUCTS = 'SET_POLICY_PRODUCTS';
export const SET_POLICY_SEQUENCES = 'SET_POLICY_SEQUENCES';
export const SET_POLICY_STATUS = 'SET_POLICY_STATUS';
export const SET_POLICY_TYPE = 'SET_POLICY_TYPE';
export const SET_POLICY_TYPE_FILTER = 'SET_POLICY_TYPE_FILTER';
export const SET_POLICY_WORST_CASE_PRICING = 'SET_POLICY_WORST_CASE_PRICING';
export const SET_REQUESTOR_GROUP_IN_REVIEWAL_SEQUENCE =
  'SET_REQUESTOR_GROUP_IN_REVIEWAL_SEQUENCE';
export const SET_PRODUCT_DETAILS_POLICY_OPTIONS =
  'SET_PRODUCT_DETAILS_POLICY_OPTIONS';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const SET_SEQUENCE = 'SET_SEQUENCE';
export const SET_WORKFLOWS = 'SET_WORKFLOWS';
export const UPDATE_LEVEL_IN_REVIEWAL_SEQUENCE =
  'UPDATE_LEVEL_IN_REVIEWAL_SEQUENCE';
export const SET_IS_REASON_REQUIRED = 'SET_IS_REASON_REQUIRED';
export const SET_DENY_REASONS = 'SET_DENY_REASONS';
export const SET_IS_REASON_ENABLED = 'SET_IS_REASON_ENABLED';
export const SET_POLICY_DOCUMENT_REQUIRED_THRESHOLD =
  'SET_POLICY_DOCUMENT_REQUIRED_THRESHOLD';
export const SET_PRICE_EXCEPTION_FILE_UPLOAD_SETTINGS =
  'SET_PRICE_EXCEPTION_FILE_UPLOAD_SETTINGS';
export const SET_RELOCK_DAYS_EXPIRED_ENABLED =
  'SET_RELOCK_DAYS_EXPIRED_ENABLED';
export const SET_RELOCK_DAYS_LOCKED_ENABLED = 'SET_RELOCK_DAYS_LOCKED_ENABLED';
export const SET_RELOCK_DAYS_EXPIRED_OPERATOR =
  'SET_RELOCK_DAYS_EXPIRED_OPERATOR';
export const SET_RELOCK_DAYS_LOCKED_OPERATOR =
  'SET_RELOCK_DAYS_LOCKED_OPERATOR';

<template>
  <div>
    <label v-if="label">
      <span>{{ label }}</span>
    </label>
    <b-input
      :id="'inputValue' + placeholder"
      ref="numericField"
      v-model="currentValue"
      :class="{ 'invalid-input': invalidValue }"
      :placeholder="placeholder"
      @blur="checkValue"
      @keypress="checkInput"
    />
    <b-tooltip
      placement="bottom"
      :show="invalidValue"
      :target="'inputValue' + placeholder"
      triggers="manual"
      variant="danger"
    >
      {{ invalidValueMessage }}
    </b-tooltip>
  </div>
</template>
<script>
import { strToPrecision } from '@shared/utils/converters.js';

export default {
  name: 'PxNumericInput',
  props: {
    value: { type: String, default: null },
    initialValue: { type: String, default: null },
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    decimal: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
  },
  data() {
    return {
      invalidValueMessage: 'Invalid Value',
      invalidValue: false,
      currentValue: this.value,
    };
  },
  watch: {
    initialValue(newValue) {
      this.currentValue = newValue;
    },
  },
  methods: {
    async checkValue() {
      if (this.required) {
        if (!this.currentValue || this.currentValue.length == 0) {
          this.invalidValueMessage = `Required`;
          this.invalidValue = true;
        } else {
          this.invalidValue = false;
        }
        this.$emit('invalid-value', this.invalidValue);
      }
      if (this.decimal && this.currentValue) {
        this.currentValue = strToPrecision(this.currentValue, 3);
      }
      this.$emit('input', this.currentValue);
    },
    async checkInput(event) {
      const keyCode = event.which ? event.which : event.keyCode;
      if (!this.decimal && keyCode === 46) {
        event.preventDefault();
      }
      if (
        keyCode > 31 &&
        (keyCode < 48 || keyCode > 57) &&
        keyCode !== 46 &&
        keyCode !== 45
      ) {
        event.preventDefault();
      } else if (
        keyCode === 46 &&
        this.currentValue &&
        this.currentValue.indexOf('.') >= 0
      ) {
        event.preventDefault();
      } else if (
        keyCode === 45 &&
        this.currentValue &&
        this.currentValue.length > 0
      ) {
        event.preventDefault();
      } else {
        return true;
      }
    },
    decimalFormat(value) {
      if (!this.decimal || value === '-') {
        return value;
      }
      return strToPrecision(value, 3);
    },
    getValue() {
      return this.$refs.numericField.localValue;
    },
  },
};
</script>

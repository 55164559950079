import { sleep } from '@shared/utils/time.js';

/**
 * Poll at a set interval, but only if we have focus.
 * @export
 * @param pollingHandler- Async function to run at interval
 * @param {number} interval
 * @param {number} jitterFactor - Percentage of interval (0-1) to set as additional random jitter.
 */
export const pollWhenActive = async (
  pollingHandler,
  interval = 30000,
  jitterFactor = 0,
) => {
  // controls whether the polling is active or not.
  let pollPaused = document.hidden;
  let jitterAmount = 0;
  if (jitterFactor > 0) {
    jitterAmount = Math.floor(Math.random() * interval * jitterFactor);
  }

  // invoke the callback immediately, then after the interval.
  await pollingHandler();

  setInterval(async function () {
    // if the page isn't visible, do not invoke the callback.
    if (pollPaused) return;

    await pollingHandler();
  }, interval + jitterAmount);

  // switch to visibility API https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
  document.addEventListener('visibilitychange', () => {
    pollPaused = document.hidden;
  });
};

export const poll = async (
  handler,
  isComplete = result => !!result,
  interval = 5000,
  updateCallback = result => result,
  maxAttemptsCount = null,
) => {
  let result = await handler();
  let attemptsCount = 1;
  while (
    !isComplete(result) &&
    (!maxAttemptsCount || attemptsCount < maxAttemptsCount)
  ) {
    await sleep(interval);
    result = await handler();
    updateCallback(result);
    attemptsCount += 1;
  }

  return result;
};

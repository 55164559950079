import * as TYPES from './mutationTypes.js';
import { getTokenData } from '@analytics/api/auth.js';

const initialState = {
  tokenData: {},
};

const getters = {};

const mutations = {
  [TYPES.SET_TOKEN_DATA](state, tokenData) {
    state.tokenData = tokenData;
  },
};

const actions = {
  async getTokenData({ commit }) {
    const tokenData = await getTokenData();
    commit(TYPES.SET_TOKEN_DATA, tokenData);
  },
};

export default {
  namespaced: true,
  state: () => initialState,
  getters,
  mutations,
  actions,
};

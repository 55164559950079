import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import api from '@shared/services/api.js';

export async function triggerHedgeDataTask(type /*'loan' or 'pool'*/, ids) {
  try {
    const { task_id } = await api.get(
      `/sell/api/download_hedge_data?type=${type}&ids=${ids}`,
    );
    return task_id;
  } catch (error) {
    onErrorHandler(error, `${type}_downloadHedgeData`);
  }
}

import getters from './getters.js';
import actions from './actions.js';
import mutations from './mutations.js';

export default {
  namespaced: false,
  state: {
    organizationDetail: {
      isLoading: true,
      data: {},
    },
    isOrganizationLogoLoading: true,
    organizationLogo: null,
    fundingCost: {
      base_rate: 'SOFR',
      cap: null,
      floor: null,
      spread: null,
      carry_start_days_delay: null,
    },
  },
  getters,
  actions,
  mutations,
};

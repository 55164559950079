import api from '@shared/services/api.js';
import { addDays, getNextBusinessDate } from '@shared/utils';
import { getPrevBusinessDate } from '@shared/utils/dates.js';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';

const state = {
  holidays: [],
  isLoadingHolidays: false,
};

const getters = {
  holidays: state => state.holidays,
  nextValidBusinessDate:
    (_, getters) =>
    (day = null, offset = 0) => {
      day = day || new Date();
      return getNextBusinessDate(
        addDays(day, parseInt(offset)),
        getters.holidays,
      );
    },
  previousValidBusinessDate:
    (_, getters) =>
    (intendedDate = null) => {
      intendedDate = intendedDate || new Date();
      return getPrevBusinessDate(intendedDate, getters.holidays);
    },
  isLoadingHolidays: state => state.isLoadingHolidays,
};

const mutations = {
  setHolidays: (state, value) => {
    state.holidays = value;
  },
  setIsLoading: (state, isLoading) => {
    state.isLoadingHolidays = isLoading;
  },
};

const actions = {
  fetchHolidays: async ({ commit }) => {
    if (state.isLoadingHolidays) return;
    commit('setIsLoading', true);
    try {
      if (state.holidays.length) return;
      const data = await api.get('/api/holidays/');
      commit(
        'setHolidays',
        data.holidays.map(holiday => new Date(holiday)),
      );
    } catch (error) {
      onErrorHandler(error, 'load-holiday-list');
    } finally {
      commit('setIsLoading', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

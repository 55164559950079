import Vue from 'vue';
import api from '@shared/services/api.js';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import cartSvc from '@sell/services/cart.js';
import * as mutationTypes from './mutationTypes.js';
import { commitmentCartTablesVisibilityModes } from '@shared/constants';

function setBidsProperty(
  pools,
  targetPoolIds,
  targetBidIds,
  propertyName,
  value,
) {
  pools = pools?.filter(p => targetPoolIds.includes(p.id)) || [];
  pools.forEach(pool => {
    const bids = pool.bids.filter(b => targetBidIds.includes(b.id));
    bids.forEach(bid => {
      Vue.set(bid, propertyName, value);
    });
  });
}

const state = {
  activeTransactionInProgress: false,
  commitInProgress: false,
  pools: null,
  bidsRefreshInProgress: false,
  cancellationToken: null,
  cartTablesVisibilityMode:
    commitmentCartTablesVisibilityModes.ALL_TABLES_HIDDEN,
  visibleCartTablesCount: 0,
};

const getters = {
  activeTransactionInProgress: (state, moduleGetters, rootState, rootGetters) =>
    state.activeTransactionInProgress ||
    rootGetters['operations/hasActiveOperations'],
  commitInProgress: (state, moduleGetters, rootState, rootGetters) =>
    state.commitInProgress || rootGetters['operations/hasActiveOperations'],
  bidsRefreshInProgress: state => state.bidsRefreshInProgress,
  pools: state => state.pools || [],
  cartTablesVisibilityMode: state => state.cartTablesVisibilityMode,
  visibleCartTablesCount: state => state.visibleCartTablesCount,
};

const mutations = {
  startTransaction(state, committingPools = false) {
    state.activeTransactionInProgress = true;
    state.commitInProgress = committingPools;
  },
  setCartTablesVisibilityMode(state, cartTablesVisibilityMode) {
    state.cartTablesVisibilityMode = cartTablesVisibilityMode;
  },
  [mutationTypes.SET_CART_VISIBLE_TABLE_COUNT](state, visibleCartTablesCount) {
    state.visibleCartTablesCount = visibleCartTablesCount;
  },
  completeTransaction(state) {
    state.activeTransactionInProgress = false;
    state.commitInProgress = false;
  },
  startRefreshing(state) {
    state.activeTransactionInProgress = true;
    state.bidsRefreshInProgress = true;
  },
  finishRefreshing(state) {
    state.activeTransactionInProgress = false;
    state.bidsRefreshInProgress = false;
  },
  setPools(state, pools) {
    state.pools = pools;
  },
  startGainLossCalculation(state, { targetPoolIds, targetBidIds }) {
    setBidsProperty(
      state.pools,
      targetPoolIds,
      targetBidIds,
      'gainlossCalcInProgress',
      true,
    );
  },
  finishGainLossCalculation(state, { targetPoolIds, targetBidIds }) {
    setBidsProperty(
      state.pools,
      targetPoolIds,
      targetBidIds,
      'gainlossCalcInProgress',
      false,
    );
  },
};

const actions = {
  async getData({ commit, dispatch }, { targetPoolIds } = {}) {
    try {
      const { commitment_pools } = await api.get(
        '/sell/api/create_commitment_pools',
      );
      commit('setPools', commitment_pools);
      dispatch('populateForwardGainLosses', targetPoolIds);
    } catch (error) {
      onErrorHandler(error, 'sell-commitment-cart-getData');
    }
  },
  updateVisibleLoanTablesCount(
    { commit, getters },
    { incrementCurrentCount, value },
  ) {
    const visibleTableCount = incrementCurrentCount
      ? getters.visibleCartTablesCount + value
      : value;
    commit(
      mutationTypes.SET_CART_VISIBLE_TABLE_COUNT,
      Math.max(0, visibleTableCount),
    );
  },
  async populateForwardGainLosses(
    { commit, dispatch, getters },
    forwardPoolIds = [],
  ) {
    const targetBidIds = [];
    const pools = getters.pools.filter(
      p =>
        cartSvc.isForwardPool(p) &&
        (!forwardPoolIds.length || forwardPoolIds.includes(p.id)),
    );
    for (const pool of pools) {
      for (const bid of pool.bids) {
        targetBidIds.push(bid.id);
      }
    }
    if (!targetBidIds.length) {
      return;
    }
    const targetPoolIds = pools.map(pool => pool.id);
    commit('startGainLossCalculation', {
      targetPoolIds,
      targetBidIds,
    });
    await dispatch('forwards/calculateGainLoss', targetBidIds, { root: true });
    commit('finishGainLossCalculation', {
      targetPoolIds,
      targetBidIds,
    });
  },
  cleanCommitmentCart({ state, commit }) {
    state.pools = null;
    state.activeTransactionInProgress = false;
    state.bidsRefreshInProgress = false;
    for (const asyncTaskId in state['asyncTasks/asyncTasks']) {
      commit('asyncTasks/clearAsyncTask', asyncTaskId, { root: true });
    }
    state.commitInProgress = false;
    state.cancellationToken = null;
    commit('forwards/stopGainLossCalculation', null, { root: true });
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <b-tabs
    v-bind="$listeners"
    active-nav-item-class="px-tab--active"
    :class="[
      'px-tabs',
      { 'px-tabs--tabs-only': tabsOnly, 'px-tabs--toggle-set': toggleSet },
    ]"
  >
    <template v-if="tabsOnly || toggleSet" #tabs-end>
      <b-nav-item
        v-for="(item, index) in items"
        :key="`${index}-${item.key}`"
        :class="[
          'px-tab',
          `px-tab--${size}`,
          { 'px-tab--active': item.key === selectedTab },
        ]"
        :data-testid="`tab-${item.key}`"
        :disabled="readOnly"
        :title="item.label"
        @click="$emit('update', item.key)"
      >
        <slot :name="item.key">
          {{ item.label }}
        </slot>
      </b-nav-item>
    </template>
    <b-tab
      v-for="(item, index) in items"
      v-else
      :key="`${index}-${item.key}`"
      :class="['px-tab', { 'px-tab--active': item.key === selectedTab }]"
      :title="item.label"
    ></b-tab>
  </b-tabs>
</template>

<script>
export default {
  name: 'PxTabs',
  model: {
    prop: 'selectedTab',
    event: 'update',
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    autoSelect: {
      default: false,
      required: false,
      type: Boolean,
    },
    selectedTab: {
      default: null,
      required: false,
      type: [String, Boolean],
    },
    tabsOnly: {
      default: false,
      required: false,
      type: Boolean,
    },
    toggleSet: {
      default: false,
      required: false,
      type: Boolean,
    },
    readOnly: {
      default: false,
      required: false,
      type: Boolean,
    },
    size: {
      default: 'md',
      type: String,
      validator: function (value) {
        return ['sm', 'md', 'lg', 'xl'].indexOf(value) !== -1;
      },
    },
  },
  created() {
    if (this.autoSelect && !this.selectedTab && this.items) {
      this.$emit('update', this.items[0].key);
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/variables';
@import '@shared/styles/mixins/main';

.px-tabs {
  color: $polly-light-blue;
  display: flex;
  background-color: transparent;

  &--toggle-set {
    ::v-deep .nav-tabs {
      display: flex;
      gap: 0px;
      background-color: #f3f3f3;
      border-radius: 1em;
    }

    .px-tab {
      border: 1px solid transparent;
      margin: 1px;
      transition: background-color 200ms ease-in-out;
      border-radius: 1em;

      &--active {
        color: #545454;
        border: 1px solid transparent;
        border-bottom: none;
        background-color: white;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
      }

      ::v-deep .nav-link {
        font-size: 10px;
        padding: 2px 25px;
        color: $darkest-gray;
      }
    }

    ::v-deep .tab-content {
      background-color: transparent;
      display: flex;
      gap: 12px;
      padding: 0;
    }
  }

  &--tabs-only {
    overflow-x: auto;
    ::v-deep .nav-tabs {
      display: flex;
      gap: 12px;
    }

    .px-tab {
      border-bottom: 2px solid transparent;
      flex-shrink: 0;

      &--sm ::v-deep .nav-link {
        @include h4;
      }

      &--md ::v-deep .nav-link {
        @include h3;
      }

      &--lg ::v-deep .nav-link {
        @include h3;
      }

      &--xl ::v-deep .nav-link {
        @include h3;
      }

      ::v-deep .nav-link {
        padding: 0;
        color: $polly-light-blue;
      }

      &--active {
        border-bottom: 2px solid;
      }
    }

    ::v-deep .tab-content {
      background-color: transparent;
      display: flex;
      gap: 12px;
      padding: 0;
    }
  }
}

@media screen and (min-width: 720px) {
  .px-tabs {
    &--tabs-only {
      .px-tab {
        &--lg ::v-deep .nav-link {
          @include h2;
        }

        &--xl ::v-deep .nav-link {
          @include h1;
        }
      }
    }
  }
}
</style>

import api from '@shared/services/api.js';

export default {
  async getCategories() {
    return api.get(`/devtools/api/v1/seed_data_categories/`);
  },
  async getScenarios(category) {
    return api.get(
      `/devtools/api/v1/seed_data_scenarios/?category=${category}`,
    );
  },
  async getScenarioRuns() {
    return api.get(`/devtools/api/v1/seed_data_scenario_runs/`);
  },
  async deleteScenarioRun(id) {
    return api.delete(`/devtools/api/v1/seed_data_scenario_runs/${id}/`);
  },
};

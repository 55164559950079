<template>
  <div class="lx-multi-select">
    <b-dropdown
      :id="ddId"
      ref="dropdown"
      class="m-0 lx-multi-select-dd-btn"
      no-flip
      :right="right"
      toggle-class="text-left border-primary"
      variant="outline-primary"
      @hide="restoreCache"
      @show="setCache"
    >
      <template #button-content>
        <div class="d-flex align-items-center">
          <span class="mx-2">{{ getSelectionLabel }}</span>
          <px-icon class="ml-auto" icon="chevron-down"></px-icon>
        </div>
      </template>
      <b-dropdown-form class="drop-down-container">
        <b-form-checkbox
          :checked="allSelected"
          :indeterminate="indeterminated"
          @change="onAllSelectedChanged"
        >
          (Select All)
        </b-form-checkbox>
        <px-tree-view-node
          v-for="group in groups"
          :key="group.name"
          :group="group"
          @change="updateGroups"
        />
      </b-dropdown-form>
      <div class="mt-2 text-center">
        <b-button class="mx-2 btn-space" variant="primary" @click="apply">
          Apply
        </b-button>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';

import {
  default as PxTreeViewNode,
  setAll,
  isGroupSelected,
  isAnySelected,
} from '@shared/components/PxTreeViewNode.vue';

export default {
  name: 'PxTreeViewMultiselect',
  components: {
    PxTreeViewNode,
  },
  model: {
    prop: 'groups',
    event: 'change',
  },
  props: {
    label: { type: String, default: '' },
    groups: { type: Array, required: true },
    right: { type: Boolean, default: false },
  },
  data() {
    return {
      ddId: `tv-${this.label.replace(/\s/g, '')}-dd`,
      groupsBackupCache: null,
    };
  },
  computed: {
    allSelected() {
      return !!(
        this.groups.length && this.groups.every(g => isGroupSelected(g))
      );
    },
    indeterminated() {
      return !!(
        !this.allSelected &&
        this.groups.length &&
        this.groups.some(g => isAnySelected(g))
      );
    },
    getSelectionLabel() {
      return `${this.label} (${
        this.allSelected ? 'All' : this.getSelectedOptionsCount(this.groups)
      })`;
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    const ps = new PerfectScrollbar(`#${this.ddId} .drop-down-container`, {
      wheelSpeed: 3,
      wheelPropagation: true,
      minScrollbarLength: 20,
      suppressScrollX: true,
    });
    this.$refs.dropdown.$on('shown', () => ps.update());
  },
  methods: {
    apply() {
      this.$refs.dropdown.hide();
      this.$emit('applyFilter');

      this.setCache();
    },
    setCache() {
      // cache thing needs for reverting filter options to initial state
      // (before dropdown was shown) in case if user close dropdown and
      // didn't click Apply button

      this.groupsBackupCache = JSON.parse(JSON.stringify(this.groups));
    },
    restoreCache() {
      this.$emit('change', this.groupsBackupCache);
    },
    getSelectedOptionsCount() {
      return this.groups.reduce(
        (accumulator, group) =>
          accumulator + this.getGroupSelectedOptionsCount(group),
        0,
      );
    },
    getGroupSelectedOptionsCount(group) {
      const selectedLength =
        (group && group.selected && group.selected.length) || 0;
      if (!group.subGroups || !group.subGroups.length) {
        return selectedLength;
      }
      return group.subGroups.reduce(
        (accumulator, current) =>
          accumulator + this.getGroupSelectedOptionsCount(current),
        selectedLength,
      );
    },
    updateGroups(group) {
      const groups = [...this.groups];
      groups.splice(
        groups.findIndex(g => g.name === group.name),
        1,
        group,
      );
      this.$emit('change', groups);
    },
    onAllSelectedChanged(value) {
      const groups = [...this.groups];
      groups.forEach(setAll(value).bind(this));
      this.$emit('change', groups);
    },
  },
};
</script>

<style lang="scss">
@import '@shared/styles/variables';
@import 'perfect-scrollbar/css/perfect-scrollbar.css';

.lx-multi-select .drop-down-container {
  position: relative;
  max-height: 350px;
}
.lx-multi-select .b-dropdown-form {
  width: 200px;
}
.lx-multi-select .custom-control {
  padding-left: 28px;
  margin-bottom: 1px;
}
.lx-multi-select .custom-control .custom-control-label {
  font-size: 12px;
}
.lx-multi-select .custom-control-label::before {
  width: 18px;
  height: 18px;
}
.lx-multi-select .custom-control-label::after {
  width: 18px;
  height: 18px;
  background: no-repeat;
}
.lx-multi-select .form-group {
  margin-bottom: 0;
}
.lx-multi-select .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #d6d6d6;
}
.lx-multi-select
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::after {
  content: $icon-check;
  font-size: 13px;
  color: $polly-light-blue;
  width: 19px;
  line-height: 1.5;
}
.lx-multi-select
  .custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: #d6d6d6;
  background-color: white;
}
.lx-multi-select
  .custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  font-family: $font-awesome-family;
  content: '\2d';
  font-size: 14px;
  width: 19px;
  text-align: center;
  color: $polly-light-blue;
  line-height: 1.4;
  background: none;
}
</style>

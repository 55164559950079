<template>
  <div class="chat-widget__header">
    <span class="chat-widget__header-text">{{ headerText }}</span>
    <div class="chat-widget__icons">
      <i
        class="fa fa-cog gear-icon"
        :class="{ 'spin': spin }"
        @click="toggleSettings"
      ></i>
      <span class="chat-widget__header-icon" @click="toggleExpanded">
        {{ isExpanded ? '-' : '+' }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatHeader',
  props: {
    headerText: {
      type: String,
      required: true,
    },
    isExpanded: {
      type: Boolean,
      required: true,
    },
    toggleExpanded: {
      type: Function,
      required: true,
    },
    handleToggleSettings: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      spin: false,
    };
  },
  methods: {
    toggleSettings() {
      this.handleToggleSettings();
      this.triggerSpin();
    },
    triggerSpin() {
      this.spin = true;
      setTimeout(() => (this.spin = false), 500);
    },
  },
};
</script>

<style lang="scss">
@import './ChatWidget.scss';
.chat-widget__header {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  height: $header-height;
  padding: 0 10px;
  background-color: oldlace;
  border-bottom: 1px solid $polly-light-blue;
  border-radius: 25px 25px 0 0;
  z-index: 2;
}

.chat-widget__header-text {
  font-weight: bold;
}

.chat-widget__header-icon {
  font-size: 20px;
  cursor: pointer;
  margin-right: 5px;
  padding: 5px;
  color: $polly-light-blue;
}

.chat-widget__icons {
  display: flex;
  gap: 5px;
  flex-direction: row;
  align-items: center;
}

.gear-icon {
  color: $polly-light-blue;
  padding: 5px;

  &:hover {
    cursor: pointer;
  }
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spinning 0.8s linear infinite;
}
</style>

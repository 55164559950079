export const SET_BASE_CONFIGURATION = 'SET_BASE_CONFIGURATION';
export const SET_CONFIGURATION = 'SET_CONFIGURATION';
export const SET_CONFIGURATION_NEIGHBORS = 'SET_CONFIGURATION_NEIGHBORS';
export const SET_INVESTOR_RATES_USED = 'SET_INVESTOR_RATES_USED';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_RATES = 'SET_RATES';
export const SET_INVESTOR_UPDATES = 'SET_INVESTOR_UPDATES';

export default {
  SET_BASE_CONFIGURATION,
  SET_CONFIGURATION_NEIGHBORS,
  SET_CONFIGURATION,
  SET_INVESTOR_RATES_USED,
  SET_IS_LOADING,
  SET_RATES,
  SET_INVESTOR_UPDATES,
};

<template>
  <div class="lx-benchmark-bar d-none d-md-block">
    <img alt="" class="logo" src="@shared/img/RefinitivLogo.png" />
    <div
      class="d-flex container justify-content-around align-items-center full-width"
    >
      <small
        v-if="refreshedTime && sortedBenchmarks.length"
        class="text-muted benchmark-refreshed text-nowrap"
      >
        Refreshed at {{ refreshedTime }}:
      </small>
      <template v-for="(benchmark, index) in sortedBenchmarks">
        <div :key="index" class="benchmark-item">
          <strong class="benchmark-label">{{ getLabel(benchmark) }}</strong>
          :
          <span class="benchmark-bid">{{ getBid(benchmark) }}</span>
          <span
            class="benchmark-bid-delta"
            :class="getBidDeltaClass(benchmark)"
          >
            ({{ getBidDelta(benchmark) }})
          </span>
        </div>
        <span :key="'splitter' + index" class="benchmark-splitter">|</span>
      </template>
    </div>
  </div>
</template>

<script>
import { strToPrecision, getMonthName } from '@shared/utils/converters.js';
import { compareBy } from '@shared/utils';

export default {
  props: {
    benchmarks: { type: Array, default: () => [] },
    refreshedTime: { type: String, default: '' },
  },
  computed: {
    sortedBenchmarks() {
      const benchmarks = this.benchmarks;
      benchmarks.sort(compareBy('name', 'rate'));
      return benchmarks;
    },
  },
  mounted() {
    // this script adds padding for navbar to avoid overlapping of bottom element by benchmark bar
    if (document.querySelector('.polly-navbar')) {
      document
        .querySelector('.polly-navbar')
        .classList.add('benchmark-padding');
    }
  },
  beforeDestroy() {
    if (document.querySelector('.polly-navbar')) {
      document
        .querySelector('.polly-navbar')
        .classList.remove('benchmark-padding');
    }
  },
  methods: {
    getLabel(benchmark) {
      return `${benchmark.name} ${strToPrecision(
        benchmark.rate,
        1,
      )} ${this.getSettlementMonth(benchmark)}`;
    },
    getBid(benchmark) {
      return strToPrecision(benchmark.bid_amount, 2);
    },
    getBidDelta(benchmark) {
      return strToPrecision(benchmark.bid_amount_delta, 2);
    },
    getSettlementMonth(benchmark) {
      return getMonthName(new Date(benchmark.settlement_at).getMonth());
    },
    getBidDeltaClass(benchmark) {
      const delta = Number(benchmark.bid_amount_delta);
      return delta > 0 ? 'pos-delta' : delta < 0 ? 'neg-delta' : '';
    },
  },
};
</script>

<style>
.lx-benchmark-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  z-index: 100;
  background-color: #dde9f7;
  color: rgba(50, 50, 50, 0.9);
}

.benchmark-item {
  white-space: nowrap;
  line-height: 30px;
}

.benchmark-bid-delta.pos-delta {
  color: rgba(30, 158, 43, 0.9);
}

.benchmark-bid-delta.neg-delta {
  color: rgba(208, 43, 43, 0.9);
}

.lx-benchmark-bar .benchmark-splitter:last-child {
  display: none;
}

.logo {
  position: absolute;
  height: 40%;
  right: 10px;
  top: 10px;
}
</style>

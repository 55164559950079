import { filterEntitiesByQuery } from '@shared/utils/helpers.js';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import api from '@shared/services/api.js';
import { mutationTypes as biddingRulesMutations } from '@useradmin/store/modules/biddingRules.js';

const state = {
  availablePartners: {
    isLoading: true,
    data: {
      all: [],
    },
  },
  partnership: {
    data: {
      rules: [],
    },
    isLoading: true,
  },
  partnerships: {
    isLoading: true,
    data: { active: [], pending: [], all: [] },
  },
  originalPartnershipData: { rules: [] },
};

const getters = {
  availablePartners(state) {
    return state.availablePartners;
  },
  partnership(state) {
    return state.partnership;
  },
  partnershipData(state) {
    return state.partnership.data || {};
  },
  originalPartnershipData(state) {
    return state.originalPartnershipData || {};
  },
  partnerships(state) {
    return state.partnerships;
  },
  activePartnerships(state) {
    return state.partnerships.data.active;
  },
  partnershipsByQuery:
    state =>
    (query = '') => {
      return {
        ...state.partnerships,
        data: {
          active: filterEntitiesByQuery(query, state.partnerships.data.active),
          pending: filterEntitiesByQuery(
            query,
            state.partnerships.data.pending,
          ),
        },
      };
    },
  isBuyer(state, getters) {
    return getters.partnershipData?.type === 'Buyer';
  },
  isAgency(state, getters) {
    return getters.partnershipData?.type === 'Agency';
  },
  isServicer(state, getters) {
    return getters.partnershipData?.type === 'Co-issuer';
  },
  isRateSheetProducer(state, getters) {
    return getters.partnershipData?.type === 'Rate Sheet Producer';
  },
  isAutomated(state, getters) {
    return getters.partnershipData?.is_automated;
  },
  isFreddieCRX(state, getters) {
    return getters.partnershipData?.is_freddie_crx;
  },
  isFreddie(state, getters) {
    return getters.partnershipData?.is_freddie;
  },
  isFannie(state, getters) {
    return getters.partnershipData?.is_fannie;
  },
  isGinnie(state, getters) {
    return getters.partnershipData?.is_ginnie;
  },
  isFannieUmbs(state, getters) {
    return getters.partnershipData?.is_fannie_umbs;
  },
  isFreddieUmbs(state, getters) {
    return getters.partnershipData?.is_freddie_umbs;
  },
};

const mutations = {
  clearCurrentPartnership(state) {
    state.partnership = {
      data: { rules: [] },
      isLoading: true,
    };
  },
  setAvailablePartners(state, payload) {
    state.availablePartners = payload;
  },
  setPartnership(state, payload) {
    state.partnership = payload;
    state.originalPartnershipData = { ...payload.data };
  },
  setPartnerships(state, payload) {
    state.partnerships = payload;
  },
  SET_PARTNERSHIP_FIELD(state, payload) {
    Object.keys(payload).forEach(field => {
      state.partnership.data[field] = payload[field];
    });
  },
};

const actions = {
  clearCurrentPartnership({ commit }) {
    commit('clearCurrentPartnership');
  },
  async getAvailablePartners({ commit }) {
    commit('setAvailablePartners', { isLoading: true });

    try {
      const availablePartners = await api.get(
        '/company/api/partnerships/partners',
      );

      commit('setAvailablePartners', {
        isLoading: false,
        data: { all: availablePartners.partners },
      });
    } catch (error) {
      onErrorHandler(error, 'useradmin-get-available-partners');
    }
  },
  async getPartnershipByPartnershipId({ commit }, partnershipId) {
    try {
      const partnership = await api.get(
        `/company/api/partnership/${partnershipId}`,
      );

      commit('setPartnership', {
        isLoading: false,
        data: {
          ...partnership.info,
          ...partnership.partnership,
          rules: partnership?.rules || [],
        },
      });
      commit(
        `biddingRules/${biddingRulesMutations.SET_BIDDING_RULES}`,
        partnership?.rules,
        { root: true },
      );
    } catch (error) {
      onErrorHandler(error, 'useradmin-get-partnership-detail');
    }
  },
  async getPartnerships({ commit }) {
    commit('setPartnerships', { isLoading: true });

    try {
      const partnerships = await api.get('/company/api/partnerships');

      const active =
        partnerships?.partners?.map(partner => {
          return {
            ...partner,
            status: 'Active',
          };
        }) || [];

      const pending =
        partnerships?.requests.map(request => {
          return {
            ...request,
            status: 'Pending',
          };
        }) || [];

      commit('setPartnerships', {
        isLoading: false,
        data: {
          active,
          pending,
          all: [...active, ...pending],
        },
      });
    } catch (error) {
      onErrorHandler(error, 'useradmin-get-partnerships');
    }
  },
  setPartnershipField({ commit }, payload) {
    commit('SET_PARTNERSHIP_FIELD', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import { triggerHedgeDataTask } from '@shared/services/hedgeData.js';
import { showSuccessMessage } from '@shared/utils/notifier.js';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import * as mutationType from '@sell/store/modules/hedgeData/mutationTypes.js';

export default {
  namespaced: true,
  state: () => ({
    isGeneratingHedgeData: false,
  }),
  getters: {
    isGeneratingHedgeData: state => state.isGeneratingHedgeData,
  },
  mutations: {
    [mutationType.SET_IS_GENERATING_STREAM_DATA](state, isGeneratingHedgeData) {
      state.isGeneratingHedgeData = isGeneratingHedgeData;
    },
  },
  actions: {
    async generateHedgeData(
      { commit, dispatch },
      { type /*'loan' or 'pool'*/, ids },
    ) {
      try {
        commit(mutationType.SET_IS_GENERATING_STREAM_DATA, true);
        const taskId = await triggerHedgeDataTask(type, ids);
        showSuccessMessage('Hedge data is generating...');

        await dispatch(
          'asyncTasks/getStreamData',
          {
            taskId: taskId,
            fileName: `hedge_data_${new Date().toLocaleDateString()}.xlsx`,
          },
          { root: true },
        );
      } catch (error) {
        onErrorHandler(error, 'sell-hedgeData-generateHedgeData');
      } finally {
        commit(mutationType.SET_IS_GENERATING_STREAM_DATA, false);
      }
    },
  },
};

export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const SET_LOANS = 'SET_LOANS';
export const SET_LOADING = 'SET_LOADING';
export const SET_ORG_ID = 'SET_ORG_ID';
export const SET_ACTION_REQUIRED_SUMMARY_LOADING =
  'SET_ACTION_REQUIRED_SUMMARY_LOADING';
export const SET_ACTION_REQUIRED_COUNT = 'SET_ACTION_REQUIRED_COUNT';
export const SET_ACTION_REQUIRED_LOANS = 'SET_ACTION_REQUIRED_LOANS';
export const SET_ACTION_REQUIRED_SUMMARY = 'SET_ACTION_REQUIRED_SUMMARY';
export const SET_ACTION_REQUIRED_LOANS_LOADING =
  'SET_ACTION_REQUIRED_LOANS_LOADING';
export const SET_AUTO_CANCELLED_COUNT = 'SET_AUTO_CANCELLED_COUNT';

export default {
  SET_SEARCH_TEXT,
  SET_LOANS,
  SET_LOADING,
  SET_ORG_ID,
  SET_ACTION_REQUIRED_SUMMARY_LOADING,
  SET_ACTION_REQUIRED_LOANS_LOADING,
  SET_ACTION_REQUIRED_COUNT,
  SET_ACTION_REQUIRED_LOANS,
  SET_ACTION_REQUIRED_SUMMARY,
  SET_AUTO_CANCELLED_COUNT,
};

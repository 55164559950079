export const CLEAR_CUSTOM_PARAMETER_DETAILS = 'CLEAR_CUSTOM_PARAMETER_DETAILS';
export const SET_IS_INVALID = 'SET_IS_INVALID';
export const SET_IS_INVALID_NAME = 'SET_IS_INVALID_NAME';
export const SET_IS_UPDATE_NEEDED = 'SET_IS_UPDATE_NEEDED';
export const SET_CUSTOM_PARAMETER = 'SET_CUSTOM_PARAMETER';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_VALUE_GROUPS = 'SET_VALUE_GROUPS';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const SET_TABLE_OPTIONS = 'SET_TABLE_OPTIONS';

export default {
  CLEAR_CUSTOM_PARAMETER_DETAILS,
  SET_IS_INVALID,
  SET_IS_INVALID_NAME,
  SET_IS_UPDATE_NEEDED,
  SET_CUSTOM_PARAMETER,
  SET_IS_LOADING,
  SET_VALUE_GROUPS,
  SET_SEARCH_TEXT,
  SET_TABLE_OPTIONS,
};

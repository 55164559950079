<template>
  <select class="h-100 position-absolute">
    <slot></slot>
  </select>
</template>

<script>
/* eslint-disable no-undef */
import { eqFilterValues } from '@shared/utils';
import { stripHTML } from '@shared/utils/form.js';

const defaultSettings = { width: '100%', hideSearch: true };
export default {
  name: 'PxSelect2',
  props: {
    options: { type: Array, default: () => [] },
    value: {
      type: [String, Object, Number, Boolean, Array],
      default: null,
    },
    settings: {
      type: [Object, Function],
      default: () => defaultSettings,
    },
    rounded: { type: Boolean, default: true },
    templateResult: null, // eslint-disable-line vue/require-default-prop
    templateSelection: null, // eslint-disable-line vue/require-default-prop
  },
  computed: {
    componentSettings() {
      const settings = {
        ...defaultSettings,
        ...(this.settings instanceof Function
          ? this.settings()
          : this.settings),
      };
      if (settings.hideSearch && !settings.multiple) {
        settings.minimumResultsForSearch = Infinity;
      }
      if (this.rounded) {
        settings.dropdownCssClass =
          (settings.dropdownCssClass || '') + ' rounded-dropdown';
        settings.containerCssClass =
          (settings.containerCssClass || '') + ' select2-rounded';
      }
      return settings;
    },
    componentOptions() {
      return this.options
        .filter(o => typeof o !== 'undefined' && o !== null)
        .map(option => {
          if (option?.text) {
            return { ...option, text: stripHTML(option.text) };
          }

          return option;
        });
    },
  },
  watch: {
    value: function (newValue) {
      // update value
      if (!this.componentSettings.multiple && !this.componentSettings.tags) {
        this.reInit(newValue);
      } else {
        if (
          newValue &&
          [...newValue].sort().join(',') !==
            [...$(this.$el).val()].sort().join(',')
        )
          this.reInit(newValue);
      }
    },
    options: function (newOptions) {
      // update options
      this.reInit(null, newOptions);
    },
  },
  mounted() {
    this.init();
  },
  destroyed: function () {
    this.destroy();
  },
  methods: {
    init(value = null, options = null) {
      const select2control = $(this.$el)
        .empty()
        .select2({
          ...this.componentSettings,
          data: options || this.componentOptions,
          templateResult: this.templateResult,
          templateSelection: this.templateSelection,
        })
        .val(value || this.value)
        .trigger('change')
        .on('change', this.onValueChanged.bind(this));

      if (
        this.componentSettings.hideSearch &&
        this.componentSettings.multiple &&
        !this.componentSettings.tags
      ) {
        select2control.on('select2:opening select2:closing', function () {
          $(this)
            .parent()
            .find('.select2-search__field')
            .prop('disabled', true)
            .css('display', 'none');
        });
      } else {
        select2control.on('select2:open', () => {
          // this is to fix an issue where opening a dropdown when another dropdown is already open, the filter would not get focused.
          // await this.$nextTick() also didn't work, we need to wait for a new paint before the new dropdown is opened, and we can
          // focus the new input.
          requestAnimationFrame(() => {
            document.querySelector('.select2-search__field').focus();
          });
        });
      }

      select2control.on('select2:closing', async () => {
        await this.$nextTick();
        this.focusRootElement();
      });
    },
    destroy() {
      $(this.$el).off().select2('destroy');
    },
    reInit(value = null, options = null) {
      this.destroy();
      this.init(value, options);
    },
    onValueChanged() {
      const newValue = $(this.$el).val();
      if (!eqFilterValues(this.value, newValue)) {
        this.$emit('input', newValue);
      }
    },
    open() {
      $(this.$el).select2('open');
    },
    focusRootElement() {
      //when selecting a value with keyboard inputs, the focus gets thrown to the body
      //element instead of back to the parent element of the Select.
      if (!this.$parent.$el.length) return;
      const parent = this.$parent.$el.querySelector('.select2-selection');
      parent.focus();
    },
  },
};
</script>

import api from '@shared/services/api.js';
import {
  showSuccessMessage,
  showErrorMessage,
  showOperationConflictMessage,
  showWarningMessage,
} from '@shared/utils/notifier.js';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import { formatMoney, strToPrecision } from '@shared/utils/converters.js';
import cartSvc from '@sell/services/cart.js';
import {
  intToLoanStatus,
  agencyTickersFreddie,
  umbsTickersFreddie,
  agencyTickersFannie,
  umbsTickersFannie,
  agencyTickersGinnie,
  commitmentPoolStatusValueMapping,
} from '@shared/constants';
import { capitalize } from '@shared/utils/stringFormatters.js';
import { getAllPoolLoans } from '@sell/services/api/commitmentPool.js';

import * as mutationTypes from './mutationTypes.js';
import {
  emptyCommitmentPool,
  getPoolReadyStatus,
  isPoolValid,
} from '@sell/utils/commitmentPool.js';
import { commitPools } from '@sell/services/api/commitmentPool.js';

export default {
  namespaced: true,
  state: () => ({
    loanToUnsell: null,
    isLoanUnselling: false,
    isCustomPool: false,
    isEditing: false,
    isCreatingPool: false,
    poolId: '',
    isCustom: false,
    pool: {
      buyer_org_name: null,
    },
    loanFields: [],
    cancelModalId: 'cancelModalDialog',
    removeModalId: 'removeModalDialog',
    extensionsModalId: 'extensionsModalDialog',
    pairoffModalId: 'pairoffModalDialog',
    poolLoans: [],
    isPoolLoansLoading: false,
    poolDraft: { ...emptyCommitmentPool },
    isCommitPoolLoading: false,
    prevRoute: null,
  }),
  getters: {
    loanToUnsell: state => state.loanToUnsell,
    isLoanUnselling: state => state.isLoanUnselling,
    poolId: state => state.poolId,
    isCreation: state => !state.poolId,
    isCustomPool: state => state.isCustomPool,
    pool: state => state.pool,
    canUnsell: (state, _getters, _rootState, rootGetters) =>
      !rootGetters['operations/hasActiveOperations'] && !state.isLoanUnselling,
    selectedAgency: (state, _getters, _rootState, rootGetters) =>
      rootGetters['core/orgAgencies']?.find(
        agency => agency.id === state.poolDraft?.buyer_org_id,
      ),
    isAgency: (_state, _getters) => {
      return _getters.isCreation || Boolean(_getters.selectedAgency);
    },
    isGinnie: (_state, _getters) =>
      _getters.selectedAgency?.ticker_symbol === agencyTickersGinnie,
    isFreddie: (_state, _getters) =>
      _getters.selectedAgency?.ticker_symbol === agencyTickersFreddie,
    isFreddieUmbs: (_state, _getters) =>
      _getters.selectedAgency?.ticker_symbol === umbsTickersFreddie,
    isFannie: (_state, _getters) =>
      _getters.selectedAgency?.ticker_symbol === agencyTickersFannie,
    isFannieUmbs: (_state, _getters) =>
      _getters.selectedAgency?.ticker_symbol === umbsTickersFannie,
    loanFields: state => state.loanFields,
    isAcceptedPool: (state, getters) =>
      (getters.isCreation && !state.isCustomPool) ||
      cartSvc.isForwardPool(state.pool),
    cancelModalId: state => state.cancelModalId,
    removeModalId: state => state.removeModalId,
    extensionsModalId: state => state.extensionsModalId,
    pairoffModalId: state => state.pairoffModalId,
    poolLoans: state => state.poolLoans || [],
    isPoolLoansLoading: state => state.isPoolLoansLoading,
    isSecurityPool: (state, _getters) =>
      state.pool?.is_security_pool ||
      _getters.isGinnie ||
      _getters.isFreddieUmbs ||
      _getters.isFannieUmbs,
    isEditing: state => state.isEditing,
    isCreatingPool: state => state.isCreatingPool,
    canEdit: (state, getters) => state.isEditing || getters.isCreation,
    isInputDisabled: (_state, getters) => !getters.canEdit,
    isCommitted: state =>
      state.pool.status === commitmentPoolStatusValueMapping.accepted,
    isCommitPoolLoading: state => state.isCommitPoolLoading,
    poolBuyerOrgName: state => state.pool.buyer_org_name,
    poolDraft: state => state.poolDraft,
    poolDraftAmount: state => state.poolDraft.amount,
    poolDraftServicingType: state => state.poolDraft.servicing_type,
    poolDraftCoissuer: state => state.poolDraft.coissuer,
    poolDraftProductCode: state => state.poolDraft.product_code,
    poolDraftPayupCode: state => state.poolDraft.payup_code,
    poolDraftSettlementMonth: state => state.poolDraft.settlement_month,
    poolDraftCouponRate: state => state.poolDraft.coupon_rate,
    poolDraftCommitmentType: state => state.poolDraft.commitment_type,
    poolDraftDecisionAt: state => state.poolDraft.decision_at,
    poolDraftContractId: state => state.poolDraft.contract_identifier,
    poolDraftBuyerOrgId: state => state.poolDraft.buyer_org_id,
    poolDraftAmortization: state => state.poolDraft.amortization_type,
    poolDraftContractDays: state => state.poolDraft.contract_days,
    poolDraftExternalPoolId: state => state.poolDraft.external_pool_id,
    prevRoute: state => state.prevRoute,
    readyStatus: state => getPoolReadyStatus(state.pool),
    isPoolValid: state => isPoolValid(state.pool),
  },
  mutations: {
    [mutationTypes.SET_LOAN_TO_UNSELL](state, loan) {
      state.loanToUnsell = loan;
    },
    [mutationTypes.SET_IS_LOAN_UNSELLING](state, isUnselling) {
      state.isLoanUnselling = isUnselling;
    },
    [mutationTypes.SET_POOL_ID](state, poolId) {
      state.poolId = poolId;
    },
    [mutationTypes.SET_POOL](state, pool) {
      state.pool = pool;
    },
    [mutationTypes.SET_LOAN_FIELDS](state, { getters }) {
      const loanFields = [
        { key: 'loan_number', label: 'Loan ID', sortable: true },
      ];
      loanFields.push(
        { key: 'price', label: 'Price', sortable: true },
        {
          key: 'unpaid_balance',
          label: 'UPB',
          sortable: true,
          formatter: v => formatMoney(v),
        },
      );
      if (!getters.isAgency) {
        loanFields.push({
          key: 'loan_program',
          label: 'Product',
          sortable: true,
        });
      }
      loanFields.push({
        key: 'rate',
        label: 'Rate',
        sortable: true,
        formatter: v => strToPrecision(v, 3) || 'N/A',
      });
      loanFields.push({
        key: 'status',
        label: 'Status',
        sortable: true,
        formatter: v => capitalize(intToLoanStatus[v]),
      });
      loanFields.push({
        key: 'best_ex',
        label: 'Best Ex',
        sortable: true,
        tdClass: 'text-align-center',
      });
      if (!getters.isAgency) {
        loanFields.push({
          key: 'external_id',
          label: 'ECID',
          tooltip: 'External Commitment ID',
          sortable: true,
        });
      }
      loanFields.push({ key: 'unsell', label: '' });
      state.loanFields = loanFields;
    },
    [mutationTypes.SET_POOL_LOANS](state, loans) {
      state.poolLoans = loans;
    },
    [mutationTypes.SET_IS_POOL_LOANS_LOADING](state, isPoolLoansLoading) {
      state.isPoolLoansLoading = isPoolLoansLoading;
    },
    [mutationTypes.SET_IS_CUSTOM_POOL](state, isCustomPool) {
      state.isCustomPool = isCustomPool;
    },
    [mutationTypes.SET_IS_EDITING](state, isEditing) {
      state.isEditing = isEditing;
    },
    [mutationTypes.SET_IS_CREATING_POOL](state, isCreatingPool) {
      state.isCreatingPool = isCreatingPool;
    },
    [mutationTypes.SET_POOL_DRAFT_AMOUNT](state, amount) {
      state.poolDraft.amount = amount.replaceAll(',', '');
    },
    [mutationTypes.SET_IS_COMMIT_POOL_LOADING](state, isCommitPoolLoading) {
      state.isCommitPoolLoading = isCommitPoolLoading;
    },
    [mutationTypes.SET_POOL_BUYER_ORG_NAME](state, buyerOrgName) {
      state.pool.buyer_org_name = buyerOrgName;
    },
    [mutationTypes.SET_POOL_DRAFT](state, poolDraft) {
      state.poolDraft = poolDraft;
    },
    [mutationTypes.SET_POOL_DRAFT_AMOUNT](state, amount) {
      state.poolDraft.amount = amount.replaceAll(',', '');
    },
    [mutationTypes.SET_POOL_DRAFT_PRODUCT](state, productCode) {
      state.poolDraft.product_code = productCode;
    },
    [mutationTypes.SET_POOL_DRAFT_COUPON_RATE](state, couponRate) {
      state.poolDraft.coupon_rate = couponRate;
    },
    [mutationTypes.SET_POOL_DRAFT_SETTLEMENT_MONTH](state, settlementMonth) {
      state.poolDraft.settlement_month = settlementMonth;
    },
    [mutationTypes.SET_POOL_DRAFT_SERVICING_TYPE](state, servicingType) {
      state.poolDraft.servicing_type = servicingType;
    },
    [mutationTypes.SET_POOL_DRAFT_COISSUER](state, coissuer) {
      state.poolDraft.coissuer = coissuer;
    },
    [mutationTypes.SET_POOL_DRAFT_PAYUP](state, payupCode) {
      state.poolDraft.payup_code = payupCode;
    },
    [mutationTypes.SET_POOL_DRAFT_COMMITMENT_TYPE](state, commitmentType) {
      state.poolDraft.commitment_type = commitmentType;
    },
    [mutationTypes.SET_POOL_DRAFT_DECISION_AT](state, decisionAt) {
      state.poolDraft.decision_at = decisionAt;
    },
    [mutationTypes.SET_POOL_DRAFT_CONTRACT_ID](state, contractIdentifier) {
      state.poolDraft.contract_identifier = contractIdentifier;
    },
    [mutationTypes.SET_POOL_DRAFT_BUYER_ORG_ID](state, buyerOrgId) {
      state.poolDraft.buyer_org_id = buyerOrgId;
    },
    [mutationTypes.SET_POOL_DRAFT_AMORTIZATION](state, amortization) {
      state.poolDraft.amortization_type = amortization;
    },
    [mutationTypes.SET_POOL_DRAFT_CONTRACT_DAYS](state, contractDays) {
      state.poolDraft.contract_days = contractDays;
    },
    [mutationTypes.SET_POOL_DRAFT_EXTERNAL_POOL_ID](state, externalPoolId) {
      state.poolDraft.external_pool_id = externalPoolId;
    },
    [mutationTypes.SET_PREV_ROUTE](state, prevRoute) {
      state.prevRoute = prevRoute;
    },
  },
  actions: {
    setLoanToUnsell({ commit }, loan) {
      commit(mutationTypes.SET_LOAN_TO_UNSELL, loan);
    },
    setIsLoanUnselling({ commit }, isUnselling) {
      commit(mutationTypes.SET_IS_LOAN_UNSELLING, isUnselling);
    },
    setPoolId({ commit }, poolId) {
      commit(mutationTypes.SET_POOL_ID, poolId);
    },
    setPrevRoute({ commit }, prevRoute) {
      commit(mutationTypes.SET_PREV_ROUTE, prevRoute);
    },
    setPool({ commit }, pool) {
      commit(mutationTypes.SET_POOL, pool);
    },
    setPoolDraft({ commit }, poolDraft) {
      commit(mutationTypes.SET_POOL_DRAFT, {
        ...emptyCommitmentPool,
        ...poolDraft,
      });
    },
    setIsCustomPool({ commit }, isCustomPool) {
      commit(mutationTypes.SET_IS_CUSTOM_POOL, isCustomPool);
    },
    setIsEditing({ commit }, isEditing) {
      commit(mutationTypes.SET_IS_EDITING, isEditing);
    },
    setIsCreatingPool({ commit }, isCreatingPool) {
      commit(mutationTypes.SET_IS_CREATING_POOL, isCreatingPool);
    },
    async unsellLoan({ commit, dispatch, getters, rootGetters }) {
      commit(mutationTypes.SET_IS_LOAN_UNSELLING, true);

      const hasActiveOperations = rootGetters['operations/hasActiveOperations'];
      if (hasActiveOperations) {
        showOperationConflictMessage();
        return;
      }

      const loan = getters.loanToUnsell;

      try {
        const data = await api.get(
          `/sell/api/remove_from_commitment/${loan.id}`,
        );
        if (data.task_id) {
          const taskResult = await dispatch(
            'asyncTasks/getTaskResult',
            {
              taskId: data.task_id,
            },
            { root: true },
          );
          if (taskResult.task_successful) {
            showSuccessMessage(data.message);
            await dispatch('getPoolData');
          } else {
            showErrorMessage('The loan was not removed from commitment.');
          }
        }
      } catch (error) {
        if (error?.response?.status === 409) {
          showOperationConflictMessage(error);
          return dispatch(
            'operations/trackActiveOperations',
            {},
            { root: true },
          );
        }
        showErrorMessage('The loan was not removed from commitment.');
        onErrorHandler(error, 'sell-pool-unsell_loan', [403], true);
      } finally {
        commit(mutationTypes.SET_IS_LOAN_UNSELLING, false);
      }
    },
    async getPoolData({ dispatch, getters }) {
      const { isCreation, poolId } = getters;
      const promises = [dispatch('getPool')];
      if (!isCreation && poolId) {
        promises.push(dispatch('audits/getAuditRecords', {}, { root: true }));
        promises.push(dispatch('getPoolLoans'));
      }
      await Promise.all(promises);
    },
    async getPool({ getters, dispatch }) {
      const { isCreation, poolId } = getters;
      if (isCreation) return;
      try {
        const { pool } = await api.get(`/sell/api/committed_pools/${poolId}/`);
        dispatch('setPool', pool);
      } catch (error) {
        onErrorHandler(error, 'sell-get-committed-pool', [403], true);
      }
    },
    setLoanFields({ commit, getters }) {
      commit(mutationTypes.SET_LOAN_FIELDS, { getters });
    },
    async getPoolLoans({ commit, getters, rootGetters }) {
      const { poolId } = getters;

      if (!poolId) {
        return;
      }

      try {
        commit(mutationTypes.SET_IS_POOL_LOANS_LOADING, true);
        const { results: loans } = await getAllPoolLoans(
          rootGetters['organizations/currentOrganizationId'],
          poolId,
        );
        commit(mutationTypes.SET_POOL_LOANS, loans);
      } catch (error) {
        onErrorHandler(error, 'sell-get-committed-pool', [403], true);
      } finally {
        commit(mutationTypes.SET_IS_POOL_LOANS_LOADING, false);
      }
    },
    setPoolAmount({ commit }, amount) {
      commit(mutationTypes.SET_POOL_DRAFT_AMOUNT, amount);
    },
    async commitPool({ commit, getters, dispatch }) {
      const { poolId, isPoolValid } = getters;
      if (!poolId || !isPoolValid) {
        return;
      }
      try {
        commit(mutationTypes.SET_IS_COMMIT_POOL_LOADING, true);

        const result = await commitPools([
          {
            id: poolId,
          },
        ]);
        const groupTaskResult = await dispatch(
          'asyncTasks/getTaskResult',
          {
            taskId: result.commit_task_id,
            isShort: true,
          },
          { root: true },
        );

        if (groupTaskResult.task_successful) {
          const commitTaskResults = groupTaskResult.task_result || [];
          if (
            !commitTaskResults.length ||
            commitTaskResults.some(
              taskResult => taskResult.user_message !== 'success',
            )
          ) {
            showMessages(commitTaskResults);
          }
          await dispatch('getPoolData');
          showSuccessMessage('Pool Committed Successfully.');
        }
      } catch (error) {
        onErrorHandler(error, 'sell-commit-pool', [403], true);
      } finally {
        commit(mutationTypes.SET_IS_COMMIT_POOL_LOADING, false);
      }
    },
    async createPool({ commit, getters }) {
      commit(mutationTypes.SET_IS_CREATING_POOL, true);
      let data = {};
      try {
        const { poolDraft, isCustomPool } = getters;
        const payload = poolDraft;
        if (isCustomPool) {
          payload.status = commitmentPoolStatusValueMapping.created;
        }
        data = await api.post('/sell/api/committed_pools/', payload);
        if (!data) {
          onErrorHandler(
            'Pool was not created. Please try again!',
            'sell-patch-committed-pool',
          );
        } else {
          showSuccessMessage('Commitment pool has been created!');
        }
      } catch (error) {
        onErrorHandler(error, 'sell-patch-committed-pool');
      } finally {
        commit(mutationTypes.SET_IS_CREATING_POOL, false);
      }
      return data;
    },
    initPool({ commit, getters }, poolObject) {
      const { pool } = getters;
      commit(mutationTypes.SET_POOL, { ...pool, ...poolObject });
    },
  },
};

const filterTaskResultsByType = (taskResults, type) => {
  return taskResults.filter(taskResult => taskResult.user_message === type);
};

const showMessages = taskResults => {
  const messageHandlers = {
    success: showSuccessMessage,
    warning: showWarningMessage,
    error: showErrorMessage,
  };
  Object.entries(messageHandlers).forEach(([key, func]) => {
    const filteredResults = filterTaskResultsByType(taskResults, key);
    filteredResults.forEach(result => {
      func(result.user_message);
    });
  });
};

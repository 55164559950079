<template>
  <div class="px-multi-file-upload" :class="{ 'disabled': disabled }">
    <label
      class="drag-and-drop-zone"
      for="attachments"
      @dragover="dragover"
      @drop="handleDrop"
    >
      <span v-if="!files.length" class="default-text">
        Drag and Drop
        <br />
        OR
        <br />
        Click to Select Files
      </span>
      <div v-else class="file-viewer">
        <div
          v-for="(file, index) in files"
          :key="index"
          class="file"
          :class="{ removing: index === removingIndex, loading: file.loading }"
          @click.prevent
        >
          <button
            v-b-tooltip.hover="{
              customClass: 'px-tooltip',
              variant: 'light-info',
              title: 'Remove Attachment',
              placement: 'top',
            }"
            aria-label="remove file"
            class="barebone remove-button"
            @click="confirmRemove($event, index)"
          >
            <px-icon icon="xmark" size="sm" />
          </button>
          <div v-if="file.loading" class="thumbnail-icon">
            <px-icon class="fa fa-spin" icon="rotate" size="md" />
          </div>
          <px-icon v-else class="thumbnail-icon" :icon="getIcon(file)" />
          <transition name="fade">
            <div v-if="index === removingIndex" class="confirm-remove">
              <div class="confirm-text">Remove this attachment?</div>
              <span class="action-container">
                <b-button
                  aria-label="cancel remove file"
                  class="short-autosize-pill btn-short"
                  variant="outline-primary"
                  @click="cancelRemove($event, index)"
                >
                  Cancel
                </b-button>
                <b-button
                  aria-label="confirm remove file"
                  class="short-autosize-pill btn-short"
                  variant="danger"
                  @click="removeFile($event, index)"
                >
                  Yes
                </b-button>
              </span>
              <div class="name">{{ file.file.name }}</div>
            </div>
          </transition>
          <div class="name">{{ file.file.name }}</div>
        </div>
      </div>
      <span class="footer-text" :class="{ invalid }">
        <div v-if="invalid">{{ invalidText }}</div>
        <div>
          {{ acceptMessage || accept }}
        </div>
      </span>

      <input
        id="attachments"
        ref="file"
        :accept="accept"
        aria-label="file select input"
        class="barebones"
        :disabled="disabled"
        multiple
        type="file"
        @change="addFileAttachments"
      />
    </label>
    <div
      v-if="totalSizeLimit"
      class="size-limit"
      :class="{ invalid: sizeLimitExceeded }"
    >
      {{ displayTotalFileSize }} of {{ displayTotalFileSizeLimit }} Max
    </div>
  </div>
</template>

<script>
import { filesize } from 'filesize';

export default {
  name: 'PxMultiFileUpload',
  // We added a few custom fields to the file object
  // loading - which isn't controlled by this component, the uploader will need to update the loading state.
  // error - same as loading, if an error occurs during upload use this field to tell the component a file failed.
  // skip - which is used to skip adding a file if the file is too large, or is the wrong file type.
  // pending - to denote whether a file has been "saved" or not from outside of this component.
  model: {
    prop: 'files',
    event: 'change',
  },
  props: {
    files: {
      type: Array,
      default: () => [],
    },
    accept: {
      type: String,
      default: null,
    },
    acceptMessage: {
      type: String,
      default: null,
    },
    fileSizeLimit: {
      type: Number,
      default: null,
    },
    totalSizeLimit: {
      type: Number,
      default: null,
    },
    errorText: { type: String, default: 'Supported file types are' },
    invalid: {
      type: Boolean,
      default: false,
    },
    invalidText: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      removingIndex: null,
    };
  },
  computed: {
    totalFileSize() {
      return this.files.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.file.size;
      }, 0);
    },
    displayTotalFileSize() {
      //human readable file size
      return this.filesize(this.totalFileSize, { round: 0 });
    },
    displayTotalFileSizeLimit() {
      return this.filesize(this.totalSizeLimit, { round: 0 });
    },
    sizeLimitExceeded() {
      return this.totalFileSize > this.totalSizeLimit;
    },

    isInvalid() {
      return this.invalid || this.sizeLimitExceeded;
    },
  },
  watch: {
    sizeLimitExceeded: function (newVal) {
      this.$emit('file-size-exceeded', newVal);
    },
  },
  methods: {
    filesize,
    addFileAttachments() {
      //get files from the input element, if theres an error don't keep the file. loop through all correct files and upload them
      [...this.$refs.file.files].forEach(file => {
        this.validateFileSize(file, this.fileSizeLimit);
        this.validateFileType(file, this.accept);

        if (file.skip) return;

        // eslint-disable-next-line vue/no-mutating-props
        this.files.push({ file, loading: false, error: null, pending: false });
      });

      //emit all the files via v-model
      this.$emit('change', this.files);
    },
    validateFileSize(file, limit) {
      if (file.size > limit) {
        file.skip = true;
        this.$notify({
          title: `${file.name} too large`,
          text: `Files must be smaller than ${this.filesize(limit, {
            round: 0,
          })}`,
          type: 'warn',
        });
      }
    },
    validateFileType(file, accepts) {
      const acceptedFormats = accepts.replaceAll('.', '').replaceAll(', ', '|');
      const regex = new RegExp(`(.*?)\\.(${acceptedFormats})$`, 'i');

      if (!regex.test(file.name) && accepts !== '') {
        file.skip = true;
        this.$notify({
          title: `${file.name} type not supported`,
          text: `${this.errorText} ${accepts}`,
          type: 'warn',
        });
      }
    },
    confirmRemove($event, index) {
      $event.preventDefault();
      this.removingIndex = index;
    },
    cancelRemove($event) {
      $event.preventDefault();
      this.removingIndex = null;
    },
    removeFile($event, index) {
      $event.preventDefault();
      this.$emit('removed', { ...this.files[index], index });
      this.removingIndex = null;
      this.$refs.file.value = '';
    },
    handleDrop($event) {
      //drag and drop doesn't care about the accepted file types, so we have to validate the file formats ourselves, and file size.
      $event.preventDefault();
      if (this.disabled) {
        return;
      }
      this.$refs.file.files = $event.dataTransfer.files;

      this.addFileAttachments();
    },
    dragover(e) {
      e.preventDefault();
    },
    getIcon(file) {
      // List of official MIME Types: http://www.iana.org/assignments/media-types/media-types.xhtml
      if (file.loading) return 'rotate';
      else if (file.error) return 'file-circle-exclamation';

      const icon_classes = {
        // Media
        image: 'file-image',
        'application/pdf': 'file-pdf',
        'application/msword': 'file-word',
        'application/vnd.ms-word': 'file-word',
        'application/vnd.oasis.opendocument.text': 'file-word',
        'application/vnd.openxmlformats-officedocument.wordprocessingml':
          'file-word',
        'application/vnd.ms-excel': 'file-spreadsheet',
        'application/vnd.openxmlformats-officedocument.spreadsheetml':
          'file-spreadsheet',
        'application/vnd.oasis.opendocument.spreadsheet': 'file-spreadsheet',
        'application/vnd.ms-powerpoint': 'file-lines',
        'application/vnd.openxmlformats-officedocument.presentationml':
          'file-lines',
        'application/vnd.oasis.opendocument.presentation': 'file-lines',
        'text/plain': 'file-lines',
        'text/html': 'file-lines',
        'application/json': 'file-lines',
        'application/gzip': 'file-lines',
        'application/zip': 'file-lines',
      };
      let icon = 'file-lines';
      for (const key in icon_classes) {
        if (icon_classes[key]) {
          if (file.file.type.search(key) === 0) {
            // Found it
            icon = icon_classes[key];
          }
        }
      }
      return icon;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/variables';

.px-multi-file-upload {
  .drag-and-drop-zone {
    background-color: $lightest-gray;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 475px;
    text-align: center;
    border-radius: 6px;
    cursor: pointer;

    .default-text {
      margin-top: auto;
      align-self: center;
    }
    .footer-text {
      margin-top: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 1rem 0;
      font-size: 12px;
      color: $light-gray;
      &.invalid {
        color: $polly-orange;
      }
    }
  }

  &.disabled {
    .drag-and-drop-zone {
      cursor: not-allowed;
    }
  }

  .file-viewer {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    width: 100%;
    padding: 2rem;
    overflow-y: auto;

    .file {
      width: calc(25% - 1.125rem);
      display: flex;
      min-width: 150px;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      position: relative;
      font-size: 10px;
      cursor: default;
      border: 1px solid transparent;
      transition: border 150ms ease-in-out;
      padding: 0.5rem;
      border-radius: 20px;

      &.loading {
        .thumbnail-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #828282;
          background-color: #fff;
          height: 81px;
          width: 59px;
          border-radius: 5px;
          font-size: 40px;
        }
      }

      &:hover,
      &:focus-within {
        border: 1px solid $polly-light-blue;
      }
      .thumbnail-icon {
        height: 81px;
        width: 59px;
        font-size: 70px;
      }

      .remove-button {
        opacity: 0;
        height: 20px;
        width: 20px;
        position: absolute;
        border-radius: 9999px;
        border: 1px solid $polly-light-blue;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -4px;
        right: -4px;
        transition: opacity 150ms ease-in-out;
        background-color: $lightest-gray;

        .px-icon {
          //close icon has a bunch of custom css that needs to be over written
          color: $polly-light-blue;
          float: unset;
          font-size: unset;
          font-weight: unset;
          opacity: unset;
          text-shadow: unset;
          line-height: unset;
        }
      }

      .confirm-remove {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        font-size: 12px;
        background-color: #fff;
        position: absolute;
        border-radius: 20px;
        padding: 0.5rem;
        gap: 0.5rem;
        inset: 0;

        .confirm-text {
          margin-top: auto;
        }
      }
      .name {
        word-break: break-all;
        font-size: 10px;
      }

      &:hover:not(.removing),
      &:focus-within:not(.removing) {
        .remove-button,
        .remove-button:hover {
          opacity: 1;
        }
      }
    }
  }
  button:focus-visible {
    outline: 2px solid $polly-dark-blue;
    outline-offset: 2px;
  }
  .attachment-input {
    display: flex;
    justify-content: center;

    label {
      color: $polly-light-blue;
      padding: 4px 8px;
      border-radius: 6px;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }

      &:has(input[type='file']:focus-visible) {
        outline: 2px solid $polly-dark-blue;
      }
    }
  }

  input[type='file'] {
    width: 0px;
    height: 0px;
    position: absolute;
  }

  .remove-attachment {
    color: $darker-gray;

    &:hover {
      color: $error-red;
    }
  }
}

.size-limit {
  text-align: right;
  color: $polly-light-blue;

  &.invalid {
    color: $polly-orange;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 250ms;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

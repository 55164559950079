<template>
  <b-button
    v-if="isLoading"
    class="text-center"
    :class="[
      'd-flex',
      'px-button',
      {
        'wide-pill': pill,
        'btn-short px-button--round': round,
        'justify-content-center align-items-center': textCenter,
      },
    ]"
    disabled
  >
    <px-icon :class="{ 'icon spinning': isLoading }" icon="arrows-rotate" />
  </b-button>
  <b-button
    v-else
    v-bind="$attrs"
    :class="[
      'd-flex',
      'px-button',
      {
        'wide-pill': pill,
        'btn-short px-button--round': round,
        'justify-content-center align-items-center': textCenter,
      },
    ]"
    :pill="pill"
    :round="round"
    v-on="$listeners"
  >
    <px-icon v-if="icon" :class="[iconClass]" :icon="icon"></px-icon>

    <span :class="['my-auto', { 'ml-2': icon && !round }]">
      <slot name="default"></slot>
    </span>

    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot v-bind="scope" :name="slot" />
    </template>
  </b-button>
</template>

<script>
export default {
  name: 'PxButton',
  inheritAttrs: true,
  props: {
    label: {
      default: '',
      required: false,
      type: String,
    },
    pill: {
      default: false,
      required: false,
      type: Boolean,
    },
    round: {
      default: false,
      required: false,
      type: Boolean,
    },
    icon: {
      default: '',
      required: false,
      type: String,
    },
    iconClass: {
      default: '',
      required: false,
      type: String,
    },
    textCenter: {
      default: true,
      required: false,
      type: Boolean,
    },
    isLoading: {
      default: false,
      required: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/variables.scss';

.px-button {
  &.wide-pill {
    height: 17px;
    width: auto;
    white-space: nowrap;
  }
  &--round {
    height: 20px;
    width: 20px;
    padding: 0;
    min-width: auto;
    border-radius: 50%;
    background-color: $white;

    &:hover,
    &:focus {
      outline: none;
      background-color: $white;
    }
  }

  &:focus-visible {
    outline: 2px solid $polly-dark-blue;
  }
}
</style>

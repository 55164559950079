<template>
  <b-badge
    class="px-badge"
    :class="{ [variant]: true, small, rectangle, accentBorder }"
  >
    <slot />
  </b-badge>
</template>

<script>
export default {
  name: 'PxBadge',
  props: {
    small: {
      type: Boolean,
    },
    rectangle: {
      type: Boolean,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    accentBorder: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/variables';

@mixin badge-color($background, $foreground: white, $color: null) {
  border-color: $background;
  background-color: $background;
  color: $foreground;

  &--outline {
    $color: $background !default;
    border-color: $background;
    background-color: $foreground;
    color: $color;
  }
  &.accentBorder {
    border-color: $foreground;
  }
}

.px-badge {
  border: 1px solid $gray-500;
  border-radius: 10px;
  padding: 3px 6px;
  font-family: $font-family;
  color: white;

  &.rectangle {
    border-radius: 4px;
  }

  &.small {
    border-radius: 8px;
    padding: 2px 4px;
  }

  &.primary {
    @include badge-color($primary);
  }

  &.red {
    @include badge-color($error-red-light, $error-red);
  }

  &.turquoise {
    @include badge-color($torquoise-light, $torquoise);
  }

  &.gray {
    @include badge-color($gray-500, $dark-gray);
  }

  &.dark-blue {
    @include badge-color($polly-dark-blue);
  }

  &.light-blue {
    @include badge-color($polly-light-blue);
  }
  &.light-gray {
    @include badge-color($gray-500, $white, $darkest-gray);
  }
  &.unavailable {
    @include badge-color($white, $warning);
  }
}
</style>

import api from '@shared/services/api.js';

export async function getLockDeskLoans() {
  return api.get('/pe/api/lock-desk/loans');
}

export async function getLockDeskLoan(loanId) {
  return api.get(`/pe/api/lock-desk/loans/${loanId}`);
}

export async function getActionRequiredCount() {
  return api.get('/pe/api/lock-desk/loans/action-required/count/');
}

export async function getActionRequiredSummary() {
  return api.get('/pe/api/lock-desk/loans/action-required/summary');
}

//TODO point this function to the correct endpoint.
export async function getActionRequiredLoans(options) {
  const url = api.constructUrl(
    '/pe/api/lock-desk/loans/action-required/',
    options,
  );
  return api.get(url);
}

/** Returns list of loan resource locks
 *
 *  @param {String} loanId los loan id.
 *
 *  @returns {Object} The result.
 */
export async function getIsLoanResourceLockedExternally(loanId) {
  return api.get(`/pe/api/loans/${loanId}/is-resource-locked-externally/`);
}

/** Returns dict of loans disconnected from pipeline monitoring */
export async function getDisconnectedPipelineMonitoringLoans() {
  const url = api.constructUrl(
    `/pe/api/pipeline-monitoring/disconnected-loans`,
  );
  return api.get(url);
}
/** Disable pipeline monitoring for selected loans
 *
 *  @param {Array} loanIds los loan ids.
 *
 *  @param {Array} loanNumbers los loan Numbers.
 */
export async function disconnectPipelineMonitoringLoans(loanIds, loanNumbers) {
  const url = api.constructUrl(
    `/pe/api/pipeline-monitoring/disconnected-loans`,
  );
  return api.post(url, {
    'mode': 'add',
    'loan_ids': loanIds,
    'loan_numbers': loanNumbers,
  });
}

<template>
  <b-form-radio-group>
    <px-radio
      v-for="(option, index) in options"
      :id="(id || cbName) + '_' + index"
      :key="index"
      ref="check"
      :checked="selected === option.value"
      :disabled="disabled || option.disabled"
      :full-color="fullColor"
      :name="cbName + '_' + index"
      :option="option"
      :stacked="stacked"
      :state="state"
      :with-icon="withIcon"
      @valueChanged="onValueChanged"
    />
  </b-form-radio-group>
</template>

<script>
export default {
  name: 'PxRadioGroup',
  model: {
    prop: 'value',
    event: 'valueChanged',
  },
  props: {
    value: { type: [Object, String, Number, Boolean], default: '' },
    id: { type: String, default: '' },
    name: { type: String, required: true },
    fullColor: { type: Boolean },
    withIcon: { type: Boolean },
    stacked: { type: Boolean },
    state: { type: Boolean, default: undefined },
    options: { type: Array, default: () => [] },
    disabled: { type: Boolean },
  },
  data() {
    return {
      selected: this.value,
    };
  },
  computed: {
    cbName() {
      return `radio-${this.name}`;
    },
  },
  watch: {
    value(newVal) {
      this.selected = newVal;
    },
  },
  methods: {
    onValueChanged(value) {
      this.selected = value;
      this.$emit('valueChanged', this.selected);
    },
  },
};
</script>

<style scoped></style>

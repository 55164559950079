<template>
  <div class="px-search border-primary rounded text-primary">
    <px-icon :inline="false" icon="magnifying-glass" />
    <b-input
      autocomplete="false"
      class="form-control-sm"
      :value="searchValue"
      @input="setSearchValue"
    />
    <span v-show="!searchValue" class="px-search-placeholder">Search</span>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import debounce from 'lodash/fp/debounce';

export default {
  name: 'PxSearch',
  props: {
    action: { type: Function, required: true },
  },
  computed: {
    ...mapGetters({
      searchValue: 'searchValue/searchValue',
    }),
  },
  watch: {
    searchValue: debounce(500, async function () {
      await this.action();
    }),
  },
  async mounted() {
    this.setSearchValue('');
  },
  methods: {
    ...mapMutations({
      setSearchValue: 'searchValue/setSearchValue',
    }),
  },
};
</script>

<style lang="scss">
.px-search {
  padding-left: 0.5rem;
  position: relative;
  height: 36px;
  min-width: 170px;
  display: flex;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  overflow: hidden;

  .px-search-placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
  }

  .form-control {
    position: absolute;
    height: 34px;
    border-width: 1px;
    background: none;
    padding-left: 1.5rem;
    border: none;
    z-index: 2;

    &:focus {
      background-color: transparent;

      & + .lx-search-placeholder {
        display: none;
      }
    }
  }
}
</style>

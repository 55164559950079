<template>
  <div :class="parentContainerClasses">
    <component
      :is="tooltipMessage ? 'side-nav-tooltip' : 'div'"
      :content="tooltipMessage"
      :is-active="active"
    >
      <router-link class="nav-button" exact-path :to="to">
        <slot name="parent-item">
          <div v-if="icon" class="nav-icon">
            <slot name="icon">
              <px-icon :icon="icon"></px-icon>
            </slot>
          </div>
          <div v-if="largeText" :class="largeHeaderClasses">
            {{ largeText }}
          </div>
          <div v-if="text" :class="headerClasses">
            {{ text }}
          </div>
        </slot>
      </router-link>
    </component>
    <div class="sub-items">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { stripTrailingSlash } from '@shared/utils/urlFormatters.js';
import SideNavTooltip from './SideNavTooltip.vue';

export default {
  components: { SideNavTooltip },
  provide: function () {
    return {
      setParentActive: this.setParentActive,
    };
  },
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
    largeText: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    emphasizeText: {
      type: Boolean,
      default: true,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  data: function () {
    return {
      active: false,
    };
  },
  computed: {
    headerClasses() {
      return {
        'nav-text': true,
        'medium-screen-text': this.largeText,
        'nav-text-header': this.emphasizeText,
      };
    },
    largeHeaderClasses() {
      const classList = ['nav-text', 'big-screen-text'];
      if (this.emphasizeText) {
        classList.push('nav-text-header');
      }
      return classList;
    },
    parentContainerClasses() {
      const classList = ['parent-item', 'flex'];
      if (this.active) {
        classList.push('active-parent-item');
      }
      return classList;
    },
    tooltipMessage() {
      if (!this.showTooltip) {
        return null;
      }
      if (this.tooltip) {
        return this.tooltip;
      }
      if (this.largeText) {
        return this.largeText;
      }
      return this.text;
    },
    targetPath() {
      if (typeof this.to === 'object') {
        return this.$router.resolve(this.to).route.path;
      } else {
        return this.to;
      }
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (
          stripTrailingSlash(to.path) === stripTrailingSlash(this.targetPath)
        ) {
          this.setParentActive();
        } else if (this.active) {
          this.active = false;
        }
      },
    },
  },
  methods: {
    setParentActive() {
      this.active = true;
    },
  },
};
</script>

import { mapGetters } from 'vuex';
import {
  PRICER_UI_QA_IS_MOBILE,
  PRICER_UI_REDESIGN_FRONTEND,
} from '@shared/constants/flags';
import { DEFAULT_ROUTES } from '@pe/router';

// frontend/src/pe/router/index.js
const LOAN_SCENARIOS_ROUTE_NAME = 'loan-scenarios';
const LOAN_PIPELINE_ROUTE_NAME = DEFAULT_ROUTES.loans;

// List of old app routing names which we intend to redirect to the new app routes
// Ensure the defined old route includes meta field "newAppPath" (new app's path)
// this will allow us redirect to the new app route
// "newAppPath" implies /app
// e.g.) { [...], newAppPath: '/pe/new-loan-scenarios' }
// ends up being directed to '/app/pe/new-loan-scenarios'
const NEW_APP_REDIRECT_ROUTES = [
  LOAN_SCENARIOS_ROUTE_NAME,
  LOAN_PIPELINE_ROUTE_NAME,
];

export default {
  data() {
    return {
      newAppLoading: false,
    };
  },
  computed: {
    ...mapGetters('core', ['isFlagActive']),
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        // NOTE: SPECIAL CASE FOR PRICER UI REDESIGN
        // redirect to new app UI
        if (
          this.isFlagActive(PRICER_UI_REDESIGN_FRONTEND) &&
          NEW_APP_REDIRECT_ROUTES.includes(to.name) &&
          (this.isFlagActive(PRICER_UI_QA_IS_MOBILE) || this.isMobile)
        ) {
          this.newAppLoading = true;
          const toPath = to.meta?.newAppPath ? to.meta.newAppPath : to.path;
          location.assign(
            import.meta.env.DEV
              ? `http://localhost:8080${toPath}`
              : `/app${toPath}`,
          );
        }
      },
    },
  },
};

<template>
  <div class="chat-widget__toggle" :class="{ 'animate': animate }">
    <span v-if="isExpanded" class="chat-widget__toggle-icon">-</span>
    <img
      v-if="!isExpanded"
      alt="Profile Image"
      class="chat-widget__toggle-icon"
      :src="toggleIcon"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ChatWidgetToggle',
  props: {
    isExpanded: {
      type: Boolean,
      required: true,
    },
    toggleIcon: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      animate: false,
    };
  },
  computed: {
    ...mapGetters({
      allMessages: 'copilot/allMessages',
    }),
  },
  watch: {
    // eslint-disable-next-line vue/no-undef-properties
    allMessages() {
      const lastMessage = this.allMessages[this.allMessages.length - 1];
      // Only trigger animation if the chat is not expanded and once the most recent message has received a response and was tracked (given an id)
      if (!this.isExpanded && lastMessage?.id) {
        this.triggerAnimation();
      }
    },
  },
  methods: {
    triggerAnimation() {
      this.animate = true;
      setTimeout(() => {
        this.animate = false;
      }, 1000); // Duration of the animation
    },
  },
};
</script>

<style lang="scss">
@import '@shared/styles/variables.scss';

.chat-widget__toggle {
  width: 60px;
  height: 60px;
  box-shadow: 0 0 5px 0 #755afc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 0.15s ease-in-out;
}

.chat-widget__toggle:hover {
  box-shadow: 0 0 10px 0 #755afc;
}

.chat-widget__toggle-icon {
  color: $polly-light-blue;
  font-size: 20px;
  user-select: none;
  width: 60px;
  text-align: center;
}

@keyframes jiggle {
  0%,
  100% {
    transform: translateX(0);
  }
  15% {
    transform: translateX(-5px);
  }
  30% {
    transform: translateX(5px);
  }
  45% {
    transform: translateX(-5px);
  }
  60% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  90% {
    transform: translateX(5px);
  }
}

.chat-widget__toggle.animate {
  animation: jiggle 0.7s ease-in-out;
}
</style>

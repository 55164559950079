<template>
  <div class="toast" :class="[type, { grouped: count }]" v-on="$listeners">
    <div class="icon">
      <i class="fas" :class="icon(type)"></i>
    </div>
    <div class="content">
      <div class="title">{{ title }} {{ count > 1 ? `(${count})` : '' }}</div>
      <div class="body">{{ text }}</div>
    </div>
    <button
      v-if="!hideClose"
      class="dismiss-btn btn btn-short barebone"
      @click.exact.stop="$emit('remove-toast', id)"
    >
      <px-icon icon="xmark" size="sm" />
    </button>
  </div>
</template>

<script>
export default {
  name: 'ToastBody',
  props: {
    id: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      default: 'success',
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    count: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      iconMap: {
        warn: 'fa-exclamation-circle',
        success: 'fa-check-circle',
        error: 'fa-times-circle',
        'soft-success': 'fa-circle-check',
      },
    };
  },
  methods: {
    icon(type) {
      return this.iconMap[type];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/variables';

.grouped:not(.expanded) {
  .toast:nth-child(1) {
    cursor: pointer;
  }
}

.grouped.expanded {
  .toast {
    margin-bottom: 1rem;
  }
}

.toast {
  pointer-events: auto;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid $border-grey;
  color: $default-text;
  padding: 1rem;
  padding-left: 1.75rem;
  flex-shrink: 0;
  gap: 1.25rem;
  left: 0;
  right: 0;
  box-shadow:
    0 4px 6px -1px transparentize($polly-light-blue, 0.8),
    0 2px 4px -2px transparentize($polly-light-blue, 0.8);

  &::before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    bottom: -1px;
    width: 8px;
    background-color: $polly-light-blue;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &.soft-success::before {
    display: none;
  }

  .icon {
    font-size: 24px;
    flex-shrink: 0;
  }

  .group-count {
    height: 24px;
    width: 24px;
    background-color: $polly-light-blue;
    font-size: 14px;
    font-weight: 800;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9999px;
    color: #fff;
  }

  .dismiss-btn {
    flex-shrink: 0;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content {
    .title {
      font-size: 14px;
      font-weight: 600;
    }
  }

  @mixin notification-style($color) {
    box-shadow:
      0 4px 6px -1px transparentize($color, 0.8),
      0 2px 4px -2px transparentize($color, 0.8);

    &::before {
      background-color: $color;
    }

    .icon {
      color: $color;
    }

    .group-count {
      background-color: $color;
    }
  }

  &.success {
    @include notification-style($polly-green);
  }

  &.warn {
    @include notification-style($polly-orange);
  }

  &.error {
    @include notification-style($error-red);
  }

  &.soft-success {
    @include notification-style($polly-green);
    .content {
      .title {
        color: $polly-green;
        font-weight: 400;
      }
      .body {
        color: $default-text;
      }
    }
  }

  &.soft-warn {
    @include notification-style($polly-orange);
    .content {
      .title {
        color: $polly-orange;
        font-weight: 400;
      }
      .body {
        color: $default-text;
      }
    }
  }

  &.soft-error {
    @include notification-style($error-red);
    .content {
      .title {
        color: $error-red;
        font-weight: 400;
      }
      .body {
        color: $default-text;
      }
    }
  }

  .content {
    flex-grow: 1;
  }
}
</style>

import api from '@shared/services/api.js';
import { downloadBlob } from '@shared/utils';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import { showMessages } from '@shared/utils/notifier.js';
import { getDateAndTime } from '@shared/utils/converters.js';
import { DEFAULT_TABLE_OPTIONS } from '@shared/constants';
import * as mutationTypes from './mutationTypes.js';

function showFailedMessage() {
  showMessages([
    {
      title: 'Upload failed',
      message:
        'Please check your file to make sure it contains valid data and is supported.',
      type: 'error',
    },
  ]);
}

export default {
  namespaced: true,
  state: () => ({
    isLoading: false,
    isUploading: false,
    uploads: [],
    lastUploadBy: null,
    lastUploadDate: null,
    tableOptions: DEFAULT_TABLE_OPTIONS,
    selectedLoanUpload: null,
  }),
  getters: {
    isLoading: state => state.isLoading,
    isUploading: state => state.isUploading,
    uploads: state => state.uploads || [],
    lastUploadBy: state => state.lastUploadBy,
    lastUploadDate: state => state.lastUploadDate,
    tableOptions: state => state.tableOptions,
    selectedLoanUpload: state => state.selectedLoanUpload,
  },
  mutations: {
    [mutationTypes.SET_LOAN_UPLOAD_DATA](state, payload) {
      if (payload.length > 0) {
        state.lastUploadBy = payload[0].user_full_name;
        state.lastUploadDate = getDateAndTime(payload[0].created_at);
      }
      state.uploads = payload;
    },
    [mutationTypes.SET_IS_LOADING_STATE](state, isLoading) {
      state.isLoading = isLoading;
    },
    [mutationTypes.SET_IS_UPLOADING_STATE](state, isUploading) {
      state.isUploading = isUploading;
    },
    [mutationTypes.SET_TABLE_OPTIONS_OBJECT](state, tableOptions) {
      state.tableOptions = tableOptions;
    },
    [mutationTypes.SET_SELECTED_LOAN_UPLOAD](state, selectedLoanUpload) {
      state.selectedLoanUpload = selectedLoanUpload;
    },
  },
  actions: {
    setTableOptions({ commit, state }, tableOptions) {
      commit(mutationTypes.SET_TABLE_OPTIONS_OBJECT, {
        ...state.tableOptions,
        ...tableOptions,
      });
    },
    async getUploads({ commit, state }) {
      try {
        commit(mutationTypes.SET_IS_LOADING_STATE, true);
        const result = await api.get('/sell/api/loan_uploads');
        const uploads = result.loanUploads;
        commit(mutationTypes.SET_TABLE_OPTIONS_OBJECT, {
          ...state.tableOptions,
          total: uploads.length,
        });
        commit(mutationTypes.SET_LOAN_UPLOAD_DATA, uploads);
      } catch (e) {
        onErrorHandler(e, 'sell-get-loan-uploads');
      } finally {
        commit(mutationTypes.SET_IS_LOADING_STATE, false);
      }
    },
    async postLoanUpload({ commit, dispatch }, formData) {
      try {
        commit(mutationTypes.SET_IS_UPLOADING_STATE, true);
        const response = await api.postFormData(
          '/sell/api/loan_uploads',
          formData,
        );
        const { task_id } = response && response.data;

        const task = await dispatch(
          'asyncTasks/getTaskResult',
          { taskId: task_id, isShort: true },
          { root: true },
        );
        if (task.task_successful) {
          const { processed, failed } = task.task_result;
          if (processed === 0 || processed === failed) {
            showMessages([
              {
                title: 'No loans uploaded',
                message: 'No loans were uploaded. Please check your file data.',
                type: 'warn',
              },
            ]);
          } else if (failed > 0) {
            showMessages([
              {
                title: 'Upload partially succeeded',
                message: `Failed to upload ${failed} of ${processed} loans. Please check your file data.`,
                type: 'warn',
              },
            ]);
          } else {
            showMessages([
              {
                title: 'Upload succeeded',
                message:
                  'You can view your loans in the Loan Manager section now.',
                type: 'success',
              },
            ]);
          }
        } else if (task.failed_message) {
          showFailedMessage();
        }
      } catch (e) {
        onErrorHandler(e, 'sell-bid-upload', [403], true);
        showFailedMessage();
      } finally {
        commit(mutationTypes.SET_IS_UPLOADING_STATE, false);
      }
      dispatch('getUploads');
    },
    async downloadSampleData() {
      try {
        const response = await api.getBlob('/sell/api/sample_loan_upload');
        const fileName = 'sample_loan_upload.csv';
        downloadBlob(fileName, response.data);
      } catch (e) {
        onErrorHandler(e, 'sell-sample-upload');
      }
    },
    async archiveLoanUpload({ commit, dispatch }, loanUploadId) {
      try {
        await api.delete(`/sell/api/loan_uploads/${loanUploadId}/`);
        showMessages([
          {
            title: '',
            message: 'Loan upload has been successfully deleted',
            type: 'success',
          },
        ]);
      } catch (e) {
        onErrorHandler(e, 'sell-api-archive-loan-upload');
      } finally {
        dispatch('getUploads');
        commit(mutationTypes.SET_SELECTED_LOAN_UPLOAD, null);
      }
    },
    setSelectedLoanUpload({ commit }, selectedLoanUpload) {
      commit(mutationTypes.SET_SELECTED_LOAN_UPLOAD, selectedLoanUpload);
    },
  },
};

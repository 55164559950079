export const routes = [
  {
    path: '/buy/',
    name: 'buyHome',
    redirect: '/buy/discovery',
  },
  {
    path: '/buy/loan/:id',
    name: 'buyLoanDetails',
    props: true,
    component: () => import('../components/LoanDetails/BuyLoanDetails.vue'),
  },
  {
    path: '/buy/discovery/',
    name: 'discovery',
    component: () =>
      import('../components/DiscoveryScreener/DiscoveryScreener.vue'),
  },
  {
    path: '/buy/active_bids',
    name: 'activeBids',
    component: () => import('../components/ActiveBids/ActiveBids.vue'),
  },
  {
    path: '/buy/bid_tape',
    name: 'bidTape',
    component: () => import('../components/BidTape/BidTape.vue'),
  },
  {
    path: '/buy/upload_bids',
    name: 'bidUpload',
    component: () => import('../components/BidUpload/BidUpload.vue'),
  },
  {
    path: '/buy/committed_loans',
    name: 'buyCommittedLoans',
    component: () => import('../components/CommittedLoans/CommittedLoans.vue'),
  },
];

/**
 * Determine if a string is numeric.
 * @param strInput
 * @returns {boolean}
 */
export function isStringValidNumber(strInput) {
  if (typeof strInput === 'string' && !strInput?.trim()) return false;
  return !isNaN(strInput) && !isNaN(parseFloat(strInput));
}

/**
 * Attempt to parse string value into a float and if that fails we return 0.
 * @param {String} value - Value is a string.
 * @return {Number} Return a float value.
 */
export function parseFloatOrZero(value) {
  const parsedValue = parseFloat(value);
  if (isNaN(parsedValue)) return 0;
  return parsedValue;
}

/**
 * Round a value to a precision amount n (default to 3)
 * @param value
 * @param {Number} precision
 * @returns {string|undefined|null}
 */
export function numberRound(value, precision = 3) {
  /**
   * Rounding half up
   *
   * If value is undefined or null we return back the value otherwise we convert to Number type and round
   */
  if (value === undefined || value === null) {
    return value;
  }
  const factor = Math.pow(10, precision);
  const roundedValue = Math.round(value * factor) / factor;
  return roundedValue.toFixed(precision);
}

/**
 * Remove trailing 0s from decimal portion of numeric string value
 *
 * @param {string} value
 * @returns {string}
 */
export function trimTrailingZeroes(value) {
  if (value.lastIndexOf('.') < 0) return value;
  return value.replace(/0+$/, '').replace(/\.$/, '');
}

/**
 * Given a string that is a decimal this will trim trailing 0s and fit the value to be
 *  at minimum 3 decimal places with the provided values or padded with 0s
 *
 * @param {Number|string} value
 * @param {Number} minimumPrecision
 * @returns {string}
 */
export function prettyRoundedNumberString(value, minimumPrecision = 3) {
  if (typeof value === 'number') {
    value = value.toString();
  }

  // return raw value with trimmed out 0s
  let formattedValue = trimTrailingZeroes(value);
  const i = formattedValue.lastIndexOf('.');

  if (i >= 0) {
    // if value contains period fill out padding to desired minimumPrecision with 0s
    const valueAfterPoint = formattedValue.substring(i, formattedValue.length);
    formattedValue = formattedValue.replace(
      valueAfterPoint,
      valueAfterPoint.padEnd(minimumPrecision + 1, '0'),
    );
  } else {
    // we got a whole number. add decimal point with padded 0s
    formattedValue = Number(formattedValue).toFixed(minimumPrecision);
  }

  return formattedValue;
}

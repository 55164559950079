import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export const routes = [
  {
    path: '/devtools',
    name: 'devtoolsHome',
    redirect: '/devtools/seed_data_scenario',
  },
  {
    path: '/devtools/seed_data_scenario',
    name: 'seed_data_scenario',
    meta: {
      checkPermissions: true,
    },
    component: () =>
      import(
        '../pages/SeedDataScenarioPage.vue' /* webpackChunkName: 'seed-data-scenario' */
      ),
  },
];

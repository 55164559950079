export const isBuyApp = () => {
  return isAppType('buy');
};

export const isDeliverApp = () => {
  return isAppType('deliver');
};

export const isSellApp = () => {
  return isAppType('sell');
};

export const isToolsApp = () => {
  return isAppType('tools');
};

const isAppType = urlPrefix => {
  return new RegExp(`^/${urlPrefix}`).test(window.location.pathname);
};

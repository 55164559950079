import {
  CLEAR_CURRENT_APPROVAL_GROUP,
  CLEAR_CURRENT_REQUESTOR_GROUP,
  SET_APPROVAL_GROUP_APPROVERS,
  SET_APPROVAL_GROUP_IS_ACTIVE,
  SET_APPROVAL_GROUP_NAME,
  SET_APPROVAL_GROUP_NOTES,
  SET_APPROVERS,
  SET_CURRENT_APPROVAL_GROUP,
  SET_CURRENT_REQUESTOR_GROUP,
  SET_HAS_UPDATES,
  SET_IS_LOADING_APPROVAL_GROUP,
  SET_IS_LOADING_APPROVERS,
  SET_IS_LOADING_LOAN_OFFICERS,
  SET_IS_LOADING_REQUESTOR_GROUP,
  SET_IS_SAVING_APPROVAL_GROUP,
  SET_IS_SAVING_REQUESTOR_GROUP,
  SET_LOAN_OFFICERS,
  SET_REQUESTOR_GROUP_IS_ACTIVE,
  SET_REQUESTOR_GROUP_LOAN_OFFICERS,
  SET_REQUESTOR_GROUP_NAME,
  SET_REQUESTOR_GROUP_NOTES,
  SET_CONNECTED_POLICIES,
  CLEAR_CONNECTED_POLICIES,
} from './mutationTypes.js';

import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import * as exceptionMgmtApi from '@shared/services/exceptionManagement.js';

const emptyRequestorGroup = {
  name: '',
  active: false,
  notes: '',
  loan_officers: [],
};

const emptyApprovalGroup = {
  name: '',
  active: false,
  notes: '',
  approvers: [],
};

const initialState = () => {
  return {
    currentRequestorGroup: { ...emptyRequestorGroup },
    isSavingRequestorGroup: false,
    isLoadingRequestorGroup: false,
    isLoadingLoanOfficers: false,
    loanOfficers: [],
    hasUpdates: false,
    currentApprovalGroup: { ...emptyApprovalGroup },
    isSavingApprovalGroup: false,
    approvers: [],
    isLoadingApprovalGroup: false,
    isLoadingApprovers: false,
    connectedPolicies: [],
  };
};

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    currentRequestorGroup: state => state.currentRequestorGroup,
    loanOfficers: state => state.loanOfficers,
    isLoadingRequestorGroup: state => state.isLoadingRequestorGroup,
    isSavingRequestorGroup: state => state.isSavingRequestorGroup,
    isLoadingLoanOfficers: state => state.isLoadingLoanOfficers,
    currentApprovalGroup: state => state.currentApprovalGroup,
    approvers: state => state.approvers,
    isLoadingApprovers: state => state.isLoadingApprovers,
    isLoadingApprovalGroup: state => state.isLoadingApprovalGroup,
    isSavingApprovalGroup: state => state.isSavingApprovalGroup,
    hasUpdates: state => state.hasUpdates,
    connectedPolicies: state => state.connectedPolicies,
  },
  mutations: {
    [SET_REQUESTOR_GROUP_NAME](state, payload) {
      state.currentRequestorGroup.name = payload;
    },
    [SET_REQUESTOR_GROUP_IS_ACTIVE](state, payload) {
      state.currentRequestorGroup.active = payload;
    },
    [SET_REQUESTOR_GROUP_NOTES](state, payload) {
      state.currentRequestorGroup.notes = payload;
    },
    [SET_REQUESTOR_GROUP_LOAN_OFFICERS](state, payload) {
      state.currentRequestorGroup.loan_officers = payload;
    },
    [CLEAR_CURRENT_REQUESTOR_GROUP](state) {
      state.currentRequestorGroup = { ...emptyRequestorGroup };
    },
    [SET_LOAN_OFFICERS](state, payload) {
      state.loanOfficers = payload;
    },
    [SET_IS_LOADING_LOAN_OFFICERS](state, payload) {
      state.isLoadingLoanOfficers = payload;
    },
    [SET_CURRENT_REQUESTOR_GROUP](state, payload) {
      state.currentRequestorGroup = payload;
    },
    [SET_IS_LOADING_REQUESTOR_GROUP](state, payload) {
      state.isLoadingRequestorGroup = payload;
    },
    [SET_IS_SAVING_REQUESTOR_GROUP](state, payload) {
      state.isSavingRequestorGroup = payload;
    },
    [SET_HAS_UPDATES](state, payload) {
      state.hasUpdates = payload;
    },
    [SET_APPROVERS](state, payload) {
      state.approvers = payload;
    },
    [SET_IS_LOADING_APPROVERS](state, payload) {
      state.isLoadingApprovers = payload;
    },
    [SET_APPROVAL_GROUP_NAME](state, payload) {
      state.currentApprovalGroup.name = payload;
    },
    [SET_APPROVAL_GROUP_IS_ACTIVE](state, payload) {
      state.currentApprovalGroup.active = payload;
    },
    [SET_APPROVAL_GROUP_NOTES](state, payload) {
      state.currentApprovalGroup.notes = payload;
    },
    [SET_APPROVAL_GROUP_APPROVERS](state, payload) {
      state.currentApprovalGroup.approvers = payload;
    },
    [CLEAR_CURRENT_APPROVAL_GROUP](state) {
      state.currentApprovalGroup = { ...emptyApprovalGroup };
    },
    [SET_CURRENT_APPROVAL_GROUP](state, payload) {
      state.currentApprovalGroup = payload;
    },
    [SET_IS_LOADING_APPROVAL_GROUP](state, payload) {
      state.isLoadingApprovalGroup = payload;
    },
    [SET_IS_SAVING_APPROVAL_GROUP](state, payload) {
      state.isSavingApprovalGroup = payload;
    },
    [SET_CONNECTED_POLICIES](state, payload) {
      state.connectedPolicies = payload;
    },
    [CLEAR_CONNECTED_POLICIES](state) {
      state.connectedPolicies = [];
    },
  },
  actions: {
    setConnectedPolicies({ commit }, payload) {
      commit(SET_CONNECTED_POLICIES, payload);
    },
    clearConnectedPolicies({ commit }) {
      commit(CLEAR_CONNECTED_POLICIES);
    },
    clearRequestorGroup({ commit }) {
      commit(CLEAR_CURRENT_REQUESTOR_GROUP);
      commit(SET_HAS_UPDATES, false);
    },
    setRequestorGroupName({ commit }, payload) {
      commit(SET_REQUESTOR_GROUP_NAME, payload);
      commit(SET_HAS_UPDATES, true);
    },
    setRequestorGroupIsActive({ commit }, payload) {
      commit(SET_REQUESTOR_GROUP_IS_ACTIVE, payload);
      commit(SET_HAS_UPDATES, true);
    },
    setRequestorGroupNotes({ commit }, payload) {
      commit(SET_REQUESTOR_GROUP_NOTES, payload);
      commit(SET_HAS_UPDATES, true);
    },
    clearApprovalGroup({ commit }) {
      commit(CLEAR_CURRENT_APPROVAL_GROUP);
      commit(SET_HAS_UPDATES, false);
    },
    setApprovalGroupName({ commit }, payload) {
      commit(SET_APPROVAL_GROUP_NAME, payload);
      commit(SET_HAS_UPDATES, true);
    },
    setApprovalGroupIsActive({ commit }, payload) {
      commit(SET_APPROVAL_GROUP_IS_ACTIVE, payload);
      commit(SET_HAS_UPDATES, true);
    },
    setApprovalGroupNotes({ commit }, payload) {
      commit(SET_APPROVAL_GROUP_NOTES, payload);
      commit(SET_HAS_UPDATES, true);
    },
    async fetchLoanOfficers({ commit }) {
      try {
        commit(SET_IS_LOADING_LOAN_OFFICERS, true);
        const loanOfficers = await exceptionMgmtApi.getLoanOfficers();
        commit(SET_LOAN_OFFICERS, loanOfficers);
        return loanOfficers;
      } catch (error) {
        onErrorHandler(error, 'exception-mgmt-fetchLoanOfficers');
      } finally {
        commit(SET_IS_LOADING_LOAN_OFFICERS, false);
      }
    },
    addLoanOfficerToRequestorGroup({ commit, state }, loanOfficer) {
      commit(SET_REQUESTOR_GROUP_LOAN_OFFICERS, [
        ...state.currentRequestorGroup.loan_officers,
        loanOfficer,
      ]);
      commit(SET_HAS_UPDATES, true);
    },
    removeLoanOfficerFromRequestorGroup({ commit, state }, loanOfficerId) {
      const filteredOfficers = state.currentRequestorGroup.loan_officers.filter(
        loanOfficer => loanOfficerId !== loanOfficer.id,
      );
      commit(SET_REQUESTOR_GROUP_LOAN_OFFICERS, filteredOfficers);
      if (filteredOfficers.length === 0) {
        commit(SET_REQUESTOR_GROUP_IS_ACTIVE, false);
      }
      commit(SET_HAS_UPDATES, true);
    },
    async saveNewRequestorGroup({ commit, state }) {
      try {
        commit(SET_IS_SAVING_REQUESTOR_GROUP, true);
        const requestBody = { ...state.currentRequestorGroup };
        requestBody.loanOfficers = requestBody.loan_officers.map(
          ({ id }) => id,
        );
        delete requestBody.loan_officers;
        const res = await exceptionMgmtApi.createRequestorGroup(requestBody);
        commit(SET_HAS_UPDATES, false);
        return res?.requestorGroupId;
      } catch (error) {
        onErrorHandler(error, 'exception-mgmt-saveNewRequestorGroup');
      } finally {
        commit(SET_IS_SAVING_REQUESTOR_GROUP, false);
      }
    },
    async updateRequestorGroup({ commit, state }) {
      const requestBody = { ...state.currentRequestorGroup };
      requestBody.loanOfficers = requestBody.loan_officers.map(({ id }) => id);
      delete requestBody.loan_officers;
      await exceptionMgmtApi.updateRequestorGroup(requestBody);
      commit(SET_HAS_UPDATES, false);
    },
    async fetchRequestorGroupById({ commit }, groupId) {
      try {
        commit(SET_IS_LOADING_REQUESTOR_GROUP, true);
        const requestorGroup = await exceptionMgmtApi.getRequestorGroupById(
          groupId,
        );
        commit(SET_CURRENT_REQUESTOR_GROUP, requestorGroup);
        commit(SET_HAS_UPDATES, false);
      } catch (error) {
        onErrorHandler(error, 'exception-mgmt-fetchRequestorGroupById');
      } finally {
        commit(SET_IS_LOADING_REQUESTOR_GROUP, false);
      }
    },
    async fetchApprovers({ commit }) {
      try {
        commit(SET_IS_LOADING_APPROVERS, true);
        const approvers = await exceptionMgmtApi.getApprovers();
        commit(SET_APPROVERS, approvers);
        return approvers;
      } catch (error) {
        onErrorHandler(error, 'exception-mgmt-fetchApprovers');
      } finally {
        commit(SET_IS_LOADING_APPROVERS, false);
      }
    },
    async fetchApprovalGroupById({ commit }, groupId) {
      try {
        commit(SET_IS_LOADING_APPROVAL_GROUP, true);
        const approvalGroup = await exceptionMgmtApi.getApprovalGroupById(
          groupId,
        );
        commit(SET_CURRENT_APPROVAL_GROUP, approvalGroup);
        commit(SET_HAS_UPDATES, false);
      } catch (error) {
        onErrorHandler(error, 'exception-mgmt-fetchApprovalGroupById');
      } finally {
        commit(SET_IS_LOADING_APPROVAL_GROUP, false);
      }
    },
    addApproverToApprovalGroup({ commit, state }, approver) {
      commit(SET_APPROVAL_GROUP_APPROVERS, [
        ...state.currentApprovalGroup.approvers,
        approver,
      ]);
      commit(SET_HAS_UPDATES, true);
    },
    removeApproverFromApprovalGroup({ commit, state }, approverId) {
      const filteredApprovers = state.currentApprovalGroup.approvers.filter(
        approver => approverId !== approver.id,
      );
      commit(SET_APPROVAL_GROUP_APPROVERS, filteredApprovers);
      if (filteredApprovers.length === 0) {
        commit(SET_APPROVAL_GROUP_IS_ACTIVE, false);
      }
      commit(SET_HAS_UPDATES, true);
    },
    async saveNewApprovalGroup({ commit, state }) {
      try {
        commit(SET_IS_SAVING_APPROVAL_GROUP, true);
        const requestBody = { ...state.currentApprovalGroup };
        requestBody.approvers = requestBody.approvers.map(({ id }) => id);
        // delete requestBody.approvers;
        const res = await exceptionMgmtApi.createApprovalGroup(requestBody);
        commit(SET_HAS_UPDATES, false);
        return res.approvalGroupId;
      } catch (error) {
        onErrorHandler(error, 'exception-mgmt-saveNewApprovalGroup');
      } finally {
        commit(SET_IS_SAVING_APPROVAL_GROUP, false);
      }
    },
    async updateApprovalGroup({ commit, state }) {
      const requestBody = { ...state.currentApprovalGroup };
      requestBody.approvers = requestBody.approvers.map(({ id }) => id);
      // delete requestBody.approvers;
      await exceptionMgmtApi.updateApprovalGroup(requestBody);
      commit(SET_HAS_UPDATES, false);
    },
  },
};

export const setWithTtl = (key, value, ttl) => {
  const now = new Date();
  const expiry = now.getTime() + ttl;

  setWithExpiry(key, value, expiry);
};

export const setWithExpiry = (key, value, expiry) => {
  const item = { value, expiry };

  localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = key => {
  const rawItem = localStorage.getItem(key);

  if (!rawItem) {
    return null;
  }

  const item = JSON.parse(rawItem);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
};

<template>
  <label class="custom-control custom-checkbox" :class="labelClasses">
    <input
      :id="id || name"
      ref="check"
      :checked="checked"
      class="custom-control-input"
      :class="inputClasses"
      :disabled="disabled || !!(option && option.disabled)"
      :indeterminate="indeterminate"
      :name="name"
      type="checkbox"
      :value="(option && option.value) || value"
      @change="$emit('change', $event)"
      @click="onClick"
    />
    <span class="custom-control-label" :class="itemColor">
      {{ cbLabel }}
    </span>
    <abbr v-if="required">*</abbr>
  </label>
</template>

<script>
export default {
  name: 'PxCheckbox',
  model: {
    prop: 'checked',
    event: 'valueChanged',
  },
  props: {
    id: { type: String, default: '' },
    name: { type: String, required: true },
    required: { type: Boolean, default: false },
    fullColor: { type: Boolean },
    stacked: { type: Boolean },
    state: { type: Boolean, default: undefined },
    option: { type: Object, default: null },
    disabled: { type: Boolean },
    checked: { type: Boolean },
    value: { type: [Object, String, Number, Boolean], default: () => true },
    label: { type: String, default: '' },
    smallSize: { type: Boolean },
    indeterminate: { type: Boolean, default: false },
  },
  computed: {
    labelClasses() {
      return [
        this.stacked ? '' : 'custom-control-inline',
        this.smallSize ? 'small-size' : '',
      ];
    },
    itemColor() {
      return this.fullColor ? 'custom-control-color' : '';
    },
    cbLabel() {
      return (this.option && this.option.text) || this.label;
    },
    inputClasses() {
      return `${
        this.state || !!(this.option && this.option.state)
          ? 'is-valid'
          : this.state === false || (this.option && this.option.state === false)
          ? 'is-invalid'
          : ''
      } ${this.indeterminate ? 'is-indeterminate' : ''}`;
    },
  },
  watch: {
    indeterminate(val) {
      if (this.$refs.check) {
        this.$refs.check.indeterminate = val;
      }
    },
  },
  mounted() {
    if (this.$refs.check) {
      this.$refs.check.indeterminate = this.indeterminate;
    }
  },
  methods: {
    onClick() {
      this.$emit(
        'valueChanged',
        this.$refs.check.checked,
        (this.option && this.option.value) || this.value,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/variables';
@import '@pe/styles/pricing/common';

.custom-checkbox {
  abbr {
    color: red;
    text-decoration: none;
    font-weight: bold;
  }
}

//If the class "small-size" is applied, adjust the sizing of the checkbox and subsequent elements to be smaller
.custom-checkbox.small-size {
  margin-bottom: 0;
  min-height: 1.2rem;
  min-width: 1.2rem;

  .custom-control-input:checked ~ .custom-control-label::after {
    font-size: 12px;
  }

  .custom-control {
    padding-left: 25px;

    ::v-deep span.custom-control-label {
      font-size: $small-text;
      color: $accent;
    }
  }

  .custom-control-input {
    width: 1.2rem;
    height: 1.2rem;
  }
  .custom-control-label {
    margin-bottom: 7px;

    &::before {
      width: 1.2rem;
      height: 1.2rem;
    }

    &::after {
      width: 1.2rem;
      height: 1.2rem;
      font-size: 1.1rem;
      line-height: 1.3;
    }
  }
}

.custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #fff;
  border-color: #007bff;
}

.custom-control-input:indeterminate ~ .custom-control-label::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 2.5px;
  background-color: #2491eb;
}

.custom-checkbox.small-size {
  .custom-control-input:indeterminate ~ .custom-control-label::after {
    width: 8px;
    height: 1px;
  }
}
</style>

import api from '@shared/services/api';
import { getConfigurations } from '@pe/services/configurations';
import { ConfigurationStatuses } from '@shared/constants';
import { onErrorHandler } from '@shared/utils/errorHandlers';

const base = '/api/pe/organization';

/** Returns product guideline documents for specific changesetId, productIds and documentIds
 *
 * @param {String} orgId organization id
 * @param {String} configurationId changeset/version/configuration id
 * @param {Array} productIds list of product ids for filtering
 * @param {Array} documentIds list of guideline document ids for filtering
 * @param {Array} returnProducts flag to include product ids array
 */
export async function getProductGuidelineDocuments(
  orgId,
  configurationId,
  productIds = null,
  documentIds = null,
  returnProducts = false,
) {
  const params = {};
  if (productIds) {
    params.product_ids = productIds;
  }
  if (documentIds) {
    params.document_ids = documentIds;
  }
  if (returnProducts) {
    params.return_products = true;
  }
  const url = api.constructUrl(
    `${base}/${orgId}/configurations/${configurationId}/product-guideline-documents/`,
    params,
  );
  try {
    return await api.get(url);
  } catch (error) {
    if (error?.response?.status === 404) {
      return [];
    }
    onErrorHandler(error, 'pe-config-audiences');
  }
}

/** Returns draft of active version/configuration */
export async function getDraftOrActiveVersion() {
  const versions = await getConfigurations({
    sortBy: 'createdOn',
    sortDesc: 'true',
  });
  const draftVersion = versions.find(
    v => v.status === ConfigurationStatuses.DRAFT,
  );
  if (draftVersion) {
    return draftVersion;
  } else {
    const activeVersion = versions.find(v => v.isPublished);
    if (activeVersion) {
      return activeVersion;
    }
  }
}

/** Creates guideline document for specific changesetId, productIds and documentIds
 *
 * @param {String} orgId organization id
 * @param {String} investorId investor organization id
 * @param {File} file file upload for guideline document
 * @param {String} url url for guideline document
 */
export async function createGuidelineDocument(orgId, investorId, file, url) {
  const formData = new FormData();
  formData.append('investor_org', investorId);
  if (file) {
    formData.append('file', file);
  }
  if (url) {
    formData.append('url', url);
  }
  return await api.post(`${base}/${orgId}/guideline-documents/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

/** Deletes guideline document by id for org
 *
 * @param {String} orgId organization id
 * @param {String} docId investor organization id
 */
export async function deleteGuidelineDocument(orgId, docId) {
  await api.delete(`${base}/${orgId}/guideline-documents/${docId}/`);
}

/** Creates product guideline document for specific changesetId, productIds and documentIds
 *
 * @param {String} orgId organization id
 * @param {String} configId changeset/version/configuration id
 * @param {Array} productIds list of product ids for filtering
 * @param {Array} documentIds list of guideline document ids for filtering
 */
export async function createProductGuidelineDocuments(
  orgId,
  configId,
  productIds,
  documentIds,
) {
  return await api.post(
    `${base}/${orgId}/configurations/${configId}/product-guideline-documents/`,
    {
      product_ids: productIds,
      guideline_document_ids: documentIds,
    },
    null,
    'application/json',
  );
}

/** Deletes product guideline document for specific changesetId, productIds and documentIds
 *
 * @param {String} orgId organization id
 * @param {String} docId guideline document id
 * @param {String} configId changeset/version/configuration id
 * @param {Array} productIds list of product ids for filtering
 */
export async function deleteProductGuidelineDocument(
  orgId,
  docId,
  configId,
  productIds,
) {
  return await api.delete(
    `${base}/${orgId}/configurations/${configId}/product-guideline-documents/${docId}/`,
    {
      product_ids: productIds,
    },
  );
}

/** Opens guideline document in separate browser tab
 *
 * @param {Object} guidelineDocument guideline document object
 */
export async function downloadGuidelineDocument(orgId, guideline_document) {
  if (guideline_document.url) {
    window.open(guideline_document.url, '_blank');
  } else {
    const url = `${base}/${orgId}/guideline-documents/${guideline_document.id}/download/`;
    window.open(url, '_blank');
  }
}

export const SET_CURRENT_AVAILABLE_PRODUCTS = 'SET_CURRENT_AVAILABLE_PRODUCTS';
export const SET_CUSTOM_CREDIT_DATA = 'SET_CUSTOM_CREDIT_DATA';
export const SET_FLOAT_DOWN_POLICY = 'SET_FLOAT_DOWN_POLICY';
export const SET_IS_AUTO_APPROVED = 'SET_IS_AUTO_APPROVED';
export const SET_IS_PRICING_INITIALIZING = 'SET_IS_PRICING_INITIALIZING';
export const SET_LOAN_DATA = 'SET_LOAN_DATA';
export const SET_CACHED_TEMPLATE = 'SET_CACHED_TEMPLATE';
export const SET_LOADING_DYNAMIC_PRICING_DETAILS =
  'SET_LOADING_DYNAMIC_PRICING_DETAILS';
export const SET_EXTERNAL_VISIBILITY_OBJECT = 'SET_EXTERNAL_VISIBILITY_OBJECT';
export const SET_CUSTOM_LOAN_TERMS = 'SET_CUSTOM_LOAN_TERMS';
export const SET_SELECTED_PRICE_PRODUCT_INFO =
  'SET_SELECTED_PRICE_PRODUCT_INFO';
export const SET_INITIAL_LOCK_PRODUCT_INFO = 'SET_INITIAL_LOCK_PRODUCT_INFO';

export const SET_LOAN_STATUS_FOR_REFRESH = 'SET_LOAN_STATUS_FOR_REFRESH';
export const SET_LOS_LOAN_DATA = 'SET_LOS_LOAN_DATA';
export const SET_LOS_USER = 'SET_LOS_USER';
export const SET_MORTGAGE_INSURANCE_DATA = 'SET_MORTGAGE_INSURANCE_DATA';
export const SET_PENDING_PERSISTED_ADJUSTMENTS =
  'SET_PENDING_PERSISTED_ADJUSTMENTS';
export const SET_POLICY_LIMIT_MESSAGE = 'SET_POLICY_LIMIT_MESSAGE';
export const SET_PRICE = 'SET_PRICE';
export const SET_SELECTING_PRICE = 'SET_SELECTING_PRICE';
export const SET_PRICING_VERSIONS = 'SET_PRICING_VERSIONS';
export const SET_SELECTED_PRICING_VERSION = 'SET_SELECTED_PRICING_VERSION';
export const SET_SELECTED_RELOCK_PERIOD = 'SET_SELECTED_RELOCK_PERIOD';
export const SET_PRODUCT_INFO = 'SET_PRODUCT_INFO';
export const SET_PRICING_TEMPLATE = 'SET_PRICING_TEMPLATE';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const SET_PRICING_SCENARIO_RESULT = 'SET_PRICING_SCENARIO_RESULT';
export const SET_PE_REQUEST_DATA = 'SET_PE_REQUEST_DATA';
export const SET_MORTGAGE_COACH_ACCOUNT = 'SET_MORTGAGE_COACH_ACCOUNT';
export const SET_SELECTED_PRODUCT_PRICES = 'SET_SELECTED_PRODUCT_PRICES';
export const SET_PREVIEW_PRICE = 'SET_PREVIEW_PRICE';
export const SET_REQUESTING_LOCK = 'SET_REQUESTING_LOCK';
export const SET_PE_RULE_FIELDS_CACHE = 'SET_PE_RULE_FIELDS_CACHE';
export const SET_SELL_SIDE_LOCKED_AT = 'SET_SELL_SIDE_LOCKED_AT';
export const SET_SELL_SIDE_LOCK_PERIOD = 'SET_SELL_SIDE_LOCK_PERIOD';
export const SET_SELL_SIDE_EXPIRES_AT = 'SET_SELL_SIDE_EXPIRES_AT';
export const SET_USER_SCENARIO_CONFIG = 'SET_USER_SCENARIO_CONFIG';
export const SET_SORTED_PRODUCTS = 'SET_SORTED_PRODUCTS';
export const SET_IS_LOADING_LOCK = 'SET_IS_LOADING_LOCK';
export const SET_DOCUMENT_BY_CONFIGURATION = 'SET_DOCUMENT_BY_CONFIGURATION';
export const SET_ALL_IN_PRICE_INVALID = 'SET_ALL_IN_PRICE_INVALID';
export const SET_IS_EXCEPTION_FIELDS_VALID = 'SET_IS_EXCEPTION_FIELDS_VALID';
export const SET_PRICE_EXCEPTION = 'SET_PRICE_EXCEPTION';

export default {
  SET_SELECTED_PRICING_VERSION,
  SET_SELECTED_RELOCK_PERIOD,
  SET_FLOAT_DOWN_POLICY,
  SET_POLICY_LIMIT_MESSAGE,
  SET_IS_AUTO_APPROVED,
  SET_IS_PRICING_INITIALIZING,
  SET_CURRENT_AVAILABLE_PRODUCTS,
  SET_EXTERNAL_VISIBILITY_OBJECT,
  SET_LOS_USER,
  SET_LOAN_DATA,
  SET_MORTGAGE_INSURANCE_DATA,
  SET_PENDING_PERSISTED_ADJUSTMENTS,
  SET_CUSTOM_CREDIT_DATA,
  SET_PRICE,
  SET_LOADING_DYNAMIC_PRICING_DETAILS,
  SET_CACHED_TEMPLATE,
  SET_PRICING_TEMPLATE,
  SET_ERROR_MESSAGE,
  SET_MORTGAGE_COACH_ACCOUNT,
  SET_SELECTED_PRODUCT_PRICES,
  SET_PREVIEW_PRICE,
  SET_REQUESTING_LOCK,
  SET_PE_RULE_FIELDS_CACHE,
  SET_SELL_SIDE_LOCKED_AT,
  SET_SELL_SIDE_LOCK_PERIOD,
  SET_SELL_SIDE_EXPIRES_AT,
  SET_USER_SCENARIO_CONFIG,
  SET_SORTED_PRODUCTS,
  SET_IS_LOADING_LOCK,
  SET_ALL_IN_PRICE_INVALID,
  SET_IS_EXCEPTION_FIELDS_VALID,
  SET_PRICE_EXCEPTION,
};

import PxToast from './PxToast.vue';
import { toastBus, generateId } from './utils.js';

const Toasts = {
  install(Vue) {
    //add a global method to our vue instance
    const notify = params => {
      params.id = generateId();
      toastBus.$emit('add', params);
    };

    Vue.prototype['$notify'] = notify;
    Vue['notify'] = notify;

    //register our toast component
    Vue.component(PxToast.name, PxToast);
  },
};

export default Toasts;

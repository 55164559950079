export const routes = [
  {
    path: '/sell/',
    name: 'sellHome',
    redirect: '/sell/pipeline',
  },
  {
    path: '/sell/pipeline',
    name: 'pipeline',
    component: () => import('../components/PipelineGrid/PipelineGrid.vue'),
  },
  {
    path: '/sell/uploads',
    name: 'uploads',
    component: () => import('../pages/LoanUploadPage.vue'),
  },
  {
    path: '/sell/loan/:id',
    name: 'loanDetails',
    props: true,
    component: () => import('../components/LoanDetails/SellLoanDetails.vue'),
  },
  {
    path: '/sell/committed_loans',
    name: 'committedLoans',
    meta: { breadcrumbsText: 'Committed Loans' },
    component: () => import('../components/CommittedLoans.vue'),
  },
  {
    path: '/sell/loan_manager',
    name: 'loanManager',
    component: () => import('../pages/LoanManagerPage.vue'),
  },
  {
    path: '/sell/import_issues',
    name: 'importIssues',
    component: () => import('../pages/ImportIssuesPage.vue'),
  },
  {
    path: '/sell/committed_pools',
    name: 'committedPools',
    meta: { breadcrumbsText: 'Committed Pools' },
    component: () => import('../pages/CommitmentPoolPage/CommittedPools.vue'),
  },
  {
    path: '/sell/committed_pools/add',
    name: 'newCommittedPool',
    props: true,
    component: () => import('../pages/CommitmentPoolDetailPage.vue'),
  },
  {
    path: '/sell/committed_pools/:poolId',
    name: 'editCommittedPool',
    props: true,
    component: () => import('../pages/CommitmentPoolDetailPage.vue'),
  },
  {
    path: '/sell/commitment_cart',
    name: 'commitmentCart',
    meta: { breadcrumbsText: 'Commitment Cart' },
    component: () => import('../components/CommitmentCart/CommitmentCart.vue'),
  },
];

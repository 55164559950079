<template>
  <div class="v-select" :class="stateClasses" :dir="dir">
    <slot v-bind="scope.header" name="header" />
    <div
      :id="`vs${uid}__combobox`"
      ref="toggle"
      :aria-expanded="dropdownOpen.toString()"
      aria-label="Search for option"
      :aria-owns="`vs${uid}__listbox`"
      class="vs__dropdown-toggle"
      role="combobox"
    >
      <div ref="selectedOptions" class="vs__selected-options">
        <slot
          v-for="option in selectedValue"
          :deselect="deselect"
          :disabled="disabled"
          :multiple="multiple"
          name="selected-option-container"
          :option="normalizeOptionForSlot(option)"
        >
          <span :key="getOptionKey(option)" class="vs__selected">
            <slot
              v-bind="normalizeOptionForSlot(option)"
              name="selected-option"
            >
              {{ getOptionLabel(option) }}
            </slot>
            <button
              v-if="multiple"
              ref="deselectButtons"
              :aria-label="`Deselect ${getOptionLabel(option)}`"
              class="vs__deselect"
              :disabled="disabled"
              :title="`Deselect ${getOptionLabel(option)}`"
              type="button"
              @click="deselect(option)"
            >
              <component :is="childComponents.Deselect" />
            </button>
          </span>
        </slot>

        <slot v-bind="scope.search" name="search">
          <input
            v-bind="scope.search.attributes"
            class="vs__search"
            v-on="scope.search.events"
          />
        </slot>
      </div>

      <!-- this is the only change in html from vSelect - toggle dropdown menu only after click on button -->
      <!-- previously it was opening after click on input -->
      <div
        ref="actions"
        class="vs__actions"
        @mousedown="toggleDropdown($event)"
      >
        <button
          v-show="showClearButton"
          ref="clearButton"
          aria-label="Clear Selected"
          class="vs__clear"
          :disabled="disabled"
          title="Clear Selected"
          type="button"
          @click="clearSelection"
        >
          <component :is="childComponents.Deselect" />
        </button>

        <slot v-bind="scope.openIndicator" name="open-indicator">
          <px-icon
            v-if="!noDrop"
            icon="chevron-down"
            v-bind="scope.openIndicator.attributes"
          />
        </slot>

        <slot v-bind="scope.spinner" name="spinner">
          <div v-show="mutableLoading" class="vs__spinner">Loading...</div>
        </slot>
      </div>
    </div>
    <transition :name="transition">
      <ul
        v-if="dropdownOpen"
        :id="`vs${uid}__listbox`"
        :key="`vs${uid}__listbox`"
        ref="dropdownMenu"
        v-append-to-body
        class="vs__dropdown-menu"
        role="listbox"
        @mousedown.prevent="onMousedown"
        @mouseup="onMouseUp"
      >
        <slot v-bind="scope.listHeader" name="list-header" />
        <li
          v-for="(option, index) in filteredOptions"
          :id="`vs${uid}__option-${index}`"
          :key="getOptionKey(option)"
          :aria-selected="index === typeAheadPointer ? true : null"
          class="vs__dropdown-option"
          :class="{
            'vs__dropdown-option--selected': isOptionSelected(option),
            'vs__dropdown-option--highlight': index === typeAheadPointer,
            'vs__dropdown-option--disabled': !selectable(option),
          }"
          role="option"
          @mousedown.prevent.stop="selectable(option) ? select(option) : null"
          @mouseover="selectable(option) ? (typeAheadPointer = index) : null"
        >
          <slot v-bind="normalizeOptionForSlot(option)" name="option">
            {{ getOptionLabel(option) }}
          </slot>
        </li>
        <li v-if="filteredOptions.length === 0" class="vs__no-options">
          <slot v-bind="scope.noOptions" name="no-options">
            Sorry, no matching options.
          </slot>
        </li>
        <slot v-bind="scope.listFooter" name="list-footer" />
      </ul>
      <ul
        v-else
        :id="`vs${uid}__listbox`"
        role="listbox"
        style="display: none; visibility: hidden"
      ></ul>
    </transition>
    <slot v-bind="scope.footer" name="footer" />
  </div>
</template>

<script>
import vSelect from 'vue-select';

export default {
  name: 'PlusItemSelectOverride',
  extends: vSelect,
  watch: {
    search(val) {
      // opening dropdown only when search string != ''

      this.open = val.length > 0;
    },
  },
  methods: {
    onSearchFocus() {
      // override: don't opening dropdown after focus

      this.$emit('search:focus');
    },
    onAfterSelect() {
      // override: after select option we are:
      // 1. making focus on input
      // 2. closing dropdown
      // 3. search string = ''

      this.searchEl.focus();
      this.open = false;
      this.search = '';
    },
    toggleDropdown(event) {
      const targetIsNotSearch = event.target !== this.$refs.search;
      if (targetIsNotSearch) {
        event.preventDefault();
      }

      //  don't react to click on deselect/clear buttons,
      //  they dropdown state will be set in their click handlers
      const ignoredButtons = [
        ...(this.$refs['deselectButtons'] || []),
        ...[this.$refs['clearButton']],
      ];

      if (
        ignoredButtons.some(
          ref => ref.contains(event.target) || ref === event.target,
        )
      ) {
        event.preventDefault();
        return;
      }

      if (this.open && targetIsNotSearch) {
        // override: when we need to close dropdown menu, we are:
        // 1. closing menu
        // 2. making focus on input

        this.open = false;
        this.searchEl.focus();
      } else if (!this.disabled) {
        this.open = true;
        this.searchEl.focus();
      }
    },
  },
};
</script>

export default {
  namespaced: true,
  state: {
    productsDetails: null,
    searchText: null,
  },
  getters: {
    productsDetails: state => state.productsDetails,
    searchText: state => state.searchText,
  },
  mutations: {
    mutateProductsDetails(state, productsDetails) {
      state.productsDetails = productsDetails;
    },
    mutateSearchText(state, searchText) {
      state.searchText = searchText;
    },
  },
  actions: {
    setProductsDetails({ commit }, id) {
      commit('mutateProductsDetails', id);
    },
    setSearchText({ commit }, id) {
      commit('mutateSearchText', id);
    },
  },
};

import * as mutationTypes from './mutationTypes.js';

const setOrganizationDetail = (state, payload) => {
  state.organizationDetail = payload;
};

const setOrganizationLogo = (state, logo) => {
  state.organizationLogo = logo;
};

const setOrganizationLogoLoading = (state, isLoading) => {
  state.isOrganizationLogoLoading = isLoading;
};

const setFundingCost = (state, fundingCost) => {
  state.fundingCost = {
    ...fundingCost,
    spread: parseInt(fundingCost.spread) || fundingCost.spread,
    base_rate: 'SOFR',
  };
};

export default {
  setOrganizationDetail,
  setOrganizationLogoLoading,
  setOrganizationLogo,
  [mutationTypes.SET_FUNDING_COST]: setFundingCost,
};

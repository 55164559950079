export const SET_LOCK_REQUESTS = 'SET_LOCK_REQUESTS';
export const REMOVE_REQUEST_BY_ID = 'REMOVE_REQUEST_BY_ID';
export const SET_REQUEST_LOADING_STATE = 'SET_REQUEST_LOADING_STATE';
export const SET_COUNTS_BY_TYPE = 'SET_COUNTS_BY_TYPE';
export const SET_REQUEST_DETAILS = 'SET_REQUEST_DETAILS';
export const SET_IS_REQUEST_DETAILS_LOADING = 'SET_IS_REQUEST_DETAILS_LOADING';
export const SET_INVESTOR_LOAN_NUMBER = 'SET_INVESTOR_LOAN_NUMBER';
export const SET_SHOW_VALIDATION = 'SET_SHOW_VALIDATION';
export const SET_PENDING_LOCK_DESK_MODIFICATIONS =
  'SET_PENDING_LOCK_DESK_MODIFICATIONS';
export const SET_IS_EDITING_BUY_SIDE = 'SET_IS_EDITING_BUY_SIDE';
export const SET_IS_EDITING_SELL_SIDE = 'SET_IS_EDITING_SELL_SIDE';
export const CLEAR_PENDING_LOCK_DESK_MODIFICATIONS =
  'CLEAR_PENDING_LOCK_DESK_MODIFICATIONS';
export const SET_SELL_SIDE_BEST_EXECUTION_PRICE =
  'SET_SELL_SIDE_BEST_EXECUTION_PRICE';
export const SET_EDITED_NEXT_PRICE_ADJUSTMENTS =
  'SET_EDITED_NEXT_PRICE_ADJUSTMENTS';
export const SET_TEMP_NEXT_PRICE_ADJUSTMENTS =
  'SET_TEMP_NEXT_PRICE_ADJUSTMENTS';
export const SET_EDITED_NEXT_RATE_ADJUSTMENTS =
  'SET_EDITED_NEXT_RATE_ADJUSTMENTS';
export const SET_TEMP_NEXT_RATE_ADJUSTMENTS = 'SET_TEMP_NEXT_RATE_ADJUSTMENTS';
export const TEMP_NEXT_ADJUSTMENTS_ADD = 'TEMP_NEXT_ADJUSTMENTS_ADD';
export const TEMP_NEXT_ADJUSTMENTS_MODIFY = 'TEMP_NEXT_ADJUSTMENTS_MODIFY';
export const TEMP_NEXT_ADJUSTMENTS_DELETE = 'TEMP_NEXT_ADJUSTMENTS_DELETE';
export const SET_ADJUSTMENTS_TOTALS = 'SET_ADJUSTMENTS_TOTALS';
export const CLEAR_ADJUSTMENT_EDITS = 'CLEAR_ADJUSTMENT_EDITS';

export const SET_INITIAL_TO_EXCEPTION_ADJUSTMENT_IDS =
  'SET_INITIAL_TO_EXCEPTION_ADJUSTMENT_IDS';

export default {
  SET_LOCK_REQUESTS,
  REMOVE_REQUEST_BY_ID,
  SET_REQUEST_LOADING_STATE,
  SET_COUNTS_BY_TYPE,
  SET_REQUEST_DETAILS,
  SET_IS_REQUEST_DETAILS_LOADING,
  SET_INVESTOR_LOAN_NUMBER,
  SET_PENDING_LOCK_DESK_MODIFICATIONS,
  SET_IS_EDITING_BUY_SIDE,
  SET_IS_EDITING_SELL_SIDE,
  CLEAR_PENDING_LOCK_DESK_MODIFICATIONS,
  SET_SELL_SIDE_BEST_EXECUTION_PRICE,
  SET_EDITED_NEXT_PRICE_ADJUSTMENTS,
  SET_TEMP_NEXT_PRICE_ADJUSTMENTS,
  SET_EDITED_NEXT_RATE_ADJUSTMENTS,
  SET_TEMP_NEXT_RATE_ADJUSTMENTS,
  TEMP_NEXT_ADJUSTMENTS_ADD,
  TEMP_NEXT_ADJUSTMENTS_MODIFY,
  TEMP_NEXT_ADJUSTMENTS_DELETE,
  SET_ADJUSTMENTS_TOTALS,
  CLEAR_ADJUSTMENT_EDITS,
  SET_INITIAL_TO_EXCEPTION_ADJUSTMENT_IDS,
};

import Vue from 'vue';
import Pluralize from 'pluralize';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

import { getImportFailures } from '@shared/api/losImportFailures.js';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import { DEFAULT_TABLE_OPTIONS } from '@shared/constants';
import { getSortBy } from '@shared/utils/urlFormatters.js';
import * as mutationTypes from './mutationTypes.js';

const DEFAULT_SORT_BY_COLUMN = 'updated_at';

const getLoanNumbersForStatus = (losImportFailures, status) => {
  return losImportFailures
    .filter(losImportFailure => losImportFailure.status === status)
    .map(losImportFailure => losImportFailure.polly_id);
};

export default {
  namespaced: true,
  state: () => ({
    searchText: null,
    selectedLosImportFailure: null,
    importFailures: [],
    isLoading: false,
    tableOptions: {
      ...DEFAULT_TABLE_OPTIONS,
      sortBy: DEFAULT_SORT_BY_COLUMN,
    },
    dateFilter: {},
  }),
  getters: {
    searchText: state => state.searchText,
    selectedLosImportFailure: state => state.selectedLosImportFailure,
    importFailures: state => state.importFailures,
    isLoading: state => state.isLoading,
    tableOptions: state => state.tableOptions,
    queryParams: state => {
      const { pageIndex, pageSize, sortBy, sortDesc } = state.tableOptions;
      const queryData = omitBy(
        {
          page: pageIndex,
          page_size: pageSize,
          sort_by: getSortBy(sortBy, sortDesc),
          polly_id__contains: state.searchText,
          updated_at__gte: state.dateFilter.start,
          updated_at__lte: state.dateFilter.end,
        },
        isNil,
      );

      return new URLSearchParams(queryData);
    },
    selectedLosImportFailures: state =>
      state.importFailures.filter(failure => failure.selected),
  },
  mutations: {
    [mutationTypes.SET_SEARCH_TEXT](state, searchText) {
      state.searchText = searchText;
    },
    [mutationTypes.SELECT_LOS_IMPORT_FAILURE](state, losImportFailure) {
      state.selectedLosImportFailure = losImportFailure;
    },
    [mutationTypes.SET_IMPORT_FAILURES](state, { results, count }) {
      state.importFailures = results || [];
      state.tableOptions.total = count;
    },
    [mutationTypes.SET_IS_LOADING](state, isLoading) {
      state.isLoading = isLoading;
    },
    [mutationTypes.SET_TABLE_OPTIONS](state, tableOptions) {
      state.tableOptions = tableOptions;
    },
    [mutationTypes.SET_DATE_FILTER](state, dateFilter) {
      state.dateFilter = dateFilter;
    },
  },
  actions: {
    setSearchText({ commit }, searchText) {
      commit(mutationTypes.SET_SEARCH_TEXT, searchText);
    },
    selectLosImportFailure({ commit }, losImportFailure) {
      commit(mutationTypes.SELECT_LOS_IMPORT_FAILURE, losImportFailure);
    },
    setTableOptions({ commit, state }, tableOptions) {
      commit(mutationTypes.SET_TABLE_OPTIONS, {
        ...state.tableOptions,
        ...tableOptions,
      });
    },
    setDateFilter({ commit }, dateFilter) {
      commit(mutationTypes.SET_DATE_FILTER, dateFilter);
    },
    async getImportFailures({ commit, getters, rootGetters }) {
      try {
        commit(mutationTypes.SET_IS_LOADING, true);
        const response = await getImportFailures(
          rootGetters['organizations/currentOrganizationId'],
          getters.queryParams,
        );
        response?.results?.forEach(failure =>
          Vue.set(failure, 'selected', false),
        );
        commit(mutationTypes.SET_IMPORT_FAILURES, response);
      } catch (e) {
        onErrorHandler(e, 'getImportFailures');
      } finally {
        commit(mutationTypes.SET_IS_LOADING, false);
      }
    },
    maybeShowCompletedNotification(_, losImportFailures) {
      const completedLoans = getLoanNumbersForStatus(
        losImportFailures,
        'COMPLETED',
      );
      if (completedLoans.length > 0) {
        const loanNumbers = completedLoans.join(', ');
        const count = completedLoans.length;
        const text = `Successfully imported ${count} ${Pluralize(
          'loan',
          count,
        )}: ${loanNumbers}`;
        Vue.notify({ title: 'Success', type: 'success', text });
      }
    },
    maybeShowFailedNotification(_, losImportFailures) {
      const failedLoans = getLoanNumbersForStatus(losImportFailures, 'FAILED');
      if (failedLoans.length > 0) {
        const loanNumbers = failedLoans.join(', ');
        const count = failedLoans.length;
        const text = `Failed to import ${count} ${Pluralize(
          'loan',
          count,
        )}: ${loanNumbers}`;
        Vue.notify({ title: 'Failed Import', type: 'warn', text });
      }
    },
  },
};

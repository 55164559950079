<template>
  <vue-tippy
    :a11y="false"
    animation="fade"
    arrow
    arrow-type="round"
    boundary="viewport"
    distance="20"
    placement="right"
    :theme="tippyThemes"
  >
    <template #trigger>
      <slot></slot>
    </template>
    <slot name="content">
      {{ content }}
    </slot>
  </vue-tippy>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tippyThemes() {
      const themes = ['light-border', 'navbar-tooltip'];
      if (this.isActive) {
        themes.push('navbar-tooltip-active');
      }
      return themes.join(' ');
    },
  },
};
</script>

<style lang="scss">
.tippy-tooltip {
  &.navbar-tooltip-theme {
    display: none;

    .tippy-content {
      color: #1e4374;
    }

    &.navbar-tooltip-active-theme {
      .tippy-content {
        color: #4591e4;
      }
    }
  }
}

@media (max-width: 1399px) and (min-width: 776px) {
  .tippy-tooltip {
    &.navbar-tooltip-theme {
      display: block;
    }
  }
}
</style>

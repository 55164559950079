import { PERulesSection } from '@shared/constants';

export default {
  namespaced: true,
  state: {
    searchText: null,
    entityType: PERulesSection.Rules,
  },
  getters: {
    searchText: state => state.searchText,
    entityType: state => state.entityType,
  },
  mutations: {
    setSearchText(state, searchText) {
      state.searchText = searchText;
    },
    setEntityType(state, entityType) {
      state.entityType = entityType;
    },
  },
  actions: {
    setSearchText({ commit }, id) {
      commit('setSearchText', id);
    },
    setEntityType({ commit }, id) {
      commit('setEntityType', id);
    },
  },
};

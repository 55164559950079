import { requestStatus } from '@shared/constants';
import { getOrganization } from '@shared/services/coreApi.js';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import api from '@shared/services/api.js';
import { isIMAServicingPriceCurrent } from '@sell/utils/servicingPricing.js';
import {
  startOrgPolling,
  cleanupOrgPolling,
} from '@sell/services/orgPolling.js';

const REQUEST_ORGANIZATION = 'requestOrganization';
const RECEIVE_ORGANIZATION_SUCCESS = 'receiveOrganizationSuccess';
const RECEIVE_ORGANIZATION_ERROR = 'receiveOrganizationError';
export const SET_ORG_MISMATCH = 'setOrgMismatch';

const initialState = {
  fetchOrganizationStatus: requestStatus.IDLE,
  organization: {},
  currentOrganizationId: window.PollyGlobals?.currentOrgId,
  orgMismatch: false,
};

const actions = {
  fetchOrganization: async ({ commit, state }) => {
    commit(REQUEST_ORGANIZATION);

    try {
      const organization = await getOrganization(state.currentOrganizationId);
      commit(RECEIVE_ORGANIZATION_SUCCESS, organization || {});
    } catch (error) {
      onErrorHandler(error, 'core-get-operation', [403], true);
      commit(RECEIVE_ORGANIZATION_ERROR);
    }
  },
  switchOrganization: async ({ commit, dispatch, state }, orgId) => {
    try {
      state.currentOrganizationId = orgId;
      await api.patch(`/api/change_default_org`, {
        org_id: orgId,
      });
      window.PollyGlobals.currentOrgId = orgId;
      await dispatch('fetchOrganization');
      commit('core/setPermissions', null, { root: true });
      await dispatch('core/getPermissions', {}, { root: true });
      await dispatch('core/refreshWaffle', {}, { root: true });
    } catch (e) {
      onErrorHandler(e, 'switch-organization');
    }
  },
  async initIMAStatusPolling({ dispatch, state, getters }) {
    await startOrgPolling({ dispatch, state, getters });
    return cleanupOrgPolling;
  },
};

const getters = {
  organization: state => state.organization,
  currentOrganizationId: state => state.currentOrganizationId,
  isFetchOrganizationLoading: state =>
    state.fetchOrganizationStatus === requestStatus.LOADING,
  isImaServicingCurrent: state =>
    isIMAServicingPriceCurrent(state.organization),
  isRefinitivCustomer: state => state.organization.is_refinitiv_customer,
  orgMismatched: state => state.orgMismatch,
};

export const mutations = {
  [REQUEST_ORGANIZATION]: state => {
    state.fetchOrganizationStatus = requestStatus.LOADING;
  },
  [RECEIVE_ORGANIZATION_SUCCESS]: (state, organization) => {
    state.fetchOrganizationStatus = requestStatus.SUCCESS;
    state.organization = { ...organization };
  },
  [RECEIVE_ORGANIZATION_ERROR]: state => {
    state.fetchOrganizationStatus = requestStatus.ERROR;
  },
  [SET_ORG_MISMATCH]: (state, mismatched) => {
    state.orgMismatch = mismatched;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

<template>
  <div class="search-container" :class="smallClass">
    <b-input-group
      v-click-outside="onClickOutside"
      :class="{ 'input-focus': isSearchActive || searchText }"
    >
      <b-input-group-prepend>
        <button class="btn btn-short barebone" tabindex="-1" @focus="iconClick">
          <px-icon icon="magnifying-glass" :inline="false" :size="iconSize" />
        </button>
      </b-input-group-prepend>
      <b-input
        ref="searchEntityRef"
        v-model="searchText"
        class="searchEntity"
        data-testid="pe-search-box"
        :placeholder="placeholder"
        trim
        type="search"
        @focus="onFocus"
        @input="search"
      />
      <b-input-group-append>
        <button
          v-if="enableAdvancedFilter"
          v-show="isSearchActive"
          id="advancedFilterBtn"
          aria-label="advanced filter icon"
          class="btn btn-short barebone"
          :class="{ 'text-primary': showAdvancedSearch }"
          @click="openAdvancedFilter"
        >
          <px-icon icon="filter" :inline="false" />
          <div
            v-if="enableAdvancedFilter && isAdvancedFilterApplied"
            class="active-filter-indicator"
          ></div>
        </button>
      </b-input-group-append>
      <b-input-group-append>
        <button
          v-show="advancedSearchKeys"
          id="advancedBtn"
          aria-label="advanced search icon"
          class="barebone icon btn-link"
          :class="{ 'text-primary': showAdvancedSearch }"
          @click="showAdvancedSearchPopup"
        >
          <px-icon icon="filter" :inline="false" />
        </button>
      </b-input-group-append>
    </b-input-group>

    <b-card
      v-if="advancedSearchKeys"
      v-show="showAdvancedSearch"
      v-click-outside="closeAdvancedSearch"
      align="center"
      class="advanced-search-card"
    >
      <div v-for="key in advancedSearchKeys" :key="key.label">
        <b-form-group
          v-if="advancedSearchSpec[key].type == 'String'"
          :label="advancedSearchSpec[key].label"
          label-cols-sm="5"
          :label-for="key"
        >
          <b-input
            :id="key"
            :ref="key"
            :placeholder="advancedSearchSpec[key].placeholder"
          />
        </b-form-group>
        <b-row
          v-else-if="
            advancedSearchSpec[key].type == 'Integer' ||
            advancedSearchSpec[key].type == 'Decimal'
          "
          :label="advancedSearchSpec[key].label"
          label-cols-sm="5"
          :label-for="key"
        >
          <b-col align="left" md="5">
            <label class="search-label">
              {{ advancedSearchSpec[key].label }}
            </label>
          </b-col>
          <b-col md="3">
            <px-numeric-input
              :id="key + '-from'"
              :ref="key + '-from'"
              :decimal="advancedSearchSpec[key].type === 'Decimal'"
              :disabled="false"
              placeholder="Min"
            />
          </b-col>
          <b-col md="3">
            <px-numeric-input
              :id="key + '-to'"
              :ref="key + '-to'"
              :decimal="advancedSearchSpec[key].type === 'Decimal'"
              :disabled="false"
              placeholder="Max"
            />
          </b-col>
        </b-row>
      </div>
      <b-button
        class="btn-outline-primary search-button"
        variant="secondary"
        @click="search"
      >
        Search
      </b-button>
    </b-card>
  </div>
</template>

<script>
import debounce from 'lodash/fp/debounce';
import vClickOutside from 'v-click-outside';
import PxNumericInput from '@shared/components/PxNumericInput.vue';

export default {
  name: 'PxSearchBox',
  components: {
    PxNumericInput,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    small: { type: Boolean, default: false },
    iconSize: { type: String, default: 'md' },
    activePlaceholderText: { type: String, default: 'Search' },
    notActivePlaceholderText: { type: String, default: 'Search' },
    advancedSearchSpec: {
      type: Object,
      default: null,
    },
    isSimpleSearch: { type: Boolean, default: false },
    enableAdvancedFilter: { type: Boolean, default: false },
    isAdvancedFilterApplied: { type: Boolean, default: false },
    isAdvancedFilterOpen: { type: Boolean, default: false },
  },
  data() {
    return {
      searchText: '',
      searchActive: false,
      showAdvancedSearch: false,
      showAdvancedSearchClicked: false,
      advancedSearchKeys: null,
    };
  },
  computed: {
    showInput() {
      if (!this.small) return true;
      return this.isSearchActive;
    },
    smallClass() {
      return { 'small-search': this.small };
    },
    isSearchActive: function () {
      return this.searchActive || this.searchText != '';
    },
    placeholder() {
      return this.isSearchActive
        ? this.activePlaceholderText
        : this.notActivePlaceholderText;
    },
  },
  watch: {
    isSearchActive(value) {
      this.$emit('expanded', value);
    },
  },
  async mounted() {
    if (this.advancedSearchSpec) {
      const searchKeys = Object.keys(this.advancedSearchSpec);
      if (
        searchKeys.length > 1 ||
        (searchKeys.length == 1 && searchKeys[0] !== 'name')
      ) {
        this.advancedSearchKeys = searchKeys;
      }
    }
  },
  methods: {
    onFocus() {
      this.searchActive = true;
    },
    onClickOutside() {
      if (!this.isAdvancedFilterApplied && !this.isAdvancedFilterOpen) {
        this.searchActive = false;
      }
    },
    iconClick() {
      this.$nextTick(() => this.$refs.searchEntityRef.focus());
    },
    search: debounce(500, async function (value) {
      let searchText = `name,${value}`;
      let searchKeys = [];
      if (this.advancedSearchSpec) {
        searchKeys = Object.keys(this.advancedSearchSpec);
      }
      if (this.isSimpleSearch) {
        searchText = value;
      } else if (
        searchKeys.length > 1 ||
        (searchKeys.length == 1 && searchKeys[0] !== 'name')
      ) {
        searchText = '';
        for (const key of searchKeys) {
          const searchItem = this.advancedSearchSpec[key];
          let ref = null;
          let refSecondary = null;
          const lookupFunction = searchItem.lookupFunction;
          let keyValue = null;
          let keySecondaryValue = null;

          // If the Advanced Search popup isn't being shown and the search item
          // shouldn't default to text entered in search box then bypass item.
          if (!this.showAdvancedSearch && !searchItem.defaultToInputSearchBox) {
            continue;
          }
          // If the search item should default to the text entered  in search box
          // then assign this value to it's `localValue`
          else if (
            !this.showAdvancedSearch &&
            searchItem.defaultToInputSearchBox &&
            searchItem.type == 'String'
          ) {
            ref = this.$refs[key][0];
            ref.localValue = value;
          }

          switch (searchItem.type) {
            case 'String':
              ref = this.$refs[key][0];
              if (lookupFunction && ref) {
                keyValue = await lookupFunction(ref.localValue);
                if (typeof keyValue == 'string' && keyValue.length == 0) {
                  keyValue = '__not_found__';
                }
              } else if (ref) {
                keyValue = ref.localValue;
              }
              break;
            case 'Integer':
            case 'Decimal':
              ref = this.$refs[`${key}-from`][0];
              refSecondary = this.$refs[`${key}-to`][0];
              if (ref && refSecondary) {
                keyValue = ref.getValue();
                keySecondaryValue = refSecondary.getValue();
              }
              break;
          }

          if (keyValue || keySecondaryValue) {
            searchText += `${key},`;
            if (keyValue) {
              searchText += `${keyValue}`;
            }
            if (keySecondaryValue) {
              searchText += `,${keySecondaryValue}`;
            }
            searchText += '^'; // Search Term Separator
          }
        }
        searchText = searchText.substring(0, searchText.length - 1);
      }
      this.$emit('search-text', searchText);
    }),
    clearSearch() {
      this.searchText = '';
      this.$emit('search-text', '');
    },
    closeAdvancedSearch() {
      if (!this.showAdvancedSearchClicked) {
        this.showAdvancedSearch = false;
      }
      this.showAdvancedSearchClicked = false;
    },
    showAdvancedSearchPopup() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
      if (!this.showAdvancedSearch) return;
      this.showAdvancedSearchClicked = true;

      // Go thru Advanced Search Spec, and set value from this.serachText on
      // items that have the `defaultToInputSearchBox` set to `true`.
      const searchKeys = Object.keys(this.advancedSearchSpec);
      for (const key of searchKeys) {
        const searchItem = this.advancedSearchSpec[key];
        if (searchItem.defaultToInputSearchBox) {
          const ref = this.$refs[key][0];
          ref.localValue = this.searchText;
        }
      }
    },
    openAdvancedFilter() {
      this.searchText = '';
      this.$emit('open-advanced-filter');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/variables';

.barebone {
  border-width: 0;
  height: unset;
  margin: 0;
  min-width: unset;
  padding: 0;
}

.active-filter-indicator {
  position: absolute;
  top: 11px;
  right: 0;
  border-radius: 7px;
  height: 7px;
  width: 7px;
  background: $polly-light-blue;
}
</style>

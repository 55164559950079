import api from '@shared/services/api.js';
import { getDayAndDateAndTime } from '@shared/utils/converters.js';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';

export async function getRates(tableOptions = {}, configuration_id = null) {
  let rates = {};
  const url = api.constructUrl('/pe/api/rates/', { ...tableOptions });
  const response = await api.get(url);
  rates = response.rates;
  if (configuration_id) {
    rates = response.rates.filter(
      rate => rate.changesetId === configuration_id,
    );
  }
  rates.some(function (item) {
    if (item.isPublished) {
      item._rowVariant = 'active';
      return true;
    }
  });
  if (response.next) {
    tableOptions.total = rates.length + 1;
  }
  return rates;
}

export async function generateBaseRates(configuration_id, sourceRateSetIds) {
  const result = await api.post('/pe/api/rates/', {
    configuration_id: configuration_id,
    source_rate_set_ids: sourceRateSetIds,
  });
  return result;
}

export const getBaseRateRequest = async (orgId, baseRateSetId) => {
  const result = await api.get(
    `/api/v1/organizations/${orgId}/base_rate_requests/${baseRateSetId}/`,
  );
  return result;
};

export async function refreshAgencyInvestorRates(agencyInvestorId) {
  return api.post('/pe/api/investor-rates', {
    agency_investor_id: agencyInvestorId,
  });
}

export async function getRecentInvestorRateInfo(investorId) {
  return api.get(`/pe/api/investor-rates/${investorId}/recent/`);
}

export async function getInvestorRates(investorRateSetId) {
  try {
    const response = await api.get(
      `/pe/api/investor-rates/${investorRateSetId}`,
    );
    return response.investor_rates;
  } catch (error) {
    onErrorHandler(error, 'pe-get-investor_rates_set');
  }
}

export async function downloadInvestorRates(investorRateSetId) {
  const investor_rates = await getInvestorRates(investorRateSetId);
  const dateTime = getDayAndDateAndTime(investor_rates.createdOn).replace(
    /\s/g,
    '',
  );
  const downloadFileName = `${investor_rates.investor}_${dateTime}.csv`;

  let csv = 'Product,Name,Rate,Price,Lock,Coupon\n';
  investor_rates.rates.forEach(rate => {
    csv += `${rate.productCode},${rate.productName},${rate.rate},${rate.price},${rate.lockPeriod},${rate.coupon}\n`;
  });

  const anchorElement = document.createElement('a');
  anchorElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
  anchorElement.target = '_blank';
  anchorElement.download = downloadFileName;
  anchorElement.click();
}

export async function uploadCustomRateSheet(customRateSheetFile) {
  const formData = new FormData();
  formData.append('customRates', customRateSheetFile, customRateSheetFile.name);
  const response = await api.postFormData('/pe/api/custom-rates', formData);
  return response.data.result;
}

<template>
  <div class="px-text-area">
    <b-form-textarea
      v-bind="$attrs"
      :max-rows="maxRows"
      :placeholder="placeholder"
      :rows="rows"
      :value="value"
      @focusout="$emit('focusout')"
      @input="changingValue"
    ></b-form-textarea>
  </div>
</template>

<script>
export default {
  name: 'PxTextArea',
  model: {
    prop: 'value',
    event: 'changingValue',
  },
  props: {
    value: { type: String, default: null },
    placeholder: { type: String, required: false, default: '' },
    maxRows: { type: Number, required: false, default: 20 },
    rows: { type: Number, required: false, default: 2 },
  },
  methods: {
    changingValue(value) {
      this.$emit('changingValue', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.px-text-area {
  textarea,
  textarea.form-control {
    overflow-y: auto !important;
    background-color: white;
    line-height: 18px;
    padding-top: 18px;

    &:focus {
      background-color: white;
    }
  }
}
</style>

import api from '@shared/services/api.js';
import { downloadBlob } from '@shared/utils';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';

export default {
  async loanDetails(id, forSeller) {
    const detailsType = forSeller ? 'sell' : 'buy';
    const url = `/api/loan/${id}/${detailsType}`;
    return await api.get(url);
  },
  async downloadCommittedLoans(ids, filename = null) {
    try {
      const url = `/sell/api/committed_loans_download?loan_ids=${ids}`;
      const response = await api.getBlob(url);
      const _filename =
        filename || `committed_loans_${new Date().toLocaleDateString()}.csv`;
      downloadBlob(_filename, response.data);
    } catch (error) {
      onErrorHandler(error, 'loans-downloadCommittedLoans');
    }
  },
  async downloadActiveBids(ids, filename = null) {
    try {
      const url = `/sell/api/active_bids_download?loan_ids=${ids}`;
      const response = await api.getBlob(url);
      const _filename =
        filename || `active_bids_${new Date().toLocaleDateString()}.csv`;
      downloadBlob(_filename, response.data);
    } catch (error) {
      onErrorHandler(error, 'loans-downloadActiveBids');
    }
  },
  async downloadLoansBidTapes(ids, filename = null) {
    try {
      const url = `/sell/api/bid_tape_download?loan_ids=${ids}`;
      const response = await api.getBlob(url);
      const _filename =
        filename || `bid_tape_${new Date().toLocaleDateString()}.csv`;
      downloadBlob(_filename, response.data);
    } catch (error) {
      onErrorHandler(error, 'loans-downloadLoansBidTapes');
    }
  },
  async removeLoanFromCommitment(id) {
    const url = `/sell/api/remove_from_commitment/${id}`;
    return await api.get(url);
  },
  async archiveLoans(ids) {
    const url = '/sell/api/loans';
    return await api.delete(url, { loan_ids: ids });
  },
  async unarchiveLoans(ids) {
    const url = '/sell/api/loans';
    await api.post(url, { loan_ids: ids });
  },
};

<template>
  <b-dropdown
    ref="pcDropdown"
    class="px-split-dropdown"
    :class="{
      'wide-pill': pill,
    }"
    :disabled="disabled"
    :dropup="menuDropUp"
    :menu-class="['mt-2', 'mb-2', menuClass]"
    :right="menuRight"
    :split="split"
    split-class="px-dd-split-btn"
    :split-variant="variant"
    :text="button.display"
    :toggle-class="split ? 'px-dd-split-toggle-btn' : 'px-dd-toggle-btn'"
    :variant="variant"
    @click="$emit('primaryBtn', button.id)"
    @mouseover="$emit('mouseover', $event)"
  >
    <template v-for="btn in dropdownItems">
      <div :id="`request-lock-${btn.id}`" :key="btn.display">
        <b-dropdown-item
          :key="btn.id"
          :class="['px-dd-item', 'text-center', variant + '-item']"
          :disabled="btn.disabled"
          @click="$emit('dropdownBtn', btn.id)"
        >
          {{ btn.display }}
        </b-dropdown-item>
      </div>
      <b-popover
        v-if="btn.disabled && !isProductEnabled"
        :key="btn.id"
        placement="top"
        :target="`request-lock-${btn.id}`"
        triggers="focus hover"
        variant="warning"
      >
        <div>
          A lock request is not permitted on this product at this time due to:
          <div
            v-for="name in productDisqualifyingRuleName"
            :key="name"
            class="product-disqualifying-rule"
          >
            <em>{{ name }}</em>
          </div>
        </div>
      </b-popover>
    </template>
  </b-dropdown>
</template>
<script>
export default {
  name: 'PxSplitButton',
  props: {
    // expects objects as {id: string, display: string}
    button: {
      type: Object,
      required: false,
      default: () => {
        return { id: '', display: '' };
      },
    },
    dropdownItems: {
      type: Array,
      required: false,
      default: () => {
        return [{ id: '', display: '' }];
      },
    },
    disabled: { type: Boolean, required: false, default: false },
    menuRight: { type: Boolean, default: false },
    menuDropUp: { type: Boolean, default: false },
    menuClass: { type: String, default: '' },
    split: { type: Boolean, default: true },
    variant: { type: String, default: 'primary' },
    isProductEnabled: { type: Boolean, default: true },
    productDisqualifyingRuleName: { type: Array, default: () => [] },
    pill: {
      default: false,
      required: false,
      type: Boolean,
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@shared/styles/variables';

$bs_secondary: #878787;
$bs_success: #2cc185;
$bs_warning: #ffdc42;
$bs_danger: #f45846;
$bs_info: #58c7ef;
$bs_light: #212529;
$bs_dark: #545454;
$bs_outline_dark: #323232;

.px-split-dropdown {
  // TODO do we want other border colors?
  ::v-deep .btn {
    &.btn-primary {
      border-color: $polly-dark-blue;

      &:first-child {
        border-left-color: $polly-dark-blue;
      }
    }
    &.btn-outline-dark:not(:hover) {
      color: $bs_outline_dark;
      border-color: $bs_outline_dark;
    }
    &.btn-outline-secondary:not(:hover) {
      color: $bs_secondary;
      border-color: $bs_secondary;
    }
  }
  &.wide-pill {
    width: auto;
    height: 20px;

    ::v-deep .dropdown-toggle.px-dd-toggle-btn {
      height: 20px;
      padding: 0;
      width: auto;
      white-space: nowrap;
      border-radius: 10px;
    }
    ::v-deep .btn-primary {
      border-color: $polly-light-blue;
    }
  }
  ::v-deep .dropdown-toggle.px-dd-toggle-btn {
    //Need padding before and after button content to align the carot more
    //to the right of the button while keeping the button text in the center
    //when using non-split dropdown.
    $carot-margin: 18px;

    &:before {
      content: '';
      margin-left: $carot-margin;
    }

    &:after {
      display: inline-block;
      margin-left: $carot-margin;
      margin-right: 5px;
    }
  }
}

::v-deep .dropdown-toggle-split.btn {
  &.dropdown-toggle {
    width: 25px;
    min-width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    &:hover:not(.disabled) {
      // TODO do we want other variant accent colors?
      &.btn-primary {
        background-color: $accent;
      }
    }

    &:after {
      display: inline-block;
    }

    &:disabled {
      border-left-color: transparent;
    }
    &.px-dd-split-toggle-btn {
      border-right-width: 1px;
    }

    &.btn-primary {
      background-color: #3c74bf;
      color: $white;
    }
    &.btn-outline-dark {
      color: $bs_outline_dark;
      border-color: $bs_outline_dark !important;
    }
  }
}

::v-deep .dropdown-menu {
  padding: 0;
  .px-dd-item {
    //Replicate bootstrap variant themes
    &.outline-secondary-item {
      a.dropdown-item {
        color: $bs_secondary;
      }
    }
    &.outline-success-item {
      a.dropdown-item {
        color: $bs_success;
      }
    }
    &.outline-warning-item {
      a.dropdown-item {
        color: $bs_warning;
      }
    }
    &.outline-danger-item {
      a.dropdown-item {
        color: $bs_danger;
      }
    }
    &.outline-info-item {
      a.dropdown-item {
        color: $bs_info;
      }
    }
    &.outline-light-item {
      a.dropdown-item {
        color: $bs_light;
      }
    }
    &.outline-dark-item {
      a.dropdown-item {
        color: $bs_dark;
      }
    }
    &:hover {
      &.primary-item {
        .disabled {
          color: $bs-secondary !important;
        }
        .dropdown-item:not(.disabled) {
          color: $white !important;
        }
      }
      &.outline-secondary-item,
      &.secondary-item {
        .dropdown-item {
          color: $white;
          background-color: $bs_secondary;
          border-color: $bs_secondary;
        }
      }
      &.outline-success-item,
      &.success-item {
        .dropdown-item {
          color: $white;
          background-color: $bs_success;
          border-color: $bs_success;
        }
      }
      &.outline-warning-item,
      &.warning-item {
        .dropdown-item {
          color: $white;
          background-color: $bs_warning;
          border-color: $bs_warning;
        }
      }
      &.outline-danger-item,
      &.danger-item {
        .dropdown-item {
          color: $white;
          background-color: $bs_danger;
          border-color: $bs_danger;
        }
      }
      &.outline-info-item,
      &.info-item {
        .dropdown-item {
          color: $white;
          background-color: $bs_info;
          border-color: $bs_info;
        }
      }
      &.outline-light-item,
      &.light-item {
        .dropdown-item {
          color: $bs_light;
          background-color: $white;
          border-color: $white;
        }
      }
      &.outline-dark-item,
      &.dark-item {
        .dropdown-item {
          color: $white;
          background-color: $bs_dark;
          border-color: $bs_dark;
        }
      }
    }
  }

  //don't use border radius between dropdown items for a clean line separating items
  .px-dd-item:first-of-type:hover {
    .dropdown-item {
      border-radius: 4px 4px 0 0;
    }
  }
  .px-dd-item:last-of-type:hover {
    .dropdown-item {
      border-radius: 0 0 4px 4px;
    }
  }
  .px-dd-item:only-of-type:hover {
    .dropdown-item {
      border-radius: 4px;
    }
  }
}
.product-disqualifying-rule {
  font-size: 9pt;
  font-weight: bold;
}
</style>

import Vue from 'vue';
import VueAnalytics from 'vue-analytics';

export default function (router) {
  if (window.gaParams && window.gaParams.id) {
    const fields = {};
    if (window.gaParams.userId) {
      fields.userId = window.gaParams.userId;
    }
    const set = [];
    if (window.gaParams.orgId) {
      set.push({
        field: 'dimension1',
        value: window.gaParams.orgId,
      });
    }
    const autoTracking = {
      exception: true,
      exceptionLogs: false,
    };
    Vue.use(VueAnalytics, {
      id: window.gaParams.id,
      router,
      fields,
      set,
      autoTracking,
    });
  }
}

import Vue from 'vue';

import { DEFAULT_TABLE_OPTIONS } from '@shared/constants';
import api from '@shared/services/api.js';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import { showMessages } from '@shared/utils/notifier.js';
import { getLoans } from '@shared/api/loans.js';
import * as mutationTypes from './mutationTypes.js';
import * as dispatcherTypes from './dispatcherTypes.js';

export default {
  namespaced: true,
  state: () => ({
    searchText: null,
    searchBidDueDate: null,
    tableOptions: {
      ...DEFAULT_TABLE_OPTIONS,
      sortBy: 'id',
      sortDesc: false,
    },
    totalAvailableLoans: null,
    loans: [],
    showArchived: false,
    isLoading: true,
    isArchiving: false,
    isRestoring: false,
    isAdvancedFilterOpen: false,
    filterLoanNumbers: [],
  }),
  getters: {
    searchText: state => state.searchText,
    searchBidDueDate: state => state.searchBidDueDate,
    tableOptions: state => state.tableOptions,
    showArchived: state => state.showArchived,
    isLoading: state => state.isLoading,
    loans: state => state.loans,
    isArchiving: state => state.isArchiving,
    isRestoring: state => state.isRestoring,
    isAdvancedFilterOpen: state => state.isAdvancedFilterOpen,
    isAdvancedFilterApplied: state => state.filterLoanNumbers.length > 0,
    totalAvailableLoans: state => state.totalAvailableLoans,
    filterLoanNumbers: state => state.filterLoanNumbers,
  },
  mutations: {
    [mutationTypes.SET_SEARCH_TEXT](state, searchText) {
      state.searchText = searchText;
    },
    [mutationTypes.SET_FILTER_LOAN_NUMBERS](state, filterLoanNumbers) {
      state.filterLoanNumbers = filterLoanNumbers;
    },
    [mutationTypes.SET_BID_DUE_DATE_TEXT](state, bidDueDate) {
      state.searchBidDueDate = bidDueDate;
    },
    [mutationTypes.SET_TABLE_OPTIONS_OBJECT](state, tableOptions) {
      state.tableOptions = tableOptions;
    },
    [mutationTypes.SET_TABLE_OPTIONS_TOTAL](state, total) {
      state.tableOptions.total = total;
    },
    [mutationTypes.SET_TOTAL_AVAILABLE_LOANS](state, totalAvailableLoans) {
      state.totalAvailableLoans = totalAvailableLoans;
    },
    [mutationTypes.SET_LOAN_OBJECT](state, loans) {
      state.loans = loans;
    },
    [mutationTypes.SET_IS_LOADING_STATE](state, isLoading) {
      state.isLoading = isLoading;
    },
    [mutationTypes.SET_SHOW_ARCHIVED_STATE](state, showArchived) {
      state.showArchived = showArchived;
    },
    [mutationTypes.SET_IS_ARCHIVING_STATE](state, isArchiving) {
      state.isArchiving = isArchiving;
    },
    [mutationTypes.SET_IS_RESTORING_STATE](state, isRestoring) {
      state.isRestoring = isRestoring;
    },
    [mutationTypes.SET_IS_ADVANCED_FILTER_OPEN](state, isAdvancedFilterOpen) {
      state.isAdvancedFilterOpen = isAdvancedFilterOpen;
    },
  },
  actions: {
    setSearchText({ commit }, searchText) {
      const searchValue = searchText ? searchText.split(',')[1].trim() : null;
      commit(mutationTypes.SET_FILTER_LOAN_NUMBERS, []);
      commit(mutationTypes.SET_SEARCH_TEXT, searchValue);
    },
    setFilterLoanNumbers({ commit }, filterLoanNumbers) {
      commit(mutationTypes.SET_FILTER_LOAN_NUMBERS, filterLoanNumbers);
    },
    setBidDueDate({ commit }, bidDueDate) {
      commit(mutationTypes.SET_BID_DUE_DATE_TEXT, bidDueDate);
    },
    setShowArchived({ commit }, showArchive) {
      commit(mutationTypes.SET_SHOW_ARCHIVED_STATE, showArchive);
    },
    setIsAdvancedFilterOpen({ commit }, isAdvancedFilterOpen) {
      commit(mutationTypes.SET_IS_ADVANCED_FILTER_OPEN, isAdvancedFilterOpen);
    },
    setTotalAvailableLoans({ commit }, totalAvailableLoans) {
      commit(mutationTypes.SET_TOTAL_AVAILABLE_LOANS, totalAvailableLoans);
    },
    setTableOptions({ commit, state }, tableOptions) {
      commit(mutationTypes.SET_TABLE_OPTIONS_OBJECT, {
        ...state.tableOptions,
        ...tableOptions,
      });
    },
    clearLoanNumberFilter({ commit, dispatch }) {
      commit(mutationTypes.SET_FILTER_LOAN_NUMBERS, []);
      commit(mutationTypes.SET_IS_ADVANCED_FILTER_OPEN, false);
      dispatch(dispatcherTypes.GET_LOANS);
    },
    async applyLoanNumberFilter({ dispatch, commit }, filterLoanNumbers) {
      commit(mutationTypes.SET_FILTER_LOAN_NUMBERS, filterLoanNumbers);
      commit(mutationTypes.SET_SEARCH_TEXT, null);
      commit(mutationTypes.SET_IS_ADVANCED_FILTER_OPEN, false);
      dispatch(dispatcherTypes.GET_LOANS);
    },
    async [dispatcherTypes.GET_LOANS]({ commit, state }) {
      try {
        const payload = {
          exclude: `sold,confirmed,selected${
            state.showArchived ? '' : ',archived'
          }`,
          search_filter: state.searchText || '',
          sort_by: state.tableOptions.sortBy,
          page: state.tableOptions.pageIndex,
          sort_desc: state.tableOptions.sortDesc,
          per_page: state.tableOptions.pageSize,
          include_available_loans_count: true,
          loan_numbers: state.filterLoanNumbers,
        };
        const data = await getLoans(payload);
        commit(mutationTypes.SET_LOAN_OBJECT, data.loans || []);
        commit(mutationTypes.SET_TABLE_OPTIONS_TOTAL, data.total || 0);
        commit(
          mutationTypes.SET_TOTAL_AVAILABLE_LOANS,
          data.total_available_loans,
        );
        for (const each_loan of state.loans) {
          Vue.set(each_loan, 'selected', false);
        }
      } catch (error) {
        onErrorHandler(error, 'sell-loanManager-getLoans');
      } finally {
        commit(mutationTypes.SET_IS_LOADING_STATE, false);
      }
    },
    async archiveLoans({ commit, dispatch }, selectedLoans) {
      try {
        commit(mutationTypes.SET_IS_ARCHIVING_STATE, true);
        const { task_id } = await api.delete('/sell/api/loans', {
          loan_ids: selectedLoans.map(loan => loan.id),
        });
        const task = await dispatch(
          'asyncTasks/getTaskResult',
          { taskId: task_id, isShort: true },
          { root: true },
        );
        if (task.task_successful) {
          showMessages([
            {
              title: '',
              message: 'Loans were archived',
              type: 'success',
            },
          ]);
        } else if (task.failed_message) {
          showMessages([
            {
              title: '',
              message: task.failed_message,
              type: 'warn',
            },
          ]);
        }
      } catch (error) {
        onErrorHandler(error, 'sell-loanManager-deleteLoans');
      } finally {
        commit(mutationTypes.SET_IS_ARCHIVING_STATE, false);
        dispatch(dispatcherTypes.GET_LOANS);
      }
    },
    async restoreLoans({ commit, dispatch }, selectedLoans) {
      try {
        commit(mutationTypes.SET_IS_RESTORING_STATE, true);
        await api.post('/sell/api/loans', {
          loan_ids: selectedLoans.map(loan => loan.id),
        });
        showMessages([
          {
            title: '',
            message: 'Loans were restored',
            type: 'success',
          },
        ]);
      } catch (error) {
        onErrorHandler(error, 'sell-loanManager-restoreLoans');
      } finally {
        commit(mutationTypes.SET_IS_RESTORING_STATE, false);
        dispatch(dispatcherTypes.GET_LOANS);
      }
    },
    async updateLoans({ state, dispatch }, selectedLoans) {
      const updateData = {
        bid_due: state.searchBidDueDate,
      };
      try {
        await api.patch('/sell/api/loans', {
          loan_ids: selectedLoans.map(loan => loan.id),
          update_data: updateData,
        });
        showMessages([
          {
            title: '',
            message: 'Your changes have been successfully processed',
            type: 'success',
          },
        ]);
      } catch (error) {
        onErrorHandler(error, 'sell-loanManager-updateLoans');
      } finally {
        dispatch(dispatcherTypes.GET_LOANS);
      }
    },
  },
};

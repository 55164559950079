export const SET_FILTER_STRING = 'SET_FILTER_STRING';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_NOTIFICATION_PREFERENCES = 'SET_NOTIFICATION_PREFERENCES';
export const SET_UNREAD_NOTIFICATIONS = 'SET_UNREAD_NOTIFICATIONS';
export const SET_TOTAL_NOTIFICATIONS = 'SET_TOTAL_NOTIFICATIONS';

export default {
  SET_FILTER_STRING,
  SET_NOTIFICATION_PREFERENCES,
  SET_NOTIFICATIONS,
  SET_TOTAL_NOTIFICATIONS,
  SET_UNREAD_NOTIFICATIONS,
};

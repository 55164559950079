<template>
  <i class="px-icon" :class="classes"></i>
</template>

<script>
export default {
  name: 'PxIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
    solid: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: null,
    },
    color: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return [
        `fa-${this.icon}`,
        this.size && `fa-${this.size}`,
        this.solid ? 'fas' : 'far',
        this.inline && 'inline',
        this.color && 'icon-color',
      ];
    },
  },
};
</script>

<style lang="scss">
@import '@shared/styles/variables';

.px-icon {
  color: currentColor;

  &.icon-color {
    color: $polly-light-blue;
  }
}

.px-icon.inline {
  display: inline;
  color: currentColor;
}
</style>

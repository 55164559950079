<template>
  <div v-if="error && fullPage">
    <b-row>
      <b-col>
        <div class="px-error-boundary__error-container full-page">
          <div class="error__title">Oops</div>

          <div class="error__message">
            It looks like something went wrong.
            <br />
            If the problem persists, please contact your administrator.
          </div>

          <div class="mt-4">{{ error }}</div>
        </div>
      </b-col>
    </b-row>
  </div>

  <div v-else-if="orgMismatch">
    <b-row>
      <b-col>
        <div class="px-error-boundary__error-container full-page">
          <div class="error__title">Error</div>

          <div class="error__message">
            You are currently logged in to another organization in an open Tab
            or Window.
          </div>
          <p>
            You can only be logged in to one organization at a time. Please
            refresh your browser to resolve this issue.
          </p>
        </div>
      </b-col>
    </b-row>
  </div>

  <div v-else-if="routeError">
    <b-row>
      <b-col>
        <div class="px-error-boundary__error-container full-page">
          <div class="error__title">{{ routeError.title }}</div>
          <div class="error__message">
            {{ routeError.message }}
          </div>
        </div>
      </b-col>
    </b-row>
  </div>

  <div v-else-if="(error && !fullPage) || errorMessage" class="d-flex">
    <p v-if="errorMessage" class="text-center w-100">{{ errorMessage }}</p>
    <p v-else>Oops, something went wrong.</p>
  </div>

  <div v-else>
    <slot></slot>
  </div>
</template>

<script>
import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import { mapState } from 'vuex';

export default {
  name: 'PxErrorBoundary',
  props: {
    errorMessage: {
      default: null,
      required: false,
      type: String,
    },
    fullPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: null,
      vm: null,
      info: null,
    };
  },
  computed: {
    ...mapState('organizations', ['orgMismatch']),
    routeError() {
      return this.$route?.meta?.routeError;
    },
  },
  errorCaptured(err, vm, info) {
    this.error = err;
    this.vm = vm;
    this.info = info;

    onErrorHandler(err, 'Unhandled Error caught in error boundary');

    return !this.stopPropagation;
  },
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/variables';

.px-error-boundary {
  &__error-container {
    background-color: $gray-4;
    border-radius: 5px;
    color: white;
    display: flex;
    flex-direction: column;
    margin-top: 48px;
    min-height: 400px;
    padding: 48px;

    & .error__title {
      font-size: 115px;
      font-weight: 300;
      margin-top: auto;
    }

    & .error__message {
      font-size: 26px;
    }
  }
}
</style>

<template>
  <div class="remove-relation-pane">
    <b-card class="card-default">
      <template #header>
        <div
          class="ml-2 justify-content-between related-objects-pane__header align-items-baseline"
        >
          <h2 class="mb-0">
            <div
              class="text-accent text-card-header d-flex flex-row align-items-baseline"
            >
              <slot name="header"></slot>
              <div v-if="insertHidden" class="text-total">{{ totalText }}</div>
            </div>
          </h2>
          <slot name="extraHeaderButtons"></slot>
          <plus-and-search
            :items="availableItems"
            :placeholder="plusItemPlaceholder"
            :show-plus="showPlus"
            :show-search="showSearch"
            @expanded="onExpand($event)"
            @plusChosen="$emit('addRelation', $event)"
            @search="$emit('search', $event)"
          />
        </div>
      </template>
      <div>
        <div class="related-objects-pane__actions">
          <slot name="actions"></slot>
        </div>
        <px-table
          v-model="tableOptions"
          :allow-select-page-size="allowSelectPageSize"
          class="text-base"
          :empty-text="emptyText"
          :fields="fields"
          :items="relatedItems"
          :server-side="false"
          :show-pagination="showPagination"
        >
          <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
            <slot v-bind="scope" :name="slot" />
          </template>
          <template v-if="showRemove" #cell(action)="data">
            <b-link href="#" @click.prevent="removeRelation(data.item)">
              <px-icon
                class="remove-button icon primary"
                :data-testid="`remove-${itemsTypeName}-${data.item.name}-btn`"
                icon="circle-minus"
                size="xl"
              ></px-icon>
            </b-link>
          </template>
        </px-table>
      </div>
    </b-card>

    <b-modal
      v-if="removeRelationModal"
      :id="idRemoveRelationModal"
      centered
      hide-footer
      modal-class="lx-sm-modal remove-relation-modal"
      no-stacking
    >
      <div class="text-center configuration-modal-content">
        <div
          class="flex-grow-1 d-flex align-items-center justify-content-center"
        >
          <slot
            :item-for-remove="itemForRemove"
            name="removeRelationModal"
          ></slot>
        </div>
        <div class="d-flex flex-row justify-content-center mt-2 mb-4 pb-2">
          <b-button
            class="mr-2"
            variant="outline-primary"
            @click="$bvModal.hide(idRemoveRelationModal)"
          >
            Cancel
          </b-button>
          <b-button
            class="ml-2"
            variant="primary"
            @click="removeRelation(itemForRemove, true)"
          >
            Remove {{ itemsTypeName }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import PlusAndSearch from '@pe/components/Common/PlusAndSearch.vue';

export default {
  name: 'PxRelatedObjectsPane',
  components: {
    PlusAndSearch,
  },
  props: {
    allowSelectPageSize: { type: Boolean, default: false, required: false },
    availableItems: { type: Array, required: true },
    customTotalText: { type: String, default: null, required: false },
    emptyText: { default: '', required: false, type: String },
    fields: { type: Array, required: true },
    itemsTypeName: { type: String, default: 'Item' },
    pageSize: { type: Number, default: 25, required: false },
    plusItemPlaceholder: { type: String, default: 'Add Object' },
    relatedItems: { type: Array, required: true },
    showPagination: { type: Boolean, default: false, required: false },
    showPlus: { type: Boolean, default: true, required: false },
    showRemove: { type: Boolean, default: true, required: false },
    showSearch: { type: Boolean, default: true, required: false },
  },
  data() {
    return {
      idRemoveRelationModal: `remove-relation-modal-${this._uid}`,
      itemForRemove: null,
      insertHidden: true,
      tableOptions: { pageSize: this.pageSize },
    };
  },
  computed: {
    removeRelationModal() {
      return (
        this.$slots.removeRelationModal || this.$scopedSlots.removeRelationModal
      );
    },
    itemsTotal() {
      return this.relatedItems.length;
    },
    totalText() {
      if (this.customTotalText) return this.customTotalText;
      return `${this.itemsTotal} Total`;
    },
  },
  methods: {
    onExpand(expanded) {
      this.insertHidden = !expanded;
      this.$emit('expanded', expanded);
    },
    removeRelation(item, confirmed = false) {
      if (this.removeRelationModal && !confirmed) {
        this.itemForRemove = item;
        this.$bvModal.show(this.idRemoveRelationModal);
      } else {
        if (this.removeRelationModal) {
          this.$bvModal.hide(this.idRemoveRelationModal);
        }
        this.$emit('removeRelation', item);
      }
    },
  },
};
</script>

<style lang="scss">
.remove-relation-modal {
  .modal-content {
    width: 22.5rem;
    color: black;

    .modal-body {
      padding: 0;

      .configuration-modal-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: 12.3125rem;
      }
    }
  }
}

.related-objects-pane__header {
  display: flex;
  height: 2em;
  max-width: 100%;
}

.text-card-header {
  white-space: nowrap;
  overflow: hidden;
}
</style>

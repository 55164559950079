import api from '@shared/services/api.js';

export default {
  async removeBidFromCart(id) {
    const url = '/sell/api/remove_bid_from_cart';
    return await api.post(url, { bid_id: id });
  },
  async getForwardBids(loanId) {
    const data = await api.get(`/sell/api/loan/${loanId}/forward_bids`);
    return data.forward_bids || [];
  },
};

import loanSvc from '@shared/services/loans.js';
import bidSvc from '@shared/services/bids.js';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';

const state = {
  loan: null,
  owner: null,
  own: null,
  ownerInfo: null,
  spotBids: [],
  forwardBids: [],
  loanFound: false,
};

const getters = {
  loan: state => state.loan,
  owner: state => state.owner,
  own: state => state.own,
  ownerInfo: state => state.ownerInfo,
  spotBids: state => state.spotBids,
  forwardBids: state => state.forwardBids,
};

const mutations = {
  setLoan(state, loan) {
    state.loan = loan;
  },
  setOwner(state, owner) {
    state.owner = owner;
  },
  setOwn(state, own) {
    state.own = own;
  },
  setOwnerInfo(state, ownerInfo) {
    state.ownerInfo = ownerInfo;
  },
  setSpotBids(state, spotBids) {
    state.spotBids = spotBids;
  },
  setForwardBids(state, forwardBids) {
    state.forwardBids = forwardBids;
  },
};

const actions = {
  async getLoanDetails({ commit }, loanId) {
    try {
      const loanData = await loanSvc.loanDetails(loanId, true);
      const forwardBids = await bidSvc.getForwardBids(loanId);

      commit('setLoan', loanData.loan);
      commit('setOwner', loanData.owner);
      commit('setOwn', loanData.own);
      commit('setOwnerInfo', loanData.owner_info);
      commit('setSpotBids', loanData.bids || []);
      commit('setForwardBids', forwardBids || []);
    } catch (e) {
      onErrorHandler(e, 'sell-loanDetails-getLoanDetails', [403, 404]);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export const CLEAR_PRODUCT_DETAILS = 'CLEAR_PRODUCT_DETAILS';
export const SET_IS_INVALID = 'SET_IS_INVALID';
export const SET_IS_INVALID_CODE = 'SET_IS_INVALID_CODE';
export const SET_IS_INVALID_NAME = 'SET_IS_INVALID_NAME';
export const SET_IS_NEW_ITEM = 'SET_IS_NEW_ITEM';
export const SET_IS_PRODUCT_OPTIONS_LOADING = 'SET_IS_PRODUCT_OPTIONS_LOADING';
export const SET_IS_READ_ONLY_ITEM = 'SET_IS_READ_ONLY_ITEM';
export const SET_PRODUCT = 'SET_PRODUCT';
export const SET_PRODUCT_POLICY_CANCEL = 'SET_PRODUCT_POLICY_CANCEL';
export const SET_PRODUCT_POLICY_EXTENSION = 'SET_PRODUCT_POLICY_EXTENSION';
export const SET_PRODUCT_POLICY_LOCK = 'SET_PRODUCT_POLICY_LOCK';
export const SET_PRODUCT_POLICY_PRICE_EXCEPTION =
  'SET_PRODUCT_POLICY_PRICE_EXCEPTION';
export const SET_PRODUCT_POLICY_RELOCK = 'SET_PRODUCT_POLICY_RELOCK';
export const SET_PRODUCT_POLICY_REPRICE = 'SET_PRODUCT_POLICY_REPRICE';
export const SET_IS_DYNAMICALLY_PRICED = 'SET_IS_DYNAMICALLY_PRICED';
export const SET_AVAILABLE_INVESTOR_PRODUCTS =
  'SET_AVAILABLE_INVESTOR_PRODUCTS';
export const SET_AVAILABLE_SOURCE_PRODUCTS = 'SET_AVAILABLE_SOURCE_PRODUCTS';
export const SET_IS_SOURCE_PRODUCTS_LOADING = 'SET_IS_SOURCE_PRODUCTS_LOADING';
export const SET_DYNAMIC_RULES = 'SET_DYNAMIC_RULES';

export default {
  CLEAR_PRODUCT_DETAILS,
  SET_IS_INVALID_CODE,
  SET_IS_INVALID_NAME,
  SET_IS_INVALID,
  SET_IS_NEW_ITEM,
  SET_IS_PRODUCT_OPTIONS_LOADING,
  SET_IS_READ_ONLY_ITEM,
  SET_PRODUCT_POLICY_CANCEL,
  SET_PRODUCT_POLICY_EXTENSION,
  SET_PRODUCT_POLICY_LOCK,
  SET_PRODUCT_POLICY_PRICE_EXCEPTION,
  SET_PRODUCT_POLICY_RELOCK,
  SET_PRODUCT_POLICY_REPRICE,
  SET_PRODUCT,
  SET_IS_DYNAMICALLY_PRICED,
  SET_AVAILABLE_INVESTOR_PRODUCTS,
  SET_AVAILABLE_SOURCE_PRODUCTS,
  SET_IS_SOURCE_PRODUCTS_LOADING,
  SET_DYNAMIC_RULES,
};

import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

import {
  ADJUSTMENT_EDITED_MAP,
  ADJUSTMENT_KIND,
  ADJUSTMENT_NAME_MATCH,
  ADJUSTMENT_TYPE,
  EXCEPTION_TYPES,
  LOCK_RATE_ACTIONS,
  PERuleCategories,
  PRICING_ROUND,
  RULE_TARGET,
  WORKFLOW_LOCK_SIDES,
  PERuleSubCategories,
} from '@shared/constants';
import { jsonToBool } from '@shared/utils/converters.js';

export function mapAdjustmentFees(adjustments) {
  return adjustments.map(adjustment => {
    if (
      [ADJUSTMENT_KIND.EXTENSION_FEE, ADJUSTMENT_KIND.RELOCK_FEE].includes(
        adjustment.kind,
      )
    ) {
      const { id, value, description, is_buy_side, is_sell_side } = adjustment;
      const feeType =
        adjustment.kind === ADJUSTMENT_KIND.EXTENSION_FEE
          ? 'Extension'
          : 'Relock';
      const ruleName = description.includes(feeType)
        ? `${description}`
        : `${feeType} Fee ${description}`;
      return {
        id,
        value,
        is_buy_side,
        is_sell_side,
        description: ruleName,
        rule_category: PERuleCategories.Adjustment,
        type: ADJUSTMENT_TYPE.PRICE,
        kind: adjustment.kind,
      };
    } else if (adjustment.description === PRICING_ROUND) {
      adjustment.rule_category = PERuleCategories.Margin;
      return adjustment;
    } else if (adjustment.rule_category === PERuleCategories.Clamp) {
      adjustment.rule_category = PERuleCategories.Adjustment;
      return adjustment;
    } else {
      return adjustment;
    }
  });
}

export function getWorkflowExtensionAdjustment(workflow) {
  if (workflow?.action === LOCK_RATE_ACTIONS.EXTENSION) {
    const { extendBy, originalExtendBy, extensionFee } = workflow;
    const days = originalExtendBy ? originalExtendBy : extendBy;
    return {
      value: extensionFee,
      description: `(${days} days)`,
      kind: ADJUSTMENT_KIND.EXTENSION_FEE,
    };
  }
}

export function filterWorkflowAdjustments(
  adjustments,
  workflowAction,
  exceptions = [],
) {
  adjustments = mapAdjustmentFees(adjustments);
  const exceptionTypes = {
    [EXCEPTION_TYPES.BRANCH]: 'Branch',
    [EXCEPTION_TYPES.CORPORATE]: 'Corporate',
  };

  for (const exc of exceptions) {
    exc.description = `${ADJUSTMENT_NAME_MATCH.EXCEPTION}${
      exceptionTypes[exc.type]
    })`;
    exc.rule_category = PERuleCategories.Adjustment;
    exc.is_buy_side = true;
    adjustments.push(exc);
  }
  return adjustments.filter(adj => Number(adj.value) !== 0);
}

export function filterPriceAdjustments(adjustments, canViewHiddenAdjustments) {
  // price adjustments are just anything that aren't rate adjustments and don't target ARM Margin

  let filtered = adjustments.filter(
    adj =>
      (adj.type !== ADJUSTMENT_TYPE.RATE &&
        adj.original_data?.target !== RULE_TARGET.ARM_MARGIN) ||
      adj.description.includes(ADJUSTMENT_NAME_MATCH.EXCEPTION),
  );
  if (!canViewHiddenAdjustments) {
    filtered = filtered
      .filter(
        adj =>
          (!adj.original_data?.isHiddenAdjustment &&
            (!adj.rule_sub_category ||
              adj.rule_sub_category === PERuleSubCategories.None)) ||
          adj.rule_sub_category != null,
      )
      .filter(adj => adj.description !== PRICING_ROUND)
      .filter(adj => adj.originalCategory !== PERuleCategories.Rounding);
  }
  return filtered.filter(adj => Number(adj.value) !== 0);
}

export function filterRateAdjustments(adjustments) {
  return adjustments.filter(
    adj =>
      adj.type === ADJUSTMENT_TYPE.RATE &&
      !adj.description.includes(ADJUSTMENT_NAME_MATCH.EXCEPTION) &&
      Number(adj.value) !== 0,
  );
}

export function filterAdjustmentsBySide(adjustments, side) {
  if (side === WORKFLOW_LOCK_SIDES.BUY) {
    return adjustments.filter(
      adj => adj.is_buy_side && Number(adj.value) !== 0,
    );
  } else {
    return adjustments.filter(
      adj => adj.is_sell_side && Number(adj.value) !== 0,
    );
  }
}

export function haveAdjustmentsBeenEdited(
  adjustmentsEdited = {},
  type,
  side,
  isUserLockDeskOrHigher,
) {
  if (!isUserLockDeskOrHigher) {
    return false;
  }
  return !!adjustmentsEdited?.[ADJUSTMENT_EDITED_MAP[`${type}-${side}`]];
}

export const areAdjustmentsEqual = (adjustmentsOne, adjustmentsTwo) => {
  return isEqual(
    adjustmentsOne.map(castAdjustment),
    adjustmentsTwo.map(castAdjustment),
  );
};

const castAdjustment = adjustment => {
  // We should eventually use an external library for decimal precision because
  // toFixed doesn't round correctly all of the time. I'm using toFixed here so that
  // the indicators are consistent with our UI.
  const fieldToCast = {
    id: makeCast(Number),
    value: makeCast(value => Number(value).toFixed(3)),
    description: makeCast(String),
    kind: makeCast(Number),
    is_deleted: makeCast(jsonToBool),
  };
  const fields = Object.keys(fieldToCast);
  const pickedAdj = pick(adjustment, fields);

  const castedAdj = fields.reduce((adj, field) => {
    const cast = fieldToCast[field];
    const value = adj[field];

    return { ...adj, [field]: cast(value) };
  }, pickedAdj);

  return castedAdj;
};

const makeCast = cast => {
  return val => {
    if (val == null) return val;

    return cast(val);
  };
};

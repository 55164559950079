export const getTerms = (productCode, peRequestData) => {
  return {
    loanTermMonths: getLoanTerm(productCode, peRequestData),
    amortizationTermMonths: getAmortizationTerm(productCode, peRequestData),
  };
};

export const getLoanTerm = (productCode, peRequestData) => {
  return getTerm(productCode, peRequestData, 'loanTermMonths');
};

export const getAmortizationTerm = (productCode, peRequestData) => {
  return getTerm(productCode, peRequestData, 'amortizationTermMonths');
};

const getTerm = (productCode, peRequestData, standardTermField) => {
  const productResult =
    peRequestData?.eligibleProducts?.find(
      result => result.code === productCode,
    ) || peRequestData?.results?.find(result => result.code === productCode);
  return productResult?.[standardTermField];
};

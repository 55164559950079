<template>
  <b-container v-bind="$attrs" class="px-base-page" :fluid="fluid">
    <px-error-boundary full-page>
      <px-loading-wrapper
        v-if="showTitle"
        :columns="5"
        :loading="isTitleLoading"
        type="row"
      >
        <b-row class="breadcrumb-container" no-gutters>
          <div v-if="breadcrumbs && breadcrumbs.length">
            <span
              v-for="(breadcrumb, index) in breadcrumbs"
              :key="`${index}-${breadcrumb.text}`"
              class="px-breadcrumbs"
            >
              <px-icon class="ml-2 mr-2" icon="chevron-left"></px-icon>
              <component
                :is="breadcrumb.to ? 'b-link' : 'span'"
                :class="breadcrumb.to ? 'breadcrumb__link' : 'breadcrumb__text'"
                :to="breadcrumb.to || null"
              >
                {{ breadcrumb.text }}
              </component>
            </span>
          </div>
        </b-row>

        <b-row
          v-if="
            title ||
            subtitle ||
            $slots.title ||
            $slots.subtitle ||
            $slots.controls
          "
          class="px-base-page__header"
          no-gutters
        >
          <div class="px-base-page__title-group">
            <div class="px-base-page__title">
              <h1
                v-if="title && title.length && !$slots.title"
                data-testid="page-title"
              >
                {{ title }}
              </h1>

              <slot v-else name="title"></slot>
            </div>

            <div class="px-base-page__subtitle">
              <h2
                v-if="subtitle && subtitle.length"
                data-testid="page-subtitle"
              >
                {{ subtitle }}
              </h2>

              <slot v-else name="subtitle"></slot>
            </div>
          </div>

          <div class="px-base-page__controls">
            <slot name="controls"></slot>
          </div>
        </b-row>

        <b-row v-if="$slots.tabs" class="mt-3" no-gutters>
          <slot name="tabs"></slot>
        </b-row>
      </px-loading-wrapper>

      <b-row
        class="px-base-page__content"
        :class="{ 'no-title': !showTitle }"
        no-gutters
      >
        <slot name="banner"></slot>
      </b-row>

      <b-row
        v-if="$slots.left || $slots.right || $slots.center"
        class="px-base-page__content"
        :class="{ 'no-title': !showTitle }"
      >
        <b-col lg="6" md="12" :xl="leftCols">
          <slot name="left"></slot>
        </b-col>
        <b-col v-if="centerCols" lg="6" md="12" :xl="centerCols">
          <slot name="center"></slot>
        </b-col>
        <b-col lg="6" md="12" :xl="rightCols">
          <slot name="right"></slot>
        </b-col>
      </b-row>

      <b-row
        class="px-base-page__content"
        :class="{ 'no-title': !showTitle }"
        no-gutters
      >
        <slot></slot>
      </b-row>
    </px-error-boundary>
  </b-container>
</template>

<script>
/**
 * Base Page Component
 * PollyEx base views should extend this base page and pass content in via slots
 * Cards should be passed into "left" and "right" named slots
 */

export default {
  name: 'PxBasePage',
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
      required: false,
    },
    isLoading: {
      default: false,
      required: false,
      type: Boolean,
    },
    isTitleLoading: {
      default: false,
      required: false,
      type: Boolean,
    },
    leftCols: {
      default: 6,
      required: false,
      type: Number,
    },
    rightCols: {
      default: 6,
      required: false,
      type: Number,
    },
    centerCols: {
      default: 0,
      required: false,
      type: Number,
    },
    subtitle: {
      default: '',
      required: false,
      type: String,
    },
    title: {
      default: '',
      required: false,
      type: String,
    },
    fluid: {
      default: true,
      type: [String, Boolean],
    },
  },
  computed: {
    showTitle() {
      return Boolean(
        this.title ||
          this.subtitle ||
          this.$slots.title ||
          this.$slots.subtitle ||
          this.$slots.controls ||
          this.$slots.tabs ||
          (this.breadcrumbs && this.breadcrumbs.length),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/variables';

.px-base-page {
  padding: 12px 24px;

  &__header {
    flex-wrap: nowrap;
  }

  &__title-group {
    display: flex;
  }

  &__title {
    ::v-deep h1 {
      margin: 0;
    }

    ::v-deep input {
      background-color: transparent;
      border-width: 0;
      border-bottom: 2px solid $border-grey;
      color: $polly-dark-blue;
      font-size: 2.5rem;

      &:active,
      &:focus,
      &:hover {
        border-bottom: 2px solid $polly-light-blue;
      }
    }
  }

  &__subtitle {
    margin: auto;
    margin-left: 24px;

    ::v-deep h2 {
      margin: 0;
    }
  }

  &__controls {
    align-items: center;
    display: flex;
    margin-left: auto;
    margin-right: 0;

    ::v-deep > a,
    ::v-deep > button,
    ::v-deep > div,
    ::v-deep > input {
      margin-left: 16px;
    }

    ::v-deep .search-container,
    ::v-deep .btn {
      height: 40px;
    }

    ::v-deep .search-container .input-group {
      top: unset;
    }
  }

  &__content {
    margin-top: 24px;

    > ::v-deep div {
      width: 100%;
    }

    &.no-title {
      margin-top: 0;
    }
  }

  .breadcrumb-container {
    font-size: 12px;
    color: $primary;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    min-height: 32px;

    .breadcrumb {
      &__link {
        color: $primary;
      }

      &__text {
        color: $default-text;
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 64px;
  }

  .px-breadcrumbs {
    display: inline-flex;
    align-items: center;
  }
}
</style>

import {
  CLEAR_CUSTOM_PARAMETER_DETAILS,
  SET_IS_INVALID,
  SET_IS_INVALID_NAME,
  SET_IS_UPDATE_NEEDED,
  SET_CUSTOM_PARAMETER,
  SET_IS_LOADING,
  SET_VALUE_GROUPS,
  SET_SEARCH_TEXT,
  SET_TABLE_OPTIONS,
} from './mutationTypes.js';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import {
  getCustomParameter,
  updateCustomParameter,
  getEntities,
  createEntity,
  updateEntity,
  deleteEntity,
} from '@pe/services/configurations.js';
import {
  EntityTypes,
  SYSTEM_PARAM_ID,
  PERuleFieldsState,
} from '@shared/constants';

const initialState = function () {
  return {
    isInvalid: false,
    isInvalidName: false,
    isUpdateNeeded: false,
    customParameter: null,
    isLoading: true,
    valueGroups: [],
    searchText: '',
    tableOptions: { sortBy: null, sortDesc: false },
  };
};

const filterValuesBySearch = (values, searchText, fieldGetter = null) => {
  if (!searchText) {
    return values;
  }
  const regex = new RegExp(searchText, 'i');
  return values.filter(value =>
    regex.test(fieldGetter ? fieldGetter(value) : value),
  );
};

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    customParameter: state => state.customParameter,
    isInvalid: state => state.isInvalid,
    isInvalidName: state => state.isInvalidName,
    isUpdateNeeded: state => state.isUpdateNeeded,
    isLoading: state => state.isLoading,
    valueGroups: state => state.valueGroups,
    searchText: state => state.searchText,
    allowedValuesAfterSearch: state =>
      filterValuesBySearch(
        state.customParameter?.allowedValues ?? [],
        state.searchText,
      ),
    valueGroupsAfterSearch: state =>
      filterValuesBySearch(
        state.valueGroups ?? [],
        state.searchText,
        item => item.name,
      ),
    isSystemParam: state => state.customParameter?.id === SYSTEM_PARAM_ID,
    parameterName: state =>
      `${state.customParameter?.valueCategory}.${state.customParameter?.name}`,
    targetField: (state, getters) =>
      getters.isSystemParam
        ? getters.parameterName
        : `CustomValue("${state.customParameter?.name}")`,
    tableOptions: state => ({
      ...state.tableOptions,
      sortBy:
        state.sortBy ?? state.customParameter?.name === PERuleFieldsState
          ? null
          : 'value',
    }),
  },
  mutations: {
    [CLEAR_CUSTOM_PARAMETER_DETAILS](state) {
      Object.assign(state, initialState());
    },
    [SET_IS_INVALID](state, isInvalid) {
      state.isInvalid = isInvalid;
    },
    [SET_IS_INVALID_NAME](state, isInvalidName) {
      state.isInvalidName = isInvalidName;
    },
    [SET_IS_UPDATE_NEEDED](state, isUpdateNeeded) {
      state.isUpdateNeeded = isUpdateNeeded;
    },
    [SET_CUSTOM_PARAMETER](state, customParameter) {
      state.customParameter = customParameter;
    },
    [SET_IS_LOADING](state, isLoading) {
      state.isLoading = isLoading;
    },
    [SET_VALUE_GROUPS](state, groups) {
      state.valueGroups = groups;
    },
    [SET_SEARCH_TEXT](state, text) {
      state.searchText = text;
    },
    [SET_TABLE_OPTIONS](state, tableOptions) {
      state.tableOptions = tableOptions;
    },
  },
  actions: {
    setTableOptions({ commit, state }, tableOptions) {
      commit(SET_TABLE_OPTIONS, { ...state.tableOptions, ...tableOptions });
    },
    clearCustomParameterDetails({ commit }) {
      commit(CLEAR_CUSTOM_PARAMETER_DETAILS);
    },
    async initCustomParameter(
      { commit },
      { configId, paramId, initEmpty = true },
    ) {
      try {
        if (initEmpty) {
          commit(SET_CUSTOM_PARAMETER, null);
        }
        const customParameter = await getCustomParameter(configId, paramId);
        commit(SET_CUSTOM_PARAMETER, customParameter);
      } catch (error) {
        onErrorHandler(error, 'pe-custom-parameter', [403, 404]);
      }
    },
    async updateCustomParameter(
      { dispatch },
      { configId, paramId, updatePayload },
    ) {
      let result;
      try {
        result = await updateCustomParameter(configId, paramId, updatePayload);
        dispatch('initCustomParameter', {
          configId,
          paramId,
          initEmpty: false,
        });
      } catch (error) {
        onErrorHandler(error, 'pe-update-custom-parameter', [403, 404]);
      }
      return result;
    },
    async getValueGroups(
      { commit },
      { configId, targetField, initEmpty = true },
    ) {
      try {
        if (initEmpty) {
          commit(SET_VALUE_GROUPS, []);
        }

        const groups = await getEntities(
          configId,
          EntityTypes.ValueGroup + 's',
          `targetField,${targetField}`,
        );
        commit(
          SET_VALUE_GROUPS,
          groups.filter(g => g.targetField === targetField) ?? [],
        );
      } catch (error) {
        onErrorHandler(error, 'pe-get-value-groups', [403, 404]);
      }
    },
    async createValueGroup(
      { dispatch },
      { configId, targetField, name, values },
    ) {
      try {
        await createEntity(configId, EntityTypes.ValueGroup + 's', {
          name,
          targetField,
          values,
        });
        dispatch('getValueGroups', {
          configId,
          targetField,
          initEmpty: false,
        });
      } catch (error) {
        onErrorHandler(error, 'pe-get-value-groups', [403, 404]);
      }
    },
    async updateValueGroup(
      { dispatch },
      { configId, targetField, valueGroupId, name, values },
    ) {
      try {
        await updateEntity(
          configId,
          EntityTypes.ValueGroup + 's',
          valueGroupId,
          {
            name,
            values,
          },
        );
        dispatch('getValueGroups', {
          configId,
          targetField,
          initEmpty: false,
        });
      } catch (error) {
        onErrorHandler(error, 'pe-update-value-groups', [403, 404]);
      }
    },
    async deleteValueGroup(
      { dispatch },
      { configId, targetField, valueGroupId },
    ) {
      let promise;
      try {
        promise = await deleteEntity(
          configId,
          EntityTypes.ValueGroup + 's',
          valueGroupId,
        );
        dispatch('getValueGroups', {
          configId,
          targetField,
          initEmpty: false,
        });
      } catch (error) {
        onErrorHandler(error, 'pe-delete-value-groups', [403, 404]);
      }

      return promise;
    },
    async setCustomParameter({ commit }, parameter) {
      commit(SET_CUSTOM_PARAMETER, null);
      commit(SET_CUSTOM_PARAMETER, parameter);
    },
    setIsInvalid({ commit }, isInvalid) {
      commit(SET_IS_INVALID, isInvalid);
    },
    setIsInvalidName({ commit }, isInvalidName) {
      commit(SET_IS_INVALID_NAME, isInvalidName);
    },
    setIsUpdateNeeded({ commit }, isUpdateNeeded) {
      commit(SET_IS_UPDATE_NEEDED, isUpdateNeeded);
    },
    setIsLoading({ commit }, isLoading) {
      commit(SET_IS_LOADING, isLoading);
    },
    setSearchText({ commit }, text) {
      commit(SET_SEARCH_TEXT, text);
    },
  },
};

import {
  ACTIVE,
  INACTIVE,
  medianIncomeRanks,
  NO,
  US_TIME_ZONES,
  YES,
} from '@shared/constants';
import moment from 'moment';
import 'moment-timezone';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import _startCase from 'lodash/startCase';
import _lowerCase from 'lodash/lowerCase';

// converts string representation of float number
// to string with % at the end and defined precision
//
// example:
// 1.1234 -> 1.12 %
export function strToPercent(str, precision = 2) {
  return strToPrecision(str, precision) + '%';
}

// converts float string to string with defined precision
export function strToPrecision(str, precision = 2, defaultValue = '') {
  if (!str && str !== 0) return defaultValue;
  // Round properly (unlike toFixed)
  const multiplier = Math.pow(10, precision);
  return (Math.round(multiplier * parseFloat(str)) / multiplier).toFixed(
    precision,
  );
}

// converts float string to string with defined precision - rounds a number DOWNWARDS to the nearest integer
export function strToFloor(str, precision = 2) {
  const multiplier = Math.pow(10, precision);
  return (Math.floor(multiplier * parseFloat(str)) / multiplier).toFixed(
    precision,
  );
}

// converts boolean type to
// 'Yes', 'No' representation
export function boolToYesNo(str) {
  return str ? YES : NO;
}

export const jsonToBool = value => {
  if (value == null) return value;

  try {
    return JSON.parse(String(value).toLowerCase());
  } catch {
    return value;
  }
};

// return date and time in format dddd, MMMM Do, YYYY [at] hh:mm a for Pricing UI
// When displayDateWithoutTime = true, does not display Time in date
// When timeZone, timeZone to use for datetime string
export function getDateTimePricingUI(
  value,
  displayDateWithoutTime = false,
  timeZone = undefined,
) {
  if (!value) return;

  let momentValue;
  if (timeZone) {
    momentValue = moment.tz(value, timeZone);
  } else {
    momentValue = moment(value);
  }

  if (displayDateWithoutTime) {
    return momentValue.format('dddd, MMM Do, YYYY');
  }
  return momentValue.format('dddd, MMM Do, YYYY [at] hh:mm a');
}

// return date in format MM/DD/YYYY
export function getDate(value) {
  if (!value) return;
  const date = typeof value === 'string' ? new Date(value) : value;
  return format(date, 'MM/dd/yyyy');
}

/**
 *
 * @param {String} dateString ISO date string
 * @returns {String} ISO date string, next business day if input string is not a business day
 */
export function getNextBusinessDay(dateString) {
  const date = new Date(dateString);

  let offsetDays = 0;
  const dayOfWeek = date.getDay();
  if (dayOfWeek === 6) {
    offsetDays = 2;
  } else if (dayOfWeek === 0) {
    offsetDays = 1;
  }

  date.setDate(date.getDate() + offsetDays);

  return date.toISOString();
}

export function getFullDate(value) {
  if (!value) return;
  return moment(value).format('MMMM D, YYYY');
}

/**
 * getCalendarDate
 * Format calendar date from now including Today at time, Yesterday at time
 * and date for days earlier than stated
 *
 * @export
 * @param {String} date
 * @returns {String} formattedDate
 */
export function getCalendarDate(date) {
  if (!date) return;

  const defaultFormat = function () {
    return `[${moment(date).format('dddd, MMMM D')}]`;
  };

  return moment(date).calendar(null, {
    lastWeek: defaultFormat,
    sameElse: defaultFormat,
  });
}

// return time with lowercase am or pm
export function getTimeWithLowercaseAmPm(date) {
  const timeOptions = { hour: '2-digit', minute: '2-digit' };
  return date.toLocaleTimeString('en', timeOptions).toLowerCase();
}

// return date in format MM/DD/YYYY HH:MM am/pm
export function getDateAndTime(value) {
  if (!value) return;
  return moment(value).format('MM/DD/YYYY hh:mm a');
}

// return date in format MM/DD/YYYY at HH:MM am/pm
// NOTE: As why try to do more friendly date formats we might need a more
// dynamic approach to this
// Should also determine possible variations of "friendly" date formats
export function getFriendlyDateAndTime(value, useTodayOrYesterday = false) {
  if (!value) return;
  const date = new Date(value);
  if (useTodayOrYesterday) {
    if (isToday(date)) {
      return 'Today at ' + getTimeWithLowercaseAmPm(date);
    } else if (isYesterday(date)) {
      return 'Yesterday at ' + getTimeWithLowercaseAmPm(date);
    }
  }
  return moment(value).format('MM/DD/YYYY [at] hh:mm a');
}

// return true if local_date with browser's timezone is today.
export function isToday(local_date) {
  const today = new Date(); // Creates date for now with browser's timezone.
  return today.toDateString() === local_date.toDateString();
}

// return true if a local_date with browser's timezone is yesterday
export function isYesterday(local_date) {
  const yesterday = new Date(); // Creates date for now with browser's timezone.
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday.toDateString() === local_date.toDateString();
}

// return date in format Weekday, Month DD, YYYY at HH:MM am/pm
// or Today/Yesterday at HH:MM am/pm if 2nd argument is true
export function getDayAndDateAndTime(
  value,
  useTodayOrYesterday = false,
  lowercaseTodayOrYesterday = false,
) {
  if (!value) return;
  let convertedDate = '';
  const date = new Date(value);
  if (useTodayOrYesterday) {
    if (isToday(date)) {
      convertedDate = lowercaseTodayOrYesterday ? 'today' : 'Today';
    } else if (isYesterday(date)) {
      convertedDate = lowercaseTodayOrYesterday ? 'yesterday' : 'Yesterday';
    }
  }
  if (convertedDate === '') {
    const longDateOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    convertedDate = date.toLocaleDateString('en', longDateOptions);
  }
  return convertedDate + ' at ' + getTimeWithLowercaseAmPm(date) || '';
}

/**
 * getDaysTill
 * Get days until a certain date, with an optional parameter for startDate
 *
 * @export
 * @param {String} endDate
 * @param {string} startDate Optional start date, defaults to today
 * @returns {number} number of days between two dates (inclusive)
 */
export function getDaysTill(endDate, startDate, addStartDay = true) {
  const date = moment(endDate);
  const start = startDate ? new Date(startDate) : new Date();
  // +1 include start day
  const adjustment = addStartDay ? 1 : 0;
  return date.diff(start, 'days') + adjustment;
}

/**
 * addDaysToDate
 *
 * @export
 * @param {String} date start date
 * @param {number} days number of days to add
 * @returns {string} new date formatted as ISO string
 */
export function addDaysToDate(date, days) {
  return moment(date).add(days, 'days').toISOString();
}

export const makeUTCDate = inputDate => {
  const date = typeof inputDate === 'string' ? new Date(inputDate) : inputDate;
  const dateUTC = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
  return dateUTC;
};

/**
 * Returns 'Today', 'Yesterday', or date in a given format
 * @param {Date} date
 * @param {String} format
 * @returns Formated string with today  or yesterday
 */
export function relativeDateFormatted(dateValue, formatString) {
  const date = new Date(dateValue);
  if (isToday(date)) return 'Today';
  else if (isYesterday(date)) return 'Yesterday';
  else {
    return format(date, formatString);
  }
}

export function addThousandsSeparator(value, thousandSeparator = ',') {
  if (value === null) {
    return null;
  }

  return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
}

/**
 * Properly round a number to the desired decimals.
 *
 * @param {number} value The number to round
 * @param {number} decimalCount The number of decimal places to round to
 * @returns {number}
 */
export function roundDecimals(value, decimalCount = 2) {
  const multiplier = Math.pow(10, decimalCount);
  return Math.round(multiplier * value) / multiplier;
}

export function formatMoney(
  value,
  showFraction = false,
  decimalCount = 2,
  currency = '$',
  decimalSeparator = '.',
  thousandSeparator = ',',
  truncate = false,
) {
  if (!value && value !== 0) return '';
  let num = Number(value);
  if (isNaN(num) || !isFinite(num)) return '';
  const isNegative = num < 0;
  if (isNegative) {
    num = -num;
  }
  if (!truncate) {
    num = roundDecimals(num, decimalCount);
  } else {
    num = truncateDecimals(num, decimalCount);
  }
  const money = num.toFixed(decimalCount).split(decimalSeparator);
  const integerReverse = money[0].split('').reverse().join('');
  const fraction = money[1];
  const integer = integerReverse
    .match(/.{1,3}/g)
    .join(thousandSeparator)
    .split('')
    .reverse()
    .join('');
  if (showFraction)
    return `${
      isNegative ? '-' : ''
    }${currency}${integer}${decimalSeparator}${fraction}`;

  return `${isNegative ? '-' : ''}${currency}${integer}`;
}

export function truncateDecimals(num, digits) {
  var numS = num.toString(),
    decPos = numS.indexOf('.'),
    substrLength = decPos == -1 ? numS.length : 1 + decPos + digits,
    trimmedResult = Number(numS.substring(0, substrLength)),
    finalResult = isNaN(trimmedResult) ? 0 : trimmedResult;

  return parseFloat(finalResult);
}

export function numberAddCommas(value) {
  if (!value && value !== 0) return '';
  if (isNaN(value)) return value;
  // splitting number by integer and fraction
  const [integer, fraction = ''] = String(value).split('.');
  const number = Number(integer);
  return `${number.toLocaleString('en')}${fraction ? `.${fraction}` : ''}`;
}

export function strToCamelCase(string) {
  return string.replace(/\S+/g, match => {
    return match.replace(/^./g, match => {
      return match.toUpperCase();
    });
  });
}

export function formatMedianIncomeRank(value) {
  return medianIncomeRanks[value] || medianIncomeRanks[0];
}

export function getMonthName(index) {
  const monthNames = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];
  return monthNames[index];
}

export function toISOString(value) {
  let date = null;
  if (typeof value === 'string') {
    if (Date.parse(value)) {
      date = new Date(value);
    } else return value;
  } else if (value instanceof Date && !isNaN(value)) {
    date = value;
  } else return value;

  return date.toISOString();
}

export function dashIfEmpty(value) {
  return !value ? '-' : value;
}

export function boolToActiveString(value) {
  return value ? ACTIVE : INACTIVE;
}

/**
 * Converts int to ordinal number (1st, 2nd, 3rd, etc.)
 * @param {number} number
 * @returns {string} Ordinal number
 */

export function formatOrdinalNumber(number) {
  const remainder = number % 10,
    suffix =
      ~~((number % 100) / 10) === 1
        ? 'th'
        : remainder === 1
        ? 'st'
        : remainder === 2
        ? 'nd'
        : remainder === 3
        ? 'rd'
        : 'th';
  return number + suffix;
}

/**
 * Converts a string into Title Case. titleCase -> Title Case
 * @param {string} string
 * @returns {string} Title Case String
 */
export function titleCase(string) {
  return _startCase(_lowerCase(string));
}

/**
 *
 * @param {string} dateString - usually in iso format
 * @param {string} formatString - e.g. MM/dd/yyyy - Additional patterns can be found at https://date-fns.org/v2.30.0/docs/format
 * @param {string} timezone - your standard timezone strings e.g. UTC, America/Los_Angeles
 * @returns {string} Formatted date string in the specified timezone
 */
export function formatInTimeZone(dateString, formatString, timezone) {
  try {
    timezone = mapDeprecatedToCurrentTimeZone(timezone);

    return format(
      utcToZonedTime(parseISO(dateString), timezone),
      formatString,
      {
        timeZone: timezone,
      },
    );
  } catch {
    // catches non-string values and RangeError when string input can't be properly interpreted as a date
    return null;
  }
}

/**
 * Converts long form of a timezone to it's short form
 * Ex. 'US/Central' -> 'CST'
 * @param {string} timezone
 * @returns {string} string of the short form timezone
 */
export function getAbbreviatedTimezone(timezone) {
  if (timezone == US_TIME_ZONES.CENTRAL) {
    return 'CST';
  } else if (timezone == US_TIME_ZONES.PACIFIC) {
    return 'PST';
  } else if (timezone == US_TIME_ZONES.EASTERN) {
    return 'EST';
  } else if (timezone == US_TIME_ZONES.ALASKA) {
    return 'AKST';
  } else if (timezone == US_TIME_ZONES.HAWAII) {
    return 'HST';
  } else if (timezone == US_TIME_ZONES.MOUNTAIN) {
    return 'MST';
  }
  return '';
}

export const mapDeprecatedToCurrentTimeZone = deprecatedTimeZone => {
  const timeZoneMapping = {
    'US/Alaska': 'America/Anchorage',
    'US/Central': 'America/Chicago',
    'US/Eastern': 'America/New_York',
    'US/Hawaii': 'Pacific/Honolulu',
    'US/Mountain': 'America/Denver',
    'US/Pacific': 'America/Los_Angeles',
  };

  return timeZoneMapping[deprecatedTimeZone] || deprecatedTimeZone;
};

import { joinParts } from '@shared/utils/stringFormatters.js';

export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const stringifyMonths = (
  totalMonths,
  yearTerm = 'year',
  monthTerm = 'month',
) => {
  if (!totalMonths) return '';

  const years = getYearsFromMonths(totalMonths);
  const months = getRemainingMonths(totalMonths, years);

  return joinParts([
    [yearTerm, years],
    [monthTerm, months],
  ]);
};

export const getYearsFromMonths = months => Math.floor(months / 12);
export const getRemainingMonths = (totalMonths, years) =>
  totalMonths - years * 12;

import api from '@shared/services/api.js';

export const calculateScenarioMovement = (organizationId, poolId, loanIds) => {
  return api.postJson(
    `/api/v1/organizations/${organizationId}/commitment_pools/${poolId}/scenarios/`,
    { loan_ids: loanIds },
  );
};
export const calculateScenarioBestExDelta = (
  organizationId,
  poolId,
  loanIds,
) => {
  return api.postJson(
    `/api/v1/organizations/${organizationId}/commitment_pools/${poolId}/loan_best_executions/`,
    { loan_ids: loanIds },
  );
};

export const moveLoansToCommitmentPool = (organizationId, poolId, loanIds) => {
  return api.patchJson(
    `/api/v1/organizations/${organizationId}/commitment_pools/${poolId}/`,
    { loan_ids: loanIds },
  );
};

import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import api from '@shared/services/api.js';

export async function getRequestorGroups() {
  try {
    return await api.get('/pe/api/exception-management/requestor-groups/');
  } catch (error) {
    onErrorHandler(error, 'exception-mgmt-getRequestorGroups');
  }
}

export async function getRequestorGroupById(id) {
  try {
    return await api.get(`/pe/api/exception-management/requestor-group/${id}`);
  } catch (error) {
    onErrorHandler(error, 'exception-mgmt-getRequestorGroupById');
  }
}

export async function createRequestorGroup(requestorGroup) {
  try {
    return await api.post(
      '/pe/api/exception-management/requestor-groups/',
      requestorGroup,
    );
  } catch (error) {
    onErrorHandler(error, 'exception-mgmt-createRequestorGroup');
  }
}

export async function updateRequestorGroup(requestorGroup) {
  try {
    return await api.patch(
      `/pe/api/exception-management/requestor-group/${requestorGroup.id}/`,
      requestorGroup,
    );
  } catch (error) {
    onErrorHandler(error, 'exception-mgmt-updateRequestorGroup');
  }
}

export async function deleteRequestorGroup(requestorGroupId) {
  try {
    return await api.delete(
      `/pe/api/exception-management/requestor-group/${requestorGroupId}/`,
    );
  } catch (error) {
    onErrorHandler(error, 'exception-mgmt-deleteRequestorGroup');
  }
}

export async function getLoanOfficers() {
  try {
    const { loan_officers } = await api.get(
      '/pe/api/exception-management/loan-officers/',
    );
    return loan_officers;
  } catch (error) {
    onErrorHandler(error, 'exception-mgmt-getLoanOfficers');
  }
}

export async function getApprovalGroups() {
  try {
    return await api.get('/pe/api/exception-management/approval-groups/');
  } catch (error) {
    onErrorHandler(error, 'exception-mgmt-getApprovalGroups');
  }
}

export async function getApprovalGroupById(id) {
  try {
    return await api.get(`/pe/api/exception-management/approval-group/${id}`);
  } catch (error) {
    onErrorHandler(error, 'exception-mgmt-getApprovalGroupById');
  }
}

export async function createApprovalGroup(approvalGroup) {
  try {
    return await api.post(
      '/pe/api/exception-management/approval-groups/',
      approvalGroup,
    );
  } catch (error) {
    onErrorHandler(error, 'exception-mgmt-createApprovalGroup');
  }
}

export async function updateApprovalGroup(approvalGroup) {
  try {
    return await api.patch(
      `/pe/api/exception-management/approval-group/${approvalGroup.id}/`,
      approvalGroup,
    );
  } catch (error) {
    onErrorHandler(error, 'exception-mgmt-updateApprovalGroup');
  }
}

export async function getApprovers() {
  try {
    const { approvers } = await api.get(
      '/pe/api/exception-management/approvers/',
    );
    return approvers;
  } catch (error) {
    onErrorHandler(error, 'exception-mgmt-getApprovers');
  }
}

export async function cloneApproverGroup(approverGroupId) {
  try {
    const { approvalGroupId } = await api.post(
      `/pe/api/exception-management/approval-group/${approverGroupId}/clone/`,
    );
    return approvalGroupId;
  } catch (error) {
    onErrorHandler(error, 'exception-mgmt-cloneApproverGroup');
  }
}

export async function deleteApproverGroup(approverGroupId) {
  try {
    return await api.delete(
      `/pe/api/exception-management/approval-group/${approverGroupId}/`,
    );
  } catch (error) {
    onErrorHandler(error, 'exception-mgmt-deleteApproverGroup');
  }
}

export async function getSequences() {
  try {
    return await api.get(`/pe/api/exception-management/sequences/`);
  } catch (error) {
    onErrorHandler(error, 'exception-mgmt-getSequence');
  }
}

export async function getPoliciesByApprovalGroupId(id) {
  try {
    return await api.get(
      `/pe/api/exception-management/approval-group/${id}/policies`,
    );
  } catch (error) {
    onErrorHandler(error, 'exception-mgmt-getPolicy');
  }
}

export async function getPoliciesByRequestorGroupId(id) {
  try {
    return await api.get(
      `/pe/api/exception-management/requestor-group/${id}/policies`,
    );
  } catch (error) {
    onErrorHandler(error, 'exception-mgmt-getPoliciesById');
  }
}

import api from '@shared/services/api.js';
import { strToPrecision, getDateAndTime } from '@shared/utils/converters.js';
import Pluralize from 'pluralize';

const EVENT_TEXT = {
  LOAN_UPLOADED: 'Loan was uploaded by {user_name}',
  LOAN_IMPORTED: 'Loan was imported by {user_name}',
  LOAN_ADDED: 'Loan was added to system by {user_name}',
  LOAN_UPDATED: 'Loan was updated by {user_name}',
  LOAN_REACTIVATED: 'Loan reactivated by {user_name}',
  LOAN_SOLD:
    'Loan was sold, purchase amount {loan_purchase_amount}, ' +
    'by {user_name}',
  BID_CREATED: '{bid_org} Bid was created, offer {offer}, by {user_name}',
  BID_CREATED_BENCHMARK:
    '{bid_org} Bid was created, ' +
    'benchmark name {benchmark_name}, ' +
    'benchmark rate {benchmark_rate}, ' +
    'benchmark delta {benchmark_delta}, ' +
    'by {user_name}',
  BID_UPDATED: '{bid_org} Bid was updated, offer {offer}, by {user_name}',
  BID_UPDATED_BENCHMARK:
    '{bid_org} Bid was updated, ' +
    'benchmark name {benchmark_name}, ' +
    'benchmark rate {benchmark_rate}, ' +
    'benchmark delta {benchmark_delta}, ' +
    'by {user_name}',
  BID_COUNTERED:
    '{bid_org} Bid was countered, counter {counter}, ' + 'by {user_name}',
  BID_SELECTED:
    '{bid_org} Bid was selected and placed in the cart ' + 'by {user_name}',
  BID_ACCEPTED: '{bid_org} Bid was accepted ' + 'by {user_name}',
  BID_CANCELLED: '{bid_org} Bid was cancelled by {user_name}',
  BID_AUTOMATICALLY_REMOVED: '{bid_org} Bid was removed due to loan changes',
  BID_REACTIVATED: '{bid_org} Bid was reactivated by {user_name}',
  BID_EXPIRES_AT_SET: '{bid_org} Bid due time was set for {expires_at}',
  BID_EXPIRES_AT_UPDATED: '{bid_org} Bid due time was updated for {expires_at}',
  BID_BENCHMARK_SETTLEMENT_AT_CHANGED:
    'Bid benchmark {benchmark_name} {benchmark_rate} settlement date was changed for {benchmark_settlement_at}',
  CENSUS_LOOKUP_FAILED: 'Loan could not be looked up in the CRA database',
  FINAL_PRICE_ADJUSTED:
    '{org_name} commitment was updated with final price: ' +
    '{final_purchase_amount}, by {user_name}',
  FINAL_PRICE_ADJUSTED_BENCHMARK:
    '{org_name} commitment was updated with final price: ' +
    '{final_purchase_amount}, benchmark: {final_benchmark_name} ' +
    '{final_benchmark_rate}, spread: {final_delta}, by {user_name}',
  LOAN_EXTERNAL_COMMITMENT_ID_UPDATE:
    'External Pool ID was set for {external_commitment_id}',
  SERVICING_NO_RESULT:
    '{api_endpoint} Servicing returned no results for' +
    ' {provider} and {agency}. {message}',
  NO_SERVICING_PROVIDERS: 'No partnerships with servicing providers',
  INCORRECT_SERVICING_PRICING:
    'Servicing Bids has failed for {agency} and' +
    ' {provider}. A calculation error has occurred.',
  INVALID_TICKER:
    'Your organization has invalid ticker symbol, please correct.',
  ENCOMPASS_WRITEBACK_FAILED: 'Encompass writeback failed',
  ENCOMPASS_WRITEBACK_SUCCESS: 'Encompass writeback succeeded',
  LOAN_REMOVAL_FROM_COMMITMENT_FAILURE:
    'Loan could not be updated in the' +
    ' LOS. Check the LOS and retry at a later time',
  LOAN_REMOVAL_FROM_COMMITMENT_SUCCESS:
    'Loan removed from commitment' + ' {commitment_id}',
  LOAN_BID_DUE_DATE_SET: 'Loan bid due date set for {due_date}',
  LOAN_BID_DUE_DATE_UPDATED: 'Loan bid due date updated for {due_date}',
  BID_SET_ELIGIBLE:
    '{bid_ticker} Bid has been' +
    ' restored due to a rule "{eligibility_rule_name}"',
  BID_SET_INELIGIBLE:
    '{bid_ticker} Bid has been' +
    ' hidden due to a rule "{eligibility_rule_name}"',
  ALL_BIDS_SET_ELIGIBLE:
    'All Bids {from_with_except} {buyer_name} have been' +
    ' restored due to a rule "{eligibility_rule_name}"',
  ALL_BIDS_SET_INELIGIBLE:
    'All Bids {from_with_except} {buyer_name} have been' +
    ' hidden due to a rule "{eligibility_rule_name}"',
  POOL_CREATED: 'Pool {id} was created with Agency ID {contract_identifier}',
  POOL_ALLOCATED: '{count} loan were allocated to this pool',
  POOL_UNALLOCATED: 'Loan {loan_number} was unallocated from this pool',
  POOL_EXTENDED:
    'Commitment period was extended by {count} day to a total' +
    ' of {contract_days} days',
  POOL_PAIR_OFF_ADDED: 'A Pair-Off of ${unpaid_balance} was recorded',
  POOL_ADDED_TO_CART: 'Pool was added to cart',
  LOS_RATELOCK_SUCCESS: 'LOS ratelock update success',
  LOS_RATELOCK_FAILED: 'LOS ratelock update failed',
  INVALID_LOAN_ATTRIBUTES:
    '{servicing_source_type}: Loan has attributes' +
    ' that are invalid. {message}',
  LOAN_EXTERNAL_LOAN_ID_UPDATE:
    'External Loan ID {external_loan_id} was provided',
  LOAN_DELIVERY_STARTED: 'Loan delivery to {buyer_name} initiated',
  LOAN_DELIVERY_SUCCEEDED: 'Successful loan delivery to {buyer_name}',
  LOAN_DELIVERY_FAILED: 'Unable to deliver loan',
};

const AUTOBID_FAILURES = {
  invalid_interest_rate: 'Invalid interest rate.',
  no_product_code: 'No product codes found for a loan.',
  no_payup_data: 'No payup data found for a loan.',
  api_error: '{message}',
  no_base_price: 'No base price for a loan.',
  invalid_required_field: 'Invalid required field {required_field}',
  underwriting_error: 'Underwriting error {uw_error}',
  benchmark_map_error: 'Unable to map security {security_slot} to benchmark',
  benchmark_ignored_warn:
    'Unable to map security {security_slot} to benchmark for ARM loan.  Converting to fixed bid.',
};

// Penny Mac does not utilize the AutobidBuyer enum and will have diff text
const AUTOBIDDERS = {
  FREDDIE_MAC: 'Freddie Mac',
  FANNIE_MAE: 'Fannie Mae',
  'Penny Mac': 'Penny Mac',
  UNSPECIFIED: 'Automatic',
};

const PLURALIZE_TEMPLATES = {
  POOL_ALLOCATED: [
    { countParam: 'count', word: 'loan' },
    { countParam: 'count', word: 'were' },
  ],
  POOL_EXTENDED: [{ countParam: 'count', word: 'day' }],
};

const pattern = /{([^}]+)}/g;

function prepareParams(params) {
  const dateFields = ['expires_at', 'due_date', 'benchmark_settlement_at'];
  const decimalFields = [
    'offer',
    'benchmark_delta',
    'final_delta',
    'loan_purchase_amount',
    'final_purchase_amount',
  ];

  dateFields.forEach(field => {
    if (field in params) params[field] = getDateAndTime(params[field]);
  });
  decimalFields.forEach(field => {
    if (field in params) params[field] = strToPrecision(params[field], 3);
  });
  return params;
}

function prepareText(type, params) {
  let text = EVENT_TEXT[type];
  if (type in PLURALIZE_TEMPLATES) {
    PLURALIZE_TEMPLATES[type].forEach(pluralParams => {
      const newWord = Pluralize(
        pluralParams.word,
        params[pluralParams.countParam],
      );
      text = text.replace(pluralParams.word, newWord);
    });
  }
  return text;
}

function substituteParams(records) {
  for (var i = 0; i < records.length; ++i) {
    const record = records[i];
    const type = record.event_type;
    const params = prepareParams(record.event_params);
    let text = prepareText(type, params);
    if (text) {
      record.text = text.replace(pattern, (_, param) => params[param]);
    }
    if (type == 'AUTOBID_FAILED') {
      const agency = AUTOBIDDERS[params.buyer] || AUTOBIDDERS.UNSPECIFIED;
      text = `${agency} bid failed.`;
      const error_message = AUTOBID_FAILURES[params.error_code];
      if (error_message) {
        record.text =
          text +
          ' ' +
          error_message.replace(pattern, (_, param) => params[param]);
      } else {
        record.text = text;
      }
    }
  }
  return records;
}

export default {
  async loanAuditInfo(objId, type, skip, take) {
    let url = `/api/audit/${type}/${objId}`;
    url = api.constructUrl(url, { skip, take });
    const { audit, total } = await api.get(url);
    return {
      items: substituteParams(audit),
      total: total,
    };
  },
  async poolAuditInfo(poolId, skip, take) {
    let url = `/api/audit/pool/${poolId}`;
    url = api.constructUrl(url, { skip, take });
    const { audit, total } = await api.get(url);
    return {
      items: substituteParams(audit),
      total: total,
    };
  },
};

import { DEFAULT_TABLE_OPTIONS } from '@shared/constants';
import api from '@shared/services/api.js';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import {
  SET_FILTER_STRING,
  SET_NOTIFICATIONS,
  SET_NOTIFICATION_PREFERENCES,
  SET_TOTAL_NOTIFICATIONS,
  SET_UNREAD_NOTIFICATIONS,
} from './mutationTypes.js';

const state = {
  filterString: '',
  isLoading: true,
  notificationPreferences: {},
  notifications: [],
  total: 0,
  unreadTotal: 0,
};

const getters = {
  filterString: state => state.filterString || '',
  isLoading: state => state.isLoading,
  notificationPreferences: state => state.notificationPreferences,
  notifications: state => state.notifications,
  total: state => state.total,
  unreadTotal: state => state.unreadTotal,
};

const mutations = {
  [SET_FILTER_STRING](state, payload) {
    state.filterString = payload;
  },
  [SET_NOTIFICATION_PREFERENCES](state, payload) {
    state.notificationPreferences = payload;
  },
  [SET_NOTIFICATIONS](state, payload) {
    state.isLoading = payload.isLoading;
    state.notifications = payload.notifications;
  },
  [SET_UNREAD_NOTIFICATIONS](state, payload) {
    state.unreadTotal = payload;
  },
  [SET_TOTAL_NOTIFICATIONS](state, payload) {
    state.total = payload;
  },
};

const actions = {
  setFilterString({ commit }, payload) {
    commit(SET_FILTER_STRING, payload.toLowerCase());
  },
  async fetchUnreadCount({ commit }) {
    const response = await api.get('/company/api/notifications/count/unread');
    commit(SET_UNREAD_NOTIFICATIONS, response?.count);
  },
  async fetchNotifications(
    { commit, getters },
    options = { ...DEFAULT_TABLE_OPTIONS },
  ) {
    commit(SET_NOTIFICATIONS, { isLoading: true });

    let sortBy;
    if (options.sortBy === 'event') {
      sortBy = 'notification__event_type';
    } else if (options.sortBy === 'product_type') {
      sortBy = 'notification__product_type';
    } else {
      sortBy = options.sortBy;
    }

    try {
      const apiUrl = api.constructUrl('/company/api/notifications/', {
        sort_by: sortBy,
        page: options?.pageIndex || 1,
        sort_desc: options?.sortDesc || '',
        per_page: options?.pageSize || 25,
        search: getters?.filterString || '',
      });

      const response = await api.get(apiUrl, options);

      const notifications = response.notifications.map(notification => {
        return { ...notification, selected: false };
      });

      commit(SET_NOTIFICATIONS, {
        isLoading: false,
        notifications,
      });
      commit(SET_TOTAL_NOTIFICATIONS, response.total);
      commit(SET_UNREAD_NOTIFICATIONS, response.unread_total);
    } catch (error) {
      onErrorHandler(error, 'fetch-notifications');

      commit(SET_NOTIFICATIONS, {
        isLoading: false,
        notifications: [],
        total: 0,
      });
      commit(SET_TOTAL_NOTIFICATIONS, 0);
      commit(SET_UNREAD_NOTIFICATIONS, 0);
    }
  },
  async fetchNotificationPreferences({ commit }) {
    try {
      const apiUrl = api.constructUrl(
        '/company/api/notifications/preferences/',
      );

      const response = await api.get(apiUrl);

      commit(SET_NOTIFICATION_PREFERENCES, response);
    } catch (error) {
      onErrorHandler(error, 'fetch-notification-preferences');
    }
  },
  setNotificationPreferences({ commit, getters }, payload) {
    const notificationPreferences = {
      ...getters.notificationPreferences,
      ...payload,
    };

    commit(SET_NOTIFICATION_PREFERENCES, notificationPreferences);
  },
  setUnreadTotal({ commit }, payload) {
    commit(SET_UNREAD_NOTIFICATIONS, payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div class="lx-datetime-modal">
    <b-button
      :disabled="!buttonEnabled"
      :variant="buttonVariant"
      @click="togglePickerModal"
    >
      {{ buttonText }}
    </b-button>
    <px-base-modal
      id="date-time-picker-calendar"
      cancel-variant="outline-primary"
      centered
      :ok-disabled="!dateInputIsValid"
      ok-title="Update"
      size="lg"
      @ok="updateValue"
    >
      <template #modal-title>
        <span class="calendar-header">{{ calendarHeader }}</span>
      </template>
      <div class="lx-date-modal-body">
        <b-row>
          <b-col class="text-center text-base date-header" md="12">
            <span>Date</span>
          </b-col>
        </b-row>
        <hr class="lx-date-line-seperator" />
        <v-date-picker
          v-model="dateValue"
          :max-date="max"
          :min-date="min"
          mode="date"
          :value="value"
        />
      </div>

      <div class="lx-date-modal-body lx-time-modal-body">
        <b-row class="picker-header">
          <b-col class="text-center text-base date-header" md="12">
            <span>Time</span>
          </b-col>
        </b-row>
        <hr class="lx-time-line-seperator" />
        <div class="lx-time-picker-body">
          <v-date-picker
            v-model="timeValue"
            :minute-increment="15"
            mode="time"
            :value="value"
          />
        </div>
      </div>
    </px-base-modal>
  </div>
</template>

<script>
export default {
  name: 'PxDateTimePickerButton',
  props: {
    value: {
      type: String,
      default: '',
    },
    calendarHeader: {
      type: String,
      required: true,
    },
    min: {
      type: Date,
      default: () => new Date(),
    },
    max: {
      type: Date,
      default: null,
      required: false,
    },
    buttonEnabled: {
      type: Boolean,
      default: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    buttonVariant: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      dateValue: new Date(),
      timeValue: new Date(),
    };
  },
  computed: {
    dateInputIsValid() {
      return this.dateValue && this.timeValue;
    },
    dateTimeValue() {
      return new Date(
        this.dateValue.getFullYear(),
        this.dateValue.getMonth(),
        this.dateValue.getDate(),
        this.timeValue.getHours(),
        this.timeValue.getMinutes(),
        this.timeValue.getSeconds(),
        this.timeValue.getMilliseconds(),
      );
    },
  },
  methods: {
    updateValue() {
      this.$emit('value-updated', this.dateTimeValue);
      this.$bvModal.hide('date-time-picker-calendar');
    },
    togglePickerModal() {
      this.$bvModal.show('date-time-picker-calendar');
    },
  },
};
</script>

<style lang="scss">
@import '@shared/styles/variables';

.lx-date-modal-body {
  display: inline-block;
  margin-top: 20px;
}

.lx-time-modal-body .lx-time-picker-body .vc-time-icon {
  display: none; // remove the clock icon item on the side
}

.lx-date-modal-body .lx-time-picker-body .vc-date {
  display: none; // remove the date specific item on the top of the time selector
}

.lx-date-modal-body .lx-date-line-seperator {
  border: 1px solid $polly-dark-blue;
  margin-bottom: 20px;
  height: 0px;
}

.lx-date-modal-body div {
  font-family: $calendar-font-family;
  font-weight: 400;
}

.lx-date-modal-body .vc-container {
  border: none;
}

.lx-date-modal-body .lx-time-picker-body .vc-pane-container {
  text-align: center;
}

.lx-time-modal-body .lx-time-picker-body .vc-date-time {
  margin-left: 2px;
}

.lx-date-modal-body .date-header {
  font-weight: bold;
  color: $polly-dark-blue;
  margin-bottom: 10px;
}

.lx-date-modal-body .lx-time-line-seperator {
  border: 1px solid $polly-dark-blue;
  margin-bottom: 28px;
}

.lx-date-modal-body .vc-header {
  margin-bottom: 20px;
}

.lx-time-modal-body {
  height: 200px;
  margin-left: 42px;
}

.lx-time-modal-body .lx-time-picker-body .vc-time-picker {
  position: relative;
  top: 90px;
}

.lx-time-modal-body .lx-time-picker-body .vc-select {
  --rounded: 0em;
}

.lx-time-modal-body .lx-time-picker-body .vc-select select {
  background-color: transparent;
  border-bottom: 1px solid $gray-500;
  border-left: none;
  border-right: none;
  border-top: none;
}

.lx-time-modal-body .lx-time-picker-body .vc-am-pm {
  background-color: transparent;
}

.lx-time-modal-body .lx-time-picker-body .vc-am-pm button.active {
  background-color: $polly-light-blue;
}

.lx-time-modal-body .lx-time-picker-body .vc-am-pm button:focus {
  border-color: transparent;
}

.lx-time-modal-body .lx-time-picker-body .vc-am-pm button.active:focus {
  border-color: transparent;
}

.lx-time-modal-body .lx-time-picker-body .vc-select-arrow {
  color: $polly-light-blue;
}

.calendar-header {
  color: $polly-dark-blue;
}

div[id^='date-time-picker-calendar'] {
  padding-bottom: 0px;
}

#date-time-picker-calendar {
  .modal-lg {
    max-width: 580px !important;
  }

  .modal-footer {
    display: inline-block !important;
    text-align: center;
  }
}
</style>

import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export const routes = [
  {
    path: '/deliver',
    name: 'deliverHome',
    redirect: '/deliver/ready',
  },
  {
    path: '/deliver/ready',
    name: 'ready',
    meta: {
      navitem: 'deliver_ready',
    },
    component: () => import('../pages/ReadyPage.vue'),
  },
  {
    path: '/deliver/in-progress',
    name: 'in-progress',
    meta: {
      navitem: 'deliver_in_progress',
    },
    component: () => import('../pages/InProgressPage.vue'),
  },
  {
    path: '/deliver/delivered',
    name: 'delivered',
    meta: {
      navitem: 'deliver_delivered',
    },
    component: () => import('../pages/DeliveredPage.vue'),
  },
];

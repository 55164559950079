<template>
  <px-base-modal
    v-bind="$attrs"
    :cancel-variant="
      $attrs['cancel-variant'] ? $attrs['cancel-variant'] : 'outline-primary'
    "
    centered
    data-testid="confirm-modal"
    :footer-class="$attrs['footer-class'] || '' + ' m-auto'"
    ok-id="$attrs['ok-id']"
    :size="size"
    v-on="$listeners"
  >
    <slot></slot>

    <template #modal-footer="{ ok, cancel }">
      <div class="w-100 d-flex">
        <b-button
          :variant="$attrs['cancel-variant'] || 'outline-primary'"
          @click="cancel()"
        >
          {{ $attrs['cancel-title'] || 'Cancel' }}
        </b-button>

        <div v-if="okLoading">
          <px-icon
            :class="{ 'icon spinning': okLoading, 'w-100': true }"
            icon="arrows-rotate"
          />
          <span>{{ okText }}</span>
        </div>
        <b-button v-else :variant="$attrs['ok-variant']" @click="ok()">
          {{ okText }}
        </b-button>
      </div>

      <div v-if="tertiaryTitle" class="text-center w-100">
        <b-button variant="link" @click="$emit('tertiary-click')">
          {{ tertiaryTitle }}
        </b-button>
      </div>
    </template>
  </px-base-modal>
</template>

<script>
export default {
  name: 'PxConfirmModal',
  props: {
    tertiaryTitle: {
      type: String,
      required: false,
      default: '',
    },
    okLoading: {
      type: Boolean,
      required: false,
      default: null,
    },
    okLoadingTitle: {
      type: String,
      required: false,
      default: '',
    },
    size: {
      type: String,
      required: false,
      default: 'sm',
    },
  },
  computed: {
    okText() {
      if (this.okLoading) {
        return this.okLoadingTitle;
      } else {
        return this.$attrs['ok-title'];
      }
    },
  },
};
</script>

import Vue from 'vue';

export function storageDateRangeKey() {
  return `${window.location.pathname.replace(/\/$/, '')}/date-range-picker`;
}

export function get_today_datetime_range() {
  const today = new Date();
  const start_today = new Date(today);
  start_today.setHours(0, 0, 0);
  const end_today = new Date(today);
  end_today.setHours(23, 59, 59);
  return { start: start_today, end: end_today };
}

export function getDateRange(route) {
  const dateRangeKey = storageDateRangeKey();
  let dateRange = null;
  if (route.params.fromNav) {
    dateRange = get_today_datetime_range();
    Vue.$storage.set(dateRangeKey, dateRange);
    return dateRange;
  }
  const storedDateRange = Vue.$storage.get(dateRangeKey);
  if (storedDateRange) {
    const { start, end } = storedDateRange;
    if (start && end) {
      dateRange = { start: new Date(start), end: new Date(end) };
    } else {
      dateRange = { start: null, end: null };
    }
  }
  return dateRange;
}

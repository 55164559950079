import { SET_SEARCH_TEXT } from './mutationTypes.js';

export default {
  namespaced: true,
  state: {
    searchText: null,
  },
  getters: {
    searchText: state => state.searchText,
  },
  mutations: {
    [SET_SEARCH_TEXT](state, searchText) {
      state.searchText = searchText;
    },
  },
  actions: {
    setSearchText({ commit }, searchText) {
      commit(SET_SEARCH_TEXT, searchText);
    },
  },
};

import { isUserException } from '@shared/api/errors.js';

import { log } from '@shared/utils/logging.js';
import { showOutsideOfMarketHoursMessage } from '@shared/utils/notifier.js';
import { setWithTtl, getWithExpiry } from '@shared/utils/localStorage.js';
import { INTERNAL_ERROR_MSG } from '../constants';
import { SET_ORG_MISMATCH } from '@shared/store/modules/organizations';
import Vue from 'vue';
import axios from 'axios';
import store from '@src/store';

export const onErrorHandler = (
  e,
  fingerPrint,
  statuses = [403],
  suppressNotification = false,
  text = undefined,
) => {
  if (axios.isCancel(e)) return;
  if (e?.response?.status === 435) {
    // Org mismatch
    store.commit(`organizations/${SET_ORG_MISMATCH}`, true);
    return;
  }

  if (e?.response?.status === 423) {
    // outside of market hours
    return showOutsideOfMarketHoursMessage(e);
  }
  if (statuses.includes(404)) {
    redirectTo404IfNeed(e);
  }

  if (
    (!e?.response || !statuses.includes(e.response.status)) &&
    !isUserException(e)
  ) {
    if (!suppressNotification) {
      Vue.notify({
        title: 'Error',
        text: text ? text : INTERNAL_ERROR_MSG,
        type: 'error',
      });
    }
    log(e, 'error', ['', fingerPrint]);
  }
};

export const redirectTo404 = () => {
  window.location.href = `${window.location.origin}/404`;
};

export const redirectTo404IfNeed = error => {
  if (error?.response?.status === 404) {
    redirectTo404();
    return true;
  } else {
    return false;
  }
};

export const AUTO_REFRESH_KEY = 'hasAutoRefreshed';
export const AUTO_REFRESH_TIMEOUT = 10000;

export const globalVueErrorHandler = err => {
  if (isModuleLoadingError(err) && hasNotRefreshed()) {
    // If the error happens again after refreshing, we want to throw the error
    // instead of continuing to refresh.
    setWithTtl(AUTO_REFRESH_KEY, true, AUTO_REFRESH_TIMEOUT);
    window.location.reload();
  } else {
    log(err, 'error', ['unhandled-error']);
  }
};

const isModuleLoadingError = err =>
  err.name === 'TypeError' &&
  err.message === "Cannot read property 'call' of undefined";

const hasNotRefreshed = () => !getWithExpiry(AUTO_REFRESH_KEY);

import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';

import { globalVueErrorHandler } from '@shared/utils/errorHandlers.js';

export const LOG_LEVEL = {
  FATAL: 'fatal',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  DEBUG: 'debug',
};

export const filterAxios = function (event, hint) {
  if (hint?.originalException?.isAxiosError) {
    if (hint.originalException.response?.data) {
      const contexts = {
        ...event.contexts,
      };
      contexts.errorResponse = {
        data: hint.originalException.response.data,
      };
      event.contexts = contexts;
    }
  }

  return event;
};

/** Initializes Sentry with VueJs integration
 * @param  {string} originTag additional tag for logging
 * @param  {Object} vue instance
 */
export function logInit(originTag, vue) {
  if (
    Sentry &&
    window.SENTRY_DSN &&
    window.RELEASE_VERSION &&
    !Sentry.getCurrentHub().getClient()
  ) {
    vue.config.errorHandler = globalVueErrorHandler;
    Sentry.init({
      dsn: window.SENTRY_DSN,
      ignoreErrors: [/^Request aborted$/, /^Request prevented$/],
      integrations: [
        new Integrations.BrowserTracing(),
        new VueIntegration({ Vue, attachProps: true }),
      ],
      release: window.RELEASE_VERSION,
      tracesSampleRate: 0.25,
      beforeSend: filterAxios,
    });
    Sentry.configureScope(scope => {
      scope.setTag('origin', originTag);
    });
  }
}

/** Logs an error with specified fingerprint and level
 * @param  {Error} error Error object
 * @param  {string} level one of: fatal, error, warning, info, and debug
 * details: https://docs.sentry.io/platforms/javascript/?platform=vue#level
 * @param  {string[]} fingerPrint,
 * fingerPrint examples: ['', 'api'], ['data-getters'],
 * details: https://docs.sentry.io/platforms/javascript/?platform=vue#setting-the-fingerprint
 */
export function log(error, level = LOG_LEVEL.ERROR, fingerPrint) {
  if (Sentry) {
    Sentry.withScope(scope => {
      if (fingerPrint && fingerPrint.length) {
        scope.setFingerprint(fingerPrint);
      }
      scope.setLevel(level);
      if (error && error.response && error.response.data) {
        scope.setExtra(
          'response.data',
          JSON.stringify(error.response.data).substr(0, 1000),
        );
      }
      if (error && error.response && error.response.headers) {
        scope.setExtra(
          'response.headers',
          JSON.stringify(error.response.headers).substr(0, 1000),
        );
      }
      Sentry.captureException(error);
    });
  }
  if (level == LOG_LEVEL.ERROR || level == LOG_LEVEL.FATAL) {
    console.error(error);
  } else if (level == LOG_LEVEL.WARNING) {
    console.warn(error);
  } else if (level == LOG_LEVEL.INFO) {
    console.info(error);
  } else if (level == LOG_LEVEL.DEBUG) {
    console.debug(error);
  }
}

/** Logs an error with specified fingerprint and level
 * @param  {Error} error Error object
 * @param  {string[]} fingerPrint,
 * fingerPrint examples: ['', 'api'], ['data-getters'],
 * details: https://docs.sentry.io/platforms/javascript/?platform=vue#setting-the-fingerprint
 */
export function logFatal(error, fingerPrint) {
  log(error, LOG_LEVEL.FATAL, fingerPrint);
}

/** Logs an error with specified fingerprint and level
 * @param  {Error} error Error object
 * @param  {string[]} fingerPrint,
 * fingerPrint examples: ['', 'api'], ['data-getters'],
 * details: https://docs.sentry.io/platforms/javascript/?platform=vue#setting-the-fingerprint
 */
export function logError(error, fingerPrint) {
  log(error, LOG_LEVEL.ERROR, fingerPrint);
}

/** Logs a warning with specified fingerprint and level
 * @param  {Error} error Error object
 * @param  {string[]} fingerPrint,
 * fingerPrint examples: ['', 'api'], ['data-getters'],
 * details: https://docs.sentry.io/platforms/javascript/?platform=vue#setting-the-fingerprint
 */
export function logWarning(error, fingerPrint) {
  log(error, LOG_LEVEL.WARNING, fingerPrint);
}

/** Logs an info with specified fingerprint and level
 * @param  {Error} error Error object
 * @param  {string[]} fingerPrint,
 * fingerPrint examples: ['', 'api'], ['data-getters'],
 * details: https://docs.sentry.io/platforms/javascript/?platform=vue#setting-the-fingerprint
 */
export function logInfo(error, fingerPrint) {
  log(error, LOG_LEVEL.INFO, fingerPrint);
}

/** Logs a debug with specified fingerprint and level
 * @param  {Error} error Error object
 * @param  {string[]} fingerPrint
 * fingerPrint examples: ['', 'api'], ['data-getters'],
 * details: https://docs.sentry.io/platforms/javascript/?platform=vue#setting-the-fingerprint
 */
export function logDebug(error, fingerPrint) {
  log(error, LOG_LEVEL.DEBUG, fingerPrint);
}

export default {
  fatal: logFatal,
  error: logError,
  warning: logWarning,
  warn: logWarning,
  info: logInfo,
  debug: logDebug,
};

import api from '@shared/services/api.js';
import { loanStatus } from '@shared/constants';

export const getAllPoolLoans = async (organizationId, poolId) => {
  const url = api.constructUrl(
    `/api/v1/organizations/${organizationId}/commitment_pools/${poolId}/loans/`,
    {
      status__in: `${loanStatus.selected},${loanStatus.sold},${loanStatus.confirmed}`,
    },
  );
  return api.get(url);
};

export const getEligiblePoolLoans = async (organizationId, poolId) => {
  return api.get(
    `/api/v1/organizations/${organizationId}/commitment_pools/${poolId}/eligible_loans/`,
  );
};

export const commitPools = async pools => {
  return api.post('/sell/api/commit_pools', {
    pools: pools,
  });
};

import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export const routes = [
  {
    path: '/tools',
    name: 'toolsHome',
    redirect: '/tools/lock_updater',
  },
  {
    path: '/tools/lock_updater',
    name: 'lock_updater',
    component: () => import('../components/LockUpdater/LockUpdater.vue'),
  },
];

import axios from 'axios';

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

/**
 * When `raiseWarnings` is passed on the config to our API service, the `UserException` error will be raised. It will
 * contain all the messages returned by the backend on the `messages` property.
 *
 * Example api call that will raise a `UserException` if warnings are returned by the backend:
 *
 * import api from '@shared/services/api.js';
 * api.post('/some-url', payload, {
 *   raiseWarnings: true,
 * });
 */

const post = async (
  relativeUrl,
  request,
  options,
  contentType = 'application/json-patch+json',
) => {
  const headers = {
    'Content-Type': contentType,
  };
  options = options || {};
  options.headers = headers;
  const response = await axios.post(relativeUrl, request, options);
  return response && response.data;
};

const put = async (
  relativeUrl,
  request,
  options,
  contentType = 'application/json-patch+json',
) => {
  const headers = {
    'Content-Type': contentType,
  };
  options = options || {};
  options.headers = headers;
  const response = await axios.put(relativeUrl, request, options);
  return response && response.data;
};

const patch = async (
  relativeUrl,
  request,
  options,
  contentType = 'application/json-patch+json',
) => {
  const headers = {
    'Content-Type': contentType,
  };
  options = options || {};
  options.headers = headers;
  const response = await axios.patch(relativeUrl, request, options);
  return response && response.data;
};

/**
 * Returns a filtered params object which does not include fields with
 * null values because null values are improperly included in the query
 * string with a string value of "null". The only way to show that a
 * field has no value via the query string is to not include the field.
 */
const filterQueryParams = params => {
  if (!params) return params;
  if (typeof params === 'string') {
    // TODO - we should log this so we can fix cases where calling code is
    //  passing query string params as strings. Not a missive issue as URLSearchParams
    //  parses the string and "fixes" it, but it can only do so much, there is no way
    //  for URLSearchParams to determine that the string "true&orgId=5" is a value.
    return params;
  }

  return Object.keys(params)
    .filter(key => params[key] !== null)
    .reduce((acc, key) => {
      acc[key] = params[key];
      return acc;
    }, {});
};

export default {
  constructUrl(url, params) {
    return `${url}?${new URLSearchParams(
      filterQueryParams(params),
    ).toString()}`;
  },
  getCancelToken() {
    return axios.CancelToken;
  },
  async get(relativeUrl, options) {
    options = options || {};
    const response = await axios.get(relativeUrl, options);
    return response && response.data;
  },
  async getBlob(relativeUrl) {
    const headers = {
      'Access-Control-Expose-Headers': 'Content-Disposition',
    };
    const response = await axios.get(relativeUrl, {
      responseType: 'blob',
      headers: headers,
    });
    return response;
  },
  post,
  async postJson(relativeUrl, request, options = {}) {
    return post(relativeUrl, request, options, 'application/json');
  },
  put,
  async putJson(relativeUrl, request, options = {}) {
    return put(relativeUrl, request, options, 'application/json');
  },
  async postFormData(relativeUrl, formData, options) {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    options = options || {};
    options.headers = headers;
    return await axios.post(relativeUrl, formData, options);
  },
  patch,
  async patchJson(relativeUrl, request, options = {}) {
    return patch(relativeUrl, request, options, 'application/json');
  },
  async delete(relativeUrl, payload) {
    const headers = {};
    const response = await axios.delete(relativeUrl, {
      headers,
      data: payload,
    });
    return response && response.data;
  },
  async head(relativeUrl, payload) {
    const headers = {};
    const response = await axios.head(relativeUrl, {
      headers,
      data: payload,
    });
    return response;
  },
};

export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    contentCols: {
      default: 7,
      type: Number,
    },
    labelCols: {
      default: 5,
      type: [Number, String],
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    invalidMessage: {
      type: String,
      default: 'Required',
    },
    dense: {
      type: Boolean,
      default: false,
    },
    precision: {
      type: [String, Number],
      default: 2,
    },
    decimal: {
      type: Boolean,
      default: false,
    },
    negatives: {
      type: Boolean,
      default: false,
    },
    hideInput: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
};

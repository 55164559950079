<template>
  <i class="fas fa-thumbs-down thumb-icon"></i>
</template>

<script>
export default {
  name: 'ThumbDown',
};
</script>

<style lang="scss">
@import './ChatWidget.scss';
</style>

import { SET_IS_UPDATE_NEEDED, SET_PREMIUM } from './mutationTypes.js';

const initialState = function () {
  return {
    isUpdateNeeded: false,
    premium: null,
  };
};

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    [SET_IS_UPDATE_NEEDED](state, isUpdateNeeded) {
      state.isUpdateNeeded = isUpdateNeeded;
    },
    [SET_PREMIUM](state, premium) {
      state.premium = premium;
    },
  },
  actions: {
    setIsUpdateNeeded({ commit }, isUpdateNeeded) {
      commit(SET_IS_UPDATE_NEEDED, isUpdateNeeded);
    },
    setPremium({ commit }, premium) {
      commit(SET_PREMIUM, premium);
    },
  },
};

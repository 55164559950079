import api from '@shared/services/api.js';

export const retryLosImportFailures = async (organizationId, data) => {
  return api.postJson(
    `/api/v1/organizations/${organizationId}/los_import_failures/retry/`,
    data,
  );
};

export const getImportFailures = async (organizationId, queryParams) => {
  return api.get(
    `/api/v1/organizations/${organizationId}/los_import_failures/?${queryParams}`,
  );
};

export const downloadLosImportFailures = async (
  organizationId,
  queryParams,
) => {
  return api.get(
    `/api/v1/organizations/${organizationId}/los_import_failures/csv/?${queryParams}`,
  );
};

export const getImportFailuresSummary = async (organizationId, queryParams) => {
  return api.get(
    `/api/v1/organizations/${organizationId}/los_import_failures/summary/?${queryParams}`,
  );
};

import api from '@shared/services/api.js';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import clone from 'lodash/cloneDeep';
import {
  showErrorMessage,
  showSuccessMessage,
} from '@shared/utils/notifier.js';

export const mutationTypes = {
  SET_BIDDING_RULES: 'setBiddingRules',
  SAVE_BIDDING_RULE: 'saveBiddingRule',
  REMOVE_BIDDING_RULE: 'removeBiddingRule',
};

const coissuerTickerSymbolFieldName =
  'servicing_provider_org.organizationinfo.ticker_symbol';

// RT doesn't have a ticket symbol. Transform value of RT to null when targeting
// "servicing_provider_org.organizationinfo.ticker_symbol" before sending to backend
function handleRuleCoIssuerRTValueFromUserInput(biddingRules) {
  biddingRules.forEach(rule => {
    rule.filters.forEach(filter => {
      if (filter.field === coissuerTickerSymbolFieldName) {
        if (Array.isArray(filter.value)) {
          filter.value.forEach((value, index) => {
            if (value === 'RT') {
              filter.value[index] = null;
            }
          });
        } else if (filter.value === 'RT') {
          filter.value = null;
        }
      }
    });
  });
  return biddingRules;
}

// Convert "null" value for field servicing_provider_org.organizationinfo.ticker_symbol
// to display "RT".
// This is due to there is no ticker available for RT value therefore we can only filter
// this by filtering a None value.
function handleRuleCoIssuerRTValueForDisplaying(rules) {
  rules.forEach(rule => {
    rule.filters.forEach(filter => {
      if (filter.field === coissuerTickerSymbolFieldName) {
        if (Array.isArray(filter.value)) {
          filter.value.forEach((value, index) => {
            if (value === null) {
              filter.value[index] = 'RT';
            }
          });
        } else if (filter.value === null) {
          filter.value = 'RT';
        }
      }
    });
  });
  return rules;
}

async function patchEligibilityRules(biddingRules, partnershipId) {
  const processedBiddingRules = handleRuleCoIssuerRTValueFromUserInput(
    clone(biddingRules),
  );
  return await api.patch(
    `/company/api/partnership/${partnershipId}/eligibility_rules`,
    {
      rules: processedBiddingRules,
    },
  );
}
export default {
  namespaced: true,
  state: {
    biddingRules: [],
  },
  getters: {
    biddingRules: state => state.biddingRules || [],
  },
  mutations: {
    [mutationTypes.SET_BIDDING_RULES](state, payload) {
      state.biddingRules = handleRuleCoIssuerRTValueForDisplaying(
        payload || [],
      );
    },
    [mutationTypes.SAVE_BIDDING_RULE](state, payload) {
      const existingRule = state.biddingRules.find(
        rule => rule.id === payload.id,
      );
      if (!existingRule) {
        state.biddingRules.push(payload);
      } else {
        existingRule.name = payload.name;
        existingRule.type = payload.type;
        existingRule.filters = payload.filters;
      }
    },
    [mutationTypes.REMOVE_BIDDING_RULE](state, payload) {
      state.biddingRules = state.biddingRules.filter(
        rule => payload.id !== rule.id,
      );
    },
  },
  actions: {
    async updateBiddingRule({ dispatch, state }, partnershipId) {
      const { task_id } = await patchEligibilityRules(
        state.biddingRules,
        partnershipId,
      );
      if (task_id) {
        dispatch('partnerships/getPartnershipByPartnershipId', partnershipId, {
          root: true,
        });
        showSuccessMessage('Partnership Eligibility Rules are updating...');

        const task = await dispatch(
          'asyncTasks/getTaskResult',
          { taskId: task_id },
          { root: true },
        );
        if (task.task_ready) {
          if (task.task_successful) {
            showSuccessMessage(
              'Partnership Eligibility Rules has successfully updated',
            );
          } else {
            showErrorMessage(
              'Partnership Eligibility Rules has failed to updated',
            );
          }
        }
      }
    },
    async saveBiddingRule(
      { commit, dispatch },
      { partnershipId, currentRule },
    ) {
      try {
        commit(mutationTypes.SAVE_BIDDING_RULE, currentRule);
        await dispatch('updateBiddingRule', partnershipId);
      } catch (error) {
        onErrorHandler(error, 'useradmin-save-partner-eligibility-rule');
      }
    },
    async removeBiddingRule(
      { commit, dispatch },
      { partnershipId, currentRule },
    ) {
      try {
        commit(mutationTypes.REMOVE_BIDDING_RULE, currentRule);
        await dispatch('updateBiddingRule', partnershipId);
      } catch (error) {
        onErrorHandler(error, 'useradmin-remove-eligibility-rule');
      }
    },
  },
};

import api from '@shared/services/api.js';
const baseApi = '/company/api';
const baseUserUrl = `${baseApi}/users`;

export const getMember = id => {
  return api.get(`/company/api/users/${id}/`);
};

export const getMembers = () => {
  return api.get(`${baseUserUrl}/`);
};

export const removeMember = id => {
  return api.delete(`${baseUserUrl}/${id}/`);
};

export const resetPassword = id => {
  return api.post(`${baseUserUrl}/${id}/reset_password/`);
};

export const updateUserRoles = async (userId, roles) => {
  return api.patch(`${baseUserUrl}/${userId}/`, { roles });
};

import {
  getInvestorRatesUsed,
  getConfiguration,
} from '@pe/services/configurations.js';
import { getRates } from '@pe/services/rates.js';
import { ConfigurationStatuses } from '@shared/constants';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import {
  SET_BASE_CONFIGURATION,
  SET_CONFIGURATION,
  SET_CONFIGURATION_NEIGHBORS,
  SET_INVESTOR_RATES_USED,
  SET_INVESTOR_UPDATES,
  SET_IS_LOADING,
  SET_RATES,
} from './mutationTypes.js';

export default {
  namespaced: true,
  state: {
    configuration: { id: '', name: '', status: '' },
    configurationNeighbors: null,
    baseConfiguration: null,
    rates: null,
    investorRatesUsed: { isLoading: true, data: null },
    isLoading: true,
    investorUpdates: {},
  },
  getters: {
    baseConfiguration: state => state.baseConfiguration,
    configuration: state => state.configuration,
    configurationName: state => state.configuration?.name,
    configurationNeighbors: state => state.configurationNeighbors,
    investorRatesUsed: state => state.investorRatesUsed,
    isConfigurationReadOnly: state =>
      state.configuration?.status !== ConfigurationStatuses.DRAFT,
    isLoading: state => state.isLoading,
    rates: state => state.rates,
    investorUpdates: state => state.investorUpdates,
  },
  mutations: {
    [SET_CONFIGURATION](state, configuration) {
      state.configuration = configuration;
    },
    [SET_CONFIGURATION_NEIGHBORS](state, configurationNeighbors) {
      state.configurationNeighbors = configurationNeighbors;
    },
    [SET_BASE_CONFIGURATION](state, baseConfiguration) {
      state.baseConfiguration = baseConfiguration;
    },
    [SET_RATES](state, rates) {
      state.rates = rates;
    },
    [SET_INVESTOR_RATES_USED](state, investorRatesUsed) {
      state.investorRatesUsed = investorRatesUsed;
    },
    [SET_IS_LOADING](state, isLoading) {
      state.isLoading = isLoading;
    },
    [SET_INVESTOR_UPDATES](state, investorUpdates) {
      state.investorUpdates = investorUpdates;
    },
  },
  actions: {
    clearConfiguration({ commit }) {
      commit(SET_CONFIGURATION, { id: '', name: '', status: '' });
    },
    async getConfiguration(
      { commit, state },
      { id, force = false, includeBase = true, params = {} },
    ) {
      commit(SET_IS_LOADING, true);

      try {
        if (state.configuration?.id === id && !force) {
          return;
        }
        // just move store to initial state
        commit(SET_CONFIGURATION, null);
        commit(SET_BASE_CONFIGURATION, null);
        let configuration = null;
        try {
          configuration = await getConfiguration(id, params);
        } catch (e) {
          onErrorHandler(e, 'get-configuration-by-id', [403, 404]);
        }
        commit(SET_CONFIGURATION, configuration);

        if (includeBase && configuration?.baseChangesetId) {
          const baseConfiguration = await getConfiguration(
            configuration.baseChangesetId,
            params,
          );

          commit(SET_BASE_CONFIGURATION, baseConfiguration);
        }
      } catch (error) {
        onErrorHandler(error, 'pe-config-details', [403, 404]);
      } finally {
        commit(SET_IS_LOADING, false);
      }
    },
    async getCurrentRates({ commit }, params) {
      try {
        commit(SET_RATES, null);
        const tableOptions = params.tableOptions;
        const id = params.id;
        const rates = await getRates(tableOptions, id);
        commit(SET_RATES, rates);
      } catch (error) {
        onErrorHandler(error, 'pe-config-details');
      }
    },
    async getInvestorRatesUsed({ commit }, configurationId) {
      try {
        commit(SET_INVESTOR_RATES_USED, { isLoading: true, data: null });
        const investorRatesUsed = await getInvestorRatesUsed(configurationId);
        commit(SET_INVESTOR_RATES_USED, {
          isLoading: false,
          data: investorRatesUsed,
        });
      } catch (error) {
        onErrorHandler(error, 'pe-config-details');
        commit(SET_INVESTOR_RATES_USED, { isLoading: false, data: null });
      }
    },
    setConfigurationNeighbors({ commit }, configurationNeighbors) {
      commit(SET_CONFIGURATION_NEIGHBORS, configurationNeighbors);
    },
    setIsLoading({ commit }, isLoading) {
      commit(SET_IS_LOADING, isLoading);
    },
    setInvestorUpdates({ commit }, investorUpdates) {
      commit(SET_INVESTOR_UPDATES, investorUpdates);
    },
  },
};

export const CLEAR_RULE_GROUP_DETAILS = 'CLEAR_RULE_GROUP_DETAILS';
export const SET_IS_INVALID = 'SET_IS_INVALID';
export const SET_IS_INVALID_NAME = 'SET_IS_INVALID_NAME';
export const SET_IS_READ_ONLY_ITEM = 'SET_IS_READ_ONLY_ITEM';
export const SET_IS_UPDATE_NEEDED = 'SET_IS_UPDATE_NEEDED';
export const SET_RULE_GROUP = 'SET_RULE_GROUP';

export default {
  CLEAR_RULE_GROUP_DETAILS,
  SET_IS_INVALID,
  SET_IS_INVALID_NAME,
  SET_IS_READ_ONLY_ITEM,
  SET_IS_UPDATE_NEEDED,
  SET_RULE_GROUP,
};

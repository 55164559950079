<template>
  <b-input-group
    class="px-card-table-filter"
    :class="{ active: filterInputVisible || value }"
  >
    <b-input-group-prepend>
      <b-button
        class="px-card-table-filter__btn btn-short"
        tabindex="-1"
        variant="link"
        @click="handleFilterToggle"
      >
        <px-icon icon="magnifying-glass"></px-icon>
      </b-button>
    </b-input-group-prepend>

    <b-form-input
      ref="pxCardFilterTable__filter"
      class="px-card-table-filter__element"
      placeholder="Search"
      :value="value"
      @blur="handleFilterHide"
      @input="input($event)"
    ></b-form-input>
  </b-input-group>
</template>

<script>
export default {
  name: 'PxCardTableFilter',
  props: {
    value: {
      type: [String, Object, Number, Boolean, Array],
      default: null,
    },
  },
  data() {
    return {
      filterInputVisible: false,
    };
  },
  watch: {
    filterInputVisible(next) {
      if (next) {
        this.$nextTick(() => {
          this.$refs.pxCardFilterTable__filter.focus();
          this.$refs.pxCardFilterTable__filter.select();
        });
      }
    },
  },
  methods: {
    handleFilterHide() {
      if (!this.value) this.filterInputVisible = false;
    },
    handleFilterToggle() {
      this.filterInputVisible = !this.filterInputVisible;
    },
    input(event) {
      this.$emit('input', event);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/variables';

.px-card-table-filter {
  border-bottom: solid 2px transparent;
  display: flex;
  height: 2rem;
  margin-left: auto;
  padding: 0;
  gap: 8px;
  transition: width 0.5s ease-in-out;
  width: 20px;
  flex-wrap: nowrap;

  .input-group-prepend {
    margin-right: 0;
  }

  &__btn {
    background-color: transparent;
    border-bottom: solid 2px transparent;
    height: 2rem;
    padding: 0;
  }

  &__element {
    border: 0;
    font-size: 1rem;
    height: 100%;
    margin: 0;
    padding: 0;

    &:active,
    &:focus {
      outline-width: 0;
    }
  }

  &:focus-within,
  &:focus-visible,
  &.active,
  &.filter-open {
    border-bottom: solid 2px $polly-light-blue;
    width: 200px;
  }

  &.active &__element {
    background-color: transparent;
  }
}
</style>

export const SET_LOAN_TO_UNSELL = 'SET_LOAN_TO_UNSELL';
export const SET_IS_LOAN_UNSELLING = 'SET_IS_LOAN_UNSELLING';
export const SET_POOL_ID = 'SET_POOL_ID';
export const SET_PREV_ROUTE = 'SET_PREV_ROUTE';
export const SET_POOL = 'SET_POOL';

export const SET_LOAN_FIELDS = 'SET_LOAN_FIELDS';
export const SET_POOL_LOANS = 'SET_POOL_LOANS';
export const SET_IS_POOL_LOANS_LOADING = 'SET_IS_POOL_LOANS_LOADING';
export const SET_IS_CUSTOM_POOL = 'SET_IS_CUSTOM_POOL';
export const SET_IS_EDITING = 'SET_IS_EDITING';
export const INITIALIZE_UPDATED_POOL = 'INITIALIZE_UPDATED_POOL';
export const SET_IS_COMMIT_POOL_LOADING = 'SET_IS_COMMIT_POOL_LOADING';
export const SET_IS_CREATING_POOL = 'SET_IS_CREATING_POOL';

export const SET_POOL_BUYER_ORG_NAME = 'SET_POOL_BUYER_ORG_NAME';

export const SET_POOL_DRAFT = 'SET_POOL_DRAFT';
export const SET_POOL_DRAFT_AMOUNT = 'SET_POOL_DRAFT_AMOUNT';
export const SET_POOL_DRAFT_PRODUCT = 'SET_POOL_DRAFT_PRODUCT';
export const SET_POOL_DRAFT_COUPON_RATE = 'SET_POOL_DRAFT_COUPON_RATE';
export const SET_POOL_DRAFT_SETTLEMENT_MONTH =
  'SET_POOL_DRAFT_SETTLEMENT_MONTH';
export const SET_POOL_DRAFT_SERVICING_TYPE = 'SET_POOL_DRAFT_SERVICING_TYPE';
export const SET_POOL_DRAFT_COISSUER = 'SET_POOL_DRAFT_COISSUER';
export const SET_POOL_DRAFT_PAYUP = 'SET_POOL_DRAFT_PAYUP';
export const SET_POOL_DRAFT_COMMITMENT_TYPE = 'SET_POOL_DRAFT_COMMITMENT_TYPE';
export const SET_POOL_DRAFT_DECISION_AT = 'SET_POOL_DRAFT_DECISION_AT';
export const SET_POOL_DRAFT_CONTRACT_ID = 'SET_POOL_DRAFT_CONTRACT_ID';
export const SET_POOL_DRAFT_BUYER_ORG_ID = 'SET_POOL_DRAFT_BUYER_ORG_ID';
export const SET_POOL_DRAFT_AMORTIZATION = 'SET_POOL_DRAFT_AMORTIZATION';
export const SET_POOL_DRAFT_CONTRACT_DAYS = 'SET_POOL_DRAFT_CONTRACT_DAYS';
export const SET_POOL_DRAFT_EXTERNAL_POOL_ID =
  'SET_POOL_DRAFT_EXTERNAL_POOL_ID';

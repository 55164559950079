<template>
  <b-form-group>
    <b-form-checkbox
      v-if="group.label && !group.hideSelectAll"
      :checked="allSelected"
      :indeterminate="indeterminated"
      @change="onAllSelectedChanged"
    >
      {{ group.label }}
    </b-form-checkbox>
    <div v-if="group.label && group.hideSelectAll">{{ group.label }}</div>
    <div :class="group.withoutIndent ? '' : 'ml-4'">
      <b-form-checkbox-group
        :checked="selectedOptions"
        :options="group.options"
        stacked
        @change="updateSelected"
      />
      <px-tree-view-group
        v-for="subGroup in subGroups"
        :key="subGroup.name"
        :group="subGroup"
        @change="updateSubgroups"
      />
    </div>
  </b-form-group>
</template>

<script>
export function isGroupSelected(group) {
  return (
    group.selected &&
    group.selected.length === group.options.length &&
    (!group.subGroups ||
      (group.subGroups.length && group.subGroups.every(isGroupSelected)))
  );
}
export function isAnySelected(group) {
  return (
    (group.selected && group.selected.length > 0) ||
    (group.subGroups &&
      group.subGroups.length &&
      group.subGroups.some(isAnySelected))
  );
}
export function setAll(value) {
  return function (group) {
    this.$set(group, 'selected', value ? group.options.map(o => o.value) : []);
    group.subGroups &&
      group.subGroups.length &&
      group.subGroups.forEach(setAll(value).bind(this));
  };
}

export default {
  name: 'PxTreeViewNode',
  components: {
    'px-tree-view-group': () => import('./PxTreeViewNode.vue'),
  },
  props: { group: { type: Object, required: true } },
  computed: {
    selectedOptions() {
      return this.group.selected || [];
    },
    subGroups() {
      return this.group.subGroups ? this.group.subGroups : [];
    },
    allSelected() {
      return isGroupSelected(this.group);
    },
    indeterminated() {
      return !this.allSelected && isAnySelected(this.group);
    },
  },
  methods: {
    onAllSelectedChanged(value) {
      setAll(value).call(this, this.group);
      this.$emit('change', this.group);
    },
    updateSubgroups(subgroup) {
      // eslint-disable-next-line vue/no-mutating-props
      this.group.subGroups.splice(
        this.group.subGroups.findIndex(g => g.name === subgroup.name),
        1,
        subgroup,
      );
      this.$emit('change', this.group);
    },
    updateSelected(selectedOptions) {
      this.$set(this.group, 'selected', selectedOptions);
      this.$emit('change', this.group);
    },
  },
};
</script>

<template>
  <div class="multi-form-step">
    <div ref="stepsContainer" class="step-wrapper">
      <transition-group
        :name="transitionDirection + '-slide'"
        @after-enter="afterEnter"
        @enter="enter"
        @leave="leave"
      >
        <div
          v-for="(step, index) in steps"
          v-show="index === activeStep"
          :key="'step_' + step + index"
        >
          <slot :name="'step' + (index + 1)"></slot>
        </div>
      </transition-group>
    </div>
    <div class="dots-wrapper" :class="{ visible: steps > 1 }">
      <px-icon
        class="text-primary"
        icon="chevron-left"
        role="button"
        @click.native="goToPrevStep"
      />
      <button
        v-for="(val, index) in steps"
        :key="index"
        class="dot"
        :class="{ 'active-dot': index === activeStep }"
        :data-testid="`dot-${index}`"
        @click="goToStep(index)"
      ></button>
      <px-icon
        class="text-primary"
        icon="chevron-right"
        role="button"
        @click.native="goToNextStep"
      />
    </div>
    <div v-if="!hideButtons" class="controls" @mouseover="$emit('validate')">
      <b-button
        id="cancel"
        data-testid="cancel-button"
        variant="outline-primary"
        @click="handleCancel"
      >
        {{ secondaryText }}
      </b-button>
      <b-button
        id="submit"
        :data-testid="`${okText}-button`"
        :disabled="isDisabled"
        variant="primary"
        @click="handleSubmit"
      >
        {{ okText }}
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PxMultiStepForm',
  props: {
    steps: {
      type: Number,
      default: 1,
    },
    submitText: {
      type: String,
      default: 'Submit',
    },
    cancelText: {
      type: String,
      default: 'Cancel',
    },
    submitDisabled: {
      type: Boolean,
      default: false,
    },
    hideButtons: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeStep: 0,
      transitionDirection: 'right',
      prevTransitionHeight: 0,
      nextTransitionHeight: 0,
    };
  },
  computed: {
    okText() {
      return this.steps >= 1 && !this.isLastStep ? 'Next' : this.submitText;
    },
    secondaryText() {
      return this.activeStep >= 1 ? 'Back' : this.cancelText;
    },
    isDisabled() {
      return this.submitDisabled;
    },
    isLastStep() {
      return this.activeStep + 1 === this.steps;
    },
  },
  methods: {
    handleSubmit() {
      if (this.isLastStep) {
        this.$emit('submit');
      } else {
        this.goToNextStep();
      }
    },
    reset() {
      this.activeStep = 0;
    },
    handleCancel() {
      this.activeStep >= 1 ? this.goToPrevStep() : this.$emit('cancel');
    },
    goToNextStep() {
      if (this.activeStep < this.steps - 1) {
        this.transitionDirection = 'right';
        this.activeStep++;
      }
      this.$emit('active-step', this.activeStep);
    },
    goToPrevStep() {
      if (this.activeStep >= 1) {
        this.transitionDirection = 'left';
        this.activeStep--;
      }
      this.$emit('active-step', this.activeStep);
    },
    goToStep(step) {
      this.transitionDirection = step > this.activeStep ? 'right' : 'left';
      this.activeStep = step;
      this.$emit('active-step', this.activeStep);
    },
    async enter(element) {
      // I found these transition hooks online, definitely did not come up with this on my own.
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = 'absolute';
      element.style.visibility = 'hidden';
      element.style.height = 'auto';

      const height = getComputedStyle(element).height;
      this.nextTransitionHeight = height;
      await this.$nextTick();
      if (this.nextTransitionHeight < this.prevTransitionHeight) {
        this.$refs.stepsContainer.classList.add('delay-transition');
      } else {
        this.$refs.stepsContainer.classList.remove('delay-transition');
      }

      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        this.$refs.stepsContainer.style.height = height;
      });
    },
    afterEnter(element) {
      if (!this.$refs.stepsContainer) return;
      this.$refs.stepsContainer.style.height = 'auto';
      element.style.position = null;
      element.style.visibility = null;
      element.style.width = 'auto';
    },
    leave(element) {
      const height = getComputedStyle(element).height;
      this.prevTransitionHeight = height;
      const width = getComputedStyle(element).width;

      element.style.width = width;
      this.$refs.stepsContainer.style.height = height;

      element.style.position = 'absolute';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/variables';
.multi-form-step {
  transition: height 250ms ease-in-out;
  overflow: hidden;
  will-change: height;
  transform: translateZ(0);
  .controls {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    padding: 1.5rem;
    padding-bottom: 0;
  }
}
.dots-wrapper {
  display: flex;
  gap: 1.5rem;
  width: 100%;
  justify-content: center;
  height: 16px;
  opacity: 0;

  &.visible {
    opacity: 1;
    transition: opacity 250ms ease-in-out;
  }
  .dot {
    appearance: none;
    border: none;
    padding: 0;
    background-color: white;
    border: 1px solid $border-grey;
    display: block;
    border-radius: 9999px;
    height: 16px;
    width: 16px;
    transition:
      border 200ms ease-in-out,
      background-color 200ms ease-in-out;

    &:hover {
      border-color: $polly-light-blue;
    }
    &.active-dot {
      background-color: $polly-light-blue;
      border-color: $polly-light-blue;
    }

    &:focus-visible {
      outline: 2px solid $polly-light-blue;
      outline-offset: 2px;
    }
  }
}

.step-wrapper {
  transition: all 250ms ease-in-out;
  &.delay-transition {
    transition: all 250ms ease-in-out;
    transition-delay: 250ms;
  }
}

.right-slide-leave-active,
.right-slide-enter-active,
.left-slide-leave-active,
.left-slide-enter-active {
  transition: all 500ms ease-in-out;
  overflow: hidden;
}

.right-slide-leave-to,
.left-slide-enter {
  opacity: 0.1;
  height: auto;
  transform: translateX(-120%);
}

.right-slide-enter,
.left-slide-leave-to {
  opacity: 0.1;
  height: auto;
  transform: translateX(120%);
}
</style>

import marketSvc from '@shared/services/market.js';

const state = {
  benchmarks: [],
  favoriteBenchmarks: [],
};

const getters = {
  benchmarks: state => {
    return state.benchmarks;
  },
  favoriteBenchmarks: state => {
    return state.benchmarks.filter(b =>
      state.favoriteBenchmarks.find(
        fb =>
          fb.name === b.name &&
          +fb.rate === +b.rate &&
          fb.period_index === b.period_index,
      ),
    );
  },
};

const mutations = {
  setBenchmarks: (state, payload) => {
    state.benchmarks = payload;
  },
  setFavoriteBenchmarks: (state, payload) => {
    state.favoriteBenchmarks = payload;
  },
};

const actions = {
  getBenchmarks: async context => {
    const date = await marketSvc.getBenchmarkBids();
    if (date && date.benchmarksBids)
      context.commit('setBenchmarks', date.benchmarksBids);
    if (date && date.favoriteBenchmarks)
      context.commit('setFavoriteBenchmarks', date.favoriteBenchmarks);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

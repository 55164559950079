import api from '@shared/services/api.js';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';

export default {
  getPayups(partnershipId, organizationId) {
    const url = `/api/v1/organizations/${organizationId}/product_payups/?page=1&page_size=250&partnership=${partnershipId}`;
    try {
      return api.get(url);
    } catch (error) {
      onErrorHandler(error, 'payups-get-partnership-payups');
    }
  },
  createPayup(organizationId, partnershipId, payup) {
    const url = `/api/v1/organizations/${organizationId}/product_payups/?partnership=${partnershipId}`;
    try {
      return api.postJson(url, payup);
    } catch (error) {
      onErrorHandler(error, 'payups-create-partnership-payups');
    }
  },
  createPayupList(organizationId, partnershipId, payups) {
    const url = `/api/v1/organizations/${organizationId}/product_payups/bulk/?partnership=${partnershipId}`;
    try {
      return api.postJson(url, payups);
    } catch (error) {
      onErrorHandler(error, 'payups-bulk-create-partnership-payups');
    }
  },
  updatePayup(organizationId, partnershipId, payup) {
    const url = `/api/v1/organizations/${organizationId}/product_payups/${payup.id}/?partnership=${partnershipId}`;
    try {
      return api.putJson(url, payup);
    } catch (error) {
      onErrorHandler(error, 'payups-update-partnership-payups');
    }
  },
  updatePayupList(organizationId, partnershipId, payups) {
    const url = `/api/v1/organizations/${organizationId}/product_payups/bulk/?partnership=${partnershipId}`;
    try {
      return api.putJson(url, payups);
    } catch (error) {
      onErrorHandler(error, 'payups-bulk-update-partnership-payups');
    }
  },
  getPayupProducts(organizationId, tickerSymbol) {
    try {
      return api.get(
        `/api/v1/organizations/${organizationId}/payup_products/?ticker_symbol=${tickerSymbol}`,
      );
    } catch (error) {
      onErrorHandler(error, 'get-payups-products');
    }
  },
};

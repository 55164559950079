import { getOperations } from '@shared/services/coreApi.js';
import { OPERATION_STATUS } from '@shared/constants';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';

export default {
  namespaced: true,
  state: {
    isGettingOperations: false,
    activeOperations: null,
    activeOperationResultPromise: null,
    onOperationCompleteCallbacks: [],
  },
  getters: {
    hasActiveOperations: state => Boolean(state.activeOperations?.length),
    activeOperations: state => state.activeOperations,
    activeOperationResultPromise: state => state.activeOperationResultPromise,
  },
  mutations: {
    setActiveOperations(state, payload) {
      state.activeOperations = payload;
    },
    setActiveOperationResultPromise(state, payload) {
      state.activeOperationResultPromise = payload;
    },
    subscribeOnOperationComplete(state, payload) {
      state.onOperationCompleteCallbacks.push(payload);
    },
    unsubscribeOnOperationComplete(state, payload) {
      state.onOperationCompleteCallbacks =
        state.onOperationCompleteCallbacks.filter(
          callback => callback !== payload,
        );
    },
  },
  actions: {
    async getActiveOperations({ commit, dispatch, rootGetters }) {
      try {
        await dispatch('core/getUserInfo', null, { root: true });
        if (
          rootGetters['core/userInfo']?.default_org?.ignore_operation_conflict
        ) {
          return;
        }
        const operations = await getOperations({
          operationStatus: OPERATION_STATUS.ACTIVE,
        });
        commit('setActiveOperations', operations);
      } catch (er) {
        onErrorHandler(er, 'operations-getActiveOperations');
      }
    },
    async trackActiveOperations(
      { dispatch, commit, getters },
      { onOperationComplete, component } = {},
    ) {
      if (onOperationComplete && component) {
        commit('subscribeOnOperationComplete', onOperationComplete);
        component.$once('hook:beforeDestroy', () =>
          commit('unsubscribeOnOperationComplete', onOperationComplete),
        );
      }
      if (getters.activeOperationResultPromise) {
        return await getters.activeOperationResultPromise;
      }
      const activeOperationResultPromise = dispatch(
        'waitUntilOperationComplete',
      );
      commit('setActiveOperationResultPromise', activeOperationResultPromise);
      try {
        await activeOperationResultPromise;
      } finally {
        commit('setActiveOperationResultPromise', null);
      }
    },
    async waitUntilOperationComplete({ getters, dispatch }) {
      await dispatch('getActiveOperations');
      if (!getters.hasActiveOperations) {
        return;
      }
      await dispatch(
        'asyncTasks/getTaskResult',
        { taskId: getters.activeOperations[0].celery_task_id },
        { root: true },
      );
      await dispatch('getActiveOperations');
      await dispatch('runOperationCompleteCallbacks');
    },
    async runOperationCompleteCallbacks({ state }) {
      state.onOperationCompleteCallbacks.forEach(
        callback => callback && callback(),
      );
    },
  },
};

import { formatMoney, strToPrecision } from '@shared/utils/converters.js';

export default {
  // this function registers global filters for given Vue instance
  // more info about Vue filters: https://vuejs.org/v2/guide/filters
  init(vue) {
    // filter for formatting of data which represents money
    vue.filter('money', function (value) {
      if (!value) return '';
      return formatMoney(
        // eslint-disable-next-line
        value.replace(/(\$\s?|\,)/gi, ''),
        false, // showFraction
        2, // decimalCount
        '', // currency
      );
    });
    // filter for formatting of data which represents points with 3 decimal
    // places
    vue.filter('points', function (value) {
      if (!value) return '';
      return strToPrecision(value, 3);
    });

    vue.filter(
      'mask',
      function (text, max_length = 10, display_length = 8, clamp = '...') {
        return text.length > max_length
          ? clamp + text.substr(text.length - display_length)
          : text;
      },
    );
  },
};

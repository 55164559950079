<template>
  <div class="settings-panel">
    <h2 class="settings-title">Settings</h2>
    <div class="settings-item">
      <label class="settings-item-label" for="vocalResponse">
        Vocal response:
      </label>
      <input
        id="vocalResponse"
        v-model="settingsVocalResponseEnabled"
        class="settings-item-input"
        type="checkbox"
        @change="setVocalResponseEnabled(settingsVocalResponseEnabled)"
      />
    </div>
    <div class="settings-item">
      <label class="settings-item-label" for="autoPrice">Auto Pricing:</label>
      <input
        id="autoPricing"
        v-model="settingsAutoPricingEnabled"
        class="settings-item-input"
        type="checkbox"
        @change="setAutoPricingEnabled(settingsAutoPricingEnabled)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatSettings',
  props: {
    vocalResponseEnabled: {
      type: Boolean,
      required: true,
    },
    autoPricingEnabled: {
      type: Boolean,
      required: true,
    },
    setVocalResponseEnabled: {
      type: Function,
      required: true,
    },
    setAutoPricingEnabled: {
      type: Function,
      required: true,
    },
  },
  data: function () {
    return {
      settingsVocalResponseEnabled: this.vocalResponseEnabled,
      settingsAutoPricingEnabled: this.autoPricingEnabled,
    };
  },
};
</script>

<style lang="scss">
@import './ChatWidget.scss';
.settings-panel {
  position: absolute;
  box-shadow: inset 0 0 5px lightgray;
  width: 100%;
  height: calc(100% - $header-height);
  background-color: oldlace;
  padding: 1rem;
  box-sizing: border-box;
  overflow-y: auto; /* Add scrolling when content is too large */
  z-index: 1;
  opacity: 0.95;
}

.settings-title {
  font-weight: bold;
  color: $polly-light-blue;
}

.settings-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  gap: 5px;

  & label {
    font-weight: bold;
    margin-bottom: 0;
  }
  & label:hover {
    cursor: pointer;
  }
}
</style>

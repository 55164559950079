import api from '@shared/services/api.js';
import axios from 'axios';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';

/**
 * Post initial lock request to API.
 * @param loanId los loan id
 * @param payload lock info
 * @returns {*}
 */
export async function postInitialLockRequest(loanId, payload) {
  return await api.post(
    `/pe/api/loans/${loanId}/initial-lock-request/`,
    payload,
  );
}

/**
 * Post reprice request to API.
 * @param loanId los loan id
 * @param payload lock info
 * @returns {*}
 */
export async function postRepriceRequest(loanId, payload) {
  return await api.post(
    `/pe/api/loans/${loanId}/lock-requests/reprice`,
    payload,
  );
}

/**
 * Post relock request to API.
 * @param loanId los loan id
 * @param payload lock info
 * @returns {*}
 */
export async function postRelockRequest(loanId, payload) {
  return await api.post('/pe/api/lock-relock-requests/', payload);
}

/**
 * Post product change request to API.
 * @param loanId los loan id
 * @param payload lock info
 * @returns {*}
 */
export async function postProductChangeRequest(loanId, payload) {
  return await api.post('/pe/api/lock-product-change-requests/', payload);
}

/**
 * Post float request to API.
 * @param loanId los loan id
 * @param payload lock info
 * @returns {*}
 */
export async function postFloatRequest(loanId, payload) {
  return await api.post('/pe/api/float-requests/', payload);
}

/**
 * Post extension request to API.
 * @param loanId los loan id
 * @param payload lock info
 * @returns {*}
 */
export async function postExtensionRequest(loanId, payload) {
  return await api.post('/pe/api/lock-extension/request/', payload);
}

/**
 * Post price exception request to API.
 * @param loanId los loan id
 * @param payload lock info
 * @returns {*}
 */
export async function postExceptionRequest(loanId, payload) {
  return await api.post('/pe/api/lock-price-exception-requests/', payload);
}

/**
 * Post float down request to API.
 * @param loanId los loan id
 * @param payload lock info
 * @returns {*}
 */
export async function postFloatDownRequest(loanId, payload) {
  return await api.post('/pe/api/lock-float-down-requests/', payload);
}

/**
 * Post cancellation request to API.
 * @param loanId los loan id
 * @param payload lock info
 * @returns {*}
 */
export async function postCancellationRequest(loanId, payload) {
  return await api.post('/pe/api/lock-cancellation-requests/', payload);
}

/**
 * Post cancellation request to API.
 * @param loanId los loan id
 * @param payload lock info
 * @returns {*}
 */
export async function postResetRequest(payload) {
  return await api.post('/pe/api/lock-reset-requests/', payload);
}

/**
 * Get lock requests.
 */
export async function getLockRequests(options) {
  const url = api.constructUrl('/pe/api/lock-requests/', options);
  return await api.get(url);
}

export function getLockRequestById(lockId, options, fromLD) {
  const url = api.constructUrl(
    `/pe/api/lock-requests/reviewal/info/${lockId}/`,
    fromLD ? { from_lock_desk: fromLD } : {},
  );
  return api.get(url, options).catch(function (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled', error.message);
    } else {
      throw error;
    }
  });
}

export function getLockRequestHistoryById(loanNumber, options) {
  const url = api.constructUrl(
    `/pe/api/pricer/loans/${loanNumber}/lock-requests/`,
    options,
  );
  return api.get(url);
}

export function deleteLockRequest(id) {
  return api.delete(`/pe/api/lock-requests/${id}`);
}

export function postLockRequestSellSideSaveToLos(payload) {
  return api.post('/pe/api/lock-requests/sell-side/', payload);
}

export function postLockRequestLosInformationSaveToLos(payload) {
  return api.post('/pe/api/lock-requests/los-information/', payload);
}

export function getPriceExceptionReviewalStatus(id) {
  return api
    .get(`/pe/api/exception-management/reviewal/info/${id}`)
    .catch(e => {
      onErrorHandler(e, 'pe-get-price-exception-reviewal-status');
    });
}

/**
 * PUT sell-side information for the lock request with `id`.
 * @param id lock request id
 * @param payload sell side info
 * @returns {*}
 */
export function putLockRequestSellSideInfo(id, payload) {
  return api.put(`/pe/api/lock-requests/${id}/sell-side/`, payload);
}

/**
 * Post custom credit request to add to float request.
 * @param lockRequestId lock request id
 * @param payload custom credit data
 * @returns {*}
 */
export async function createCustomCreditToFloatRequest(lockRequestId, payload) {
  return await api.post(
    `/pe/api/float-requests/${lockRequestId}/custom-credit/`,
    payload,
  );
}

/**
 * Check if loan can be locked
 * @param lockRequestId pending lock request ID
 * @return {Promise<boolean>}
 */
export async function canLockLoan(lockRequestId) {
  try {
    const response = await api.get(
      `/pe/api/pricing/request/${lockRequestId}/is-lock-allowed`,
    );
    return response?.canLockLoan;
  } catch (error) {
    onErrorHandler(error, 'pe_can_lock_loan');
    throw error;
  }
}

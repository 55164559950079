import api from '@shared/services/api.js';

export default {
  get(model_name) {
    return api.get(`/api/dynamic_query?model_name=${model_name}`);
  },
  post(model_name, query_filter) {
    return api.post(
      `/api/dynamic_query?model_name=${model_name}`,
      query_filter,
    );
  },
};

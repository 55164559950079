import { requestStatus } from '@shared/constants';

let intervalId, focusHandler, blurHandler, isComponentFocused;
const ORG_IMA_STATUS_POLLING_INTERVAL_MS = 30000;

async function shouldStopPolling({ dispatch, state, getters }) {
  await dispatch('fetchOrganization');
  return (
    !isComponentFocused ||
    state.fetchOrganizationStatus === requestStatus.ERROR ||
    getters.isImaServicingCurrent
  );
}

export async function startOrgPolling(context) {
  await maybeStartPolling(context);
  startListening(context);
}

async function maybeStartPolling(context) {
  isComponentFocused = true;
  if ((await shouldStopPolling(context)) || intervalId) {
    return;
  }
  startPolling(context);
}

function startPolling(context) {
  intervalId = setInterval(async () => {
    if (!isComponentFocused || (await shouldStopPolling(context))) {
      stopPolling();
    }
  }, ORG_IMA_STATUS_POLLING_INTERVAL_MS);
}

function stopPolling() {
  clearInterval(intervalId);
  intervalId = null;
}

function startListening(context) {
  window.addEventListener('focus', getFocusHandler(context));
  window.addEventListener('blur', getBlurHandler());
}

function stopListening() {
  if (focusHandler) {
    window.removeEventListener('focus', focusHandler);
  }
  if (blurHandler) {
    window.removeEventListener('blur', blurHandler);
  }
}

export function cleanupOrgPolling() {
  isComponentFocused = false;
  stopPolling();
  stopListening();
}

function getFocusHandler(context) {
  if (!focusHandler) {
    focusHandler = async () => {
      isComponentFocused = true;
      if (await shouldStopPolling(context)) {
        stopPolling();
      } else {
        startPolling(context);
      }
    };
  }
  return focusHandler;
}

function getBlurHandler() {
  if (!blurHandler) {
    blurHandler = () => {
      isComponentFocused = false;
      stopPolling();
    };
  }
  return blurHandler;
}

import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import {
  isBuyApp,
  isDeliverApp,
  isSellApp,
  isToolsApp,
} from '@shared/utils/routing.js';

const BENCHMARK_REFRESH_INTERVAL = 30000; // in miliseconds (30 sec)
const MAX_BENCHMARK_GETTING_DURATION = 1200000; // in miliseconds (1200 sec = 20 min)

export default {
  data() {
    return {
      refreshedTime: '',
    };
  },
  updated() {
    if (this.shouldShowBenchmarks() && !this.gettingInterval) {
      this.getBenchmarksMixin();
      this.gettingInterval = setInterval(
        this.getBenchmarksMixin.bind(this),
        BENCHMARK_REFRESH_INTERVAL,
      );
      const cleanUp = function () {
        clearInterval(this.gettingInterval);
        clearTimeout(this.stopGettingTimeout);
        this.refreshedTime = moment().format('LT');
      }.bind(this);
      this.stopGettingTimeout = setTimeout(
        cleanUp,
        MAX_BENCHMARK_GETTING_DURATION,
      );
      this.$once('hook:beforeDestroy', cleanUp);
    }
  },
  methods: {
    ...mapActions({
      getBenchmarks: 'benchmarks/getBenchmarks',
    }),
    async getBenchmarksMixin() {
      try {
        await this.getBenchmarks();
      } catch (e) {
        onErrorHandler(e, 'shared-get-benchmark-bids');
        clearInterval(this.gettingInterval);
      }
    },
    shouldShowBenchmarks() {
      return (
        this.isRefinitivCustomer &&
        (isSellApp() || isBuyApp() || isDeliverApp() || isToolsApp())
      );
    },
  },
  computed: {
    ...mapGetters('benchmarks', ['favoriteBenchmarks']),
    ...mapGetters('organizations', ['isRefinitivCustomer']),
    benchmarks() {
      return this.favoriteBenchmarks;
    },
  },
};

import mutationTypes from '@devtools/store/testScenarios/mutationTypes.js';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import { DEFAULT_TABLE_OPTIONS } from '@shared/constants';

import svc from '@devtools/services/testScenarios.js';
import { getModelOptions } from '@devtools/services/api/seedData.js';

export default {
  namespaced: true,
  state: () => ({
    categories: [],
    selectedDataScenario: null,
    isCategoriesLoading: false,
    scenarioRuns: [],
    tableOptions: DEFAULT_TABLE_OPTIONS,
    modelOptions: {},
  }),
  getters: {
    categories: state => state.categories,
    selectedDataScenario: state => state.selectedDataScenario,
    isCategoriesLoading: state => state.isCategoriesLoading,
    tableOptions: state => state.tableOptions,
  },
  mutations: {
    [mutationTypes.SET_CATEGORIES](state, categories) {
      state.categories = categories;
    },
    [mutationTypes.SET_IS_CATEGORIES_LOADING](state, isLoading) {
      state.isCategoriesLoading = isLoading;
    },
    [mutationTypes.SET_SELECTED_SCENARIO](state, selectedDataScenario) {
      state.selectedDataScenario = selectedDataScenario;
    },
    [mutationTypes.SET_TABLE_OPTIONS](state, tableOptions) {
      state.tableOptions = tableOptions;
    },
    [mutationTypes.SET_MODEL_OPTIONS](state, modelOptions) {
      state.modelOptions = { ...state.modelOptions, ...modelOptions };
    },
    [mutationTypes.SET_SCENARIO_RUNS](state, scenarioRuns) {
      state.scenarioRuns = scenarioRuns;
    },
  },
  actions: {
    async getCategories({ commit }) {
      try {
        commit(mutationTypes.SET_IS_CATEGORIES_LOADING, true);
        const { results: categories } = await svc.getCategories();
        commit(mutationTypes.SET_CATEGORIES, categories);
      } catch (error) {
        onErrorHandler(error, 'devtools-get-scenario-categories', [403], true);
      } finally {
        commit(mutationTypes.SET_IS_CATEGORIES_LOADING, false);
      }
    },
    async getModelOptions({ commit, state }, payload) {
      const { scenarioId, argName, contentType, filters } = payload;

      let modelOptions = state.modelOptions?.[scenarioId]?.[argName];
      if (!modelOptions) {
        const response = await getModelOptions(contentType, filters);
        modelOptions = response?.options;
        commit(mutationTypes.SET_MODEL_OPTIONS, {
          [scenarioId]: { [argName]: modelOptions },
        });
      }
      return modelOptions;
    },
    async getScenarioRuns({ commit }) {
      try {
        const { results: scenarioRuns } = await svc.getScenarioRuns();
        commit(mutationTypes.SET_SCENARIO_RUNS, scenarioRuns);
      } catch (error) {
        onErrorHandler(error, 'devtools-get-scenario-runs', [], true);
      }
    },
    setSelectedScenario({ commit }, scenarioData) {
      commit(mutationTypes.SET_SELECTED_SCENARIO, scenarioData);
    },
    setTableOptions({ commit, state }, tableOptions) {
      commit(mutationTypes.SET_TABLE_OPTIONS, {
        ...state.tableOptions,
        ...tableOptions,
      });
    },
  },
};

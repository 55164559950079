<template>
  <b-dropdown
    ref="dd"
    v-bind="$attrs"
    class="lx-dd position-relative"
    :class="{ ddClass, 'invalid-input': isError, 'warning-input': isWarning }"
    v-on="$listeners"
  >
    <template #button-content>
      <slot name="button-content"></slot>
      <div
        v-if="isError || isWarning"
        class="position-absolute information-text"
        :class="{ 'invalid-text': isError, 'warning-text': isWarning }"
      >
        <span>{{ invalidText }}</span>
      </div>
      <div v-if="withArrow" class="arrow"></div>
    </template>

    <slot></slot>
  </b-dropdown>
</template>

<script>
export default {
  name: 'LxDropdown',
  props: {
    withArrow: { type: Boolean, default: true },
    isError: { type: Boolean, default: false },
    isWarning: { type: Boolean, default: false },
    invalidText: { type: String, default: 'Required' },
  },
  computed: {
    ddClass() {
      return this.withArrow ? 'lx-dd-arrow' : '';
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    const $span = $('<span>', { class: 'icon-dropdown fa-angle-down' });
    // eslint-disable-next-line no-undef
    $(this.$refs.dd.$el).find('.dropdown-toggle-split').prepend($span);
  },
  methods: {
    hide() {
      this.$refs.dd.hide(true);
    },
    show() {
      this.$refs.dd.show();
    },
  },
};
</script>

<style lang="scss">
.lx-dd {
  .information-text {
    left: 0;
    bottom: -1.6rem;
  }
}
</style>

<template>
  <component
    :is="tooltipMessage ? 'side-nav-tooltip' : 'div'"
    :content="tooltipMessage"
    :is-active="isActive"
  >
    <router-link :class="linkClasses" :exact-path="exactPathMatch" :to="navTo">
      <div class="nav-icon">
        <slot name="icon">
          <px-badge
            v-if="badgeText"
            class="icon__badge"
            small
            variant="light-blue"
          >
            <slot name="badge-content">{{ badgeText || 1 }}</slot>
          </px-badge>
          <px-icon :icon="icon" inline />
        </slot>
      </div>
      <div class="nav-text">
        <slot>{{ text }}</slot>
      </div>
    </router-link>
  </component>
</template>

<script>
import { stripTrailingSlash } from '@shared/utils/urlFormatters.js';
import SideNavTooltip from './SideNavTooltip.vue';

export default {
  components: { SideNavTooltip },
  inject: {
    setParentActive: {
      from: 'setParentActive',
      default: null,
    },
  },
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
    icon: {
      type: String,
      default: '',
      required: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    navitem: {
      type: String,
      default: '',
    },
    exactPathMatch: {
      type: Boolean,
      default: false,
    },
    otherMatchingRoutes: {
      type: Array,
      required: false,
      default: () => [],
    },
    excludedRoutes: {
      type: Array,
      required: false,
      default: () => [],
    },
    badgeText: {
      type: [String, Number],
      default: '',
      required: false,
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    tooltipMessage() {
      return this.tooltip ? this.tooltip : this.text;
    },
    targetPath() {
      return this.pathFromRoute(this.to);
    },
    linkClasses() {
      return {
        'nav-button': true,
        'router-link-active': this.isActive,
      };
    },
    navTo() {
      // Add the fromNav param to the route
      let to = this.to;
      if (typeof this.to !== 'object') {
        to = { path: this.to };
      }
      return { params: { fromNav: true }, ...to };
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.isActive = this.isRouteActive(to);
        if (this.isActive && this.setParentActive) {
          this.setParentActive();
        }
      },
    },
  },
  methods: {
    pathFromRoute(route) {
      if (typeof route === 'object') {
        return stripTrailingSlash(this.$router.resolve(route).route.path);
      } else {
        return stripTrailingSlash(route);
      }
    },
    isRouteActive(currentRoute) {
      const currentPath = stripTrailingSlash(currentRoute.path);
      const currentNavItem = currentRoute.meta?.navitem;

      if (this.excludedRoutes.length !== 0) {
        const matchedExcludedRoutes = this.excludedRoutes.some(
          route => currentPath === this.pathFromRoute(route),
        );
        if (matchedExcludedRoutes) return false;
      }

      // If the route belongs to the same navitem then we consider the route active.
      if (this.navitem && currentNavItem === this.navitem) return true;

      if (this.exactPathMatch) {
        if (currentPath === this.targetPath) return true;
        return this.otherMatchingRoutes.some(
          route => currentPath === this.pathFromRoute(route),
        );
      }

      if (currentPath.startsWith(this.targetPath)) return true;
      return this.otherMatchingRoutes.some(route =>
        currentPath.startsWith(this.pathFromRoute(route)),
      );
    },
  },
};
</script>

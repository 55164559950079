// The following keycodes represent digits 0-9
const NUMERIC_INPUT_KEYCODES = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
const MINUS_CODE = 45;
const DOT_CODE = 46;

/**
 * Returns `true` if the specified value is a valid decimal representation
 * If maxPrecision == 0 then only integers are allowed.
 * (eg., 1.034 or -0.5).
 *
 * @param value The value being validated
 * @param maxPrecision The max allowed decimal precision
 * @param allowNegative Whether or not the value can be negative
 */
export function isValidDecimal(value, maxPrecision, allowNegative) {
  if (maxPrecision == 0) {
    const reg = new RegExp(allowNegative ? `^-?\\d*$` : `^\\d*$`);
    return reg.test(value);
  }

  const reg = new RegExp(
    allowNegative
      ? `^-?\\d*\\.?\\d{0,${maxPrecision}}$`
      : `^\\d*\\.?\\d{0,${maxPrecision}}$`,
  );

  return reg.test(value);
}

/**
 * Returns what we expect the field value will be after the new characters
 * are included.
 */
export function getExpectedOutputForOnBeforeInput($event) {
  return (
    $event.target.value.substring(0, $event.currentTarget.selectionStart) +
    $event.data +
    $event.target.value.substring(
      $event.currentTarget.selectionEnd,
      $event.target.value.length,
    )
  );
}

/**
 * Returns an event handler for validating decimal field input.
 *
 * @param maxPrecision The max allowed decimal precision
 * @param allowNegative Whether or not the value can be negative
 * @returns {(function(*): void)|*} Event handler
 */
export function onBeforeInputValidateDecimal(maxPrecision, allowNegative) {
  return function ($event) {
    if (
      ['deleteContentForward', 'deleteContentBackward'].includes(
        $event.inputType,
      )
    ) {
      // A deletion can't result in the string being less valid than it was, so
      // we will bypass the validation.
      return;
    }
    if ($event.currentTarget.selectionStart === null) {
      console.log("This event handler only validates inputs of type 'text'.");
      return;
    }
    const value = getExpectedOutputForOnBeforeInput($event);
    if (!isValidDecimal(value, maxPrecision, allowNegative)) {
      $event.preventDefault();
    }
  };
}

export function checkDecimalPlaces(decimalCount, allowNegative, integerCount) {
  return function ($event) {
    const keyCode = $event.keyCode ? $event.keyCode : $event.which;
    const value = $event.target.value;

    if ($event.currentTarget.selectionStart === null) {
      console.log("This event handler only validates inputs of type 'text'.");
    }
    // Allow only digits (48-57) and dot (46) and
    // minus (45) if allowNegative
    if (
      keyCode !== DOT_CODE &&
      !NUMERIC_INPUT_KEYCODES.includes(keyCode) &&
      (!allowNegative || keyCode != MINUS_CODE)
    ) {
      $event.preventDefault();
    } else {
      const dotIndex = value.indexOf('.');
      if (dotIndex > -1) {
        // if there is a dot already
        if (
          keyCode === DOT_CODE && // if pressed on dot and dot not inside a text selection
          !(
            $event.currentTarget.selectionStart <= dotIndex &&
            $event.currentTarget.selectionEnd > dotIndex
          )
        ) {
          // Allow only one dot
          $event.preventDefault();
        } else if (
          value.split('.')[1].length > decimalCount - 1 && // if decimal points count equals max limit and...
          // if text selection is empty and cursor after the decimal point
          $event.currentTarget.selectionEnd -
            $event.currentTarget.selectionStart ===
            0 &&
          $event.currentTarget.selectionStart > dotIndex
        ) {
          // Allow up to <decimalCount> decimal places
          $event.preventDefault();
        }
      } else if (
        keyCode === DOT_CODE && // if pressed on dot and...
        (value.length - $event.currentTarget.selectionEnd > decimalCount ||
          decimalCount <= 0)
      ) {
        // if length of ending equals or greater than max limit
        // Allow up to <decimalCount> decimal places
        // or disallow 'dot' in case of decimalCount less or equal to 0
        $event.preventDefault();
      }
      if (
        integerCount >= 1 &&
        value.split('.')[0].length >= integerCount &&
        $event.currentTarget.selectionEnd ===
          $event.currentTarget.selectionStart &&
        keyCode !== DOT_CODE &&
        (dotIndex < 0 || $event.currentTarget.selectionStart <= dotIndex)
      ) {
        $event.preventDefault();
      }
      if (keyCode === MINUS_CODE) {
        // if pressed on minus
        const minusIndex = value.indexOf('-');
        // Allow dash only in a first place
        if (
          $event.currentTarget.selectionStart > 0 || // if minus not at the begining or...
          (minusIndex > -1 && // if there is minus and minus not inside text selection
            !(
              $event.currentTarget.selectionStart <= minusIndex &&
              $event.currentTarget.selectionEnd > minusIndex
            ))
        ) {
          $event.preventDefault();
        }
      }
    }
  };
}

/** Checks a keypress event to make sure it was a numeric, period(.), minus(-), and not enter.
 * @param {*} $event
 * @returns {Boolean} true if key event was a number key
 */
export function checkNumeric($event) {
  const value = $event.key;
  if ($event.key === 'Enter') return true;
  var reg = /^-?\d*\.?\d*$/;
  return reg.test(value);
}

/** Creates a query string from the specified parameters
 * @param  {String} baseUrl Base url, e.g. http://google.com
 * @param  {Object} params
 * @returns {String} A query string
 */
export function createQueryString(baseUrl, params) {
  params = params || {};
  const paramsObject = new URLSearchParams(params);
  baseUrl.trimRight('?');
  return baseUrl + '?' + paramsObject.toString();
}

/** Initiates file download for a user, given a blob
 * @param  {String} name File name
 * @param  {Blob} blob A blob object
 */
export function downloadBlob(name, blob) {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, name);
  } else {
    const a = document.createElement('a');
    a.hidden = true;
    document.body.appendChild(a);
    const url = URL.createObjectURL(blob);
    a.style = 'display:none';
    a.href = url;
    a.download = name;
    a.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
}

/**
 * Convert a base64 encoded bytes string to a Blob that can be downloaded.
 *
 * Stolen from: https://stackoverflow.com/a/20151856
 *
 * @param base64Data - base64 encoded bytes string representing the file data.
 * @param contentType - the type of the file
 * @returns {Blob}
 */
export function base64toBlob(base64Data, contentType) {
  contentType = contentType || '';
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

export function downloadCsv(fileName, csvContent) {
  const blob = new Blob([csvContent], { type: 'octet/stream' });
  return downloadBlob(fileName, blob);
}
// https://stackoverflow.com/questions/3916191/download-data-url-file
export function downloadURI(url, filename) {
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    })
    .catch(console.error);
}
/** Performs grouping of array items by property
 * @param  {Array} items given array of items
 * @param  {String} property Name of item property (possibly nested), e.g.: 'someProperty', 'key.subKey'
 */
export function groupBy(items, prop) {
  return items.reduce((groups, item) => {
    // magic with (possibly nested) item properties
    const key = prop
      .split('.')
      .reduce((subItem, p) => (subItem ? subItem[p] : undefined), item);
    groups[key] = groups[key] || [];
    groups[key].push(item);
    return groups;
  }, {});
}

/** Performs comparing of two items by specified properties
 * @param  {Array} props for sorting ['name'], ['value', 'city'], ['-date']
 * to set descending order on object property just add '-' at the begining of property
 */
export const compareBy =
  (...props) =>
  (a, b) => {
    for (let i = 0; i < props.length; i++) {
      const ascValue = props[i].startsWith('-') ? -1 : 1;
      const prop = props[i].startsWith('-') ? props[i].substr(1) : props[i];
      if (a[prop] !== b[prop]) {
        return a[prop] > b[prop] ? ascValue : -ascValue;
      }
    }
    return 0;
  };

/** Check that API response is success.
 * You can use it when FrontendMessage used on backend side.
 * @param  {Object} response API endpoint response object
 */
export function isSuccessResponse(response) {
  if (response && response.messages) {
    if (response.messages.length > 0) {
      if (response.messages[0].type == 'success') {
        return true;
      }
    }
  }

  return false;
}

/** Check that two string sets equal to each other
 * You can use it to compare two arrays
 * @param  {Set} as first Set of strings
 * @param  {Set} bs Second Set of strings
 */
export function eqStringSet(as, bs) {
  if (as.size !== bs.size) return false;
  for (const a of as) if (!bs.has(a)) return false;
  return true;
}

// Checks that select2 values are equivalent
export function eqFilterValues(a, b) {
  if (
    Array.isArray(a) &&
    Array.isArray(b) &&
    eqStringSet(new Set(a), new Set(b))
  ) {
    return true;
  }
  if (!Array.isArray(a) && !Array.isArray(b) && a === b) {
    return true;
  }
  return false;
}

// the filter is applicable if the filter is not empty. It means that
// filter value is not falsey if it is not array otherwise
// it should be nonempty and should have not falsey elements in the array
export function isFilterApplicable(filter) {
  const isValueApplicable = val =>
    val !== null && val !== undefined && val !== '';
  // if there is no filter operator the filter is not aplicable
  if (!filter.op) {
    return false;
  }
  // check filter value if it is not Array
  if (!Array.isArray(filter.value)) {
    return isValueApplicable(filter.value);
  }
  // check filter value if it is Array
  return filter.value.length && filter.value.every(isValueApplicable);
}

import moment from 'moment';
// gives next (or previous depending on increment params passed in) from
// input date assuming that weekend is 0th and 6th days of the week
export function getNextBusinessDate(date, increment = 1) {
  let nextBusinessDate = moment(date);
  for (let i = 0; i < 7; i++) {
    const dayOfWeek = nextBusinessDate.toDate().getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      return nextBusinessDate.toDate();
    }
    nextBusinessDate = nextBusinessDate.add(increment, 'days');
  }
}

// this function adds given daysCount to given date and returns the result
export function addDays(date, daysCount) {
  return moment(new Date(date)).add(daysCount, 'days').toDate();
}

/** This function returns a formatted string based on the number of months provided.
 *  @param
 *    {Number} months Number of months for which to return formatted term string.
 *  @returns
 *    {String} A formatted strings based on the number of months passed,
 *             if number of months is less than 12 but greater than 0,
 *             the resulting string will look like 'X mo' (X being number of months),
 *             otherwise when more than 12 months, it'll be 'X yr'.
 *             If number of months is 0 or negative, 'N/A' is returned.
 */
export function termFormatter(months) {
  if (months >= 12) {
    return `${months / 12} yr`;
  } else if (months > 0) {
    return `${months} mo`;
  }
  return 'N/A';
}

/** Parses money string value from UI by removing of commas
 *  and currency sign
 * @param  {String} moneyString representing money value
 * @returns {String} cleaned money value
 */
export function parseMoney(moneyString) {
  return moneyString.replace(/,|\$/g, '');
}

export function getFileExtension(fileName) {
  // https://stackoverflow.com/questions/190852/how-can-i-get-file-extensions-with-javascript/12900504#12900504
  return fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2);
}

export const reloadJs = (src, onLoad = () => {}) => {
  document.querySelector(`script[src="${src}"]`)?.remove();

  const script = document.createElement('script');
  script.setAttribute('src', src);
  script.onload = onLoad;

  document.querySelector('head').appendChild(script);
};

export const reloadWaffle = onLoad => reloadJs('/wafflejs', onLoad);

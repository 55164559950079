import Vue from 'vue';

export default {
  setFilterDefinition(state, filter) {
    state.filterDefinition = filter;
  },
  addFilterDefinition(state, arg) {
    state.filterDefinition.push(arg);
  },
  updateFilterDefinition(state, arg) {
    state.filterDefinition.forEach((service, index) => {
      if (service.field === arg.field) {
        Vue.set(state.filterDefinition, index, arg);
      }
    });
  },
  removeFilterDefinition(state, field) {
    state.filterDefinition.splice(
      state.filterDefinition.findIndex(x => x.field === field),
      1,
    );
  },
};

import api from '@shared/services/api.js';
import {
  mapDeprecatedToCurrentTimeZone,
  strToPrecision,
} from '@shared/utils/converters.js';

const organizationDetail = state => {
  return state.organizationDetail;
};

const isOrganizationLogoLoading = state => {
  return state.isOrganizationLogoLoading;
};

const organizationLogo = state => {
  return state.organizationLogo;
};

const organizationTimezone = state => {
  return mapDeprecatedToCurrentTimeZone(
    state.organizationDetail?.data?.time_zone,
  );
};

async function getServicingFeeOptions() {
  const data = await api.get('/company/api/info/servicing-fees');
  const serviceFees = [];
  for (const serviceFee of data) {
    serviceFees.push(strToPrecision(serviceFee, 4));
  }
  return serviceFees;
}

const fundingCost = state => state.fundingCost;

export default {
  getServicingFeeOptions,
  organizationDetail,
  organizationTimezone,
  isOrganizationLogoLoading,
  organizationLogo,
  fundingCost,
};

export const SET_PE_REVIEWAL_STATUS = 'SET_PE_REVIEWAL_STATUS';
export const SET_PRICE_EXCEPTIONS = 'SET_PRICE_EXCEPTIONS';
export const CLEAR_REVIEWAL_INFO = 'CLEAR_REVIEWAL_INFO';
export const SET_EXCEPTION_MANAGEMENT_LEVELS_WITH_STATUS =
  'SET_EXCEPTION_MANAGEMENT_LEVELS_WITH_STATUS';

export default {
  SET_PE_REVIEWAL_STATUS,
  SET_PRICE_EXCEPTIONS,
  CLEAR_REVIEWAL_INFO,
  SET_EXCEPTION_MANAGEMENT_LEVELS_WITH_STATUS,
};

const state = {
  searchValue: null,
};

const getters = {
  searchValue: state => state.searchValue || '',
};

const mutations = {
  setSearchValue(state, value) {
    state.searchValue = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};

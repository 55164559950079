export const SET_BUY_SIDE_SHOW_CWF_PRICE_EXCEPTION =
  'SET_BUY_SIDE_SHOW_CWF_PRICE_EXCEPTION';
export const SET_CURRENT_LOCK_DESK = 'SET_CURRENT_LOCK_DESK';
export const SET_PENDING_CHANGES_BY_SIDE = 'SET_PENDING_CHANGES_BY_SIDE';
export const SET_PENDING_PRICE_ADJUSTMENTS = 'SET_PENDING_PRICE_ADJUSTMENTS';
export const SET_PENDING_RATE_ADJUSTMENTS = 'SET_PENDING_RATE_ADJUSTMENTS';
export const SET_EDITING_RESPONSE = 'SET_EDITING_RESPONSE';
export const CLEAR_PENDING_CHANGES = 'CLEAR_PENDING_CHANGES';
export const CACHE_EDITING_PAYLOAD = 'CACHE_EDITING_PAYLOAD';
export const SHOW_PE_TOGGLE_CHANGED = 'SHOW_PE_TOGGLE_CHANGED';

export default {
  SET_BUY_SIDE_SHOW_CWF_PRICE_EXCEPTION,
  SET_CURRENT_LOCK_DESK,
  SET_PENDING_CHANGES_BY_SIDE,
  SET_PENDING_PRICE_ADJUSTMENTS,
  SET_PENDING_RATE_ADJUSTMENTS,
  SET_EDITING_RESPONSE,
  CLEAR_PENDING_CHANGES,
  CACHE_EDITING_PAYLOAD,
  SHOW_PE_TOGGLE_CHANGED,
};

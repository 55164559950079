import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import {
  getAllPoolLoans,
  getEligiblePoolLoans,
} from '@sell/services/api/commitmentPool.js';
import {
  calculateScenarioBestExDelta,
  calculateScenarioMovement,
  moveLoansToCommitmentPool,
} from '@sell/services/api/poolScenario.js';
import { showSuccessMessage } from '@shared/utils/notifier.js';
import * as mutationTypes from './mutationTypes.js';

function strCmp(strA, strB) {
  if (strA === strB) {
    return 0;
  }
  return strA > strB ? 1 : -1;
}
function sortLoans(currentSavedIds, loans) {
  return [...loans].sort((a, b) => {
    if (currentSavedIds.includes(a.id) && currentSavedIds.includes(b.id)) {
      return strCmp(a.loan_number, b.loan_number);
    }
    if (currentSavedIds.includes(a.id)) {
      return -1;
    }
    if (currentSavedIds.includes(b.id)) {
      return 1;
    }
    return strCmp(a.loan_number, b.loan_number);
  });
}

export default {
  namespaced: true,
  state: () => ({
    allocatedLoans: [],
    savedAllocatedLoansIds: [],
    poolScenario: {},
    availableLoans: [],
    savedAvailableLoansIds: [],
    isAvailableLoansLoading: false,
    isAllocatedLoansLoading: false,
    isAllocateLoansModalOpen: false,
    loanExInfo: {},
  }),
  getters: {
    isAllocatedLoansLoading: state => state.isAllocatedLoansLoading,
    isAvailableLoansLoading: state => state.isAvailableLoansLoading,
    savedAllocatedLoansIds: state => state.savedAllocatedLoansIds,
    allocatedLoans: (state, getters) =>
      sortLoans(getters.savedAllocatedLoansIds, state.allocatedLoans),
    poolScenario: state => state.poolScenario,
    savedAvailableLoansIds: state => state.savedAvailableLoansIds,
    availableLoans: (state, getters) =>
      sortLoans(getters.savedAvailableLoansIds, state.availableLoans),
    isAllocateLoansModalOpen: state => state.isAllocateLoansModalOpen,
    allocatedLoansForTable: (_state, getters) =>
      getters.allocatedLoans.map(loan => {
        return { ...loan, ...getters.loanExInfo[loan.id] };
      }),
    availableLoansForTable: (_state, getters) =>
      getters.availableLoans.map(loan => {
        return { ...loan, ...getters.loanExInfo[loan.id] };
      }),
    loanExInfo: state => state.loanExInfo,
  },
  mutations: {
    [mutationTypes.SET_ALLOCATED_LOANS](state, loans) {
      state.allocatedLoans = loans;
    },
    [mutationTypes.SET_AVAILABLE_LOANS](state, loans) {
      state.availableLoans = loans;
    },
    [mutationTypes.SET_SAVED_ALLOCATED_LOANS_IDS](state, loanIds) {
      state.savedAllocatedLoansIds = loanIds;
    },
    [mutationTypes.SET_SAVED_AVAILABLE_LOANS_IDS](state, loanIds) {
      state.savedAvailableLoansIds = loanIds;
    },
    [mutationTypes.SET_IS_ALLOCATED_LOANS_LOADING](state, isLoading) {
      state.isAllocateLoansLoading = isLoading;
    },
    [mutationTypes.SET_IS_AVAILABLE_LOANS_LOADING](state, isLoading) {
      state.isAvailableLoansLoading = isLoading;
    },
    [mutationTypes.SET_IS_ALLOCATED_LOANS_MODAL_OPEN](state, isOpen) {
      state.isAllocateLoansModalOpen = isOpen;
    },
    [mutationTypes.SET_POOL_SCENARIO](state, poolScenario) {
      state.poolScenario = poolScenario;
    },
    [mutationTypes.SET_LOAN_EX_INFO](state, loanExInfo) {
      state.loanExInfo = loanExInfo;
    },
  },
  actions: {
    setIsAllocatedLoansModalOpen({ commit }, isOpen) {
      commit(mutationTypes.SET_IS_ALLOCATED_LOANS_MODAL_OPEN, isOpen);
    },
    setPoolScenario({ commit }, pool) {
      commit(mutationTypes.SET_POOL_SCENARIO, pool);
    },
    setAllocatedLoans({ commit }, loans) {
      commit(mutationTypes.SET_ALLOCATED_LOANS, loans);
    },
    setAvailableLoans({ commit }, loans) {
      commit(mutationTypes.SET_AVAILABLE_LOANS, loans);
    },
    async getAllocatedLoans({ commit, rootGetters }) {
      try {
        commit(mutationTypes.SET_IS_ALLOCATED_LOANS_LOADING, true);
        const { results: loans } = await getAllPoolLoans(
          rootGetters['organizations/currentOrganizationId'],
          rootGetters['commitmentPool/poolId'],
        );
        commit(mutationTypes.SET_ALLOCATED_LOANS, loans);
        commit(mutationTypes.SET_SAVED_ALLOCATED_LOANS_IDS, [
          ...loans.map(loan => loan.id),
        ]);
      } catch (error) {
        onErrorHandler(error, 'sell-get-allocated-loans', [403], true);
      } finally {
        commit(mutationTypes.SET_IS_ALLOCATED_LOANS_LOADING, false);
      }
    },
    async getAvailableLoans({ commit, rootGetters }) {
      try {
        commit(mutationTypes.SET_IS_AVAILABLE_LOANS_LOADING, true);
        const { results: loans } = await getEligiblePoolLoans(
          rootGetters['organizations/currentOrganizationId'],
          rootGetters['commitmentPool/poolId'],
        );
        commit(mutationTypes.SET_AVAILABLE_LOANS, loans);
        commit(mutationTypes.SET_SAVED_AVAILABLE_LOANS_IDS, [
          ...loans.map(loan => loan.id),
        ]);
      } catch (error) {
        onErrorHandler(error, 'sell-get-available-loans', [403], true);
      } finally {
        commit(mutationTypes.SET_IS_AVAILABLE_LOANS_LOADING, false);
      }
    },
    async getAllLoansExInfo({ commit, rootGetters, getters }) {
      const organizationId = rootGetters['organizations/currentOrganizationId'];
      const poolId = rootGetters['commitmentPool/poolId'];
      const loanIds = [
        ...getters.availableLoans.map(loan => loan.id),
        ...getters.allocatedLoans.map(loan => loan.id),
      ];

      try {
        const {
          results: { loan_scenarios },
        } = await calculateScenarioBestExDelta(organizationId, poolId, loanIds);
        commit(mutationTypes.SET_LOAN_EX_INFO, {
          ...loan_scenarios,
        });
      } catch (error) {
        onErrorHandler(error, 'get-loan-ex-info', [403], true);
      }
    },
    async getAllocatedLoansScenario({ commit, rootGetters, getters }) {
      const organizationId = rootGetters['organizations/currentOrganizationId'];
      const poolId = rootGetters['commitmentPool/poolId'];
      const loanIds = getters.allocatedLoans.map(loan => loan.id);

      try {
        const {
          // eslint-disable-next-line no-unused-vars
          results: { loan_scenarios, ...rest },
        } = await calculateScenarioMovement(organizationId, poolId, loanIds);
        commit(mutationTypes.SET_POOL_SCENARIO, rest);
      } catch (error) {
        onErrorHandler(error, 'get-pool-movement', [403], true);
      }
    },
    async saveAllocation({ dispatch, rootGetters, getters }) {
      const organizationId = rootGetters['organizations/currentOrganizationId'];
      const poolId = rootGetters['commitmentPool/poolId'];
      const loanIds = getters.allocatedLoans.map(loan => loan.id);

      try {
        await moveLoansToCommitmentPool(organizationId, poolId, loanIds);
        dispatch('setIsAllocatedLoansModalOpen', false);
        showSuccessMessage('Allocation Saved');

        dispatch('commitmentPool/getPoolData', {}, { root: true });
      } catch (error) {
        onErrorHandler(error, 'save-allocation', [403], true);
      }
    },
  },
};

import Vue from 'vue';
import Vuex, { createLogger } from 'vuex';

import cart from '@sell/store/modules/cart';
import forwards from '@sell/store/modules/forwards';
import loanDetails from '@buy/store/modules/loanDetails';
import importIssues from '@sell/store/modules/importIssues';
import commitmentPool from '@sell/store/modules/commitmentPool';
import poolBuilder from '@sell/store/modules/poolBuilder';
import loanManager from '@sell/store/modules/loanManager';
import loanUpload from '@sell/store/modules/loanUpload';
import pipeline from '@sell/store/modules/pipeline';
import testScenarios from '@devtools/store/testScenarios';
import asyncTasks from '@shared/store/modules/asyncTasks.js';
import audits from '@shared/store/modules/audits.js';
import benchmarks from '@shared/store/modules/benchmarks.js';
import core from '@shared/store/modules/core.js';
import holidays from '@shared/store/modules/holidays.js';
import operations from '@shared/store/modules/operations.js';
import organizations from '@shared/store/modules/organizations.js';
import partnerFilter from '@shared/store/modules/partnerFilter.js';
import searchValue from '@shared/store/modules/searchValue.js';
import availablePartners from '@useradmin/store/modules/availablePartners';
import notifications from '@useradmin/store/modules/notifications';
import biddingRules from '@useradmin/store/modules/biddingRules.js';
import connectedSystems from '@useradmin/store/modules/connectedSystems.js';
import eligibility from '@useradmin/store/modules/eligibility.js';
import members from '@useradmin/store/modules/members.js';
import partnerships from '@useradmin/store/modules/partnerships.js';
import adminOrganization from '@useradmin/store/modules/adminOrganization';
import exceptionManagement from '@useradmin/store/modules/exceptionManagement';
import channelDetails from '@pe/store/modules/channelDetails';
import channelList from '@pe/store/modules/channelList';
import customParameterDetails from '@pe/store/modules/customParameterDetails';
import customParametersList from '@pe/store/modules/customParametersList';
import lockDeskWorkflowPolicies from '@pe/store/modules/lockDeskWorkflowPolicies';
import loanPipeline from '@pe/store/modules/loanPipeline';
import pricing from '@pe/store/modules/pricing';
import pricingVersionDetails from '@pe/store/modules/pricingVersionDetails';
import pricingVersionsList from '@pe/store/modules/pricingVersionsList';
import productDetails from '@pe/store/modules/productDetails';
import productList from '@pe/store/modules/productList';
import ruleDetails from '@pe/store/modules/ruleDetails';
import ruleGroupDetails from '@pe/store/modules/ruleGroupDetails';
import premiumDetails from '@pe/store/modules/premiumDetails';
import ruleList from '@pe/store/modules/ruleList';
import versions from '@pe/store/modules/versions';
import lockDesk from '@pe/store/modules/lockDesk';
import lockDeskEditing from '@pe/store/modules/lockDeskEditing';
import priceExceptionReviewalStatus from '@pe/store/modules/priceExceptionReviewalStatus';
import rateAlert from '@pe/store/modules/rateAlert';
import sellLoanDetails from '@sell/store/modules/loanDetails';
import hedgeData from '@sell/store/modules/hedgeData';
import cumulAuth from '@analytics/store/modules/cumulAuth';
import cumulDashboards from '@analytics/store/modules/cumulDashboards';
import copilot from '@shared/store/modules/copilot.js';

import actions from './actions.js';
import getters from './getters.js';
import mutations from './mutations.js';

Vue.use(Vuex);

const state = {
  viewData: JSON.parse(
    document.getElementsByTagName('body')[0].getAttribute('data') || '{}',
  ),
  filterDefinition: null,
};

// change to true to enable mutation logging locally
const enableLogger = false;

export const store = {
  plugins:
    enableLogger && process.env.NODE_ENV === 'development'
      ? [createLogger()]
      : [],
  state: () => ({ ...state }),
  mutations,
  actions,
  getters,
  modules: {
    asyncTasks,
    audits,
    benchmarks,
    cart,
    core,
    exceptionManagement,
    forwards,
    holidays,
    importIssues,
    commitmentPool,
    poolBuilder,
    loanDetails,
    loanManager,
    loanUpload,
    testScenarios,
    operations,
    organizations,
    partnerFilter,
    pipeline,
    searchValue,
    availablePartners,
    notifications,
    biddingRules,
    connectedSystems,
    eligibility,
    members,
    partnerships,
    adminOrganization,
    channelDetails,
    channelList,
    customParameterDetails,
    customParametersList,
    lockDeskWorkflowPolicies,
    loanPipeline,
    pricing,
    pricingVersionDetails,
    pricingVersionsList,
    productDetails,
    productList,
    ruleDetails,
    ruleGroupDetails,
    premiumDetails,
    ruleList,
    versions,
    lockDesk,
    lockDeskEditing,
    priceExceptionReviewalStatus,
    rateAlert,
    sellLoanDetails,
    hedgeData,
    cumulAuth,
    cumulDashboards,
    copilot,
  },
};

export default new Vuex.Store(store);

//plugin for creating a fallback icon set when the font awesome kit cdn goes down.
export default {
  install: () => {
    //create the script tag for the hosted FA Kit script.
    const scriptTag = document.createElement('script');
    scriptTag.src = 'https://kit.fontawesome.com/c766cf88f8.js';
    scriptTag.setAttribute('charset', 'utf-8');
    scriptTag.setAttribute('crossorigin', 'anonymous');
    //If the script returns an error, or is unreachable, import all of the required FA styles we need.
    scriptTag.onerror = () => {
      import('@shared/assets/fontawesome/css/styles.css');
    };

    document.head.appendChild(scriptTag);
  },
};

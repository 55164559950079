import { parseFloatOrZero } from '@shared/utils/numbers';
import { ADJUSTMENT_TYPE, FORMATTED_EXCEPTION_TYPES } from '@shared/constants';
import moment from 'moment';

// Function to be called to convert the values to the correct format in order to compare and to detect changes.
export const MODIFIED_FIELD_NAME_VALUE_CONVERSION_FUNC_MAPPING = {
  apor: parseFloat,
  exceptionAmount: parseFloatOrZero,
  extensionFee: parseFloatOrZero,
  relockFee: parseFloatOrZero,
  netPrice: parseFloat,
  priceBeforeAdjustments: parseFloat,
  noteRate: parseFloat,
  exceptionType: value => FORMATTED_EXCEPTION_TYPES[value] ?? value,
  exceptionReason: str => str, // This should be a string now... so just present it.
  lockPeriod: parseInt,
  extendBy: parseInt,
  relockPeriod: parseInt,
  lenderCredit: parseFloatOrZero,
  discount: parseFloatOrZero,
  origination: parseFloatOrZero,
  allInPrice: parseFloatOrZero,
  timeLockedWithInvestor: value => {
    if (typeof value === 'string') return value;
    if (value instanceof Date) return moment(value).utc().format('HH:mm:ss');
    return value;
  },
};

// If the key isn't defined here then we assume that it can be referenced by the same name on both sides.
// If the value is a list, then it will use the first key in the list that returns a value other than null or undefined
const MODIFIED_FIELD_NAME_TO_WORKFLOW_KEY = {
  exceptionAmount: ['exceptionAmount', 'proposedException', 'workflowFee'],
  productName: 'product',
  noteRate: 'rate',
  extendBy: ['extendBy', 'daysToExtend'],
  relockFee: ['relockFee', 'workflowFee'],
  extensionFee: ['extensionFee', 'workflowFee'],
};

/**
 * Attempt to convert the fieldValue based of the fieldName determined from MODIFIED_FIELD_NAME_VALUE_CONVERSION_FUNC_MAPPING.
 * @param {String} fieldName - A field name to target.
 * @param {any} fieldValue - A value to get typed.
 * @return {any} Return the value in the expected type.
 */
export function convertFieldValue(fieldName, fieldValue) {
  if (fieldValue === null || fieldValue === undefined) return fieldValue;
  if (fieldName in MODIFIED_FIELD_NAME_VALUE_CONVERSION_FUNC_MAPPING)
    return MODIFIED_FIELD_NAME_VALUE_CONVERSION_FUNC_MAPPING[fieldName](
      fieldValue,
    );
  return fieldValue;
}

/**
 * Get the original value for the field, will attempt to see if there is a key conversion specified and will
 * use that key to access the field from the workflow data.
 * @param {String} fieldName - A field name to target.
 * @param {Object} workflowDetails - Data structure in which we lookup based off the fieldName.
 * @return {any} Retrieve a targeted value based off of the fieldName.
 */
export function getOriginalFieldValue(fieldName, workflowDetails) {
  const fieldKey = fieldName;
  let fieldValue = null;
  if (fieldName in MODIFIED_FIELD_NAME_TO_WORKFLOW_KEY) {
    const fieldKeys = MODIFIED_FIELD_NAME_TO_WORKFLOW_KEY[fieldName];
    let workflowKey = fieldKeys;
    // Loop over all possible keys and use the first one that returns a value other than null or undefined
    if (Array.isArray(fieldKeys)) {
      workflowKey = fieldKeys.find(
        key =>
          workflowDetails[key] !== null && workflowDetails[key] !== undefined,
      );
    }
    fieldValue = workflowDetails?.[workflowKey];
  } else {
    fieldValue = workflowDetails?.[fieldKey];
  }
  return convertFieldValue(fieldName, fieldValue);
}

const PRICE_TYPES = [ADJUSTMENT_TYPE.PRICE, ADJUSTMENT_TYPE.PROFITABILITY];
const RATE_TYPES = [ADJUSTMENT_TYPE.RATE];

/**
 * Determine if the supplied type is a price adjustment
 * @param {Number} type - A numerical representation of an adjustment type
 * @return {Boolean} true or false
 */
export function isPriceAdjustment(type) {
  return PRICE_TYPES.includes(type);
}

/**
 * Determine if the supplied type is a rate adjustment
 * @param {Number} type - A numerical representation of an adjustment type
 * @return {Boolean} true or false
 */
export function isRateAdjustment(type) {
  return RATE_TYPES.includes(type);
}

export default {
  convertFieldValue,
  getOriginalFieldValue,
  isPriceAdjustment,
  isRateAdjustment,
};

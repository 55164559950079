import api from '@shared/services/api.js';
import {
  CONNECTED_SYSTEMS_DISPLAY_NAMES,
  CONNECTED_SYSTEMS_DISPLAY_TYPES,
} from '@shared/constants';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';

const state = {
  connectedSystems: {
    isLoading: true,
    data: {
      active: [],
    },
  },
  currentConnectedSystem: {
    isLoading: true,
    data: null,
  },
  externalSystems: {
    isLoading: true,
    isOptionsLoading: true,
    data: {
      systems: [],
      options: [],
      info: {},
    },
  },
};

const getters = {
  connectedSystems: state => {
    return state.connectedSystems || [];
  },
  currentConnectedSystem: state => {
    return state.currentConnectedSystem || {};
  },
  externalSystems: state => {
    return state.externalSystems || [];
  },
};

const mutations = {
  setConnectedSystems: (state, payload) => {
    state.connectedSystems = payload;
  },
  setCurrentConnectedSystem: (state, payload) => {
    state.currentConnectedSystem = payload;
  },
  setExternalSystems: (state, payload) => {
    state.externalSystems = payload;
  },
};

const actions = {
  addConnectedSystem: async ({ commit }, payload) => {
    commit('setCurrentConnectedSystem', {
      data: null,
      isLoading: true,
    });

    let response = null;
    try {
      response = await api.post('/company/api/connected_systems', payload);
      return response.id;
    } catch (error) {
      commit('setCurrentConnectedSystem', {
        isLoading: false,
        data: null,
      });
      onErrorHandler(error, 'useradmin-add-new-system');
    }
  },
  getCurrentConnectedSystem: async ({ commit }, payload) => {
    commit('setCurrentConnectedSystem', {
      data: null,
      isLoading: true,
    });

    let response = null;

    try {
      if (payload) {
        response = await api.get(`/company/api/connected_systems/${payload}`);

        const currentSystem = response.system;

        commit('setCurrentConnectedSystem', {
          isLoading: false,
          data: {
            ...currentSystem,
            displayName:
              CONNECTED_SYSTEMS_DISPLAY_NAMES[currentSystem.name] ||
              currentSystem.name,
            displayType: CONNECTED_SYSTEMS_DISPLAY_TYPES[currentSystem.type],
          },
        });
      } else {
        commit('setCurrentConnectedSystem', {
          isLoading: false,
          data: null,
        });
      }
    } catch (error) {
      commit('setCurrentConnectedSystem', {
        isLoading: false,
        data: null,
      });
      onErrorHandler(error, 'get-current-connected-system', [403, 404]);
    }
  },
  getExternalSystems: async ({ commit }) => {
    commit('setExternalSystems', { isOptionsLoading: true });

    try {
      const externalSystems = await api.get('/company/api/external_systems');

      const systemsOptions = Object.values(externalSystems.systems);

      commit('setExternalSystems', {
        data: { systems: externalSystems.systems, options: systemsOptions },
        isOptionsLoading: false,
      });
    } catch (error) {
      onErrorHandler(error, 'useradmin-get-external-systems');
    }
  },
  getConnectedSystems: async ({ commit }) => {
    commit('setConnectedSystems', { isLoading: true });

    try {
      const connectedSystems = await api.get('/company/api/connected_systems');

      const active = connectedSystems.systems.map(system => {
        return {
          ...system,
          displayName:
            CONNECTED_SYSTEMS_DISPLAY_NAMES[system.name] || system.name,
          displayType: CONNECTED_SYSTEMS_DISPLAY_TYPES[system.type],
        };
      });

      commit('setConnectedSystems', {
        isLoading: false,
        data: { active },
      });
    } catch (error) {
      onErrorHandler(error, 'useradmin-get-connected-systems');
    }
  },
  saveCurrentConnectedSystem: async ({ commit, state }) => {
    commit('setCurrentConnectedSystem', {
      ...state.currentConnectedSystem,
    });

    let response = null;

    try {
      const { account_info, ...rest } = state.currentConnectedSystem.data;

      const body = {
        ...rest,
        info: account_info,
      };

      delete body.system_info;

      response = await api.post(
        `/company/api/connected_systems/${state.currentConnectedSystem.data.id}`,
        body,
      );

      const currentSystem = response.system;

      commit('setCurrentConnectedSystem', {
        data: {
          ...currentSystem,
          displayName:
            CONNECTED_SYSTEMS_DISPLAY_NAMES[currentSystem.name] ||
            currentSystem.name,
          displayType: CONNECTED_SYSTEMS_DISPLAY_TYPES[currentSystem.type],
        },
      });
    } catch (error) {
      commit('setCurrentConnectedSystem', {
        ...state,
      });
      onErrorHandler(error, 'save-current-connected-system', [403, 404]);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import add from 'date-fns/add';
import sub from 'date-fns/sub';
import differenceInDays from 'date-fns/differenceInDays';
import startOfToday from 'date-fns/startOfToday';
import endOfToday from 'date-fns/endOfToday';
import isBefore from 'date-fns/isBefore';
import parseISO from 'date-fns/parseISO';
import startOfISOWeek from 'date-fns/startOfISOWeek';
import { isSameDay } from 'date-fns';
import isWeekend from 'date-fns/isWeekend';
import { zonedTimeToUtc } from 'date-fns-tz';
import startOfDay from 'date-fns/startOfDay';

export function getNextBusinessDate(selectedDate, holidays = []) {
  let date = new Date(selectedDate);
  while (isWeekend(date) || isDateHoliday(date, holidays)) {
    date = add(date, { days: 1 });
  }
  return date;
}

export function getPrevBusinessDate(selectedDate, holidays = []) {
  let date = new Date(selectedDate);
  while (isWeekend(date) || isDateHoliday(date, holidays)) {
    date = sub(date, { days: 1 });
  }
  return date;
}

const USEasternEndOfBusinessDayHoursOffset = 17;
const USEasternTimezoneName = 'US/Eastern';
/**
 * Gets the date corresponding 5 PM US/Eastern for the same date
 * @param {Date | Number} date incoming date
 * @returns {Date}
 */
const getEndOfBusinessDay = date => {
  const zonedStartOfDay = zonedTimeToUtc(
    startOfDay(date),
    USEasternTimezoneName,
  );
  return add(zonedStartOfDay, {
    hours: USEasternEndOfBusinessDayHoursOffset,
  });
};

/**
 * Gets the closest upcoming end of business day (5 PM US/Eastern)
 * If now is after the end of business day today,
 * then the end of next business day will be returned
 * @returns {Date}
 */
export const getNextEndOfBusinessDay = date => {
  date = date || new Date();
  // test if day before is valid day due to timezone offsets
  const dayBefore = add(date, { days: -1 });
  const zonedEndOfBusinessDayYesterday = getEndOfBusinessDay(dayBefore);
  if (zonedEndOfBusinessDayYesterday > date) {
    return zonedEndOfBusinessDayYesterday;
  }
  // otherwise test if today is valid
  const zonedEndOfBusinessDayToday = getEndOfBusinessDay(date);
  if (zonedEndOfBusinessDayToday > date) {
    return zonedEndOfBusinessDayToday;
  }
  // get next business day end calculate end of business day
  const dayAfter = add(date, { days: 1 });
  const nextZonedEndOfBusinessDay = getNextBusinessDate(dayAfter);
  return getEndOfBusinessDay(nextZonedEndOfBusinessDay);
};

export const getNextEndOfBusinessDayISOString = date =>
  getNextEndOfBusinessDay(date).toISOString();

export function isDateHoliday(selectedDate, holidays) {
  return holidays?.some(
    holiday => holiday.toDateString() === selectedDate.toDateString(),
  );
}

/**
 * Returns a locale-based datetime string given a date string or Date instance.
 * @param datetime {string|Date} A Date instance or a datetime string.
 * @returns {string} A datetime string
 */
export function getLocalDateTimeString(datetime) {
  let date = datetime;
  if (!(date instanceof Date)) {
    date = new Date(datetime);
  }
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
}

/**
 * Returns a UTC offset from timezone in hours.
 * @param timeZone {string} A timezone name.
 * @returns {int} UTC offset
 */
export function getUTCOffsetHours(timeZone = 'UTC') {
  const date = new Date();

  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
  return (tzDate.getTime() - utcDate.getTime()) / 6e4 / 60;
}

/**
 * returns the difference in days between two dates using UTC time, so DST doesn't
 * effect the end date difference.
 * @param date1 {String|Date} the later date
 * @param date2 {String|Date} the earlier date
 * @returns {Number} number of dates
 */
export function getUTCDifferenceInDays(date1, date2) {
  if (!date1 || !date2) return 0;

  if (!(date1 instanceof Date)) {
    date1 = new Date(date1);
  }
  if (!(date2 instanceof Date)) {
    date2 = new Date(date2);
  }

  const utcDate1 = Date.UTC(
    date1.getUTCFullYear(),
    date1.getUTCMonth(),
    date1.getUTCDate(),
  );
  const utcDate2 = Date.UTC(
    date2.getUTCFullYear(),
    date2.getUTCMonth(),
    date2.getUTCDate(),
  );

  return Math.abs((utcDate1 - utcDate2) / (1000 * 3600 * 24));
}

export const getTimeParts = dateTime => {
  const time = dateTime.toLocaleString('en-US', {
    timeZone: 'UTC',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
  return time.split(/:|\s/);
};

export {
  add,
  sub,
  differenceInDays,
  startOfToday,
  endOfToday,
  isBefore,
  parseISO,
  startOfISOWeek,
  isSameDay,
  startOfDay,
};

import {
  SET_CHANNEL,
  SET_IS_FORM_VALID,
  SET_IS_INVALID_NAME,
  SET_IS_READ_ONLY_ITEM,
  SET_IS_UPDATE_NEEDED,
} from './mutationTypes.js';

export default {
  namespaced: true,
  state: {
    channel: null,
    isFormValid: true,
    isInvalidName: false,
    isReadOnlyItem: true,
    isUpdateNeeded: true,
  },
  getters: {
    channel: state => state.channel,
    isFormValid: state => state.isFormValid,
    isInvalidName: state => state.isInvalidName,
    isReadOnlyItem: state => state.isReadOnlyItem,
    isUpdateNeeded: state => state.isUpdateNeeded,
  },
  mutations: {
    [SET_CHANNEL](state, channel) {
      state.channel = channel;
    },
    [SET_IS_FORM_VALID](state, isFormValid) {
      state.isFormValid = isFormValid;
    },
    [SET_IS_INVALID_NAME](state, isInvalidName) {
      state.isInvalidName = isInvalidName;
    },
    [SET_IS_READ_ONLY_ITEM](state, isReadOnlyItem) {
      state.isReadOnlyItem = isReadOnlyItem;
    },
    [SET_IS_UPDATE_NEEDED](state, isUpdateNeeded) {
      state.isUpdateNeeded = isUpdateNeeded;
    },
  },
  actions: {
    setChannel({ commit }, channel) {
      commit(SET_CHANNEL, channel);
    },
    setIsFormValid({ commit }, isFormValid) {
      commit(SET_IS_FORM_VALID, isFormValid);
    },
    setIsInvalidName({ commit }, isInvalidName) {
      commit(SET_IS_INVALID_NAME, isInvalidName);
    },
    setIsReadOnlyItem({ commit }, isReadOnlyItem) {
      commit(SET_IS_READ_ONLY_ITEM, isReadOnlyItem);
    },
    setIsUpdateNeeded({ commit }, isUpdateNeeded) {
      commit(SET_IS_UPDATE_NEEDED, isUpdateNeeded);
    },
  },
};

import Vue from 'vue';
import {
  GET_ASYNC_TASK_INTERVAL,
  GET_ASYNC_TASK_INTERVAL_SHORT,
  GET_ASYNC_TASK_MAX_ATTEMPTS,
} from '@shared/constants';
import { getAsyncTask } from '@shared/services/coreApi.js';
import { downloadBlob, getFileExtension } from '@shared/utils';
import { showErrorMessage } from '@shared/utils/notifier.js';
import api from '@shared/services/api.js';

const asyncTasksState = {
  asyncTasks: {},
  cancellationToken: null,
};

const getters = {
  asyncTasks: state => state.asyncTasks,
};

// Wraps setInterval check task into Promise and return it
function getIntervalPromise({ commit, state }, { taskId, isShort, isGroup }) {
  let attemptsCount = 0;
  return new Promise((resolve, reject) => {
    const intervalId = setInterval(
      async () => {
        try {
          // Limit checking async task by max attempts count
          attemptsCount++;
          if (attemptsCount > GET_ASYNC_TASK_MAX_ATTEMPTS) {
            commit('clearAsyncTask', taskId);
            reject();
          }
          const taskResult = await getAsyncTask(
            { taskId, isGroup },
            {
              cancelToken: state.cancellationToken,
            },
          );
          if (taskResult.task_ready) {
            commit('clearAsyncTask', taskId);
            resolve(taskResult);
          }
        } catch (e) {
          commit('clearAsyncTask', taskId);
          reject(e);
        }
      },
      isShort ? GET_ASYNC_TASK_INTERVAL_SHORT : GET_ASYNC_TASK_INTERVAL,
    );
    commit('setAsyncTask', { intervalId, taskId });
  });
}

const mutations = {
  setCancellationToken(state, token) {
    state.cancellationToken = token;
  },
  setAsyncTask(state, { intervalId, taskId }) {
    state.asyncTasks[taskId] = intervalId;
  },
  clearAsyncTask(state, taskId) {
    clearInterval(state.asyncTasks[taskId]);
    Vue.delete(state.asyncTasks, taskId);
  },
};

const actions = {
  setCancellationToken({ commit }, token) {
    return commit('setCancellationToken', token);
  },
  getTaskResult({ commit, state }, taskData) {
    if (state.asyncTasks[taskData.taskId]) {
      // if interval already exists, clear it before recreating promise
      commit('clearAsyncTask', taskData.taskId);
    }
    return getIntervalPromise({ commit, state }, taskData);
  },

  async getStreamData({ dispatch, rootGetters }, { taskId, fileName }) {
    const taskResult = await dispatch('getTaskResult', { taskId });
    if (!taskResult.task_ready || !taskResult.task_successful) {
      showErrorMessage('Failed to get task result');
      return;
    }

    const { content_hash, cache_event_prefix } = taskResult.task_result;
    const fileExt = getFileExtension(fileName);
    const response = await api.getBlob(
      api.constructUrl(
        `/api/v1/organizations/${rootGetters['organizations/currentOrganizationId']}/stream_data/`,
        {
          file_hash: content_hash,
          cache_event_prefix,
          file_ext: fileExt,
        },
      ),
    );

    await downloadBlob(fileName, response.data);
  },
};

export default {
  namespaced: true,
  state: asyncTasksState,
  getters,
  mutations,
  actions,
};

import {
  SET_ACTIVE_ID,
  SET_IS_CONFIGURATION_LOADING,
  SET_SEARCH_TEXT,
} from './mutationTypes.js';

export default {
  namespaced: true,
  state: {
    activeId: null,
    isConfigurationLoading: false,
    searchText: null,
  },
  getters: {
    activeId: state => state.activeId,
    isConfigurationLoading: state => state.isConfigurationLoading,
    searchText: state => state.searchText,
  },
  mutations: {
    [SET_ACTIVE_ID](state, activeId) {
      state.activeId = activeId;
    },
    [SET_IS_CONFIGURATION_LOADING](state, isConfigurationLoading) {
      state.isConfigurationLoading = isConfigurationLoading;
    },
    [SET_SEARCH_TEXT](state, searchText) {
      state.searchText = searchText;
    },
  },
  actions: {
    setActiveId({ commit }, activeId) {
      commit(SET_ACTIVE_ID, activeId);
    },
    setIsConfigurationLoading({ commit }, isConfigurationLoading) {
      commit(SET_IS_CONFIGURATION_LOADING, isConfigurationLoading);
    },
    setSearchText({ commit }, searchText) {
      commit(SET_SEARCH_TEXT, searchText);
    },
  },
};

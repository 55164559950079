import api from '@shared/services/api.js';
const baseApi = '/company/api';
const baseUserUrl = `${baseApi}/users`;

export const getInvitations = () => {
  return api.get(`${baseApi}/invitations/`);
};

export const inviteMember = (email, roles, channels, customParameters) => {
  return api.post(`${baseApi}/invitations/`, {
    email,
    roles,
    channels,
    customParameters,
  });
};

export const deleteInvite = (userId, inviteId) => {
  return api.delete(`${baseUserUrl}/${userId}/invitations/`, {
    invitation_id: inviteId,
  });
};

export const resendInvite = (userId, inviteId) => {
  return api.post(`${baseUserUrl}/${userId}/invitations/`, {
    invitation_id: inviteId,
  });
};

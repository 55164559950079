<template>
  <div class="px-table-pagination mt-md-3 form-override">
    <div
      v-if="showPageSizeSelector"
      key="page-size-selector"
      class="page-size-select-container position-relative"
    >
      <px-select2
        class="page-size-selector mr-1"
        :options="pageOptions"
        :settings="{
          width: '40px',
          dropdownCssClass: 'lx-page-size-options',
        }"
        size="sm"
        :value="pageSize"
        @input="pageSizeChanged"
      />
      <span class="page-size-label">per page</span>
    </div>
    <div
      class="d-flex justify-content-between align-items-center pag-indices"
      :class="{
        'ml-auto': allowSelectPageSize,
        'm-auto': !allowSelectPageSize,
      }"
    >
      <div v-if="showArrows" key="left-arrows" class="side-arrows mr-0 mr-sm-3">
        <transition name="fade">
          <b-button
            v-show="showFirst"
            key="first"
            class="btn-short"
            data-testid="first-page-btn"
            title="Go to first page"
            variant="link"
            @click="setFirstPage"
          >
            <px-icon class="pag-icon rotated" icon="chevrons-right" />
          </b-button>
        </transition>
        <transition name="fade">
          <b-button
            v-show="showPrev"
            key="prev"
            class="btn-short"
            data-testid="prev-page-btn"
            title="Go to previous page"
            variant="link"
            @click="setPrevPage"
          >
            <px-icon class="pag-icon rotated" icon="chevron-right" />
          </b-button>
        </transition>
      </div>
      <span class="pag-label">
        {{ paginationLabel }}
      </span>
      <div
        v-if="showArrows"
        key="right-arrows"
        class="side-arrows d-flex flex-row-reverse ml-0 ml-sm-3"
      >
        <transition name="fade">
          <b-button
            v-show="showLast"
            key="last"
            class="btn-short"
            data-testid="last-page-btn"
            title="Go to last page"
            variant="link"
            @click="setLastPage"
          >
            <px-icon class="pag-icon" icon="chevrons-right" />
          </b-button>
        </transition>
        <transition name="fade">
          <b-button
            v-show="showNext"
            key="next"
            class="btn-short"
            data-testid="next-page-btn"
            title="Go to next page"
            variant="link"
            @click="setNextPage"
          >
            <px-icon class="pag-icon" icon="chevron-right" />
          </b-button>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { DEFAULT_PAGE_OPTIONS } from '@shared/constants';
import Pluralize from 'pluralize';

export default {
  name: 'PxTablePagination',
  props: {
    total: { type: Number, default: 0 },
    pageIndex: { type: Number, default: 1 },
    pageSize: { type: Number, default: 25 },
    allowSelectPageSize: { type: Boolean, default: true },
    pageOptions: { type: Array, default: () => DEFAULT_PAGE_OPTIONS },
  },
  computed: {
    showFirst() {
      return this.pageIndex > 1;
    },
    showPrev() {
      return this.pageIndex > 1;
    },
    showNext() {
      return this.pageIndex < this.maxPageIndex;
    },
    showLast() {
      return this.pageIndex < this.maxPageIndex;
    },
    maxPageIndex() {
      let maxPageIndex = Math.floor(this.total / this.pageSize);
      if (this.total % this.pageSize > 0) {
        // there is a remaining items
        maxPageIndex++;
      }
      return maxPageIndex;
    },
    showPageSizeSelector() {
      const minimalPageSizeOption = Math.min.apply(null, [
        ...this.pageOptions.map(o => o.id),
        this.pageSize,
      ]);
      return this.allowSelectPageSize && this.total > minimalPageSizeOption;
    },
    showArrows() {
      return this.total > this.pageSize;
    },
    paginationLabel() {
      if (this.total === 0) {
        return '';
      }
      if (!this.showArrows) {
        return `${this.total} ${Pluralize('item', this.total)}`;
      }
      const startItemNumber = (this.pageIndex - 1) * this.pageSize + 1;
      const lastItemNumber =
        this.pageIndex * this.pageSize > this.total
          ? this.total
          : this.pageIndex * this.pageSize;
      return `${startItemNumber} - ${lastItemNumber} of ${this.total} items`;
    },
  },
  methods: {
    setFirstPage() {
      this.$emit('change', { pageIndex: 1 });
    },
    setPrevPage() {
      if (this.pageIndex > 1) {
        this.$emit('change', { pageIndex: this.pageIndex - 1 });
      }
    },
    setNextPage() {
      if (this.pageIndex < this.maxPageIndex) {
        this.$emit('change', { pageIndex: this.pageIndex + 1 });
      }
    },
    setLastPage() {
      if (this.pageIndex < this.maxPageIndex) {
        this.$emit('change', { pageIndex: this.maxPageIndex });
      }
    },
    pageSizeChanged(pageSize) {
      this.$emit('change', { pageSize: Number(pageSize) });
    },
  },
};
</script>

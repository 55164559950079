import api from '@shared/services/api';
import { onErrorHandler } from '@shared/utils/errorHandlers';

export async function getPricingScenarioUserConfig(organizationId) {
  try {
    const userConfigData = await api.get(
      `/api/pe/organization/${organizationId}/pricing-scenario-user-configurations/user-default-scenario/`,
    );
    return userConfigData;
  } catch (e) {
    if (e.message.includes('404')) {
      // user does not have scenario config set up yet
      return null;
    }
    onErrorHandler(e, 'get-user-default-scenario');
  }
}

export async function createPricingScenarioUserConfig(
  scenarioId,
  organizationId,
) {
  try {
    return await api.postJson(
      `/api/pe/organization/${organizationId}/pricing-scenario-user-configurations/`,
      {
        default_scenario: scenarioId,
      },
    );
  } catch (e) {
    onErrorHandler(e, 'create-user-default-scenario');
  }
}

export async function getPricingScenarios(organizationId, options) {
  let url = `/api/v1/organizations/${organizationId}/custom_pricing_scenarios/`;

  url = api.constructUrl(url, options);
  try {
    return await api.get(url);
  } catch (e) {
    onErrorHandler(e, 'get-custom-scenarios');
  }
}

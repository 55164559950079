export default {
  namespaced: true,
  state: {
    searchText: null,
  },
  getters: {
    searchText: state => state.searchText,
  },
  mutations: {
    mutateSearchText(state, searchText) {
      state.searchText = searchText;
    },
  },
  actions: {
    setSearchText({ commit }, searchText) {
      commit('mutateSearchText', searchText);
    },
  },
};

export const SET_BID_DUE_DATE_TEXT = 'SET_BID_DUE_DATE_TEXT';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const SET_TABLE_OPTIONS_OBJECT = 'SET_TABLE_OPTIONS_OBJECT';
export const SET_LOAN_OBJECT = 'SET_LOAN_OBJECT';
export const SET_IS_LOADING_STATE = 'SET_IS_LOADING_STATE';
export const SET_SHOW_ARCHIVED_STATE = 'SET_SHOW_ARCHIVED_STATE';
export const SET_IS_ARCHIVING_STATE = 'SET_IS_ARCHIVING_STATE';
export const SET_IS_RESTORING_STATE = 'SET_IS_RESTORING_STATE';
export const SET_TOTAL_AVAILABLE_LOANS = 'SET_TOTAL_AVAILABLE_LOANS';
export const SET_TABLE_OPTIONS_TOTAL = 'SET_TABLE_OPTIONS_TOTAL';
export const SET_IS_ADVANCED_FILTER_OPEN = 'SET_IS_ADVANCED_FILTER_OPEN';
export const SET_FILTER_LOAN_NUMBERS = 'SET_FILTER_LOAN_NUMBERS';
export const SET_APPLIED_FILTER_LOAN_NUMBERS =
  'SET_APPLIED_FILTER_LOAN_NUMBERS';

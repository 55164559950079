export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export function instantiateProductRates(products) {
  const productRates = [];
  let currentRateId = 0;
  products.forEach(product => {
    product.prices.forEach(price => {
      productRates.push({
        rateId: currentRateId,
        product: product.name,
        rate: price.rate,
        price: price.netPrice,
        principalAndInterest: price.principalAndInterest,
        creditCost: price.rebate,
        lockPeriod: price.lockPeriod,
      });
      currentRateId++;
    });
  });
  return productRates;
}

export function getRatesFromRateIds(initialSelectionResults, parsedRates) {
  // Create a map of rateIds to rates for easy lookup
  const rateIdToRateMap = parsedRates.reduce((acc, rate) => {
    acc[rate.rateId] = rate;
    return acc;
  }, {});

  // Map each array of rateIds in initialSelectionResults to an array of rates
  let rates = initialSelectionResults.map(rateIds =>
    JSON.parse(rateIds).map(rateId => {
      // Check if rateId exists in the map
      if (!rateIdToRateMap.hasOwnProperty(rateId)) {
        return null;
      }
      return rateIdToRateMap[rateId];
    }),
  );

  // Filter out any null values, flatten the array and sort it
  rates = rates.flat().filter(rate => rate !== null);
  rates = sortRatesByRate(rates);

  return rates;
}

export function sortRatesByRate(rates) {
  return rates.sort((a, b) => parseFloat(a.rate) - parseFloat(b.rate));
}

export function formatRateStackSearchResultsString(
  results,
  selectedColumns = [1, 2, 3, 4, 5, 6],
) {
  const headers = {
    '1': 'Product',
    '2': 'Rate (Percent)',
    '3': 'Price (Points)',
    '4': 'Principal And Interest (Dollars)',
    '5': 'Credit/Cost (Dollars)',
    '6': 'Lock Period (Days)',
  };

  const selectedHeaders = selectedColumns
    .filter(column => headers.hasOwnProperty(column))
    .map(column => headers[column]);
  selectedHeaders.unshift('Rate ID');

  const extractors = [
    result => result.rateId,
    result => result.product,
    result => result.rate,
    result => result.price,
    result => result.principalAndInterest.toFixed(2),
    result => result.creditCost.toFixed(2),
    result => result.lockPeriod,
  ];

  const rateOptionInfo = results.map(result => {
    const currentRow = selectedColumns.map(column =>
      extractors[column](result),
    );
    currentRow.unshift(result.rateId);
    return currentRow;
  });

  let csvContent = selectedHeaders.join(',') + '\n';
  rateOptionInfo.forEach(item => {
    csvContent += item.join(',') + '\n';
  });

  return csvContent;
}

function determinGroupSize(selectedRates) {
  return Math.max(15, Math.min(10, Math.ceil(selectedRates.length / 2)));
}

export function groupRates(selectedRates) {
  const groupSize = determinGroupSize(selectedRates);
  const groups = Array.from(
    { length: Math.ceil(selectedRates.length / groupSize) },
    (v, i) => selectedRates.slice(i * groupSize, i * groupSize + groupSize),
  );

  // If the last group is less than 5, merge it one item at a time with each of the other groups until its empty, its too small to anaylze
  if (groups[groups.length - 1].length < 5) {
    const lastGroup = groups.pop();
    let groupIndex = 0;
    while (lastGroup.length > 0) {
      groups[groupIndex % groups.length].push(lastGroup.pop());
      groupIndex++;
    }
  }

  return groups;
}

export function determineMatchCount(groups) {
  // Appropriately size the match count based on the number of groups so the final selection round falls in the range of 10-15
  let matchCount = 2;
  if (groups.length == 1) {
    matchCount = 10;
  } else if (groups.length <= 3) {
    matchCount = 5;
  } else if (groups.length <= 5) {
    matchCount = 3;
  } else if (groups.length <= 7) {
    matchCount = 2;
  } else if (groups.length <= 10) {
    matchCount = 1;
  }

  return matchCount;
}

export const rateStackGlossary = JSON.stringify({
  GLOSSARY: {
    Rate: 'The interest rate percentage for the loan product.',
    'Credit/Cost':
      'A negative number indicates a credit, rebate, or money that is being paid to the borrower. A positive number indicates a cost, or money that is being paid by the borrower. With that in mind, a credit or rebate thats the highest is the lowest CREDIT/COST. A credit or rebate thats greater than, more than, above, at least, higher than x, is actually a CREDIT/COST below x. Inversely, the lowest credit or rebate translates to the highest CREDIT/COST.',
    'Principal and Interest':
      'The principal and interest for the loan product. This equates to the monthly payment. It can also be referred to as P&I',
    'Lock Period': 'The lock period for the loan product in days.',
    Price:
      'The price for the loan product in points (which correspond to percent). Par price is typically defined as 100 points. When someone is referencing points, they are typically referring to price. There are 100 basis points in 1 point. All basis points should be converted to points for consistency. The PRICE can be discounted. The discount is the number of percentage points the current price is away from the PAR price. For example, a price of 88.375 has a discount of 11.625 (88.375 + 11.625 = 100). A price of 100.75 has a discount of -0.75',
    Product: 'The name of the loan product.',
  },
});

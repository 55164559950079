import api from '@shared/services/api.js';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';

const state = {
  gainlosses: null,
  gainLossCalculationInProgress: false,
};

const getters = {
  gainlosses: state => state.gainlosses || {},
  gainLossCalculationInProgress: state => state.gainLossCalculationInProgress,
};

const mutations = {
  setGainlosses(state, gainloses) {
    state.gainlosses = {
      ...(state.gainlosses || {}),
      ...gainloses,
    };
  },
  startGainLossCalculation(state) {
    state.gainLossCalculationInProgress = true;
  },
  finishGainLossCalculation(state) {
    state.gainLossCalculationInProgress = false;
  },
  stopGainLossCalculation(state) {
    state.gainloses = null;
    state.gainLossCalculationInProgress = false;
  },
};

const actions = {
  async calculateGainLoss({ commit, dispatch }, targetBidIds = []) {
    try {
      commit('startGainLossCalculation');
      const api_url = '/sell/api/calculate_forward_gainlosses';
      const { task_id } = await api.post(api_url, { bid_ids: targetBidIds });
      const task = await dispatch(
        'asyncTasks/getTaskResult',
        { taskId: task_id },
        { root: true },
      );
      if (task.task_successful) {
        commit('setGainlosses', task.task_result);
      }
    } catch (error) {
      onErrorHandler(error, 'sell-forwards-populateForwardGainLosses');
    } finally {
      commit('finishGainLossCalculation');
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

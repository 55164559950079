import api from '@shared/services/api';
import { insertCustomParametersValues } from '@pe/components/Pricing/defaults.js';

export const saveScenario = async (
  scenarioName,
  pe3CustomRequest,
  customParameters,
  organizationId,
) => {
  // custom parameters are actually not part of the template, so merge them in here
  insertCustomParametersValues(
    Object.values(customParameters || {}),
    pe3CustomRequest,
  );
  const payload = {
    scenario: {
      name: scenarioName,
    },
    pe3_custom_request: pe3CustomRequest,
  };
  return await api.postJson(
    `/api/v1/organizations/${organizationId}/custom_pricing_scenarios/`,
    payload,
  );
};

export const getScenarios = async (
  organizationId,
  tableOptions,
  searchText = '',
) => {
  let url = `/api/v1/organizations/${organizationId}/custom_pricing_scenarios/`;

  if (searchText) {
    tableOptions.page = 1;
  }

  url = api.constructUrl(url, tableOptions);
  return await api.get(url);
};

import {
  SET_IS_INVALID,
  SET_IS_INVALID_CODE,
  SET_IS_INVALID_NAME,
  SET_IS_NEW_ITEM,
  SET_IS_PRODUCT_OPTIONS_LOADING,
  SET_IS_READ_ONLY_ITEM,
  SET_PRODUCT,
  CLEAR_PRODUCT_DETAILS,
  SET_IS_DYNAMICALLY_PRICED,
  SET_AVAILABLE_INVESTOR_PRODUCTS,
  SET_AVAILABLE_SOURCE_PRODUCTS,
  SET_IS_SOURCE_PRODUCTS_LOADING,
  SET_DYNAMIC_RULES,
} from './mutationTypes.js';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import {
  getAvailableInvestorsProducts,
  getAvailableSourceProducts,
  getDynamicRules,
} from '@pe/services/configurations.js';

function initialState() {
  return {
    isInvalid: false,
    isInvalidCode: false,
    isInvalidName: false,
    isNewItem: false,
    isProductOptionsLoading: true,
    isReadOnlyItem: true,
    product: null,
    isDynamicallyPriced: false,
    availableInvestorProducts: [],
    availableSourceProducts: [],
    isSourceProductsLoading: false,
    dynamicRules: [],
  };
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    isInvalid: state => state.isInvalid,
    isInvalidCode: state => state.isInvalidCode,
    isInvalidName: state => state.isInvalidName,
    isNewItem: state => state.isNewItem,
    isProductOptionsLoading: state => state.isProductOptionsLoading,
    isReadOnlyItem: state => state.isReadOnlyItem,
    product: state => state.product,
    isDynamicallyPriced: state => state.isDynamicallyPriced,
    availableInvestorProducts: state => state.availableInvestorProducts,
    availableSourceProducts: state => state.availableSourceProducts,
    isSourceProductsLoading: state => state.isSourceProductsLoading,
    dynamicRules: state => state.dynamicRules,
  },
  mutations: {
    [CLEAR_PRODUCT_DETAILS](state) {
      Object.assign(state, initialState());
    },
    [SET_IS_INVALID](state, isInvalid) {
      state.isInvalid = isInvalid;
    },
    [SET_IS_INVALID_CODE](state, isInvalidCode) {
      state.isInvalidCode = isInvalidCode;
    },
    [SET_IS_INVALID_NAME](state, isInvalidName) {
      state.isInvalidName = isInvalidName;
    },
    [SET_IS_NEW_ITEM](state, isNewItem) {
      state.isNewItem = isNewItem;
    },
    [SET_IS_PRODUCT_OPTIONS_LOADING](state, payload) {
      state.isProductOptionsLoading = payload;
    },
    [SET_IS_READ_ONLY_ITEM](state, isReadOnlyItem) {
      state.isReadOnlyItem = isReadOnlyItem;
    },
    [SET_PRODUCT](state, product) {
      state.product = product;
    },
    [SET_IS_DYNAMICALLY_PRICED](state, isDynamicallyPriced) {
      state.isDynamicallyPriced = isDynamicallyPriced;
    },
    [SET_AVAILABLE_INVESTOR_PRODUCTS](state, availableInvestorProducts) {
      state.availableInvestorProducts = availableInvestorProducts;
    },
    [SET_AVAILABLE_SOURCE_PRODUCTS](state, availableSourceProducts) {
      state.availableSourceProducts = availableSourceProducts;
    },
    [SET_IS_SOURCE_PRODUCTS_LOADING](state, isLoading) {
      state.isSourceProductsLoading = isLoading;
    },
    [SET_DYNAMIC_RULES](state, dynamicRules) {
      state.dynamicRules = dynamicRules;
    },
  },
  actions: {
    clearProductDetails({ commit }) {
      commit(CLEAR_PRODUCT_DETAILS, initialState());
    },
    setIsInvalid({ commit }, isInvalid) {
      commit(SET_IS_INVALID, isInvalid);
    },
    setIsInvalidCode({ commit }, isInvalidCode) {
      commit(SET_IS_INVALID_CODE, isInvalidCode);
    },
    setIsInvalidName({ commit }, isInvalidName) {
      commit(SET_IS_INVALID_NAME, isInvalidName);
    },
    setIsNewItem({ commit }, isNewItem) {
      commit(SET_IS_NEW_ITEM, isNewItem);
    },
    setIsProductOptionsLoading({ commit }, payload) {
      commit(SET_IS_PRODUCT_OPTIONS_LOADING, payload);
    },
    setIsReadOnlyItem({ commit }, isReadOnlyItem) {
      commit(SET_IS_READ_ONLY_ITEM, isReadOnlyItem);
    },
    setProduct({ commit }, product) {
      commit(SET_PRODUCT, product);
    },
    setIsDynamicallyPriced({ commit }, isDynamicallyPriced) {
      commit(SET_IS_DYNAMICALLY_PRICED, isDynamicallyPriced);
    },
    setIsSourceProductsLoading({ commit }, isLoading) {
      commit(SET_IS_SOURCE_PRODUCTS_LOADING, isLoading);
    },
    async getAvailableInvestorProducts({ commit }) {
      try {
        const availableInvestorProducts = await getAvailableInvestorsProducts();
        commit(SET_AVAILABLE_INVESTOR_PRODUCTS, availableInvestorProducts);
      } catch (error) {
        onErrorHandler(error, 'pe-get-available-investor-products');
        commit(SET_AVAILABLE_INVESTOR_PRODUCTS, []);
      }
    },
    async getAvailableSourceProducts({ commit }) {
      try {
        const availableSourceProducts = await getAvailableSourceProducts();
        commit(SET_AVAILABLE_SOURCE_PRODUCTS, availableSourceProducts);
      } catch (error) {
        onErrorHandler(error, 'pe-get-available-source-products');
        commit(SET_AVAILABLE_SOURCE_PRODUCTS, []);
      }
    },
    async setDynamicRules({ commit }, payload) {
      try {
        const dynamicRules = await getDynamicRules(
          payload.configId,
          payload.productId,
        );
        commit(SET_DYNAMIC_RULES, dynamicRules);
      } catch (error) {
        onErrorHandler(error, 'pe-set-dynamic-rules');
        commit(SET_DYNAMIC_RULES, []);
      }
    },
    resetDynamicRules({ commit }) {
      commit(SET_DYNAMIC_RULES, []);
    },
  },
};

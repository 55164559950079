export default {
  methods: {
    paginationChanged({ pageIndex }) {
      this.currentPage = pageIndex;
    },
    tooltipConfig(data) {
      let config = {};
      if (data.field.tooltip?.length > 0) {
        config = {
          title: data.field.tooltip,
          placement: 'bottom',
          variant: 'light',
          trigger: 'hover',
        };
      }
      return config;
    },
    sortIcon(data) {
      return data.field.key === this.sortBy
        ? this.sortDesc
          ? 'sort-down'
          : 'sort-up'
        : 'sort';
    },
  },
};

import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import api from '@shared/services/api.js';
import { showMessages } from '@shared/utils/notifier.js';
import {
  getLatestFundingCost,
  saveFundingCost as postFundingCost,
} from '@shared/api/fundingCosts.js';
import * as mutationTypes from './mutationTypes.js';
import omit from 'lodash/omit';

const COMPANY_LOGO_API_URL = '/company/api/info/logo';

const getOrganizationDetail = async ({ commit }) => {
  commit('setOrganizationDetail', { isLoading: true });

  try {
    const organizationDetail = await api.get('/company/api/info');

    if (!organizationDetail.new_user) {
      commit('setOrganizationDetail', {
        isLoading: false,
        data: organizationDetail,
      });
    } else {
      commit('setOrganizationDetail', { isLoading: false, data: {} });
    }
  } catch (error) {
    onErrorHandler(error, 'useradmin-get-organization-info');
  }
};

const saveOrganizationDetail = async ({ state }, payload) => {
  try {
    const body = {
      name: state.organizationDetail.data.name,
      ticker_symbol: state.organizationDetail.data.ticker_symbol,
      time_zone: payload?.time_zone || state.organizationDetail.data.time_zone,
      bid_tape_download_file_ext:
        payload?.bid_tape_download_file_ext ||
        state.organizationDetail.data.bid_tape_download_file_ext,
      service_fee:
        payload?.service_fee || state.organizationDetail.data.service_fee,
      settlement_cutoff:
        payload?.settlement_cutoff ||
        state.organizationDetail.data.settlement_cutoff,
      exclude_ineligible_bids_on_export:
        payload?.exclude_ineligible_bids_on_export,
      additional_settlement_months: payload?.additional_settlement_months,
      address: payload?.address,
      address2: payload?.address2,
      city: payload?.city,
      state: payload?.state,
      zip: payload?.zip,
      email: payload?.email,
    };

    const response = await api.post('/company/api/info', body);

    // Redirect logic may not be needed after changed requirements.
    // A user cannot reach this page without proper access nor new as a new org
    if (response?.redirect_url) {
      window.location = response.redirect_url;
    }
  } catch (error) {
    onErrorHandler(error, 'useradmin-save-organization-info');
  }
};

const getOrganizationLogo = async ({ commit }) => {
  commit('setOrganizationLogoLoading', true);
  commit('setOrganizationLogo', null);

  try {
    const organizationLogo = await api.getBlob(COMPANY_LOGO_API_URL);
    if (organizationLogo.status !== 204) {
      if (organizationLogo.data) {
        const reader = new FileReader();
        reader.onload = () => {
          commit('setOrganizationLogo', reader.result);
          commit('setOrganizationLogoLoading', false);
        };
        reader.onerror = () => {
          commit('setOrganizationLogo', null);
          commit('setOrganizationLogoLoading', false);
        };
        reader.readAsDataURL(organizationLogo.data);
      }
    } else {
      commit('setOrganizationLogoLoading', false);
    }
  } catch (error) {
    onErrorHandler(error, 'get-organization-logo');
    commit('setOrganizationLogoLoading', false);
  }
};

const deleteOrganizationLogo = async ({ commit }) => {
  try {
    await api.delete(COMPANY_LOGO_API_URL);
    commit('setOrganizationLogo', null);
  } catch (error) {
    onErrorHandler(error, 'delete-organization-logo');
  }
};

const cleanUpOrganizationInfo = ({ commit }) => {
  commit('setOrganizationLogo', null);
  commit('setOrganizationLogoLoading', true);
};

const uploadOrganizationLogo = async ({ commit, dispatch }, formData) => {
  try {
    commit('setOrganizationLogoLoading', true);
    await api.postFormData(COMPANY_LOGO_API_URL, formData);
  } catch (e) {
    onErrorHandler(e, 'delete-organization-upload');
  } finally {
    commit('setOrganizationLogoLoading', false);
  }
  dispatch('getOrganizationLogo');
};

const getFundingCost = async ({ commit, getters, rootGetters }) => {
  try {
    const res = await getLatestFundingCost(
      rootGetters['organizations/organization'].id,
    );
    commit(
      mutationTypes.SET_FUNDING_COST,
      res.results[0] || getters['fundingCost'],
    );
  } catch (e) {
    onErrorHandler(e, 'get-funding-cost');
  }
};

const saveFundingCost = async ({ commit, getters, rootGetters }) => {
  try {
    const org_id = rootGetters['organizations/organization'].id;
    const data = { ...omit(getters.fundingCost, 'base_rate'), org_id };
    const res = await postFundingCost(org_id, data);

    commit(mutationTypes.SET_FUNDING_COST, res);
    showMessages([
      {
        title: 'Funding cost saved',
        message: 'Successfully saved funding cost.',
        type: 'success',
      },
    ]);
  } catch (e) {
    onErrorHandler(e, 'save-funding-cost');
  }
};

export default {
  getOrganizationDetail,
  saveOrganizationDetail,
  getOrganizationLogo,
  uploadOrganizationLogo,
  deleteOrganizationLogo,
  cleanUpOrganizationInfo,
  getFundingCost,
  saveFundingCost,
};

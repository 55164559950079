import api from '@shared/services/api.js';

export const getLatestFundingCost = async organizationId => {
  const queryData = {
    page_size: 1,
    sort_by: '-effective-at',
  };
  const queryParams = new URLSearchParams(queryData);

  return api.get(
    `/api/v1/organizations/${organizationId}/funding_costs/?${queryParams}`,
  );
};

export const saveFundingCost = async (organizationId, data) => {
  return api.postJson(
    `/api/v1/organizations/${organizationId}/funding_costs/`,
    data,
  );
};

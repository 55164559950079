<template>
  <div class="px-indicator-icon-item d-flex justify-content-between">
    <div class="px-indicator-icon-item__icon">
      <px-icon size="2x" :icon="icon"></px-icon>
    </div>
    <div class="text-right px-indicator-icon-item__data">
      <div class="px-indicator-icon-item__value">
        <slot>
          {{ value }}
        </slot>
      </div>
      <div class="px-indicator-icon-item__description">
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PxIndicatorIconItem',
  props: {
    icon: {
      default: '',
      required: true,
      type: String,
    },
    value: {
      default: '',
      required: false,
      type: [String, Number],
    },
    description: {
      default: '',
      required: false,
      type: String,
    },
  },
};
</script>

<style lang="scss">
@import '@shared/styles/variables';
.px-indicator-icon-item {
  padding: 1rem 0;
  &:not(:last-of-type) {
    border-bottom: 1px solid $light-gray;
  }

  &__icon {
    align-self: center;
    color: $primary;
  }

  &__value {
    font-size: 2rem;
  }

  &__description {
    font-size: 11px;
  }
}
</style>

<template>
  <div class="text-base" :class="elementClass">
    <b-link
      class="d-flex"
      href="#"
      role="plus-btn"
      tabindex="-1"
      @click.prevent="showSelect"
    >
      <px-icon icon="circle-plus" />
    </b-link>
    <plus-item-select-override
      ref="select"
      v-model="chosenOption"
      class="text-base"
      :empty-text="emptyText"
      :get-option-label="option => option.label"
      :options="options"
      :placeholder="placeholder"
      @input="emitValueChosen"
      @search:blur="selectHidden = true"
      @search:focus="selectHidden = false"
    >
      <template #option="{ label }">
        {{ label }}
      </template>
      <span slot="no-options">{{ emptyText }}</span>
    </plus-item-select-override>
  </div>
</template>

<script>
import PlusItemSelectOverride from '@shared/components/PlusItemSelectOverride.vue';

export default {
  name: 'PxPlusItem',
  components: {
    PlusItemSelectOverride,
  },
  props: {
    options: { type: Array, required: true },
    placeholder: { type: String, default: '' },
    emptyText: { type: String, default: 'No available options' },
  },
  data() {
    return {
      selectHidden: true,
      chosenOption: null,
    };
  },
  computed: {
    elementClass() {
      const classStr = 'plus-item d-flex flex-row align-items-center';
      return `${classStr} ${
        this.selectHidden ? 'select-hidden' : 'select-shown'
      }`;
    },
  },
  watch: {
    selectHidden(value) {
      this.$emit('hidden', value);
    },
  },
  methods: {
    showSelect() {
      if (this.selectHidden) {
        this.selectHidden = false;
        this.$nextTick(() => {
          this.$refs.select.$el.querySelector('input').focus();
        });
      }
    },
    emitValueChosen(option) {
      this.selectHidden = true;
      this.$emit('valueChosen', option.value);
      this.chosenOption = null;
    },
  },
};
</script>

<template>
  <b-form-group
    class="px-box-textarea-container"
    :content-cols="contentCols"
    :label="label"
    label-class="px-box-textarea-label"
    :label-cols="labelCols"
    :label-for="inputId"
  >
    <b-input-group>
      <b-form-textarea
        v-if="!hideInput"
        :id="inputId"
        v-bind="$attrs"
        class="px-box-textarea"
        :class="{
          invalid,
          'underline-variant': underlineVariant,
          'hide-input': hideInput,
          'right-align-text': rightAlignText,
        }"
        :max-rows="maxRows"
        :rows="rows"
        v-on="$listeners"
        @beforeinput="decimal && checkDecimals($event)"
      />
      <span
        v-if="invalid"
        v-b-tooltip.hover.bottomleft="{
          customClass: 'px-tooltip',
          variant: 'light-warning',
          title: invalidMessage,
        }"
        class="invalid-icon-container"
      >
        <px-icon
          class="invalid-icon"
          icon="triangle-exclamation"
          size="sm"
          title="invalid-icon"
        ></px-icon>
      </span>
      <slot></slot>
    </b-input-group>
  </b-form-group>
</template>

<script>
import { onBeforeInputValidateDecimal } from '@shared/utils';
import baseBoxInput from '@shared/mixins/baseBoxInput.js';
import { uniqueId } from 'lodash';

export default {
  name: 'PxBoxTextArea',
  mixins: [baseBoxInput],
  props: {
    underlineVariant: { type: Boolean, required: false, default: false },
    rightAlignText: { type: Boolean, required: false, default: false },
    hideInput: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxRows: { type: Number, required: false, default: 20 },
    rows: { type: Number, required: false, default: 2 },
  },
  data: () => {
    return {
      inputId: uniqueId(),
    };
  },
  methods: {
    checkDecimals($event) {
      return onBeforeInputValidateDecimal(
        this.precision,
        this.negatives,
      )($event);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/variables';

.px-box-textarea-container {
  margin: 4px 0;
  position: relative;
  display: flex;
  align-items: center;

  textarea {
    background: $white;
  }

  ::v-deep .px-box-textarea-label {
    font-weight: bold;
    width: 100%;
    padding: 4px 0;
  }

  ::v-deep .col-* {
    padding: 0;
  }

  .px-box-textarea {
    border: 1px solid $border-grey;
    border-radius: 5px;
    padding: 8px;
    height: min-content;
    &.underline-variant {
      border: none;
      border-bottom: 1px solid $border-grey;
      border-radius: 0;
    }
    &.hide-input {
      display: none;
    }

    + .invalid-icon-container {
      align-items: center;
      top: 0;
      height: 100%;
      position: absolute;
      right: 0.875rem;
      display: flex;

      .invalid-icon {
        color: $polly-orange;
      }
    }

    &:active,
    &:focus {
      outline-width: 0;
      border-color: $polly-light-blue;
    }

    &.invalid {
      border-color: $polly-orange;
      padding-right: 1.5rem;
    }
  }
  .right-align-text {
    text-align: right;
  }
}
</style>

<template>
  <div class="px-table">
    <!-- Setting rows to be 80% of the pageSize in order to make up for
         loader rows being larger than normal rows -->
    <px-loading-wrapper
      :columns="numColumns"
      :loading="isLoading"
      :rows="loaderRows || options.pageSize * 0.8"
      type="table"
    >
      <b-table
        ref="table"
        v-bind="$attrs"
        class="table-grid mb-0"
        :class="tableClass"
        :current-page="currentPage"
        :fields="fields"
        :hover="showHover"
        :items="items"
        :no-local-sorting="serverSide"
        :per-page="perPage"
        :responsive="responsive"
        show-empty
        small
        :sort-by.sync="sortBy"
        :sort-compare="sortCompare"
        :sort-desc.sync="sortDesc"
        :thead-class="theadClass"
        v-on="$listeners"
      >
        <template #empty="scope">
          <em v-if="scope.emptyText" data-testid="table-no-option-message">
            {{ scope.emptyText }}
          </em>
          <em v-else data-testid="table-no-option-message">
            There are no records to show
          </em>
        </template>

        <template #head()="data">
          <div v-b-tooltip="tooltipConfig(data)" class="d-inline">
            {{ data.label }}
          </div>
          <px-icon
            v-if="data.field.sortable"
            alt="sort-icon"
            class="sort-icon"
            focusable="false"
            :icon="sortIcon(data)"
            :inline="false"
            role="presentation"
            solid
          />
        </template>

        <!-- Add new slot and override any of the above if present -->
        <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
          <slot v-bind="scope" :name="slot" />
        </template>
      </b-table>
    </px-loading-wrapper>
    <px-table-pagination
      v-if="showPaginationRow"
      :allow-select-page-size="allowSelectPageSize"
      :page-index="options.pageIndex"
      :page-options="pageOptions"
      :page-size="options.pageSize"
      :total="total"
      @change="paginationChanged"
    ></px-table-pagination>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
import debounce from 'lodash/debounce';
import PxTablePagination from '@shared/components/PxTablePagination.vue';
import { DEFAULT_TABLE_OPTIONS, DEFAULT_PAGE_OPTIONS } from '@shared/constants';
import { stripTrailingSlash } from '@shared/utils/urlFormatters.js';
import tableMixin from '@pe/mixins/tableMixin.js';

function cleanOptions(options) {
  if (!options) {
    return {};
  }
  // 'total' property is omitted from options object
  return Object.keys(options)
    .filter(key => key !== 'total')
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: options[key],
      };
    }, {});
}

export default {
  name: 'PxTable',
  components: {
    PxTablePagination,
  },
  mixins: [tableMixin],
  model: {
    prop: 'options',
    event: 'onOptionsChanged',
  },
  props: {
    options: { type: Object, default: () => ({ ...DEFAULT_TABLE_OPTIONS }) },
    serverSide: { type: Boolean, default: true },
    fields: { type: Array, default: () => [] },
    items: { type: Array, default: () => [] },
    responsive: { type: Boolean, default: true },
    showPagination: { type: Boolean, default: true },
    sortCompare: { type: Function, default: () => false },
    allowSelectPageSize: { type: Boolean, default: true },
    cachedPrefix: { type: String, default: '' }, // fill this property by some identity on px-table if want to use caching table options
    showHover: { type: Boolean, default: true },
    isLoading: { type: Boolean, default: false },
    enableCustomHead: { type: Boolean, default: false }, // temporary hack to enable head() usage when we use px-table
    theadClassProp: { type: String, default: '' },
    pageOptions: { type: Array, default: () => DEFAULT_PAGE_OPTIONS },
    loaderRows: {
      type: Number,
      default: null,
    },
    loaderCols: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      updateLayout: () => {},
    };
  },
  computed: {
    sortBy: {
      get() {
        return this.options.sortBy;
      },
      set(newVal) {
        if (newVal === this.sortBy) return;
        this.updateOptions({ sortBy: newVal });
      },
    },
    sortDesc: {
      get() {
        return this.options.sortDesc;
      },
      set(newVal) {
        if (newVal === this.sortDesc) return;
        this.updateOptions({ sortDesc: newVal });
      },
    },
    total() {
      return this.serverSide ? this.options.total : this.items.length;
    },
    perPage() {
      return this.serverSide ? undefined : this.options.pageSize;
    },
    currentPage() {
      return this.serverSide ? undefined : this.options.pageIndex;
    },
    showPaginationRow() {
      // hide pagination if the special flag raised or there are no items
      if (!this.showPagination || this.total <= 0) {
        return false;
      }
      // hide pagination until there is a need for multiple pages of pagination
      if (!this.allowSelectPageSize && this.total <= this.options.pageSize) {
        return false;
      }
      // otherwise show pagination
      return true;
    },
    tableClass() {
      return this.showPaginationRow ? '' : 'no-paging';
    },
    theadClass() {
      return (
        (this.items && this.items.length ? '' : 'no-header') +
        this.theadClassProp
      );
    },
    useCache() {
      return Boolean(this.cachedPrefix);
    },
    numColumns() {
      // number of labeled columns
      return this.loaderCols || this.fields.filter(field => field.label).length;
    },
    tableOptionsKey() {
      return `${stripTrailingSlash(window.location.pathname)}/${
        this.cachedPrefix
      }`;
    },
  },
  watch: {
    items() {
      this.updateLayout();
    },
    fields() {
      this.updateLayout();
    },
  },
  created() {
    let initialOptions = {
      ...DEFAULT_TABLE_OPTIONS,
      ...this.options,
    };
    if (this.useCache) {
      const storedOptions = this.getTableOptions() || {};
      initialOptions = { ...initialOptions, ...storedOptions };
    }
    this.$emit('onOptionsChanged', initialOptions);
  },
  mounted() {
    const tableSelector = '.table-grid.table-responsive';
    if (!document.querySelector(tableSelector)) return;
    // eslint-disable-next-line no-undef
    const ps = new PerfectScrollbar(tableSelector);
    const debounced = debounce(ps.update.bind(ps), 100);
    window.addEventListener('resize', debounced);
    this.$once('hook:beforeDestroy', function () {
      window.removeEventListener('resize', debounced);
      ps.destroy();
    });
    this.updateLayout = debounced;
  },
  methods: {
    selectRow(index) {
      this.$refs.table.selectRow(index);
    },
    updateOptions(props) {
      const mergedOptions = {
        ...this.options,
        ...props,
      };
      this.$emit('onOptionsChanged', mergedOptions);
      this.$emit('onloadingData');
      this.updateLayout();
      if (this.useCache) {
        this.setTableOptions(mergedOptions);
      }
    },
    paginationChanged({ pageIndex, pageSize }) {
      const props = {};
      if (pageIndex && pageIndex !== this.options.pageIndex) {
        props.pageIndex = pageIndex;
      }
      if (pageSize && pageSize !== this.options.pageSize) {
        props.pageSize = pageSize;
        props.pageIndex = 1;
      }
      if (Object.keys(props).length) {
        this.updateOptions(props);
      }
    },
    setTableOptions(options) {
      this.$storage.set(this.tableOptionsKey, cleanOptions(options));
    },
    getTableOptions() {
      return cleanOptions(this.$storage.get(this.tableOptionsKey));
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (item._rowVariant) return `table-${item._rowVariant}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/variables';
@import 'perfect-scrollbar/css/perfect-scrollbar.css';

.px-table {
  width: 100%;

  ::v-deep {
    .table {
      tbody tr {
        &.table-primary {
          background-color: #54545410 !important;
          color: #000000 !important;

          &:hover,
          &:focus {
            background-color: darken(#54545410, 5%) !important;
          }

          td {
            background-color: #54545410 !important;
            color: #000000 !important;

            &:hover,
            &:focus {
              background-color: darken(#54545410, 5%) !important;
            }
          }
        }
      }
    }
  }

  ::v-deep td {
    hyphens: auto;
    word-break: normal;
    word-wrap: normal;
  }
}

::v-deep .table.b-table > thead > tr [aria-sort] {
  background-image: none;
}
</style>

<template>
  <label class="custom-control custom-radio" :class="labelClasses">
    <input
      :id="id || name"
      ref="radio"
      :checked="checked"
      class="custom-control-input"
      :class="inputClasses"
      :disabled="(option && option.disabled) || disabled"
      :name="name"
      type="radio"
      :value="computedValue"
      @click="onClick"
    />
    <span class="custom-control-label" :class="itemColor">
      {{ radioLabel }}
    </span>
  </label>
</template>

<script>
export default {
  name: 'PxRadio',
  model: {
    prop: 'checked',
    event: 'valueChanged',
  },
  props: {
    value: { type: [Object, String, Number, Boolean], default: () => true },
    id: { type: String, default: '' },
    name: { type: String, required: true },
    fullColor: { type: Boolean },
    withIcon: { type: Boolean },
    stacked: { type: Boolean },
    state: { type: Boolean, default: undefined },
    option: { type: Object, default: null },
    disabled: { type: Boolean },
    checked: { type: Boolean },
    label: { type: String, default: '' },
  },
  computed: {
    labelClasses() {
      return `${this.stacked ? '' : 'custom-control-inline'} ${
        this.withIcon ? 'custom-radio-icon' : ''
      }`;
    },
    itemColor() {
      return this.fullColor ? 'custom-control-color' : '';
    },
    radioLabel() {
      return (this.option && this.option.text) || this.label;
    },
    inputClasses() {
      return `${
        (this.option && this.option.state) || this.state
          ? 'is-valid'
          : (this.option && this.option.state === false) || this.state === false
          ? 'is-invalid'
          : ''
      }`;
    },
    computedValue() {
      return this.option && typeof this.option.value !== 'undefined'
        ? this.option.value
        : this.value;
    },
  },
  methods: {
    onClick() {
      this.$emit('valueChanged', this.computedValue);
    },
  },
};
</script>

<template>
  <b-skeleton-wrapper
    class="px-loading-wrapper"
    :class="card ? 'card' : null"
    :loading="loading"
  >
    <template #loading>
      <b-skeleton
        v-if="title"
        animation="fade"
        class="loading-header"
        width="50%"
      ></b-skeleton>

      <div v-if="type === loaderType.TABLE">
        <b-skeleton-table
          animation="fade"
          :columns="columns"
          :rows="rows"
          :table-props="{ borderless: true }"
        ></b-skeleton-table>
      </div>

      <div v-if="type === loaderType.PARAGRAPH">
        <b-skeleton
          v-for="each in rows"
          :key="each"
          animation="fade"
          class="loading-paragraph"
          :width="`${getRowWidth()}%`"
        ></b-skeleton>
      </div>

      <div v-if="type === loaderType.CONTENT">
        <b-skeleton
          animation="fade"
          class="loading-content"
          :height="`${rows * 30}px`"
          width="100%"
        ></b-skeleton>
      </div>

      <div v-if="type === loaderType.LINES">
        <b-skeleton
          v-for="each in rows"
          :key="each"
          animation="fade"
          class="loading-line"
          width="100%"
        ></b-skeleton>
      </div>

      <div v-if="type === loaderType.ROW">
        <b-skeleton
          animation="fade"
          class="loading-line"
          :width="`${columns * 10}%`"
        ></b-skeleton>
      </div>

      <div v-if="type === loaderType.TEXT">
        <div class="lx-loader">
          <div class="lx-loader-overlay"></div>
          <div class="lx-loader-content">Loading...</div>
        </div>
      </div>
    </template>
    <template #default>
      <div class="loaded-content">
        <slot></slot>
      </div>
    </template>
  </b-skeleton-wrapper>
</template>

<script>
import { LOADER_TYPE } from '@shared/constants';

export default {
  name: 'PxLoadingWrapper',
  props: {
    card: {
      default: false,
      type: Boolean,
    },
    columns: {
      default: 3,
      required: false,
      type: Number,
    },
    loading: {
      type: Boolean,
      required: true,
      default: true,
    },
    rows: {
      default: 3,
      required: false,
      type: Number,
    },
    title: {
      default: false,
      required: false,
      type: Boolean,
    },
    type: {
      default: LOADER_TYPE.CONTENT,
      required: false,
      type: String,
    },
    width: {
      default: 80,
      required: false,
      type: Number,
    },
  },
  data() {
    return {
      loaderType: LOADER_TYPE,
    };
  },
  methods: {
    getRowWidth() {
      return Math.floor(100 - Math.random() * (100 - this.width));
    },
  },
};
</script>

<style lang="scss" scoped>
.px-loading-wrapper {
  width: 100%;

  .table-borderless {
    td,
    th {
      padding: 0.5rem;
    }

    tr:last-child td {
      padding-bottom: 0;
    }

    td,
    th {
      border-width: 0;
    }
  }

  .loading-header {
    height: 28px;
    margin-bottom: 20px;
  }

  .b-skeleton-text,
  .loading-paragraph {
    height: 24px;
  }

  .loading-line:not(:last-child) {
    margin-bottom: 12px;
  }
}
</style>

import {
  CLEAR_RULE_GROUP_DETAILS,
  SET_IS_INVALID,
  SET_IS_INVALID_NAME,
  SET_IS_READ_ONLY_ITEM,
  SET_IS_UPDATE_NEEDED,
  SET_RULE_GROUP,
} from './mutationTypes.js';

const initialState = function () {
  return {
    isInvalid: false,
    isInvalidName: false,
    isReadOnlyItem: true,
    isUpdateNeeded: false,
    ruleGroup: null,
  };
};

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    isInvalid: state => state.isInvalid,
    isInvalidName: state => state.isInvalidName,
    isReadOnlyItem: state => state.isReadOnlyItem,
    isUpdateNeeded: state => state.isUpdateNeeded,
    ruleGroup: state => state.ruleGroup,
  },
  mutations: {
    [CLEAR_RULE_GROUP_DETAILS](state) {
      Object.assign(state, initialState());
    },
    [SET_IS_INVALID](state, isInvalid) {
      state.isInvalid = isInvalid;
    },
    [SET_IS_INVALID_NAME](state, isInvalidName) {
      state.isInvalidName = isInvalidName;
    },
    [SET_IS_READ_ONLY_ITEM](state, isReadOnlyItem) {
      state.isReadOnlyItem = isReadOnlyItem;
    },
    [SET_IS_UPDATE_NEEDED](state, isUpdateNeeded) {
      state.isUpdateNeeded = isUpdateNeeded;
    },
    [SET_RULE_GROUP](state, ruleGroup) {
      state.ruleGroup = ruleGroup;
    },
  },
  actions: {
    clearRuleGroupDetails({ commit }) {
      commit(CLEAR_RULE_GROUP_DETAILS);
    },
    setIsInvalid({ commit }, isInvalid) {
      commit(SET_IS_INVALID, isInvalid);
    },
    setIsInvalidName({ commit }, isInvalidName) {
      commit(SET_IS_INVALID_NAME, isInvalidName);
    },
    setIsReadOnlyItem({ commit }, isReadOnlyItem) {
      commit(SET_IS_READ_ONLY_ITEM, isReadOnlyItem);
    },
    setIsUpdateNeeded({ commit }, isUpdateNeeded) {
      commit(SET_IS_UPDATE_NEEDED, isUpdateNeeded);
    },
    setRuleGroup({ commit }, ruleGroup) {
      commit(SET_RULE_GROUP, ruleGroup);
    },
  },
};

export const SET_CHANNEL = 'SET_CHANNEL';
export const SET_IS_FORM_VALID = 'SET_IS_FORM_VALID';
export const SET_IS_INVALID_NAME = 'SET_IS_INVALID_NAME';
export const SET_IS_READ_ONLY_ITEM = 'SET_IS_READ_ONLY_ITEM';
export const SET_IS_UPDATE_NEEDED = 'SET_IS_UPDATE_NEEDED';

export default {
  SET_CHANNEL,
  SET_IS_FORM_VALID,
  SET_IS_INVALID_NAME,
  SET_IS_READ_ONLY_ITEM,
  SET_IS_UPDATE_NEEDED,
};

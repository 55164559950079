<template>
  <div>
    <i class="far fa-thumbs-up thumb-icon" @click="thumbUp()"></i>
    <i class="far fa-thumbs-down thumb-icon" @click="thumbDown()"></i>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async thumbUp() {
      this.$emit('thumbUp');
    },
    async thumbDown() {
      this.$emit('thumbDown');
    },
  },
};
</script>

<style lang="scss">
@import './ChatWidget.scss';
</style>

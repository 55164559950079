import { bidStatus, commitmentPoolStatusValueMapping } from '@shared/constants';
import { isBefore, endOfToday, parseISO } from '@shared/utils/dates.js';

const bidUpbReducer = (acc, bid) => acc + parseFloat(bid.loan.unpaid_balance);
// getHighBalanceUpb sums up the unpaid balance for all loans marked
// as high balance that belong to the bids in the given pool.
const getHighBalanceUpb = pool =>
  pool.bids
    .filter(bid => bid.loan.is_high_balance)
    .reduce((total, bid) => total + parseFloat(bid.loan.unpaid_balance), 0.0);

// calcHighBalancePercent is used as a helper function for the getHighBalancePercentText
// method. It splits out the math so that the method can focus on the text.
const calcHighBalancePercent = (highBalanceUpb, poolAmount) =>
  (highBalanceUpb / poolAmount) * 100;

// calcUpbForPool is used to sum up the unpaid balance for all the loans belonging
// to the bids within the given pool.
const calcUpbForPool = pool => pool.bids.reduce(bidUpbReducer, 0);

// getPoolAmountOrUpb is used to get the pool amounts for both
// forwards and auto pools. Forwards have the pool amount specified
// by the user upon their creation. Auto pools however do not have
// an amount specified by the user and it is determined by the
// total unpaid balance of all the loans in the pool.
const getPoolAmountOrUpb = pool => {
  let poolAmount = Number.parseFloat(pool.amount || 0);

  if (poolAmount === 0) {
    poolAmount = calcUpbForPool(pool);
  }

  return poolAmount;
};

const isNewPool = pool =>
  pool?.status === commitmentPoolStatusValueMapping.created;
const isSelectedBid = bid => bid?.status === bidStatus.selected;
const poolContainsSelectedBids = pool => pool?.has_selected_bids;

// Pools in an accepted state within the cart are considered forward pools
// regardless if the pool was manually created or auto generated.
const isForwardPool = pool =>
  pool?.status === commitmentPoolStatusValueMapping.accepted;

const getExpirationTense = pool => {
  if (isPoolExpired(pool)) {
    return 'expired';
  } else {
    return 'expires';
  }
};

const isPoolExpired = pool => isBefore(parseISO(pool.expires_at), endOfToday());

export default {
  getExpirationTense,
  isPoolExpired,
  isNewPool,
  isForwardPool,
  isSelectedBid,
  poolContainsSelectedBids,
  getPoolAmountOrUpb,
  calcHighBalancePercent,
  getHighBalanceUpb,
  calcUpbForPool,
  bidUpbReducer,
  bidGainLoss(bid, forwardPoolsGainlosses) {
    if (bid.is_forward_bid) {
      if (
        forwardPoolsGainlosses &&
        // eslint-disable-next-line
        forwardPoolsGainlosses.hasOwnProperty(bid.id)
      ) {
        return forwardPoolsGainlosses[bid.id];
      } else {
        return 0;
      }
    } else {
      return parseFloat(bid.offer) - parseFloat(bid.best_ex);
    }
  },
};

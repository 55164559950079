import isEqual from 'lodash/isEqual';
import reduce from 'lodash/reduce';

export const getChanges = (original, updated) => {
  if (isEqual(original, updated)) {
    return {};
  }

  return reduce(
    original,
    (result, value, key) => {
      if (value == updated[key]) return result;

      return { ...result, [key]: updated[key] };
    },
    {},
  );
};

export const stripHTML = str => {
  const parser = new DOMParser();
  const dom = parser.parseFromString(
    '<!doctype html><body>' + str,
    'text/html',
  );

  return dom.body.textContent;
};

export const SET_ALLOCATED_LOANS = 'SET_ALLOCATED_LOANS';
export const SET_AVAILABLE_LOANS = 'SET_AVAILABLE_LOANS';
export const SET_IS_ALLOCATED_LOANS_LOADING = 'SET_IS_ALLOCATED_LOANS_LOADING';
export const SET_IS_AVAILABLE_LOANS_LOADING = 'SET_IS_AVAILABLE_LOANS_LOADING';
export const SET_IS_ALLOCATED_LOANS_MODAL_OPEN =
  'SET_IS_ALLOCATED_LOANS_MODAL_OPEN';
export const SET_POOL_SCENARIO = 'SET_POOL_SCENARIO';
export const SET_LOAN_EX_INFO = 'SET_LOAN_EX_INFO';
export const SET_SAVED_ALLOCATED_LOANS_IDS = 'SET_SAVED_ALLOCATED_LOANS_IDS';
export const SET_SAVED_AVAILABLE_LOANS_IDS = 'SET_SAVED_AVAILABLE_LOANS_IDS';

import svc from '@shared/services/audit.js';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';

export const mutationTypes = {
  INIT_AUDIT_LOG: 'initAuditLog',
  SET_AUDIT_RECORDS: 'setAuditRecords',
  SET_IS_LOADING: 'setIsLoading',
};

export default {
  namespaced: true,
  state: {
    auditRecords: [],
    isLoading: false,
    loanId: null,
    poolId: null,
    isSell: false,
    auditRecordsCount: 0,
    getTableOptions: null,
  },
  getters: {
    isLoading: state => state.isLoading,
    auditRecords: state => state.auditRecords,
  },
  mutations: {
    [mutationTypes.SET_AUDIT_RECORDS](state, { items, total }) {
      state.auditRecords = items || [];
      state.auditRecordsCount = total || 0;
      state.getTableOptions().total = state.auditRecordsCount;
    },
    [mutationTypes.INIT_AUDIT_LOG](
      state,
      { loanId, poolId, isSell, getTableOptions },
    ) {
      state.auditRecords = [];
      state.loanId = loanId;
      state.poolId = poolId;
      state.isSell = isSell;
      state.getTableOptions = getTableOptions;
    },
    [mutationTypes.SET_IS_LOADING](state, payload) {
      state.isLoading = payload;
    },
  },
  actions: {
    initAuditLog({ commit }, { loanId, poolId, isSell, getTableOptions }) {
      commit(mutationTypes.INIT_AUDIT_LOG, {
        loanId,
        poolId,
        isSell,
        getTableOptions,
      });
    },
    async getAuditRecords({ state, commit }) {
      try {
        commit(mutationTypes.SET_IS_LOADING, true);
        const tableOptions = state.getTableOptions();
        const skip = (tableOptions.pageIndex - 1) * tableOptions.pageSize;
        const take = tableOptions.pageSize;
        let result = null;
        if (state.loanId) {
          const type = state.isSell ? 'loan_sell' : 'loan_buy';
          result = await svc.loanAuditInfo(state.loanId, type, skip, take);
        } else if (state.poolId) {
          result = await svc.poolAuditInfo(state.poolId, skip, take);
        }
        commit(mutationTypes.SET_AUDIT_RECORDS, result);
      } catch (error) {
        onErrorHandler(error, 'core-get-operation', [403], true);
      } finally {
        commit(mutationTypes.SET_IS_LOADING, false);
      }
    },
  },
};

export const removeFromArrayByIndex = (array, index) => {
  if (index > -1) {
    array.splice(index, 1);
  }
};

export const filterEntitiesByQuery = (query = '', entities = []) => {
  return query
    ? entities.filter(entity =>
        Object.keys(entity).some(
          key =>
            String(entity[key])
              .toLocaleLowerCase()
              .indexOf(query.toLocaleLowerCase()) >= 0,
        ),
      )
    : entities;
};

export function checkUserPermissions(to, protectedRoutes, orgUserPermissions) {
  if (!to.meta.checkPermissions) {
    return true;
  }

  const matchedProtectedRoutes = protectedRoutes.filter(protectedRoute =>
    new RegExp(`^${protectedRoute.path}`).test(to.fullPath),
  );

  const allPermissions = matchedProtectedRoutes.flatMap(
    route => route.requiredPermissions,
  );

  return allPermissions.every(permission => orgUserPermissions[permission]);
}

export function deepObjectAssign(originalObject, updatedObject) {
  for (const key in updatedObject) {
    if (
      typeof updatedObject[key] === 'object' &&
      !Array.isArray(updatedObject[key])
    ) {
      if (originalObject[key]) {
        deepObjectAssign(originalObject[key], updatedObject[key]);
      }
    } else if (key in originalObject) {
      originalObject[key].value = updatedObject[key];
    }
  }
}

export function filterKeysFromObj(obj, keysToRemove) {
  const filteredObj = Object.keys(obj)
    .filter(key => !keysToRemove.includes(key))
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
  return filteredObj;
}

export function filterObject(obj, filterFunction) {
  return Object.fromEntries(
    Object.entries(obj).filter(([, param]) => filterFunction(param)),
  );
}

export function pickRandomArrayItem(array) {
  return array[Math.floor(Math.random() * array.length)];
}

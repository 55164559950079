import Vue from 'vue';
import Router from 'vue-router';

import { routes as buyRoutes } from '@buy/router';
import { routes as analyticsRoutes } from '@analytics/router';
import { routes as deliverRoutes } from '@deliver/router';
import { routes as peRoutes } from '@pe/router';
import { routes as sellRoutes } from '@sell/router';
import { routes as toolsRoutes } from '@tools/router';
import { routes as userAdminRoutes } from '@useradmin/router';
import { routes as devtoolsRoutes } from '@devtools/router';
import { routes as dashboardRoutes } from '@dashboard/router';
import { updateActiveSubpageRoute } from '@shared/utils/headerSetup.js';
import { ORG_USER_PERMISSIONS } from '@shared/constants';
import { checkUserPermissions } from '@shared/utils/helpers.js';
import store from './store';

Vue.use(Router);
const {
  PE,
  MI_RATE_CARD,
  PUBLISH_PRICING,
  LOCK_DESK,
  LOAN_PIPELINE,
  DEV_TOOLS,
} = ORG_USER_PERMISSIONS;

export const routes = [
  ...buyRoutes,
  ...analyticsRoutes,
  ...deliverRoutes,
  ...peRoutes,
  ...sellRoutes,
  ...toolsRoutes,
  ...userAdminRoutes,
  ...devtoolsRoutes,
  ...dashboardRoutes,
];

const protectedRoutes = [
  {
    requiredPermissions: [PE],
    path: '/pe/*',
  },
  {
    requiredPermissions: [MI_RATE_CARD],
    path: '/pe/mi-rate-card-versions/*',
  },
  {
    requiredPermissions: [PUBLISH_PRICING],
    path: '/pe/pricing/*',
  },
  {
    requiredPermissions: [LOCK_DESK],
    path: '/pe/lock-desk/*',
  },
  {
    requiredPermissions: [LOAN_PIPELINE],
    path: '/pe/loans/*',
  },
  {
    requiredPermissions: [DEV_TOOLS],
    path: '/devtools/*',
  },
];

const router = new Router({
  mode: 'history',
  props: true,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

const pageTitles = {
  '/accounts': 'Admin',
  '/buy': 'Buy',
  '/company': 'Admin',
  '/deliver': 'Deliver',
  '/devtools': 'Developer Tools',
  '/pe': 'PPE',
  '/sell': 'Sell',
  '/tools': 'Tools',
  '/': 'Dashboard',
};

router.beforeEach(async (to, from, next) => {
  // Set page titles based on the path
  for (const title in pageTitles) {
    if (title === to.path || to.path.startsWith(title)) {
      to.meta.title = pageTitles[title];
      break;
    }
  }

  if (to.meta.checkPermissions) {
    // TODO: check this works
    const permissions = await store.dispatch('core/getPermissions');
    const hasRequiredPermissions = checkUserPermissions(
      to,
      protectedRoutes,
      permissions.orgUser,
    );

    if (!hasRequiredPermissions) {
      to.meta.routeError = {
        title: 403,
        message: 'You are not authorized to view this page',
      };
    }
  }
  next();
});

// eslint-disable-next-line
router.afterEach(function (to, _from) {
  if (to.meta.forceReload) {
    location.reload();
  }

  Vue.notify({
    clean: true,
  });

  if (to.path.startsWith('/pe')) {
    updateActiveSubpageRoute(to, 'pe-header-links');

    if (to.path.startsWith('/pe/mi-rate-card')) {
      store.commit('core/setIsMiRateCard', true);
    } else {
      store.commit('core/setIsMiRateCard', false);
    }
  }
});

export default router;

import { getDashboards } from '@analytics/api/dashboard.js';

const initialState = {
  dashboards: {
    isInitialized: false,
    isLoading: true,
    data: [],
  },
  selectedDashboard: null,
};

const getters = {};

const mutations = {
  setDashboardsField(state, { field, value }) {
    state.dashboards = { ...state.dashboards, [field]: value };
  },
  setSelectedDashboard(state, dashboard) {
    state.selectedDashboard = dashboard;
  },
};

const actions = {
  async getDashboards({ commit, rootGetters }) {
    commit('setDashboardsField', 'isLoading', true);

    const orgId = rootGetters['organizations/currentOrganizationId'];
    const org = rootGetters['organizations/organization'];
    const { results: dashboards } = await getDashboards(orgId);
    const selectedDashboard =
      dashboards.find(d => d.id === org.default_analytics_dashboard) ||
      dashboards[0];

    commit('setDashboardsField', { field: 'data', value: dashboards });
    commit('setSelectedDashboard', selectedDashboard);
    commit('setDashboardsField', { field: 'isLoading', value: false });
    commit('setDashboardsField', { field: 'isInitialized', value: true });
  },
};

export default {
  namespaced: true,
  state: () => initialState,
  getters,
  mutations,
  actions,
};

import { isToday } from 'date-fns';

export function isIMAServicingPriceCurrent(organization) {
  if (!organization.can_trade_early) return true;

  return (
    organization.ima_servicing_current_at &&
    isToday(new Date(organization.ima_servicing_current_at))
  );
}

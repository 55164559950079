import axios from 'axios';
import { showMessages } from '@shared/utils/notifier.js';
import { UserException } from '@shared/api/errors.js';
import { SET_ORG_MISMATCH } from '@shared/store/modules/organizations';
import store from '@src/store';

export default function setup() {
  axios.interceptors.request.use(config => {
    if (store?.getters?.['organizations/orgMismatched'] === true) {
      throw new Error('Request prevented');
    }
    // Add org id to verify the user is still logged in for this org.
    if (window?.PollyGlobals?.currentOrgId) {
      config.headers['Polly-Org'] = window?.PollyGlobals?.currentOrgId;
    }
    return config;
  }, undefined);
  axios.interceptors.response.use(
    response => {
      if (response?.data?.messages) {
        showMessages(response.data.messages);
      }
      return response;
    },
    error => {
      if (error?.response?.status === 435) {
        // Org Mismatch status code
        store.commit(`organizations/${SET_ORG_MISMATCH}`, true);
        return Promise.reject(error);
      }

      if (
        error?.response?.status === 403 &&
        error?.response?.data?.redirect_url
      ) {
        window.location = error.response.data.redirect_url;
        throw error;
      } else if (error?.response?.data?.messages) {
        showMessages(error.response.data.messages);
        if (error?.config?.raiseWarnings)
          throw new UserException(error.response.data.messages, {
            cause: error,
          });
      } else {
        throw error;
      }
    },
  );
}

export function updateActiveSubpageRoute(to, appId) {
  if (to.meta && to.meta.navitem && document.getElementById(appId)) {
    const elements = document
      .getElementById(appId)
      .querySelectorAll('li.nav-item');
    elements.forEach(navItem => navItem.classList.remove('active-tab'));
    document
      .querySelector(`li.nav-item#${to.meta.navitem}`)
      .classList.add('active-tab');
  }
}

<template>
  <b-form ref="fileform" @submit.prevent="upload">
    <input
      ref="file"
      :accept="accept"
      style="display: none"
      type="file"
      @change="fileSelected($event)"
    />
    <div class="upload-container">
      <div class="upload-options d-flex flex-column flex-md-row">
        <div>
          <div class="drop-area h-100 py-2" @click="$refs.file.click()">
            <div v-if="dropAreaTxt" class="file-name">{{ dropAreaTxt }}</div>
            <div v-else>
              <div>Drag and Drop File</div>
              <div>OR</div>
              <div>Click to Select</div>
              <slot name="formats" />
            </div>
          </div>
        </div>
        <div class="fields-container ml-0 ml-md-4" :class="fieldsClass">
          <slot name="fields" />
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
export default {
  name: 'PxFileUpload',
  model: {
    prop: 'file',
    event: 'change',
  },
  props: {
    file: { type: File, default: () => null },
    accept: { type: String, default: '.xls, .xlsx, .csv' },
    fieldsClass: { type: String, default: '' },
    errorText: { type: String, default: 'Supported bidtape file formats are' },
  },
  computed: {
    dropAreaTxt() {
      return this.file && this.file.name;
    },
  },
  watch: {
    file(newValue) {
      if (!this.$refs || !this.$refs.file || newValue) return;
      this.$refs.file.value = null;
    },
  },
  mounted() {
    if (this.supportsDragDrop()) {
      [
        'drag',
        'dragstart',
        'dragend',
        'dragover',
        'dragenter',
        'dragleave',
        'drop',
      ].forEach(
        function (evt) {
          this.$refs.fileform.addEventListener(
            evt,
            function (e) {
              e.preventDefault();
              e.stopPropagation();
            }.bind(this),
            false,
          );
        }.bind(this),
      );
      this.$refs.fileform.addEventListener(
        'drop',
        function (e) {
          if (e.dataTransfer.files.length > 0) {
            const file = e.dataTransfer.files[0];
            const acceptedFormats = this.accept
              .replaceAll('.', '')
              .replaceAll(', ', '|');
            const regex = new RegExp(`(.*?)\\.(${acceptedFormats})$`, 'i');
            if (regex.test(file.name)) {
              this.resetForm();
              this.$emit('change', file);
            } else {
              this.$notify({
                title: 'File format not supported',
                text: `${this.errorText} ${this.accept.replaceAll('.', '*.')}`,
                type: 'warn',
              });
            }
          }
        }.bind(this),
      );
    }
  },
  methods: {
    supportsDragDrop() {
      var div = document.createElement('div');
      return (
        ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
        'FormData' in window &&
        'FileReader' in window
      );
    },
    fileSelected() {
      if (!this.$refs || !this.$refs.file) return;
      const files = this.$refs.file.files;
      if (files.length > 0) {
        this.$emit('change', files[0]);
      }
    },
    resetForm() {
      this.$emit('reset');
      this.$emit('change', null);
    },
    upload() {
      this.$emit('upload');
    },
  },
};
</script>

<style>
.upload-container {
  text-align: left;
  background-color: #ffffff;
  margin: 0px;
  padding: 5px;
}

.fields-container {
  max-width: 400px;
  width: 100%;
  padding: 0 10px 0 10px;
}

.fields-container label {
  white-space: nowrap;
}

.upload-options {
  padding: 5px;
}

.fields-container .form-group {
  margin-bottom: 5px;
}

.drop-area {
  background-color: #f2f2f2;
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 13px;
  border-radius: 5px;
}

.file-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

import api from '@shared/services/api.js';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import { showMessages } from '@shared/utils/notifier.js';
import svc from '@shared/services/loans.js';
import {
  COMMIT_ERROR_HEADER,
  COMMIT_ERROR_MESSAGE,
  COMMIT_WARNING_HEADER,
  getBenchmarkValues,
  loanStatus,
} from '@shared/constants';
import mutationTypes from '@buy/store/modules/loanDetails/mutationTypes.js';

function showSuccessMessages(taskResults) {
  const successMsgs = taskResults.filter(
    taskResult => taskResult.is_successful,
  );
  if (successMsgs.length) {
    const ofMessage = `${successMsgs.length} of ${taskResults.length}`;
    showMessages([
      {
        title: 'Countered bids committed',
        message: `Successfully committed countered bids in ${ofMessage} pools`,
        type: 'success',
      },
    ]);
  }
}
function showWarningMessages(taskResults) {
  const failedTasksResults = taskResults.filter(
    taskResult => !taskResult.is_successful,
  );
  if (failedTasksResults.length) {
    const ofMessage = `${failedTasksResults.length} of ${taskResults.length}`;
    showMessages([
      {
        title: 'Commitment failure',
        message: `Failed to commit countered bids in ${ofMessage} pools`,
        type: 'warn',
      },
    ]);
  }
}
function isSuccessfulCommitmentTaskResult(commitTaskResult) {
  const taskResults = commitTaskResult.task_result || [];
  return (
    commitTaskResult.task_successful &&
    (!taskResults.length ||
      taskResults.every(taskResult => taskResult.is_successful))
  );
}

function showTaskResultMessages(commitTaskResult) {
  if (commitTaskResult.task_successful) {
    const taskResults = commitTaskResult.task_result || [];
    if (
      !taskResults.length ||
      taskResults.some(taskResult => !taskResult.is_successful)
    ) {
      // at least partial failure
      showSuccessMessages(taskResults);
      showWarningMessages(taskResults);
    }
  } else {
    // single failure message if task failed
    const is_warning = commitTaskResult.is_user_message;
    const errorType = is_warning ? 'warn' : 'error';
    const header = is_warning ? COMMIT_WARNING_HEADER : COMMIT_ERROR_HEADER;
    const message = is_warning
      ? commitTaskResult.failed_message
      : COMMIT_ERROR_MESSAGE;
    showMessages([{ title: header, message, type: errorType }]);
  }
}
export default {
  namespaced: true,
  actions: {
    async getLoanDetails({ commit }, loanId) {
      try {
        commit(mutationTypes.SET_LOADING, true);
        const data = await svc.loanDetails(loanId, false);
        commit(mutationTypes.SET_LOAN_DETAILS, data);
      } catch (e) {
        onErrorHandler(e, 'buy-get-loan-details', [403, 404]);
      } finally {
        commit(mutationTypes.SET_LOADING, false);
      }
    },
    async commitAggregatorBids(
      { dispatch, getters, commit, state },
      { bidIds, refreshLoanDetails = true } = {},
    ) {
      try {
        if (getters.activeTransactionInProgress) {
          return;
        }
        commit(mutationTypes.START_TRANSACTION);

        const { commit_task_ids, errors } = await api.post(
          '/buy/api/bulk/accept',
          {
            bid_ids: bidIds || [state.bid.id],
          },
        );
        if (errors?.length) {
          showMessages(
            errors.map(error => ({
              title: 'Failed to commit bids',
              message: error.error,
              type: 'warn',
            })),
          );
        }
        const taskResultPromises = commit_task_ids.map(taskId =>
          dispatch(
            'asyncTasks/getTaskResult',
            {
              taskId: taskId,
              isShort: true,
            },
            { root: true },
          ),
        );
        const taskResults = await Promise.all(taskResultPromises);
        dispatch('handleCommitmentResult', taskResults);
        if (refreshLoanDetails) {
          await dispatch('getLoanDetails', state.loan.id);
        }
      } catch (error) {
        onErrorHandler(error, 'buy-loan-details-commit-bid');
      } finally {
        commit(mutationTypes.COMPLETE_TRANSACTION);
      }
    },
    handleCommitmentResult(actionContext, commitTaskResults) {
      if (
        commitTaskResults?.length &&
        commitTaskResults.every(commitTaskResult =>
          isSuccessfulCommitmentTaskResult(commitTaskResult),
        )
      ) {
        // show single message if all bids are committed
        showMessages([
          {
            title: 'Successful Commitment',
            message: 'Bids have been successfully committed',
            type: 'success',
          },
        ]);
        return;
      }
      // show messages for every task result
      commitTaskResults.forEach(showTaskResultMessages);
    },
    async reactivateAggregatorBid({ commit, state }) {
      try {
        commit(mutationTypes.START_TRANSACTION);
        const response = await api.get(
          `/buy/api/bid/${state.bid.id}/reactivate`,
        );
        if (response?.messages?.[0]?.data?.bid) {
          commit(mutationTypes.SET_BID_DATA, response.messages[0].data.bid);
        }
      } catch (e) {
        onErrorHandler(e, 'buy-bid-reactivate');
      } finally {
        commit(mutationTypes.COMPLETE_TRANSACTION);
      }
    },
    async deleteAggregatorBid({ commit, state }) {
      try {
        commit(mutationTypes.START_TRANSACTION);
        const response = await api.delete(`/buy/api/bid/${state.bid.id}/`);
        if (response?.messages?.[0]?.data?.bid) {
          commit(mutationTypes.SET_BID_DATA, response.messages[0].data.bid);
        }
      } catch (e) {
        onErrorHandler(e, 'buy-bid-delete');
      } finally {
        commit(mutationTypes.COMPLETE_TRANSACTION);
      }
    },
    async submitAggregatorBid(
      { state, commit },
      { bidPrice, bidBenchmark, bidExpirationDate },
    ) {
      try {
        let url = '/buy/api/bid/';
        let handler = api.post;
        if (state.bid && state.bid.id) {
          url = `/buy/api/bid/${state.bid.id}/`;
          handler = api.patch;
        }
        const benchmarkValues = getBenchmarkValues(state.loanProgram);
        commit(mutationTypes.START_TRANSACTION);
        const response = await handler(url, {
          loan_id: state.loan.id,
          offer: bidPrice,
          benchmark_name: benchmarkValues[bidBenchmark].benchmark_name,
          benchmark_rate: benchmarkValues[bidBenchmark].benchmark_rate,
          expires_at: bidExpirationDate,
        });
        if (response?.messages?.[0]?.data?.bid) {
          commit(mutationTypes.SET_BID_DATA, response.messages[0].data.bid);
        }
      } catch (e) {
        onErrorHandler(e, 'buy-bid-submit');
      } finally {
        commit(mutationTypes.COMPLETE_TRANSACTION);
      }
    },
  },
  mutations: {
    [mutationTypes.SET_LOAN_DETAILS](state, loanDetails) {
      state.loan = loanDetails?.loan;
      state.owner = loanDetails?.owner;
      state.ownerInfo = loanDetails?.owner_info;
      state.bid = loanDetails?.bid;
      state.loanFound = !!state.loan?.id;
      state.own = loanDetails?.own;
      state.hasSellerPartnership = loanDetails?.hasSellerPartnership;
      state.isLoanArchived = loanDetails?.loan?.status === loanStatus.archived;
      state.purchaseAmount = state.loan?.purchase_amount;
      state.soldAt = state.loan?.sold_at;
      state.loanProgram = state.loan?.loan_program || '';
    },
    [mutationTypes.SET_BID_DATA](state, bid) {
      state.bid = bid;
    },
    [mutationTypes.START_TRANSACTION](state) {
      state.isActiveTransactionInProgress = true;
    },
    [mutationTypes.COMPLETE_TRANSACTION](state) {
      state.isActiveTransactionInProgress = false;
    },
    [mutationTypes.SET_LOADING](state, payload) {
      state.isLoading = payload;
    },
  },
  state: () => ({
    loan: null,
    owner: null,
    ownerInfo: null,
    bid: null,
    loanFound: null,
    own: false,
    hasSellerPartnership: false,
    isLoanArchived: false,
    isActiveTransactionInProgress: false,
    purchaseAmount: null,
    soldAt: null,
    loanProgram: '',
    isLoading: false,
  }),
};

export const CLEAR_CURRENT_APPROVAL_GROUP = 'CLEAR_CURRENT_APPROVAL_GROUP';
export const CLEAR_CURRENT_REQUESTOR_GROUP = 'CLEAR_CURRENT_REQUESTOR_GROUP';
export const SET_APPROVAL_GROUP_APPROVERS = 'SET_APPROVAL_GROUP_APPROVERS';
export const SET_APPROVAL_GROUP_IS_ACTIVE = 'SET_APPROVAL_GROUP_IS_ACTIVE';
export const SET_APPROVAL_GROUP_NAME = 'SET_APPROVAL_GROUP_NAME';
export const SET_APPROVAL_GROUP_NOTES = 'SET_APPROVAL_GROUP_NOTES';
export const SET_APPROVERS = 'SET_APPROVERS';
export const SET_CURRENT_APPROVAL_GROUP = 'SET_CURRENT_APPROVAL_GROUP';
export const SET_CURRENT_REQUESTOR_GROUP = 'SET_CURRENT_REQUESTOR_GROUP';
export const SET_HAS_UPDATES = 'SET_HAS_UPDATES';
export const SET_IS_LOADING_APPROVAL_GROUP = 'SET_IS_LOADING_APPROVAL_GROUP';
export const SET_IS_LOADING_APPROVERS = 'SET_IS_LOADING_APPROVERS';
export const SET_IS_LOADING_LOAN_OFFICERS = 'SET_IS_LOADING_LOAN_OFFICERS';
export const SET_IS_LOADING_REQUESTOR_GROUP = 'SET_IS_LOADING_REQUESTOR_GROUP';
export const SET_IS_SAVING_APPROVAL_GROUP = 'SET_IS_SAVING_APPROVAL_GROUP';
export const SET_IS_SAVING_REQUESTOR_GROUP = 'SET_IS_SAVING_REQUESTOR_GROUP';
export const SET_LOAN_OFFICERS = 'SET_LOAN_OFFICERS';
export const SET_REQUESTOR_GROUP_IS_ACTIVE = 'SET_REQUESTOR_GROUP_IS_ACTIVE';
export const SET_REQUESTOR_GROUP_LOAN_OFFICERS =
  'SET_REQUESTOR_GROUP_LOAN_OFFICERS';
export const SET_REQUESTOR_GROUP_NAME = 'SET_REQUESTOR_GROUP_NAME';
export const SET_REQUESTOR_GROUP_NOTES = 'SET_REQUESTOR_GROUP_NOTES';
export const SET_CONNECTED_POLICIES = 'SET_CONNECTED_POLICIES';
export const CLEAR_CONNECTED_POLICIES = 'CLEAR_CONNECTED_POLICIES';

import api from '@shared/services/api.js';

export const getSeedDataCategories = () => {
  return api.get('/devtools/api/v1/seed_data_categories/');
};

export const getSeedDataScenarios = category => {
  const urlParams = category ? { category } : {};
  const url = api.constructUrl(
    '/devtools/api/v1/seed_data_scenarios/',
    urlParams,
  );
  return api.get(url);
};

export const postSeedDataScenarioBulkRun = scenarios => {
  return api.postJson(`/devtools/api/v1/seed_data_scenarios/bulk_run/`, {
    scenarios,
  });
};

export const postSeedDataScenarioRun = scenario => {
  return api.postJson(`/devtools/api/v1/seed_data_scenarios/run/`, scenario);
};

export const getModelOptions = (contentType, filters) => {
  return api.postJson('/devtools/api/v1/model_options/', {
    contentType,
    filters,
  });
};
